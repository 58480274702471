import { Button, IconText } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useExcelUpload from "./useExcelUpload";

const ExcelUpload = () => {
  const {
    formik,
    handleClose,
    handleDocumentUpload,
    handleExcelUpload,
    getFieldError,
  } = useExcelUpload();
  return (
    <>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Excel Upload
        </label>
        <div className="input-drag">
          <input
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            placeholder="placeholder"
            className={`pro-input lg ${
              getFieldError("excel_file") && " error"
            }`}
            id="excel_file"
            onBlur={formik.handleBlur("excel_file")}
            onChange={(e) => handleExcelUpload(e)}
          />

          <span className="input-drag-box" style={{ padding: "50px" }}>
            {formik?.values?.excel_file?.name ? (
              formik?.values?.excel_file?.name
            ) : formik?.values?.excel_file ? (
              formik?.values?.excel_file
            ) : (
              <IconText title={`Drop files to attach or browse`} />
            )}
          </span>
          {getFieldError("excel_file") && (
            <span className="error-text">{getFieldError("excel_file")}</span>
          )}
        </div>
      </div>
      {/* <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Additional Document
        </label>
        <div className="input-drag">
          <input
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            placeholder="placeholder"
            className={`pro-input lg ${
              getFieldError("additional_document") && " error"
            }`}
            id="additional_document"
            onBlur={formik.handleBlur("additional_document")}
            onChange={(e) => handleDocumentUpload(e)}
          />
          <span className="input-drag-box" style={{ padding: "50px" }}>
            {formik?.values?.additional_document?.name ? (
              formik?.values?.additional_document?.name
            ) : formik?.values?.additional_document ? (
              formik?.values?.additional_document
            ) : (
              <IconText title={`Drop files to attach or browse`} />
            )}
          </span>
          {getFieldError("additional_document") && (
            <span className="error-text">
              {getFieldError("additional_document")}
            </span>
          )}
        </div>
      </div> */}
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button className={"pro-btn-link lg pro-px-5"} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {"Submit"}
        </Button>
      </div>
    </>
  );
};

export default ExcelUpload;
