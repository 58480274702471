/* eslint-disable eqeqeq */
import React from "react";
import Style from "../qc.module.scss";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import RemarkCard from "./RemarkCard";
const ShowForm = ({
  formik,
  itemID,
  certificateNumber,
  formData,
  getFieldError,
  statusData,
  imagePreview,
  isNewDesc,
  descriptions,
  handleDescChange,
  handleImageChange,
  handleStatusChange,
  handleAddRemarkkCardClick,
}) => {
  return (
    <div className={`col pro-pt-4 ${Style.compare_wrap}`}>
      <div
        className={`pro-w-100 pro-d-flex pro-flex-column ${Style.compare_inner_wrap}`}
      >
        <h5 className="pro-ttl  h5">{itemID}</h5>
        <h6 className="pro-ttl  h6">{certificateNumber}</h6>
        {/* qc filelds */}
        <>
          <label
            htmlFor="qc_fields"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            QC Fields
          </label>
          {formData?.qc_fields?.map((value, index) => {
            return (
              <React.Fragment key={index}>
                {value?.slug === "color" ||
                value?.slug === "clarity" ||
                value?.slug === "grade" ||
                value?.slug === "finish" ||
                value?.slug === "shape" ? (
                  <div className="input-wrap pro-mb-4" key={index}>
                    <label
                      htmlFor=""
                      className="pro-font-sm pro-mb-1 pro-fw-medium"
                    >
                      {value?.name}
                    </label>
                    <Select
                      id={value?.slug}
                      placeholder={"Select"}
                      className={`pro-input lg  ${
                        getFieldError(`${value?.slug}`) && "error"
                      }`}
                      classNamePrefix="pro-input"
                      name={value?.slug}
                      options={formData?.[value?.slug]}
                      getOptionValue={(option) => option?._id}
                      getOptionLabel={(option) => option?.name}
                      onBlur={formik.handleBlur(`${value?.slug}`)}
                      value={
                        formData?.[value?.slug]?.filter(
                          (val) => val?.name === formik?.values?.[value?.slug]
                        )?.[0] ?? null
                      }
                      onChange={(e) =>
                        formik?.setFieldValue(`${value?.slug}`, e?.name)
                      }
                      menuPlacement="auto"
                    />
                    {getFieldError(`${value?.slug}`) && (
                      <span className="error-text">
                        {getFieldError(`${value?.slug}`)}
                      </span>
                    )}
                  </div>
                ) : value?.slug === "description" ? (
                  <div className="input-wrap pro-mb-4" key={index}>
                    <label
                      htmlFor=""
                      className="pro-font-sm pro-mb-1 pro-fw-medium"
                    >
                      {value?.name}
                    </label>
                    <CreatableSelect
                      isClearable
                      options={descriptions ?? []}
                      id={value?.slug}
                      placeholder="Description"
                      className={`pro-input lg  ${
                        getFieldError(`${value?.slug}`) && "error"
                      }`}
                      classNamePrefix="pro-input"
                      name={value?.slug}
                      onBlur={formik.handleBlur(`${value?.slug}`)}
                      value={
                        isNewDesc
                          ? {
                              label: formik?.values?.[value?.slug],
                              value: formik?.values?.[value?.slug],
                            }
                          : descriptions?.filter(
                              (val) =>
                                val?.label === formik?.values?.[value?.slug]
                            )?.[0] ?? ""
                      }
                      onChange={(e) => {
                        handleDescChange(e, value?.slug);
                      }}
                      menuPlacement="auto"
                    />
                  </div>
                ) : (
                  <>
                    <Input
                      key={index}
                      label={value?.name}
                      type="text"
                      id={value?.slug}
                      name={value?.slug}
                      className={`pro-input lg ${
                        getFieldError(`${value?.slug}`) && " error"
                      }`}
                      onChange={(e) =>
                        formik?.setFieldValue(
                          `${value?.slug}`,
                          e?.target?.value
                        )
                      }
                      onBlur={formik?.handleBlur(value?.slug)}
                      error={getFieldError(value?.slug)}
                      errorMessage={getFieldError(value?.slug)}
                      value={formik?.values?.[value?.slug]}
                    />
                  </>
                )}
              </React.Fragment>
            );
          })}
        </>
        {/* center stone fields */}
        {formData?.cstone_sstone === 1 && (
          <>
            <small>Center Stone Fields</small>
            {formData?.center_stone_fields?.map((value, index) => {
              return (
                <React.Fragment key={index}>
                  {value?.slug === "color" ||
                  value?.slug === "clarity" ||
                  value?.slug === "grade" ||
                  value?.slug === "finish" ||
                  value?.slug === "shape" ? (
                    <div className="input-wrap pro-mb-4" key={index}>
                      <label
                        htmlFor=""
                        className="pro-font-sm pro-mb-1 pro-fw-medium"
                      >
                        {value?.name}
                      </label>
                      <Select
                        id={`center_stone_${value?.slug}`}
                        placeholder={"Select"}
                        className={`pro-input lg  ${
                          getFieldError(`center_stone_${value?.slug}`) &&
                          "error"
                        }`}
                        classNamePrefix="pro-input"
                        name={`center_stone_${value?.slug}`}
                        options={formData?.[value?.slug]}
                        getOptionValue={(option) => option?._id}
                        getOptionLabel={(option) => option?.name}
                        onBlur={formik.handleBlur(
                          `center_stone_${value?.slug}`
                        )}
                        value={
                          formData?.[value?.slug]?.filter(
                            (val) =>
                              val?.name ===
                              formik?.values?.[`center_stone_${value?.slug}`]
                          )?.[0]
                        }
                        onChange={(e) =>
                          formik?.setFieldValue(
                            `center_stone_${value?.slug}`,
                            e?.name
                          )
                        }
                        menuPlacement="auto"
                      />
                      {getFieldError(`center_stone_${value?.slug}`) && (
                        <span className="error-text">
                          {getFieldError(`center_stone_${value?.slug}`)}
                        </span>
                      )}
                    </div>
                  ) : (
                    <Input
                      key={index}
                      label={value?.name}
                      type="text"
                      id={`center_stone_${value?.slug}`}
                      name={`center_stone_${value?.slug}`}
                      className={`pro-input lg ${
                        getFieldError(`${`center_stone_${value?.slug}`}`) &&
                        "error"
                      }`}
                      onChange={(e) =>
                        formik?.setFieldValue(
                          `${`center_stone_${value?.slug}`}`,
                          e?.target?.value
                        )
                      }
                      onBlur={formik?.handleBlur(`center_stone_${value?.slug}`)}
                      error={getFieldError(`center_stone_${value?.slug}`)}
                      errorMessage={getFieldError(
                        `center_stone_${value?.slug}`
                      )}
                      value={formik?.values?.[`center_stone_${value?.slug}`]}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </>
        )}
        {/* center stone fields */}
        {formData?.cstone_sstone === 1 && (
          <>
            <small>Side Stone Fields</small>
            {formData?.side_stone_fields?.map((value, index) => {
              return (
                <React.Fragment key={index}>
                  {value?.slug === "color" ||
                  value?.slug === "clarity" ||
                  value?.slug === "grade" ||
                  value?.slug === "finish" ||
                  value?.slug === "shape" ? (
                    <div className="input-wrap pro-mb-4" key={index}>
                      <label
                        htmlFor=""
                        className="pro-font-sm pro-mb-1 pro-fw-medium"
                      >
                        {value?.name}
                      </label>
                      <Select
                        id={`side_stone_${value?.slug}`}
                        placeholder={"Select"}
                        className={`pro-input lg  ${
                          getFieldError(`side_stone_${value?.slug}`) && "error"
                        }`}
                        classNamePrefix="pro-input"
                        name={`side_stone_${value?.slug}`}
                        options={formData?.[value?.slug]}
                        getOptionValue={(option) => option?._id}
                        getOptionLabel={(option) => option?.name}
                        onBlur={formik.handleBlur(`side_stone_${value?.slug}`)}
                        value={
                          formData?.[value?.slug]?.filter(
                            (val) =>
                              val?.name ===
                              formik?.values?.[`side_stone_${value?.slug}`]
                          )?.[0]
                        }
                        onChange={(e) =>
                          formik?.setFieldValue(
                            `side_stone_${value?.slug}`,
                            e?.name
                          )
                        }
                        menuPlacement="auto"
                      />
                      {getFieldError(`side_stone_${value?.slug}`) && (
                        <span className="error-text">
                          {getFieldError(`side_stone_${value?.slug}`)}
                        </span>
                      )}
                    </div>
                  ) : (
                    <Input
                      key={index}
                      label={value?.name}
                      type="text"
                      id={`side_stone_${value?.slug}`}
                      name={`side_stone_${value?.slug}`}
                      className={`pro-input lg ${
                        getFieldError(`${`side_stone_${value?.slug}`}`) &&
                        "error"
                      }`}
                      onChange={(e) =>
                        formik?.setFieldValue(
                          `${`side_stone_${value?.slug}`}`,
                          e?.target?.value
                        )
                      }
                      onBlur={formik?.handleBlur(`side_stone_${value?.slug}`)}
                      error={getFieldError(`side_stone_${value?.slug}`)}
                      errorMessage={getFieldError(`side_stone_${value?.slug}`)}
                      value={formik?.values?.[`side_stone_${value?.slug}`]}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </>
        )}
        <Input
          label={"Remark"}
          type="text"
          id={"remarks"}
          name={"remarks"}
          className={`pro-input lg ${
            getFieldError(`${"remarks"}`) && " error"
          }`}
          onChange={(e) => formik?.setFieldValue(`remarks`, e?.target?.value)}
          onBlur={formik?.handleBlur("remarks")}
          error={getFieldError("remarks")}
          errorMessage={getFieldError("remarks")}
          value={formik?.values?.remarks}
        />
        {/* Status dropdown */}
        <div className="input-wrap pro-mb-4">
          <label
            htmlFor="status"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Status
          </label>
          <Select
            id="status"
            name="status"
            placeholder={"Select"}
            className={`pro-input lg  ${getFieldError("status") && " error"}`}
            classNamePrefix="pro-input"
            options={statusData ?? []}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
            onBlur={formik.handleBlur("status")}
            isMulti={false}
            onChange={(value) => {
              handleStatusChange(value);
            }}
            menuPlacement="auto"
            value={statusData?.filter(
              (value) => value?.id === formik?.values?.status
            )}
          />
          {getFieldError("status") && (
            <span className="error-text">{getFieldError("status")}</span>
          )}
        </div>

        {/* remark and image */}
        {formik?.values?.status != 2 && formik?.values?.status != "" && (
          <>
            <Input
              label={"Reason"}
              type="text"
              id={"reason"}
              name={"reason"}
              className={`pro-input lg ${getFieldError("reason") && " error"}`}
              onChange={(e) =>
                formik?.setFieldValue(`reason`, e?.target?.value)
              }
              onBlur={formik?.handleBlur("reason")}
              error={getFieldError("reason")}
              errorMessage={getFieldError("reason")}
              value={formik?.values?.reason}
            />
            {formik?.values?.group?.map((_, index) => {
              return (
                <RemarkCard
                  formData={formData}
                  formik={formik}
                  key={index}
                  itmIndex={index}
                />
              );
            })}
            <Button
              className={`pro-btn-primary lg pro-w-100 pro-mb-4`}
              onClick={handleAddRemarkkCardClick}
              type="button"
            >
              {`Add Another`}
            </Button>
            <div className="input-wrap pro-mb-4">
              <label
                htmlFor=""
                className={`pro-font-sm pro-mb-1 pro-fw-medium`}
              >
                Image
              </label>
              <div className="input-drag">
                <input
                  type="file"
                  accept=".jpeg,.jpg , .png "
                  className={`pro-input ${
                    formik.errors.image && formik.touched.image && " error"
                  }`}
                  id="image"
                  onBlur={formik.handleBlur("logo")}
                  onChange={(e) => handleImageChange(e)}
                />
                <span className="input-drag-box">
                  <IconText
                    title={
                      formik?.values?.image?.name ??
                      `Drop files to attach or browse`
                    }
                  />
                </span>
                {formik.touched.image && formik.errors.image && (
                  <span className="error-text">{formik.errors.image}</span>
                )}
              </div>
              <div className={`col-2 pro-my-2`}>
                <div className={``}>
                  <Image
                    width={100}
                    height={100}
                    src={imagePreview}
                    alt={`image - 01`}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        <div
          className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn pro-py-4 ${Style.ftr_btn_wrap}`}
        >
          <Button
            className={"pro-btn-primary lg pro-ms-3"}
            type="submit"
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ShowForm;
