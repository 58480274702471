import {
  ModalLayout,
  Pagination,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import View from "../View";
import AddStatus from "../AddStatus";
import Certificate from "../Certificate";
import Style from "../Packing.module.scss";
import usePackingDetails from "./usePackingDetails";
import PassAll from "../../../Employees/Packing/PassAll";
import RemarksTableRecheck from "../../../Global/RemarksTableRecheck";

const PackingDetails = ({ id }) => {
  const {
    disableMultiSelect,
    isFetching,
    itemId,
    packingList,
    packingState,
    paginationOptions,
    tableFields,
    globalRemarkModal,
    handleModalClose,
    getRow,
    handleCloseViewModal,
    handleConfirm,
    handleConfirmModal,
    handleEditClick,
    handleEditClose,
    handlePageSize,
    handlePagination,
    handleView,
    setPackingTypeId,
    setSubmissionStatus,
    handlePassAll,
    setRemarks,
    handlePassAllRecheckModal,
  } = usePackingDetails({ id });
  return (
    <>
      <div className={`row gx-3 ${Style.multicolumn_wrapper}`}>
        <div className="col">
          <div className="pro-d-flex pro-justify-end">
            <div className={Style.btns}>
              <button
                className={`${Style.Print_btn} pro-btn pro-items-center pro-btn-outline`}
                onClick={handlePassAllRecheckModal}
              >
                <span className="material-symbols-outlined">check_circle</span>
                <span>Pass All</span>
              </button>
            </div>
            <button
              className="pro-btn pro-btn-outline-primary pro-items-center"
              onClick={handleView}
            >
              <span class="material-symbols-outlined">visibility</span>
              <span>View</span>
            </button>
          </div>
          <Table
            multiSelect={disableMultiSelect ? false : true}
            data={packingList?.data?.data || []}
            uniqueID={"id"}
            fields={tableFields}
            showCheckBox={true}
            getRow={getRow}
            perpage={10}
            assignable={true}
            editable={true}
            deletable={false}
            assignText={"Submit"}
            assignIcon={
              <span className="material-symbols-outlined">check_circle</span>
            }
            editIcon={<span className="material-symbols-outlined">badge</span>}
            editText={"View certificate"}
            handleAssign={handleConfirmModal}
            handleEdit={handleEditClick}
            clear={packingState.clear}
            loading={isFetching}
          />
        </div>
      </div>
      {packingList?.data?.data?.length > 0 && (
        <Pagination
          currentPage={packingState?.currentPage}
          totalPageCount={Math.ceil(
            packingList?.data?.total_count / packingState.currentPageSize
          )}
          onPageChange={handlePagination}
          options={paginationOptions}
          onActionChange={handlePageSize}
          center
        />
      )}

      <ModalLayout
        show={packingState?.viewModal}
        handleClose={handleCloseViewModal}
        propStyle={{ root: Style.viewModal }}
      >
        <div className="pro-m-5">
          <View />
        </div>
      </ModalLayout>
      <ModalLayout
        show={packingState?.showAddPackingModal}
        handleClose={handleConfirmModal}
        title={"Choose status of submission?"}
      >
        <div className="pro-m-5">
          <AddStatus
            handleConfirm={handleConfirm}
            handleConfirmModal={handleConfirmModal}
            itemId={itemId}
            setSubmissionStatus={setSubmissionStatus}
            setPackingTypeId={setPackingTypeId}
            setRemarks={setRemarks}
          />
        </div>
      </ModalLayout>
      <ModalLayout
        show={packingState?.showPassAllRecheckModal}
        handleClose={handlePassAllRecheckModal}
        title={"Pass All"}
      >
        <div className="pro-m-5">
          <PassAll
            handlePassAll={handlePassAll}
            itemId={packingState.selectedId}
          />
        </div>
      </ModalLayout>

      <ModalLayout
        show={packingState?.showCertificate}
        handleClose={handleEditClose}
        propStyle={{ root: Style.modal_root }}
        closeIcon={<span className="material-symbols-outlined">close</span>}
        title={" "}
      >
        <div className="pro-m-5">
          <Certificate />
        </div>
      </ModalLayout>
      <ModalLayout
        show={globalRemarkModal}
        handleClose={handleModalClose}
        title={"Remarks"}
        propStyle={{ root: "pro-modal-xxl" }}
        closeIcon={<span className="material-symbols-outlined">close</span>}
      >
        <div className="pro-m-5">
          <RemarksTableRecheck />
        </div>
      </ModalLayout>
    </>
  );
};

export default PackingDetails;
