import { Auth } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useLogin from "./useLogin";
import { useOutletContext } from "react-router-dom";

const Login = () => {
  const { emailRef } = useOutletContext();
  const {
    formik,
    loginStatus,
    showPassword,
    forgotPasswordStatus,
    handleShowPassword,
    handleForgotPassword,
  } = useLogin(emailRef);

  return (
    <>
      <Auth
        title={"Login"}
        buttonText={"Login"}
        handleClick={formik?.handleSubmit}
        actionText={"Forgot Password ?"}
        handleActionClick={handleForgotPassword}
        loading={loginStatus === "pending"}
        actionLoading={forgotPasswordStatus === "pending"}
      >
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
            Username
          </label>
          <input
            name="user_name"
            value={formik.values.user_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="text"
            className={`pro-input lg ${
              formik.errors.user_name &&
              (formik.touched.user_name || formik.values.user_name) &&
              "error"
            } ${
              loginStatus === "pending" || forgotPasswordStatus === "pending"
                ? "disabled"
                : ""
            } `}
          />
          {formik.errors.user_name &&
            (formik.touched.user_name || formik.values.user_name) && (
              <span className="error-text">{formik?.errors?.user_name}</span>
            )}
        </div>

        <div className="input-wrap pro-mb-5 p-re">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Password
          </label>
          <input
            name="password"
            id="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="password"
            autoComplete="on"
            className={`pro-input lg ${
              formik.errors.password && formik.touched.password && "error"
            } ${
              loginStatus === "pending" || forgotPasswordStatus === "pending"
                ? "disabled"
                : ""
            } hide-icon-input`}
          />

          {showPassword ? (
            <button
              type="button"
              className={`hide-icon`}
              onClick={(e) => handleShowPassword(e)}
            >
              <span className="material-symbols-outlined">
                {" "}
                visibility_off{" "}
              </span>
            </button>
          ) : (
            <button
              type="button"
              className={`hide-icon`}
              onClick={(e) => handleShowPassword(e)}
            >
              <span className="material-symbols-outlined">visibility</span>
            </button>
          )}
          {formik.errors.password && formik.touched.password && (
            <span className="error-text">{formik?.errors?.password}</span>
          )}
        </div>
      </Auth>
    </>
  );
};

export default Login;
