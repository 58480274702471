import React from "react";
import RecheckStaffWindow from "../../Global/RecheckStaffWindow";

const StaffRecheck = () => {
  return (
    <div>
      <RecheckStaffWindow />
    </div>
  );
};

export default StaffRecheck;
