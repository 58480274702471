import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useBasic from "./useBasic";

const Basic = ({ formik, getFieldError, formData }) => {
  const {
    isEdit,
    logoPreview,
    handleLogoChange,
    handleCloseModal,
    handleNext,
  } = useBasic(formik);
  return (
    <div className="row">
      <Input
        label={"Branch Name"}
        type="text"
        id="branch_name"
        name="branch_name"
        className={`pro-input lg ${getFieldError("branch_name") && " error"}`}
        {...formik.getFieldProps("branch_name")}
        error={getFieldError("branch_name")}
        errorMessage={getFieldError("branch_name")}
      />
      <Input
        label={"Branch Prefix"}
        type="text"
        id="branch_prefix"
        name="branch_prefix"
        className={`pro-input lg ${getFieldError("branch_prefix") && " error"}`}
        {...formik.getFieldProps("branch_prefix")}
        error={getFieldError("branch_prefix")}
        errorMessage={getFieldError("branch_prefix")}
      />
      <Input
        label={"Branch Email"}
        type="text"
        id="branch_email"
        name="branch_email"
        className={`pro-input lg ${getFieldError("branch_email") && " error"}`}
        {...formik.getFieldProps("branch_email")}
        error={getFieldError("branch_email")}
        errorMessage={getFieldError("branch_email")}
      />
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Branch Type
        </label>
        <Select
          id="branch_type"
          name="branch_type"
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("branch_type") && " error"
          }`}
          classNamePrefix="pro-input"
          options={formData?.branch_type ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          value={formik?.values?.branch_type}
          onBlur={formik.handleBlur("branch_type")}
          onChange={(value) =>
            formik?.setFieldValue("branch_type", value || null)
          }
          menuPlacement="auto"
        />
        {getFieldError("branch_type") && (
          <span className="error-text">{getFieldError("branch_type")}</span>
        )}
      </div>
      <Input
        label={"Serial Number"}
        type="text"
        id="serial_number"
        name="serial_number"
        className={`pro-input lg ${getFieldError("serial_number") && " error"}`}
        {...formik.getFieldProps("serial_number")}
        error={getFieldError("serial_number")}
        errorMessage={getFieldError("serial_number")}
        disabled={isEdit}
      />
      <Input
        label={"GST Number"}
        type="text"
        id="gst_number"
        name="gst_number"
        className={`pro-input lg ${getFieldError("gst_number") && " error"}`}
        {...formik.getFieldProps("gst_number")}
        error={getFieldError("gst_number")}
        errorMessage={getFieldError("gst_number")}
      />
      <Input
        label={"PAN Number"}
        type="text"
        id="pan_number"
        name="pan_number"
        className={`pro-input lg ${getFieldError("pan_number") && " error"}`}
        {...formik.getFieldProps("pan_number")}
        error={getFieldError("pan_number")}
        errorMessage={getFieldError("pan_number")}
      />
      <Input
        label={"SAC Number"}
        type="text"
        id="sac_number"
        name="sac_number"
        className={`pro-input lg ${getFieldError("sac_number") && " error"}`}
        {...formik.getFieldProps("sac_number")}
        error={getFieldError("sac_number")}
        errorMessage={getFieldError("sac_number")}
      />
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Branch Logo
        </label>
        <div className="input-drag">
          <input
            type="file"
            accept=".jpeg,.jpg , .png "
            className={`pro-input ${
              formik.errors.logo && formik.touched.logo && " error"
            }`}
            id="logo"
            onBlur={formik.handleBlur("logo")}
            onChange={(e) => handleLogoChange(e)}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.logo?.name ?? `Drop files to attach or browse`
              }
            />
          </span>
          {formik.touched.logo && formik.errors.logo && (
            <span className="error-text">{formik.errors.logo}</span>
          )}
        </div>
        <div className={`col-2 pro-my-2`}>
          <div className={``}>
            <Image
              width={100}
              height={100}
              src={logoPreview}
              alt={`image - 01`}
            />
          </div>
        </div>
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button className={"pro-btn-primary lg pro-ms-3"} onClick={handleNext}>
          {"Next"}
        </Button>
      </div>
    </div>
  );
};

export default Basic;
