import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Master/Packing/packingSlice";
import * as Yup from "yup";
import { updatePackingData } from "../api";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const useAddPacking = ({ refetch }) => {
  const dispatch = useDispatch();
  const queryState = useSelector((state) => state.packing);

  useEffect(() => {
    if (queryState?.isEdit && queryState?.itemData) {
      formik?.setFieldValue("packing_type", queryState?.itemData?.packing_type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryState?.isEdit, queryState?.itemData]);

  const validation = Yup.object({
    packing_type: Yup.string()
      .trim()
      .required("Enter Name")
      .max(50, "Enter valid Name"),
  });

  const formik = useFormik({
    initialValues: {
      packing_type: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      let data = {
        packing_type: values?.packing_type,
      };
      if (queryState?.isEdit) {
        data = {
          packing_type: values?.packing_type,
          packing_type_id: queryState?.selectedItem,
        };
      }
      updatePackingData(data).then((response) => {
        if (response?.data?.status_code === 200) {
          handleCloseModal();
          toast.success("Successfully Added");
          refetch();
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
        } else {
          toast.error("Something went wrong");
        }
      });
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.showAddPackingModal = false)));
  };
  return {
    formik,
    queryState,
    getFieldError,
    handleCloseModal,
  };
};

export default useAddPacking;
