import { useEffect } from "react";
import { globalRemarkTable } from "../api";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/Global";

const useRemaks = () => {
  const dispatch = useDispatch();
  const slug = localStorage?.getItem("slug");
  const { globalRemarkId, globalRemarkTableData } = useSelector(
    (state) => state.global
  );
  useEffect(() => {
    let data = {
      id: globalRemarkId,
      slug,
    };
    globalRemarkTable(data).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig(
            (state) => (state.globalRemarkTableData = response?.data)
          )
        );
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => (
        <>
          <p className="pro-mb-0">
            {" "}
            {data[field]?.remarks ? "Remarks :" : ""} {data[field]?.remarks}
          </p>
          <p className="pro-mb-0 no-wrap pro-mt-1">
            {data[field]?.created_user ? "User : " : ""}
            {data[field]?.created_user}
          </p>
        </>
      ),
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };
  return {
    globalRemarkTableData,
    getRow,
  };
};

export default useRemaks;
