import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 20,
  sortBy: "",
  sortOrder: "",
  search: "",
  showAddCertificatePrefixModal: false,
  showPassword: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedCertificatePrefix: "",
  isEdit: false,
  certificatePrefixData: [],
  logoPreview: "",
};

const certificatePrefixSlice = createSlice({
  name: "certificatePrefix",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = certificatePrefixSlice.actions;

export default certificatePrefixSlice.reducer;
