import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../../../components/Global/OrderColumn";
import useCertificateList from "./useCertificateList";
import EstimatePdf from "../Print/EstimatePdf";
import Style from "./certificatelist.module.scss";

const CertificateList = () => {
  const {
    isLoading,
    isFetching,
    tableFields,
    estimateRef,
    showEditModal,
    paginationOptions,
    billingReissueState,
    billingReissueCertificateList,
    updateBillingReissueCertificateFields,
    handleReissueCertificate,
    handleEditColumnsClick,
    handleCertificatePrint,
    handlePagination,
    handleClearClick,
    handlePageSize,
    handleSearch,
    handleClose,
    handleSort,
    refetch,
    getRow,
  } = useCertificateList();
  return (
    <div>
      <HeadingGroup title={"Billing Certificate"} className={`pro-mb-4`} />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          showActions={true}
          handleActionClick={handleEditColumnsClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: billingReissueState?.search }}
        />
        <Table
          multiSelect={true}
          data={billingReissueCertificateList?.data?.data || []}
          uniqueID={"id"}
          fields={tableFields}
          SortIcon={<FaSort />}
          assignIcon={
            <span className="material-symbols-outlined">receipt</span>
          }
          handleSort={handleSort}
          clear={billingReissueState.clearSelection}
          getRow={getRow}
          loading={isFetching}
          perpage={billingReissueState?.currentPageSize}
          assignable={true}
          assignText={"Generate Estimate"}
          handleAssign={handleReissueCertificate}
          editable={false}
          deletable={false}
          showCheckBox={true}
        />
        {billingReissueCertificateList?.data?.data?.length > 0 && (
          <Pagination
            currentPage={billingReissueState?.currentPage}
            totalPageCount={Math.ceil(
              billingReissueCertificateList?.data?.total_count /
                billingReissueState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          show={billingReissueState?.showPrintConfirmModal}
          handleClose={handleClose}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure to print the selected certificates ?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleClose}
              submitText={"Yes"}
              submitAction={handleCertificatePrint}
              isRight={true}
            />
          </div>
        </ModalLayout>

        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={billingReissueCertificateList?.data?.fields}
              moduleId={billingReissueCertificateList?.data?.module_id}
              updateData={updateBillingReissueCertificateFields}
            />
          </div>
        </ModalLayout>
        <div className={Style.pdf_container}>
          <EstimatePdf estimateRef={estimateRef} />
        </div>
      </div>
    </div>
  );
};

export default CertificateList;
