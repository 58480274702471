import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useFilterForm from "./useFilterForm";
import Select from "react-select";

const FilterForm = () => {
  const { formik, filterFormData, handleCloseModal, getFieldError } =
    useFilterForm();
  return (
    <div className="col">
      <div className={`pro-p-4`}>
        <div>
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Item type
            </label>
            <Select
              id="itemType"
              name="itemType"
              isMulti={true}
              placeholder={"Select"}
              className={`pro-input lg  ${
                getFieldError("itemType") && " error"
              }`}
              classNamePrefix="pro-input"
              options={filterFormData?.item_types ?? []}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?._id}
              value={formik?.values?.itemType}
              onChange={(value) => {
                formik?.setFieldValue("itemType", value);
              }}
              menuPlacement="auto"
            />
          </div>
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Shop
            </label>
            <Select
              id="shop"
              name="shop"
              isMulti={true}
              placeholder={"Select"}
              className={`pro-input lg  ${getFieldError("shop") && " error"}`}
              classNamePrefix="pro-input"
              options={filterFormData?.shops ?? []}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?._id}
              value={formik?.values?.shop}
              onChange={(value) => {
                formik?.setFieldValue("shop", value);
              }}
              menuPlacement="auto"
            />
          </div>
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Priority
            </label>
            <Select
              id="priority"
              name="priority"
              isMulti={true}
              placeholder={"Select"}
              className={`pro-input lg  ${
                getFieldError("priority") && " error"
              }`}
              classNamePrefix="pro-input"
              options={filterFormData?.status ?? []}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?.id}
              value={formik?.values?.priority}
              onChange={(value) => {
                formik?.setFieldValue("priority", value);
              }}
              menuPlacement="auto"
            />
          </div>
        </div>
        <div
          className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
        >
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>

          <Button
            className={"pro-btn-primary lg pro-ms-3"}
            type="submit"
            onClick={formik.handleSubmit}
          >
            {"Filter"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FilterForm;
