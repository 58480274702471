import React from "react";
import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
  OffCanvasLayout,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../../components/Global/OrderColumn";
import useReport from "./useReport";
import AddReport from "./AddReport";

const Report = () => {
  const {
    reportState,
    showEditModal,
    tableFields,
    reportList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    handleDelete,
    handleDeleteModal,
    handleEditAction,
    handleSearch,
    updateReportFields,
    handleClearClick,
    handlePageSize,
    refetch,
  } = useReport();
  return (
    <div>
      <HeadingGroup
        title={"Report"}
        className={`pro-mb-4`}
        buttonTitle={reportList?.data?.create_permission && "Add new"}
        handleClick={handleModalClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          showActions={true}
          handleActionClick={handleEditColumnsClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: reportState?.search }}
        />
        <Table
          multiSelect={false}
          data={reportList?.data?.data || []}
          uniqueID={"_id"}
          fields={tableFields}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          clear={reportState.clearSelection}
          perpage={reportState?.currentPageSize}
          assignable={false}
          handleEdit={handleEditAction}
          handleDelete={handleDeleteModal}
          deletable={reportList?.data?.delete_permission}
          editable={reportList?.data?.edit_permission}
          showCheckBox={true}
        />
        {reportList?.data?.data?.length > 0 && (
          <Pagination
            currentPage={reportState?.currentPage}
            totalPageCount={Math.ceil(
              reportList.data.total_count / reportState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          show={reportState?.showDeleteModal}
          handleClose={handleDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure delete the selected branch ?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteModal}
              submitText={"Yes"}
              submitAction={handleDelete}
              isRight={true}
            />
          </div>
        </ModalLayout>

        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={reportList?.data?.fields}
              moduleId={reportList?.data?.module_id}
              updateData={updateReportFields}
            />
          </div>
        </ModalLayout>
        <OffCanvasLayout
          show={reportState?.showAddReportModal}
          handleClose={handleModalClick}
          title={reportState?.isEdit ? "Update Report" : "Add Report"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
        >
          <AddReport refetch={refetch} reportState={reportState} />
        </OffCanvasLayout>
      </div>
    </div>
  );
};

export default Report;
