import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";

const PassAll = ({ formik, handleSubmit, handleCancel }) => {
  return (
    <div className="row pro-m-2 ">
      <div className="input-wrap">
        <Input
          label={"Remarks"}
          type="text"
          id="remark"
          name="remark"
          className={`pro-input lg ${
            formik?.errors?.remark && formik?.touched?.remark && "error"
          }`}
          value={formik?.values?.remark}
          onChange={(e) => formik?.setFieldValue("remark", e.target.value)}
        />
        {formik?.touched?.remark && formik.errors?.remark && (
          <span className="error-text">{formik.errors?.remark}</span>
        )}
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end pro-my-3  ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCancel}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default PassAll;
