import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useAddDepartment from "./useDepartment";

const AddDepartment = (refetch) => {
  const { formik, getFieldError, handleCloseModal, departmentState,departmentSelect } =
    useAddDepartment(refetch);
  return (
    <div className="row pro-p-4">
      <Input
        label={"Name"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${getFieldError("name") && " error"}`}
        {...formik.getFieldProps("name")}
        error={getFieldError("name")}
        errorMessage={getFieldError("name")}
      />
         <div className="input-wrap pro-mb-4">
        <label htmlFor="color" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Process 
        </label>
        <Select
          id="process"
          name="process"
          isMulti={true}
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("process") && " error"}`}
          classNamePrefix="pro-input"
          options={departmentSelect?.data?.balanced_process_list}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?._id}
          value={formik?.values?.process}
          onBlur={formik.handleBlur("process")}
          onChange={(value) => formik?.setFieldValue("process", value || null)}
          menuPlacement="auto"
        />
        {getFieldError("process") && (
          <span className="error-text">{getFieldError("process")}</span>
        )}
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {departmentState?.isEdit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default AddDepartment;
