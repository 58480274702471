import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Reports/reportsSlice";
import { useSelector } from "react-redux";
import { useGetGLIReportDetailsTableDataQuery } from "../../../../store/queries/Reports";
import { useLocation } from "react-router-dom";
import { subDays } from "date-fns";

const useGLIDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const id = searchParams.get("id");
  const activeTab = searchParams.get("activeTab");
  const ID = id ?? sessionStorage?.getItem("active-gli");

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const reportState = useSelector((state) => state.reports);

  // eslint-disable-next-line no-unused-vars
  const [date, setDate] = useState({
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
    key: "selection",
  });

  const startDate = new Date(date.startDate).toISOString().split("T")[0];
  const endDate = new Date(date.endDate).toISOString().split("T")[0];
  const initialFormattedDate = [startDate, endDate];
  const [formattedDate, setFormattedDate] = useState(initialFormattedDate);

  const {
    data: reportDetailData = {},
    isFetching,
    isLoading,
  } = useGetGLIReportDetailsTableDataQuery({
    receipt_id: ID,
    date: formattedDate,
    tab_type:
      activeTab === "Passed"
        ? 1
        : activeTab === "Marked"
        ? 2
        : activeTab === "Rejected"
        ? 3
        : 4,
    search: reportState?.GLIReport?.details?.search,
    per_page: reportState?.GLIReport?.details?.currentPageSize,
    page: reportState?.GLIReport?.details?.currentPage,
    sort_by: reportState?.GLIReport?.details?.sortBy,
    sort_order: reportState?.GLIReport?.details?.sortOrder,
  });

  useEffect(() => {
    dispatch(
      updateConfig((state) => (state.GLIReport.details.activeTab = activeTab))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const navigation = [
    {
      label: "Passed",
      title: "Passed",
      link: `/report/gli/detail?id=${ID}&activeTab=Passed`,
      active: activeTab.includes("Passed"),
    },
    {
      label: "Marked",
      title: "Marked",
      link: `/report/gli/detail?id=${ID}&activeTab=Marked`,
      active: activeTab.includes("Marked"),
    },
    {
      label: "Rejected",
      title: "Rejected",
      link: `/report/gli/detail?id=${ID}&activeTab=Rejected`,
      active: activeTab.includes("Rejected"),
    },
    {
      label: "Employee",
      title: "Employee",
      link: `/report/gli/detail?id=${ID}&activeTab=Employee`,
      active: activeTab.includes("Employee"),
    },
  ];

  const handleDateChange = (date) => {
    const startDateUTC = new Date(date.startDate);
    const endDateUTC = new Date(date.endDate);
    const timeZoneOffset = 5.5 * 60 * 60 * 1000;
    const startDateIST = new Date(startDateUTC.getTime() + timeZoneOffset);
    const endDateIST = new Date(endDateUTC.getTime() + timeZoneOffset);

    const formatDate = (date) => {
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    const startDateFormatted = formatDate(startDateIST);
    const endDateFormatted = formatDate(endDateIST);
    const dateArray = [startDateFormatted, endDateFormatted];
    setFormattedDate(dateArray);
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.GLIReport.details.search = e.target.value;
        state.GLIReport.details.currentPage = 1;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.GLIReport.details.currentPageSize = page_size;
        state.GLIReport.details.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.GLIReport.details.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (reportState.GLIReport.details.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.GLIReport.details.currentPage = 1;
          state.GLIReport.details.sortOrder =
            reportState.GLIReport.details.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.GLIReport.details.currentPage = 1;
          state.GLIReport.details.sortBy = label;
          state.GLIReport.details.sortOrder = "asc";
        })
      );
    }
  };

  return {
    date,
    isLoading,
    reportState,
    isFetching,
    reportDetailData,
    paginationOptions,
    tabNavs: navigation,
    getRow,
    handleSort,
    handleSearch,
    handlePageSize,
    handleDateChange,
    handlePagination,
  };
};

export default useGLIDetails;
