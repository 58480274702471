import React from "react";
import { Outlet } from "react-router-dom";
import CommonLayout from "../../pages/Layouts/CommonLayout";
import useMenus from "../Global/useMenus";


const Orders = () => {
  const { drawerMenu } = useMenus();
  return (
    <CommonLayout drawerMenu={drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default Orders;
