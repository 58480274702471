import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  updateConfig,
  getDeliveryRecheckListData,
} from "../../../store/slices/Delivery/deliverySlice";
import { debounce } from "../../../utils/hooks/useDebounce";
import { startTimerRecheck } from "../api";
import { toast } from "react-toastify";
// import { getDeliveryCertificate } from "./api";

const useDeliveryRecheck = () => {
  const deliveryState = useSelector((state) => state.delivery);
  const deliveryRecheckData = deliveryState.deliverySectionRecheckData;
  const [showDetail, setShowDetail] = useState(false);
  const [activeCard, setActiveCard] = useState({ id: "", key: "" });
  const [pendingPage, setPendingPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [gliNumber, setGliNumber] = useState("");
  // const [certificateListData, setCertificateList] = useState();
  const dispatch = useDispatch();
  const slipRef = useRef(null);
  const chellanRef = useRef(null);
  const slug = localStorage?.getItem("slug");

  useEffect(() => {
    let params = {
      page: pendingPage,
      // per_page: deliveryState.currentPageSize,
      // search: deliveryState.search,
      process_slug: slug,
    };
    dispatch(getDeliveryRecheckListData(params));

    // eslint-disable-next-line
  }, [pendingPage, deliveryState.currentPageSize]);

  const handleSearchInput = (e) => {
    const searchValue = e.target.value;
    dispatch(
      updateConfig((state) => {
        state.deliverySectionRecheckData.Pending = [];
      })
    );
    let params = {
      page: 1,
      per_page: deliveryState.currentPageSize,
      search: searchValue,
      process_slug: slug,
    };
    dispatch(getDeliveryRecheckListData(params));
  };

  const handleSearch = debounce(handleSearchInput, 1000);

  // const handleSearch = (e) => {
  //   dispatch(
  //     updateConfig((state) => {
  //       state.search = e.target.value;
  //       state.currentPage = 1;
  //     })
  //   );
  // };

  const startTimerDelivery = (item) => {
    let body = {
      receipt_id: item?._id,
      process_slug: slug,
    };
    startTimerRecheck(body).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Timer Started");
      } else {
        toast.error("Can't Start Timer");
      }
    });
  };

  const handleChange = (item, key, index) => {
    setActiveCard({ id: item?._id, key: key });
    dispatch(
      updateConfig((state) => {
        state.selectedRecheckCardId = item?._id;
      })
    );
    setGliNumber(item?.gli_number);
    setActiveCard({ id: item?._id, key: "Pending" });
    if (key === "Pending") {
      setShowDetail(true);
      startTimerDelivery(item);
      // getDeliveryCertificate(item?._id, slug).then((response) => {
      //   const certificateList = response?.data;
      //   setCertificateList(certificateList);
      // });
    } else {
      setShowDetail(false);
      dispatch(
        updateConfig((state) => {
          state.receipt_id = item?._id;
        })
      );
    }
  };
  const handleLoadMore = (mouseHover) => {
    if (
      mouseHover === "Pending" &&
      deliveryRecheckData?.Pending?.length !== deliveryState?.total?.Pending
    ) {
      setPendingPage(pendingPage + 1);
    }
  };

  return {
    gliNumber,
    showDetail,
    isLoading,
    activeCard,
    totalLength: {
      Pending: deliveryState.total.Pending,
    },
    apiStatus: {
      Pending: deliveryState.deliveryRecheckSectionDataStatus,
    },
    deliveryRecheckData,
    // certificateListData,
    handleSearch,
    setIsLoading,
    handleLoadMore,
    handleChange,
    // setCertificateList,
    slipRef,
    chellanRef,
  };
};

export default useDeliveryRecheck;
