import QRCode from "react-qr-code";
import Assets from "../../../../../assets/Assets";
import { useCardPrint } from "./useCardPrint";
import Barcode from "react-barcode";

export const PaperStaticA = ({value}) => {
  
  const { renderText } = useCardPrint();
  const arrayItems = value?.data?.graded_values;

  const topArray = arrayItems?.slice(0,3);
  const middleArray = arrayItems?.slice(3)?.filter(item=>
    item?.slug !== "comments" && 
    item?.slug !== "conclusion");
  const bottomArray = arrayItems?.filter(item=>
    item?.slug === "comments" || 
    item?.slug === "conclusion");
    const bg_img = value?.background_image;
    const outerWrapperStyle = {
    ...styles.outer_wrapper,
    backgroundImage: bg_img ? `url(${bg_img})` : 'none',
  };
  return (
    <>
      <div style={outerWrapperStyle}>
        <div style={styles.header_wrap}>
             <img style={styles.header_img} src={value?.front_top_image} alt="Header"/>
             {parseInt(value?.qr_and_image_format) === 2 && value?.qr && (
              <span style={styles.qr_wrap}>
                <QRCode
                  value={value?.qr}
                  width={70}
                  height={70}
                  style={styles.qr_code}
                />
              </span>
            )}
        </div>

        <div style={styles.lab_report_wrap}>
            <div style={styles.barcode_title}>
              <div style={styles.col}>
                <h4 style={styles.report_title}>LAB REPORT</h4>
              </div>
              <div style={styles.col}>
                <div style={styles.barcode_wrap}>
                    {value?.certificate_number && (
                          <Barcode
                          value={value?.certificate_number}
                          height={15}
                          width={0.5}
                          displayValue={false}
                          margin={0}
                          />
                      )}
                </div>
              </div>
            </div>

            {/* <h4 style={styles.report_title}>LAB REPORT</h4> */}
            <div style={styles.certificate_num}>
              <div style={styles.row}>
                  <div style={styles.col_40}>
                      <div style={styles.row}>
                          <span style={styles.certi_key}>
                          {value?.bold === 1 ? (
                            <b style={{fontWeight : "600"}}>CERTIFICATE NO</b>
                          ) : "CERTIFICATE NO"}
                          </span>
                          <span style={styles.certi_separate}>:</span>
                          <span style={styles.certi_value}>
                          {value?.bold === 1 ? (
                            <b style={{fontWeight : "600"}}>{value?.certificate_number}</b>
                          ): value?.certificate_number}
                          </span>
                      </div>  
                  </div>
                  <div style={styles.col_60}>
                      <strong style={styles.certi_info}>THIS Document Contains Valuable Data so keep in a secure Area</strong>
                  </div>
              </div>
            </div>
        </div>

        <div style={styles.body}>
          <div style={styles.top_wrap}>
          {/* <div style={styles.barcode_wrap}>
            {value?.certificate_number && (
                  <Barcode
                  value={value?.certificate_number}
                  height={15}
                  width={0.5}
                  displayValue={false}
                  margin={0}
                  />
              )}
          </div> */}
          {topArray?.map((item,index)=>(
            <>
              <div style={styles.row} key={index}>
                <p style={styles.key}>
                  {item?.bold === 1  ?(
                    <b style={{fontWeight : "600"}}>{renderText(item?.text, item?.key_font_case)}</b>
                  ):renderText(item?.text, item?.key_font_case)}
                </p>
                <p style={styles.separate}>:</p>
                <p style={styles.value}>
                  {item?.bold === 1 ? (
                    <b style={{fontWeight : "600"}}>{renderText(item?.value, item?.value_font_case)}</b>
                  ) : renderText(item?.value, item?.value_font_case)}
                </p>
              </div>
            
            </>
            ))}
          </div>
          <div style={styles.middle_wrap}>
            <div style={styles.content_wrap}>
            {middleArray?.map((item, index)=>(
              <div style={styles.row} key={index}>
                <p style={styles.key}>
                  {item?.bold === 1  ?(
                    <b style={{fontWeight : "600"}}>{renderText(item?.text, item?.key_font_case)}</b>
                  ):renderText(item?.text, item?.key_font_case)}
                </p>
                <p style={styles.separate}>:</p>
                <p style={styles.value}>
                  {item?.bold === 1 ? (
                    <b style={{fontWeight : "600"}}>{renderText(item?.value, item?.value_font_case)}</b>
                  ) : renderText(item?.value, item?.value_font_case)}
                </p>
              </div>
             
            ))}
             
            </div>
            <div style={styles.img_wrap}>
                <img style={styles.main_img} src={value?.image} alt="Card images" />
            </div>
          </div>
          <div style={styles.bottom_wrap} className="bottomsec">
          {bottomArray?.map((item, index)=>(
            <div style={styles.row} key={index}>
              <p style={styles.key}>
                {item?.bold === 1  ?(
                    <b style={{fontWeight : "600"}}>{renderText(item?.text, item?.key_font_case)}</b>
                  ):renderText(item?.text, item?.key_font_case)}
              </p>
              <p style={styles.separate}>:</p>
              <p style={styles.value}>
                {item?.bold === 1 ? (
                    <b style={{fontWeight : "600"}}>{renderText(item?.value, item?.value_font_case)}</b>
                  ) : renderText(item?.value, item?.value_font_case)}
              </p>
            </div>
          
          ))}
          </div>
        </div>
        <div style={styles.footer_wrap}>
            {/* <img style={styles.footer_img} src={Assets.PDF_FOOTER} alt="Card footer"/> */}
            <img style={styles.footer_img} src={value?.front_foot_image} alt="Card footer"/>
        </div>
      </div>
    </>
  );
};

const styles = {
  outer_wrapper:  {
    position:'relative',
    width  :'100%',
    minWidth : 760,
    maxWidth : 760,
    fontSize: 15,
    color : "#000000",
    backgroundColor: "#ffffff",
    overflow : "hidden",
    fontFamily: "'Graphik',san-serif",
  },
  header_wrap : {
    display : "flex",
    alignItems : "center",
    maxWidth : '100%',
    maxHeight : 112,
    // height : '1.2cm'
    width : '100%',
    position : 'relative',
  },
  header_img :{
    // maxWidth : 321,
    width : '100%',
    maxHeight : 112,
    objectFit : "contain",
    // height : 47.24,
  },
  qr_wrap : {
    width : 70, 
    height : 70, 
    display : "inline-block", 
    position : "absolute",  
    top : 20,
    right : 30,
  },
  qr_code : {
    width: 70,
    height: 70,
  },
  barcode_title:{
    display:'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding:'10px 0 15px'
  },
  
  barcode_wrap:{
    textAlign:'right'
  },
  footer_wrap : {
    // height : 14,
    // height : '0.8cm'
    maxHeight:365,
    height:365,
    width : '100%',
    display : "flex",
    justifyContent:'center',
    alignItems:'center',
    position:'relative',
    paddingBottom:10,
    borderBottom: '1px solid #ccc',
    // bottom:20,
    // left:20,
    //right:20
  },
  footer_img :{
    width : '100%',
    maxHeight:365,
    // maxHeight : 15,
    // height : 15,
  },
  body: {
    // height : 141.76,
    // maxHeight : 141.76,
    padding : '1px 10px',
    height:530
  },
  middle_wrap: {
    display : 'flex',
    padding : '1px 0',
    marginTop:20,
    marginBottom:20
  },
  row :{
    display : 'flex',
    padding : '0.8px 0px',
    fontSize: 16
  },
  col:{
    // width:'50%',
    padding : '0px 15px'
  },
  col_40:{
    width:'40%',
    
  },
  col_60:{
    width:'60%',
  },
  certi_key: {
    textTransform: 'uppercase',
    flex : "1",
    verticalAlign: "bottom",
    fontWeight: 400,
    lineHeight: "1.1",
    marginBottom:0,
    fontSize : 15,
    letterSpacing:'0.5px',
  },
  certi_value: {
    textTransform: 'uppercase',
    flex : "1",
    verticalAlign: "bottom",
    fontWeight: 400,
    lineHeight: "1.1",
    marginBottom:0,
    fontSize : 15,
    letterSpacing:'0.5px',
  },
  certi_separate: {
    width: 10,
    paddingRight: 4,
    paddingLeft: 4,
    fontWeight : 400,
    fontSize:  15,
    lineHeight: "1.1",
    marginBottom:0,
  },
  certi_info: {
    textTransform: 'uppercase',
    fontWeight:600,
    fontSize:11,
    display:'block',
    textAlign:'right'
  },
  key: {
    width:165,
    fontSize: 15,
    fontWeight: 400,
    lineHeight: "1.3",
    marginBottom:8,
    paddingRight:10,
  },
  value: {
    flex : "1",
    verticalAlign: "top",
    fontWeight: 400,
    lineHeight: "1.3",
    marginBottom:8,
    fontSize : 15,
    paddingLeft:15
  },
  separate: {
    width: 20,
    paddingRight: 4,
    paddingLeft: 4,
    fontWeight : 400,
    fontSize:  15,
    lineHeight: "1.1",
    textAlign:'center',
    marginBottom:0,
  },
  content_wrap :{
    flex : "1",
  },
  img_wrap : {
    maxWidth : 290,
    width:'100%',
  },
  main_img :{
    // width: '100%',
    display:'block',
    maxWidth : '100%',
    height : 170,
    maxHeight : 170,
    objectFit: 'contain',
    marginLeft: 'auto',
    marginRight:'auto'
  },
  lab_report_wrap:{
    position:'relative',
    marginBottom:20,
  },
  report_title: {
    fontSize: 15,
    fontWeight: 400,
    lineHeight: "1",
    margin:0,
    textAlign:'left'
  },
  certificate_num: {
    backgroundColor: '#18a3f3',
    color:'#fff',
    fontWeight: 400,
    padding: '10px 20px',
  },
  bottom_wrap:{
    paddingBottom:40
  } 
};
