import { getAxiosInstance } from "../../../../api";

export const getEstimate = async (body) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(
      `/back-office-billing/reissue/generate-estimate`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
