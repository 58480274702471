import { getAxiosInstance } from "../../../../api";

export const getEstimate = async (id) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(
      `/back-office-billing/print-estimate?estimate_id=${id}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getInvoice = async (id) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(
      `/back-office-billing/print-invoice?invoice_id=${id}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getReceipt = async (id) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(
      `/back-office-billing/payment-receipt?invoice_id=${id}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const updateInvoice = async (body) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`/back-office-billing/invoice-update`, body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getEditedData = async (id, pageSize, currentPage) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(
      `/back-office-billing/recheck/estimate-details?estimate_id=${id}&per_page=${
        pageSize ?? 20
      }&current_page=${currentPage ?? 1}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
