import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 20,
  currentReissuePage: 1,
  currentReissuePageSize: 20,
  showPrintConfirmModal: false,
  sortBy: "",
  sortOrder: "",
  search: "",
  selectedId: "",
  selectedIds: [],
  estimatePrintData: [],
};

const billingReissueSlice = createSlice({
  name: "billingReissue",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = billingReissueSlice.actions;

export default billingReissueSlice.reducer;
