import React from "react";
import { useSelector } from "react-redux";

const EstimatePdf = React.forwardRef(({ estimateRef }, ref) => {
  const { estimatePrintData } = useSelector((state) => state.billingReissue);

  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();
  const todayDate = `${dd}.${mm}.${yyyy}`;
  // const data = estimatePrintData?.estimate_details?.slice(0, -1);
  const data = estimatePrintData?.estimate_details?.filter(function (item) {
    return item.slug !== "total";
  });

  const total = estimatePrintData?.estimate_details?.filter(function (item) {
    return item.slug === "total";
  });

  return (
    <div ref={estimateRef}>
      <table
        border={0}
        cellSpacing={0}
        role="presentation"
        cellPadding={0}
        style={{
          backgroundColor: "#ffffff",
          maxWidth: "364px",
          width: "100%",
          borderCollapse: "collapse",
          margin: "0 auto",
          fontSize: 8,
        }}
      >
        <tbody>
          {/* <tr>
            <td>
              <table
                align="center"
                border={0}
                cellSpacing={0}
                role="presentation"
                cellPadding={0}
                width="100%"
                style={{
                  width: "100%",
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ width: 36 }}>
                      <img
                        src={Assets.STOCK_PRINT_LOGO}
                        alt="logo"
                        width={36}
                        height={36}
                        style={{
                          width: 36,
                          height: 36,
                          minWidth: 36,
                          maxWidth: 36,
                        }}
                      />
                    </td>
                    <td
                      style={{
                        verticalAlign: "top",
                      }}
                    >
                      <table
                        align="center"
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        width="100%"
                        style={{
                          borderCollapse: "collapse",
                          backgroundColor: "#ffffff",
                          boxSizing: "border-box",
                          width: "auto",
                          marginLeft: "100px",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td style={{ width: 91 }}>
                              <table
                                align="center"
                                border={0}
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                width="100%"
                                style={{
                                  borderCollapse: "collapse",
                                  backgroundColor: "#000000",
                                  boxSizing: "border-box",
                                  color: "#ffffff",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        fontSize: 8,
                                        textTransform: "uppercase",
                                        fontWeight: 600,
                                        padding: "2px 6px",
                                      }}
                                    >
                                      ROUGH ESTIMATE
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr> */}
          {/* <tr>
            <td>
              <table>
                <tbody>
                  <tr>
                    <td style={{ width: 48, paddingRight: 34 }}>
                      <img
                        src={estimatePrintData?.branch?.branch_logo}
                        alt="logo"
                        width={48}
                        height={48}
                        style={{
                          width: 48,
                          height: 48,
                          minWidth: 48,
                          maxWidth: 48,
                        }}
                      />
                    </td>
                    <td>
                      <table
                        align="center"
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        width="100%"
                        style={{
                          borderCollapse: "collapse",
                          backgroundColor: "#ffffff",
                          boxSizing: "border-box",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                fontSize: 15,
                                fontWeight: 800,
                                textAlign: "center",
                                lineHeight: "28px",
                              }}
                            >
                              {estimatePrintData?.branch?.branch_name}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontSize: 8,
                                fontWeight: 600,
                                textAlign: "center",
                              }}
                            >
                              {
                                estimatePrintData?.branch?.branch_address
                                  ?.address_line2
                              }
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontSize: 8,
                                fontWeight: 600,
                                textAlign: "center",
                                paddingBottom: 10,
                              }}
                            >
                              {estimatePrintData?.branch?.country_code}-
                              {estimatePrintData?.branch?.contact_number_1},
                              {estimatePrintData?.branch?.contact_number_2 && (
                                <>
                                  {estimatePrintData?.branch?.country_code}-
                                  {estimatePrintData?.branch?.contact_number_2}
                                </>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr> */}

          <tr>
            <td style={{ padding: "0px 115px 8px" }}>
              <table
                align="center"
                border={0}
                cellSpacing={0}
                role="presentation"
                cellPadding={0}
                width="100%"
                style={{
                  borderCollapse: "collapse",
                  backgroundColor: "#000000",
                  boxSizing: "border-box",
                  color: "#ffffff",
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                        fontSize: 8,
                        textTransform: "uppercase",
                        fontWeight: 600,
                        padding: "2px 0",
                      }}
                    >
                      ROUGH ESTIMATE
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td
              style={{
                padding: "15px 0",
              }}
            >
              <table
                border={0}
                cellSpacing={0}
                role="presentation"
                cellPadding={0}
                style={{
                  width: "100%",
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        verticalAlign: "top",
                      }}
                    >
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                      >
                        <tbody>
                          {/* <tr>
                        <td>R.No</td>
                        <td
                          style={{
                            width: 6,
                            padding: "0px 2px",
                            fontWeight: 500,
                          }}
                        >
                          :
                        </td>
                        <td
                          style={{
                            fontWeight: 600,
                          }}
                        >
                          {printData?.gli_number}
                        </td>
                      </tr> */}
                          <tr>
                            <td
                              style={{
                                width: 66,
                              }}
                            >
                              Estimate No.
                            </td>
                            <td
                              style={{
                                width: 6,
                                padding: "0px 2px",
                                fontWeight: 500,
                              }}
                            >
                              :
                            </td>
                            <td style={{ paddingLeft: "2px", fontWeight: 500 }}>
                              {estimatePrintData?.estimate_number}
                            </td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                width: 18,
                              }}
                            >
                              GLI.No
                            </td>
                            <td
                              style={{
                                width: 6,
                                padding: "0px 2px",
                                fontWeight: 500,
                              }}
                            >
                              :
                            </td>
                            <td style={{ paddingLeft: "2px", fontWeight: 500 }}>
                              {estimatePrintData?.gli_number}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td>
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          marginLeft: "auto",
                          marginRight: 0,
                        }}
                      >
                        <tbody>
                          <tr>
                            <td>Date</td>
                            <td
                              style={{
                                width: 6,
                                padding: "2px 2px",
                                fontWeight: 500,
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                              }}
                            >
                              {todayDate}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td
              style={{
                paddingBottom: 20,
              }}
            >
              <table
                border={0}
                cellSpacing={0}
                role="presentation"
                cellPadding={0}
                style={{
                  width: "100%",
                  verticalAlign: "top",
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        paddingRight: 8,
                      }}
                    >
                      <table>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                width: 56,
                                verticalAlign: "top",
                                padding: "6px 0",
                              }}
                            >
                              Supplier Name
                            </td>
                            <td
                              style={{
                                padding: "6px 4px",
                                fontWeight: 500,
                                width: 10,
                                verticalAlign: "top",
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                                padding: "6px 0",
                              }}
                            >
                              {estimatePrintData?.supplier?.name},
                              {
                                estimatePrintData?.supplier?.address
                                  ?.address_line1
                              }
                              ,
                              {
                                estimatePrintData?.supplier?.address
                                  ?.address_line2
                              }
                              ,
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td
                      style={{
                        paddingLeft: 8,
                      }}
                    >
                      <table>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                width: 42,
                                verticalAlign: "top",
                                padding: "6px 0",
                              }}
                            >
                              Shop Name
                            </td>
                            <td
                              style={{
                                padding: "6px 4px",
                                fontWeight: 500,
                                width: 10,
                                verticalAlign: "top",
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                                padding: "6px 0",
                              }}
                            >
                              {estimatePrintData?.shop?.name},
                              {estimatePrintData?.shop?.address?.address_line1},
                              {estimatePrintData?.shop?.address?.address_line2},
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  {/* removed as per client */}
                  {/* <tr>
                    <td
                      style={{
                        width: "50%",
                        paddingRight: 8,
                      }}
                    >
                      <table>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                width: 56,
                                verticalAlign: "top",
                                padding: "6px 0",
                              }}
                            >
                              Bill To
                            </td>
                            <td
                              style={{
                                padding: "6px 4px",
                                fontWeight: 500,
                                width: 10,
                                verticalAlign: "top",
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                                padding: "6px 0",
                              }}
                            >
                              {estimatePrintData?.bill_to?.shop_name},
                              {estimatePrintData?.bill_to?.address_line1},
                              {estimatePrintData?.bill_to?.address_line2},
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td></td>
                  </tr> */}
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td>
              <table
                border={0}
                cellSpacing={0}
                role="presentation"
                cellPadding={0}
                style={{
                  width: "100%",
                }}
              >
                <tbody>
                  <tr>
                    <td>
                      <table
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                          border: "0.5px solid #C8C8C8",
                          borderRadius: 1,
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                fontWeight: 500,
                                fontSize: 7,
                                backgroundColor: "#FDFEFF",
                                borderBottom: "0.5px solid #C8C8C8",
                                borderRight: "0.5px solid #C8C8C8",
                                textAlign: "left",
                              }}
                            >
                              Sl. No.
                            </th>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                fontWeight: 500,
                                fontSize: 7,
                                backgroundColor: "#FDFEFF",
                                borderBottom: "0.5px solid #C8C8C8",
                                borderRight: "0.5px solid #C8C8C8",
                                textAlign: "left",
                              }}
                            >
                              Description
                            </th>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                fontWeight: 500,
                                fontSize: 7,
                                backgroundColor: "#FDFEFF",
                                borderBottom: "0.5px solid #C8C8C8",
                                borderRight: "0.5px solid #C8C8C8",
                                textAlign: "center",
                              }}
                            >
                              Quantity
                            </th>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                fontWeight: 500,
                                fontSize: 7,
                                backgroundColor: "#FDFEFF",
                                borderBottom: "0.5px solid #C8C8C8",
                                borderRight: "0.5px solid #C8C8C8",
                                textAlign: "center",
                              }}
                            >
                              Rate
                            </th>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                fontWeight: 500,
                                fontSize: 7,
                                backgroundColor: "#FDFEFF",
                                borderBottom: "0.5px solid #C8C8C8",
                                borderRight: "0.5px solid #C8C8C8",
                                textAlign: "center",
                              }}
                            >
                              Piece/ Carat
                            </th>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                fontWeight: 500,
                                fontSize: 7,
                                backgroundColor: "#FDFEFF",
                                borderBottom: "0.5px solid #C8C8C8",
                                textAlign: "right",
                              }}
                            >
                              Amount
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.map((value, index) => {
                            return (
                              <tr key={index}>
                                <td
                                  style={{
                                    padding: "5.5px 5px",
                                    borderRight: "0.5px solid #C8C8C8",
                                    fontSize: 7,
                                    fontWeight: 400,
                                    textAlign: "left",
                                  }}
                                >
                                  {value?.serial_number}
                                </td>
                                <td
                                  style={{
                                    padding: "5.5px 5px",
                                    borderRight: "0.5px solid #C8C8C8",
                                    fontSize: 7,
                                    fontWeight: 400,
                                    textAlign: "left",
                                  }}
                                >
                                  <p
                                    style={{
                                      marginBottom: 0,
                                      maxWidth: 100,
                                    }}
                                  >
                                    {value?.description}
                                  </p>
                                </td>
                                <td
                                  style={{
                                    padding: "5.5px 5px",
                                    borderRight: "0.5px solid #C8C8C8",
                                    fontSize: 7,
                                    fontWeight: 400,
                                    textAlign: "center",
                                  }}
                                >
                                  {value?.quantity}
                                </td>
                                <td
                                  style={{
                                    padding: "5.5px 5px",
                                    borderRight: "0.5px solid #C8C8C8",
                                    fontSize: 7,
                                    fontWeight: 400,
                                    textAlign: "center",
                                  }}
                                >
                                  {value?.rate}
                                </td>
                                <td
                                  style={{
                                    padding: "5.5px 5px",
                                    borderRight: "0.5px solid #C8C8C8",
                                    fontSize: 7,
                                    fontWeight: 400,
                                    textAlign: "center",
                                  }}
                                >
                                  {value?.pcs_carat}
                                </td>
                                <td
                                  style={{
                                    padding: "5.5px 5px",
                                    borderRight: "0.5px solid #C8C8C8",
                                    fontSize: 7,
                                    fontWeight: 400,
                                    textAlign: "right",
                                  }}
                                >
                                  {value?.amount}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <thead>
                          <tr>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                paddingTop: 30,
                                fontWeight: 700,
                                fontSize: 8,
                                backgroundColor: "#FDFEFF",
                                borderRight: "0.5px solid #C8C8C8",
                                textAlign: "left",
                              }}
                            ></th>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                paddingTop: 30,
                                fontWeight: 700,
                                fontSize: 8,
                                backgroundColor: "#FDFEFF",
                                borderRight: "0.5px solid #C8C8C8",
                                textAlign: "left",
                              }}
                            ></th>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                paddingTop: 30,
                                fontWeight: 700,
                                fontSize: 8,
                                backgroundColor: "#FDFEFF",
                                borderRight: "0.5px solid #C8C8C8",
                                textAlign: "left",
                              }}
                            ></th>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                paddingTop: 30,
                                fontWeight: 700,
                                fontSize: 8,
                                backgroundColor: "#FDFEFF",
                                borderRight: "0.5px solid #C8C8C8",
                                textAlign: "left",
                              }}
                            ></th>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                paddingTop: 30,
                                fontWeight: 700,
                                fontSize: 7,
                                backgroundColor: "#FDFEFF",
                                borderRight: "0.5px solid #C8C8C8",
                                textAlign: "center",
                              }}
                            >
                              Total
                            </th>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                paddingTop: 30,
                                fontWeight: 700,
                                fontSize: 7,
                                backgroundColor: "#FDFEFF",
                                textAlign: "right",
                              }}
                            >
                              {total?.[0]?.amount}
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td
              style={{
                fontWeight: 500,
                padding: "12px 0",
              }}
            >
              {estimatePrintData?.total_in_words}
            </td>
          </tr>

          <tr>
            <td
              style={{
                fontWeight: 400,
                fontSize: 6,
              }}
            >
              *GST Charges will be added on Original Bill
            </td>
          </tr>

          <tr>
            <td
              style={{
                fontWeight: 500,
                padding: "20px 0 0",
                textAlign: "right",
              }}
            >
              Authorised Signatory
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});

export default EstimatePdf;
