import { getAxiosInstance } from "../../../api";

export const getServiceData = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/service/edit?service_id=${id}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const addProcessData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/service/create`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const editProcessData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/service/update`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteService = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.delete(`/service/delete`, { data: body });
    return response;
  } catch (error) {
    return error.response.data;
  }
};
export const approveServiceRequest = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/service/approve`, params);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
