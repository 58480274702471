import React from "react";
import useEngravingCertificate from "./useEngravingCertificate";
import {
  ModalLayout,
  Pagination,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import AddStatus from "../../../Global/AddStatus";
import { ProfileCard } from "../../../Employees/EngravingSection/EngravingCertificate/ProfileCard";
import Style from "../engravingSection.module.scss";
import PassAll from "../../../Global/PassAll";
import RemarksTableRecheck from "../../../Global/RemarksTableRecheck";

const EngravingCertificate = ({ gliNumber }) => {
  const {
    paginationOptions,
    disableMultiSelect,
    engravingSectionState,
    imageData,
    showImageModal,
    certificateList,
    isFetching,
    globalRemarkModal,
    passAllModal,
    formik,
    handlePassAllClick,
    handleModalClose,
    closeModal,
    handleDownload,
    getRow,
    handlePassAll,
    handleConfirm,
    handlePageSize,
    handlePagination,
    handleConfirmModal,
  } = useEngravingCertificate();

  return (
    <>
      <h5 className="pro-ttl h5 pro-mb-2">{gliNumber}</h5>
      <div className={Style.btns}>
        <button
          className={`${Style.Print_btn} pro-btn pro-items-center pro-btn-outline`}
          onClick={handlePassAllClick}
        >
          <span className="material-symbols-outlined">check_circle</span>
          <span>Pass All</span>
        </button>
      </div>
      <Table
        multiSelect={disableMultiSelect ? false : true}
        data={certificateList?.data?.data || []}
        uniqueID={"id"}
        fields={certificateList?.data?.fields || []}
        showCheckBox={true}
        loading={isFetching}
        SortIcon={<FaSort />}
        getRow={getRow}
        perpage={10}
        assignable={true}
        editable={false}
        deletable={false}
        assignText={"Submit"}
        assignIcon={
          <span className="material-symbols-outlined">check_circle</span>
        }
        handleAssign={handleConfirmModal}
        clear={engravingSectionState?.clear}
      />
      <ModalLayout show={showImageModal} handleClose={closeModal}>
        <ProfileCard
          data={imageData}
          handleClose={closeModal}
          handleDownload={handleDownload}
        />
      </ModalLayout>
      <ModalLayout
        show={engravingSectionState?.showAddEngravingSecModal}
        handleClose={handleConfirmModal}
        title={"Choose status of submission?"}
      >
        <div className="pro-m-5">
          <AddStatus
            handleConfirm={handleConfirm}
            handleConfirmModal={handleConfirmModal}
          />
        </div>
      </ModalLayout>

      <Pagination
        currentPage={certificateList?.data?.current_page}
        totalPageCount={Math.ceil(
          certificateList?.data?.total_count / certificateList?.data?.per_page
        )}
        onPageChange={handlePagination}
        options={paginationOptions}
        onActionChange={handlePageSize}
        center
      />
      <ModalLayout
        show={passAllModal}
        title={"Pass All"}
        handleClose={handlePassAllClick}
        closeIcon={<span className="material-symbols-outlined">close</span>}
      >
        <PassAll
          formik={formik}
          handleSubmit={handlePassAll}
          handleCancel={handlePassAllClick}
        />
      </ModalLayout>
      <ModalLayout
        show={globalRemarkModal}
        handleClose={handleModalClose}
        title={"Remarks"}
        propStyle={{ root: "pro-modal-xxl" }}
        closeIcon={<span className="material-symbols-outlined">close</span>}
      >
        <div className="pro-m-5">
          <RemarksTableRecheck />
        </div>
      </ModalLayout>
    </>
  );
};

export default EngravingCertificate;
