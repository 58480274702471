import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../store/slices/Orders/orderSlice";
import { updateOrderData } from "../../../../../store/slices/Orders/addOrderSlice";
import { useSelector } from "react-redux";
import { useState } from "react";

const useService = ({ formik }) => {
  const dispatch = useDispatch();
  const { isEdit, orderData } = useSelector((state) => state.order);
  const [certificateImage, setCertificateImage] = useState(
    isEdit ? orderData?.image_for_certificate : ""
  );

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddOrderModal = false;
      })
    );
  };

  const handleDocumentChange = (e) => {
    formik?.setFieldValue("additional_document", e?.target?.files?.[0]);
  };
  const handleDocumentChangeFirst = async (e) => {
    formik?.setFieldValue("excel_file", e?.target?.files?.[0]);
  };

  const handleCertificateChange = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik?.setFieldValue("image_for_certificate", e?.target?.files?.[0]);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setCertificateImage(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      // toast.error("The image must be less than 5MB in size.");
      formik.setFieldError(
        "image_for_certificate",
        "The image must be less than 5MB in size."
      );
    }
  };

  const handleNext = () => {
    dispatch(
      updateOrderData((state) => {
        state.activeTab = "Engraving";
      })
    );
  };

  return {
    orderData,
    certificateImage,
    handleNext,
    handleCloseModal,
    handleDocumentChange,
    handleCertificateChange,
    handleDocumentChangeFirst,
  };
};

export default useService;
