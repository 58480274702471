import React from "react";
import useEditForm from "./useEditForm";
import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import Style from "../../staffBilling.module.scss";
import MasterData from "./MasterData";
import Select from "react-select";

const EditForm = ({ refetch }) => {
  const {
    formik,
    isChecked,
    invoiceData,
    getFieldError,
    handleCloseModal,
    handleCheckChange,
    basicData
  } = useEditForm({
    refetch,
  });

  return (
    <div>
      <div className="row">
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Shop/ Supplier
        </label>
        <Select
          id="shop_or_supplier_type"
          placeholder={"Select"}
          className={"pro-input lg"}
          classNamePrefix="pro-input"
          isMulti={false}
          name="shop_or_supplier_type"
          options={basicData?.data?.shop_supplier}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={basicData?.data?.shop_supplier?.filter(
            (p) => p.id === formik.values.shop_or_supplier_type
          )}
          onChange={(value) =>
            formik.setFieldValue(
              "shop_or_supplier_type",value?.id
            )
          }
          menuPlacement="auto"
        />
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Shop/ Supplier Name
        </label>
        <Select
          id="shop_or_supplier_id"
          placeholder={"Select"}
          className={"pro-input lg"}
          classNamePrefix="pro-input"
          isMulti={false}
          name="shop_or_supplier_id"
          options={formik?.values?.shop_or_supplier_type === 1 ? basicData?.data?.supplier : basicData?.data?.shop}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={formik?.values?.shop_or_supplier_type === 1 ? basicData?.data?.supplier?.filter(
            (p) => p._id === formik.values.shop_or_supplier_id
          ) : basicData?.data?.shop?.filter(
            (p) => p._id === formik.values.shop_or_supplier_id
          )}
          onChange={(value) =>
            {
              formik.setFieldValue("shop_or_supplier_id", value?._id);
              formik.setFieldValue("supplier_name", value?.name);
            }
          }
          menuPlacement="auto"
        />
      </div>
        <Input
          label={formik?.values?.shop_or_supplier_type === 1 ? `Supplier Name` : `Shop Name`}
          type="text"
          id="supplier_name"
          name="supplier_name"
          className={`pro-input lg ${
            getFieldError("supplier_name") && " error"
          }`}
          {...formik.getFieldProps("supplier_name")}
          error={getFieldError("supplier_name")}
          errorMessage={getFieldError("supplier_name")}
        />

        <div className={Style.container}>
          <small className={Style.head}>Bill to</small>

          <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Shop/ Supplier
        </label>
        <Select
          id="billing_address_type"
          placeholder={"Select"}
          className={"pro-input lg"}
          classNamePrefix="pro-input"
          isMulti={false}
          name="billing_address_type"
          options={basicData?.data?.shop_supplier}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={basicData?.data?.shop_supplier?.filter(
            (p) => p.id === formik.values.billing_address_type
          )}
          onChange={(value) =>
            formik.setFieldValue(
              "billing_address_type",value?.id
            )
          }
          menuPlacement="auto"
        />
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Shop/ Supplier Name
        </label>
        <Select
          id="billing_address_id"
          placeholder={"Select"}
          className={"pro-input lg"}
          classNamePrefix="pro-input"
          isMulti={false}
          name="billing_address_id"
          options={formik?.values?.billing_address_type === 1 ? basicData?.data?.supplier : basicData?.data?.shop}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={formik?.values?.billing_address_type === 1 ? basicData?.data?.supplier?.filter(
            (p) => p._id === formik.values.billing_address_id
          ) : basicData?.data?.shop?.filter(
            (p) => p._id === formik.values.billing_address_id
          )}
          onChange={(value) =>
            {
              formik.setFieldValue("billing_address_id", value?._id);
              formik.setFieldValue("shop_name", value?.name);
              formik.setFieldValue("address_line1", value?.billing_address?.address_line1 ?? "");
              formik.setFieldValue("address_line2", value?.billing_address?.address_line2 ?? "");
              formik.setFieldValue("city", value?.billing_address?.city ?? "");
              formik.setFieldValue("postal_code", value?.billing_address?.postal_code ?? "");
              formik.setFieldValue("gst_number", value?.gst_number ?? "");
            }
          }
          menuPlacement="auto"
        />
      </div>
          <Input
            label={formik?.values?.billing_address_type === 1 ? `Supplier Name` : `Shop Name`}
            type="text"
            id="shop_name"
            name="shop_name"
            className={`pro-input lg ${getFieldError("shop_name") && " error"}`}
            {...formik.getFieldProps("shop_name")}
            error={getFieldError("shop_name")}
            errorMessage={getFieldError("shop_name")}
          />
          <Input
            label={"Address Line 1"}
            type="text"
            id="address_line1"
            name="address_line1"
            className={`pro-input lg ${
              getFieldError("address_line1") && " error"
            }`}
            {...formik.getFieldProps("address_line1")}
            error={getFieldError("address_line1")}
            errorMessage={getFieldError("address_line1")}
          />
          <Input
            label={"Address Line 2"}
            type="text"
            id="address_line2"
            name="address_line2"
            className={`pro-input lg ${
              getFieldError("address_line2") && " error"
            }`}
            {...formik.getFieldProps("address_line2")}
            error={getFieldError("address_line2")}
            errorMessage={getFieldError("address_line2")}
          />
          <Input
            label={"City"}
            type="text"
            id="city"
            name="city"
            className={`pro-input lg ${getFieldError("city") && " error"}`}
            {...formik.getFieldProps("city")}
            error={getFieldError("city")}
            errorMessage={getFieldError("city")}
          />
          <Input
            label={"Postal Code"}
            type="text"
            id="postal_code"
            name="postal_code"
            className={`pro-input lg ${
              getFieldError("postal_code") && " error"
            }`}
            {...formik.getFieldProps("postal_code")}
            error={getFieldError("postal_code")}
            errorMessage={getFieldError("postal_code")}
          />
          <Input
            label={"GST Number"}
            type="text"
            id="gst_number"
            name="gst_number"
            className={`pro-input lg ${
              getFieldError("gst_number") && " error"
            }`}
            {...formik.getFieldProps("gst_number")}
            error={getFieldError("gst_number")}
            errorMessage={getFieldError("gst_number")}
          />
        </div>
        <Input
          label={"Discount Amount"}
          type="text"
          id="discount_amount"
          name="discount_amount"
          className={`pro-input lg ${
            getFieldError("discount_amount") && " error"
          }`}
          {...formik.getFieldProps("discount_amount")}
          error={getFieldError("discount_amount")}
          errorMessage={getFieldError("discount_amount")}
        />
        {formik?.values?.updated_service_data?.length > 0 && (
          <small>Master Data</small>
        )}
        {formik?.values?.updated_service_data?.map((val, index) => {
          return (
            <React.Fragment key={index}>
              <MasterData
                values={val}
                key={index}
                formik={formik}
                getFieldError={getFieldError}
                itemIndex={index}
              />
            </React.Fragment>
          );
        })}
        {invoiceData?.invoice_type === 2 && (
          <Input
            label={"Grand Total"}
            type="text"
            id="unit_price"
            name="unit_price"
            className={`pro-input lg ${
              getFieldError("unit_price") && " error"
            }`}
            {...formik.getFieldProps("unit_price")}
            error={getFieldError("unit_price")}
            errorMessage={getFieldError("unit_price")}
          />
        )}
        <div className="pro-check-box">
          <input
            type="checkbox"
            className="pro-check"
            id="courier_charge"
            name="courier_charge"
            onChange={handleCheckChange}
            checked={isChecked}
          />
          <label htmlFor="courier_charge" className="pro-check-label">
            {"Courier Charge "}
          </label>
        </div>
        {isChecked && (
          <div className={Style.courier}>
            <Input
              label={"SAC Number"}
              type="text"
              id="sac_number"
              name="sac_number"
              className={`pro-input lg ${
                getFieldError("sac_number") && " error"
              }`}
              {...formik.getFieldProps("sac_number")}
              error={getFieldError("sac_number")}
              errorMessage={getFieldError("sac_number")}
            />
            <Input
              label={"Courier Charge"}
              type="text"
              id="courier_charge"
              name="amount"
              className={`pro-input lg ${getFieldError("amount") && " error"}`}
              {...formik.getFieldProps("amount")}
              error={getFieldError("amount")}
              errorMessage={getFieldError("amount")}
            />
          </div>
        )}

        <div
          className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
        >
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button
            className={"pro-btn-primary lg pro-ms-3"}
            type="submit"
            onClick={formik.handleSubmit}
          >
            {"Update"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditForm;
