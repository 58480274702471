import { useDispatch } from "react-redux";
import { debounce } from "../../../utils/hooks/useDebounce";
import {
  getPhotoSectionList,
  updateConfig,
} from "../../../store/slices/PhotoSection/PhotoSectionSlice";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { startTimer } from "../api";
import { toast } from "react-toastify";

const usePhotoSection = () => {
  const dispatch = useDispatch();
  const [activeCard, setActiveCard] = useState({ id: "", key: "" });
  const [pendingPage, setPendingPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const photoSectionState = useSelector((state) => state.photoSection);
  const slug = localStorage?.getItem("slug");

  

  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.cardData.Pending = [];
        state.search = "";
      })
    );
    dispatch(
      getPhotoSectionList({
        page: 1,
        per_page: 10,
        process_slug: slug,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  useEffect(() => {
    if (pendingPage !== 1) {
      if (
        photoSectionState.cardData.Pending?.length <=
        photoSectionState.total?.Pending
      ) {
        dispatch(
          getPhotoSectionList({
            page: pendingPage,
            per_page: 10,
            process_slug: slug,
            search: photoSectionState.search,
          })
        );
      }
      else if (photoSectionState.cardData.Pending?.length > photoSectionState.total?.Pending) {
        setIsLoading(false);
    }

    }
    // eslint-disable-next-line
  }, [pendingPage]);

  const handleSearchInput = (searchValue) => {
    setPendingPage(1);
    dispatch(
      updateConfig((state) => {
        state.cardData.Pending = [];
        state.currentPage = 1;
      })
    );
    dispatch(
      getPhotoSectionList({
        search: searchValue,
        page: 1,
        process_slug: slug,
        per_page: 10,
      })
    );
  };

  const startTimerPhoto = (item) => {
    let body = {
      receipt_id: item?._id,
      process_slug: slug,
    };
    startTimer(body).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Timer Started");
      } else {
        toast.error("Can't Start Timer");
      }
    });
  };

  const handleCardClick = (e) => {
    setActiveCard({ id: e._id, key: "Pending" });
    startTimerPhoto(e);
    dispatch(
      updateConfig((state) => {
        state.showTable = true;
        state.selectedId = e?._id;
        state.gli_id = e?.gli_number;
        state.currentPage = 1;
      })
    );
  };

  const handleLoadMore = (mouseHover) => {
    if (
      mouseHover === "Pending" &&
      photoSectionState?.cardData?.Pending?.length <=
        photoSectionState?.total?.Pending
    ) {
      
      setPendingPage(pendingPage + 1);
      dispatch(
        updateConfig((state) => {
          state.currentPage = pendingPage + 1;
        })
      );
    }
  };
  const debouncedHandleSearchInput = debounce(handleSearchInput, 1000);

  const handleSearch = (e) => {
    setPendingPage(1);
    // dispatch(
    //   updateConfig((state) => {
    //     state.currentPage = 1;
    //   }))
    const searchValue = e.target.value;
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.search = searchValue;
      }))

    debouncedHandleSearchInput(searchValue);
  };

  return {
    activeCard,
    isLoading,
    photoSectionState,
    cardData: photoSectionState?.cardData,
    totalLength: { Pending: photoSectionState.total.Pending },
    apiStatus: { Pending: photoSectionState?.pendingCardStatus },
    setIsLoading,
    handleSearch,
    handleCardClick,
    handleLoadMore,
  };
};

export default usePhotoSection;
