import { getAxiosInstance } from "../../../api";

export const recheckEmpDetails = async ({ data }) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(
      `/work/recheck-employee-list?stock_receipt_id=${data?.id}&process_slug=${data?.slug}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
