import { getAxiosInstance } from "../../../api";

export const getSourceData = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/source/edit?source_id=${id}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const updateSource = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("/source/save", body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteSource = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.delete("/source/delete", { data: body });
    return response;
  } catch (error) {
    return error.response.data;
  }
};
export const approveSourceRequest = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/source/approve`, params);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
