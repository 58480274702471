// import { useEffect } from "react";

export const useItemFields = (formik) => {
  const handleRemoveField = (fieldIndex) => {
    let data = formik?.values?.item_fields?.filter(
      (item, index) => index !== fieldIndex
    );
    formik.setFieldValue("item_fields", data);
  };

  //   useEffect(() => {
  //     if (formik?.values) {

  //     }
  //   }, []);
  return { handleRemoveField };
};
