import { useSelector } from "react-redux";

const useDisabledTabs = () => {
  const { formData, recheckTab } = useSelector((state) => state.qc);
  const items = formData?.previous_data?.[recheckTab?.activeTabId - 2];
  return {
    formData,
    items,
  };
};

export default useDisabledTabs;
