import React from "react";
import useReportPrint from "./useReportPrint";
import Style from "../report.module.scss";

const ReportPrint = () => {
  const { availableReports, handlePrintClick } = useReportPrint();
  return (
    <div className={`${Style.root} pro-p-5`}>
      {availableReports?.data?.map((value, index) => {
        return (
          <>
            {value?.status && (
              <div key={index} className={Style.container}>
                <span>{value?.name}</span>
                <button
                  onClick={() => handlePrintClick(index)}
                  className={"pro-btn pro-btn-link pro-ms-3 pro-p-2"}
                >
                  <span className={`material-symbols-outlined ${Style.icon}`}>
                    download
                  </span>
                </button>
              </div>
            )}
          </>
        );
      })}
    </div>
  );
};

export default ReportPrint;
