import { combineReducers } from "@reduxjs/toolkit";
import globalSlice from "./slices/Global";
import addOrderSlice from "./slices/Orders/addOrderSlice";
import orderSlice from "./slices/Orders/orderSlice";
import sourceSlice from "./slices/Master/Source/sourceSlice";
import { master } from "./queries/Master";
import branchesSlice from "./slices/Master/Branches/branchesSlice";
import certificatePrefixSlice from "./slices/Master/CertificatePrefix/certificatePrefixSlice";
import shopSlice from "./slices/Master/Shop/shopSlice";
import itemGradingSlice from "./slices/Master/ItemGrading/itemGradingSlice";
import itemSlice from "./slices/Master/Item/itemSlice";
import packingSlice from "./slices/Master/Packing/packingSlice";
import processSlice from "./slices/Master/Process/processSlice";
import serviceSlice from "./slices/Master/Service/serviceSlice";
import itemTypeSlice from "./slices/Master/ItemType/itemTypeSlice";
import departmentSlice from "./slices/Master/Department/departmentSlice";
import roleSlice from "./slices/Master/Role/roleSlice";
import engravingSlice from "./slices/Master/Engraving/engravingSlice";
import billingSlice from "./slices/Master/Billing/billingSlice";
import employeeSlice from "./slices/Master/Employee/employeeSlice";
import reportSlice from "./slices/Master/Report/reportSlice";
import { orders } from "./queries/Orders";
import { verification } from "./queries/Verification";
import approvedSlice from "./slices/Verification/approvedSlice";
import verificationSlice from "./slices/Verification/verificationSlice";
import QCSlice from "./slices/QC/QCSlice";
import photoUploadSlice from "./slices/photoUpload/photoUploadSlice";
import recheckSlice from "./slices/Recheck/recheckSlice";
import { recheckStaff } from "./queries/Recheck";
import engravingSectionSlice from "./slices/EngravingSection/engravingSectionSlice";
import overViewSlice from "./slices/OverSlice/overViewSlice";
import packingsSlice from "./slices/Packings/packingsSlice";
import staffBillingSlice from "./slices/Billing/staffBillingSlice";
import { billing } from "./queries/Billing";
import staffSlice from "./slices/Staff/staffSlice";
import { staff } from "./queries/Staff";
import CVDSlice from "./slices/CVD/CVDSlice";
import deliverySlice from "./slices/Delivery/deliverySlice";
import recheckStaffSlice from "./slices/Staff/recheckStaffSlice";
import printingSlice from "./slices/Printing/printingSlice";
import { printing } from "./queries/Printing";
import { delivery } from "./queries/Delivery";
import { engraving } from "./queries/Engraving";
import { packing } from "./queries/Packing";
import mainReportSlice from "./slices/MainReport/mainReportSlice";
import { mainReport } from "./queries/MainReport";
import { QC } from "./queries/QC";
import { CVD } from "./queries/CVD";
import PhotoSectionSlice from "./slices/PhotoSection/PhotoSectionSlice";
import { photoSection } from "./queries/PhotoSection";
import billingReissueSlice from "./slices/Billing/billingReissueSlice";
import { reports } from "./queries/Reports";
import reportsSlice from "./slices/Reports/reportsSlice";
import invoiceNumberFormatSlice from "./slices/Master/InvoiceNumberFormat/invoiceNumberFormatSlice";
import reassignSlice from "./slices/Reassign/reassignSlice";

const appReducer = combineReducers({
  [master.reducerPath]: master.reducer,
  [orders.reducerPath]: orders.reducer,
  [verification.reducerPath]: verification.reducer,
  [recheckStaff.reducerPath]: recheckStaff.reducer,
  [billing.reducerPath]: billing.reducer,
  [staff.reducerPath]: staff.reducer,
  [printing.reducerPath]: printing.reducer,
  [delivery.reducerPath]: delivery.reducer,
  [engraving.reducerPath]: engraving.reducer,
  [packing.reducerPath]: packing.reducer,
  [mainReport.reducerPath]: mainReport.reducer,
  [QC.reducerPath]: QC.reducer,
  [CVD.reducerPath]: CVD.reducer,
  [photoSection.reducerPath]: photoSection.reducer,
  [reports.reducerPath]: reports.reducer,
  global: globalSlice,
  reports: reportsSlice,
  packings: packingsSlice,
  packing: packingSlice,
  photoSection: PhotoSectionSlice,
  photoUpload: photoUploadSlice,
  overview: overViewSlice,
  approved: approvedSlice,
  verification: verificationSlice,
  addOrder: addOrderSlice,
  order: orderSlice,
  branches: branchesSlice,
  certificatePrefix: certificatePrefixSlice,
  source: sourceSlice,
  shop: shopSlice,
  itemGrading: itemGradingSlice,
  item: itemSlice,
  process: processSlice,
  service: serviceSlice,
  itemType: itemTypeSlice,
  department: departmentSlice,
  role: roleSlice,
  engraving: engravingSlice,
  billing: billingSlice,
  employee: employeeSlice,
  report: reportSlice,
  qc: QCSlice,
  recheck: recheckSlice,
  reassign: reassignSlice,
  engravingSection: engravingSectionSlice,
  staffBilling: staffBillingSlice,
  staff: staffSlice,
  recheckStaff: recheckStaffSlice,
  cvd: CVDSlice,
  delivery: deliverySlice,
  printing: printingSlice,
  mainReport: mainReportSlice,
  billingReissue: billingReissueSlice,
  invoiceNumberFormat: invoiceNumberFormatSlice
});

export default appReducer;
