import React from "react";
import Select from "react-select";
import useItem from "./useItem";
import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import AddItem from "./Additem";

const Item = ({
  isStickyFooter,
  formik,
  optionsData,
  isEdit,
  // isDLoose,
  setValidationType,
  numberOfItem,
  numberOfCertificate
}) => {
  let temp = [];
  const {
    isEditProfile,
    handleNext,
    getFieldError,
    handleCloseModal,
    handleAddItemClick,
    handleDownloadSheet,
    handleItemTypeChange,
  } = useItem({ formik, setValidationType, optionsData });

  let itemTypeObj = optionsData?.item_types?.filter(
    (item) => item?._id === formik?.values?.item_type
  );

  return (
    <div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Item Type
        </label>
        <Select
          id="item_type"
          name="item_type"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("item_type") && " error"}`}
          classNamePrefix="pro-input"
          options={optionsData?.item_types ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?._id}
          onChange={(value) => handleItemTypeChange(value)}
          value={itemTypeObj}
          onBlur={formik?.handleBlur("item_type")}
          menuPlacement="auto"
        />
        {getFieldError("item_type") && (
          <span className="error-text">{getFieldError("item_type")}</span>
        )}
      </div>
      <Input
        label={"Box Weight"}
        type="text"
        id="box_weight"
        name="box_weight"
        className={`pro-input lg ${getFieldError("box_weight") && " error"}`}
        {...formik.getFieldProps("box_weight")}
        error={getFieldError("box_weight")}
        errorMessage={getFieldError("box_weight")}
      />
      {formik?.values?.group?.map((value, index) => {
        temp = [...temp, formik?.values?.group[index]?.item_id];
        return (
          <AddItem
            key={index}
            itmIndex={index}
            optionsData={optionsData}
            values={value}
            formik={formik}
            isEdit={isEdit}
            tempIds={temp}
            // isDLoose={isDLoose}
            getFieldError={getFieldError}
          />
        );
      })}
      {formik?.values?.group?.length !== optionsData?.items?.length && (
        <Button
          className={`pro-btn-primary lg pro-w-100 pro-mt-4`}
          onClick={handleAddItemClick}
          type="button"
        >
          {`Add Another`}
        </Button>
      )}

  {formik?.values?.group?.length !== optionsData?.items?.length && (
    <>
    <Input
        label={"Total No. of Items"}
        value={numberOfItem}
        type="text"
        
        disabled={true}
      />
      <Input
        label={"Total No. of Certificate"}
        value={  numberOfCertificate}
        type="text"
        disabled={true}
      />
    </>
        
      )}
      <div
        className={`col-12 pro-d-flex pro-justify-end pro-pt-3  pro-mt-5 ${
          isStickyFooter && "offcanvas-footer-sticky-btn"
        }`}
      >
        {/* <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleDownloadSheet}
          type="button"
          disabled={formik?.values?.item_type ? false : true}
        >
          Download Sheet
        </Button> */}
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={handleNext}
          type="button"
        >
          {isEditProfile ? "Update" : "Next"}
        </Button>
      </div>
    </div>
  );
};

export default Item;
