import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Master/Process/processSlice";
import * as Yup from "yup";
import { updateProcessData } from "../api";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const useAddProcess = ({ refetch }) => {
  const dispatch = useDispatch();
  const processState = useSelector((state) => state.process);

  useEffect(() => {
    if (processState?.itemData) {
      formik?.setFieldValue("name", processState?.itemData?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processState?.showAddProcessModal, processState?.itemData]);

  const validation = Yup.object({
    name: Yup.string().required("Enter Name").max(50, "Enter valid Name"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      let data = {
        name: values?.name,
        process_id: processState?.selectedItem,
      };
      updateProcessData(data).then((response) => {
        if (response?.data?.status_code === 200) {
          handleCloseModal();
          toast.success("Successfully Updated");
          refetch();
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
        } else {
          toast.error("Something went wrong");
        }
      });
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.showAddProcessModal = false)));
  };
  return {
    formik,
    processState,
    getFieldError,
    handleCloseModal,
  };
};

export default useAddProcess;
