import { getAxiosInstance } from "../../../../api";

export const getPackingTypeList = async (id) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(`/packing/packing-types`);
    return res;
  } catch (error) {
    return error.response.data;
  }
};
