import React from "react";
import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../../components/Global/OrderColumn";
import useItemGrading from "./useItemGrading";
import AddItemGrading from "./AddItemGrading";
const ItemGrading = () => {
  const {
    queryState,
    showEditModal,
    tableFields,
    queryList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    handleDelete,
    handleDeleteModal,
    handleEditAction,
    handleSearch,
    updateItemFields,
    handleClearClick,
    handlePageSize,
    refetch,
  } = useItemGrading();
  return (
    <div>
      <HeadingGroup
        title={"Item Grading"}
        className={`pro-mb-4`}
        buttonTitle={queryList?.data?.create_permission && "Add new"}
        handleClick={handleModalClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          showActions={true}
          handleActionClick={handleEditColumnsClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: queryState?.search }}
        />
        <Table
          multiSelect={false}
          data={queryList?.data?.data || []}
          uniqueID={"id"}
          fields={tableFields}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          clear={queryState.clearSelection}
          perpage={queryState?.currentPageSize}
          assignable={false}
          handleEdit={handleEditAction}
          handleDelete={handleDeleteModal}
          deletable={queryList?.data?.delete_permission}
          editable={queryList?.data?.edit_permission}
          showCheckBox={true}
        />
        {queryList?.data?.data?.length > 0 && (
          <Pagination
            currentPage={queryState?.currentPage}
            totalPageCount={Math.ceil(
              queryList.data.total_count / queryState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          show={queryState?.showDeleteModal}
          handleClose={handleDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure delete the selected Item ?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteModal}
              submitText={"Yes"}
              submitAction={handleDelete}
              isRight={true}
            />
          </div>
        </ModalLayout>

        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={queryList?.data?.fields}
              moduleId={queryList?.data?.module_id}
              updateData={updateItemFields}
            />
          </div>
        </ModalLayout>
        <ModalLayout
          show={queryState?.showAddItemGradingModal}
          handleClose={handleModalClick}
          title={
            queryState?.isEdit ? "Update Item Grading" : "Add Item Grading"
          }
          closeIcon={<span className="material-symbols-outlined"> close </span>}
        >
          <AddItemGrading refetch={refetch} />
        </ModalLayout>
      </div>
    </div>
  );
};

export default ItemGrading;
