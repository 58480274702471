import { getAxiosInstance } from "../../../api";

export const addToRecheckList = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`/recheck/item`, params);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const addToReissueList = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`/recheck/reissue`, params);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const printReport = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(`/qc-reports/recheck?id=${params?.id}`, {
      responseType: "blob",
    });
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const printSlip = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(`/recheck/slip?id=${params?.id}`);
    return res;
  } catch (error) {
    return error.response.data;
  }
};
