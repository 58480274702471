import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../store/slices/Master/Source/sourceSlice";
import { updateSource } from "../api";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useEffect } from "react";

const useAddSource = ({ refetch }) => {
  const dispatch = useDispatch();
  const sourceState = useSelector((state) => state.source);

  useEffect(() => {
    if (sourceState?.sourceData && sourceState?.isEdit) {
      formik?.setFieldValue(
        "source_name",
        sourceState?.sourceData?.source_name
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceState?.sourceData]);

  const validation = Yup.object({
    source_name: Yup.string()
      .trim()
      .required("Enter Source Name")
      .max(50, "Enter valid Source Name"),
  });

  const formik = useFormik({
    initialValues: {
      source_name: "",
    },
    validationSchema: validation,

    onSubmit: (values) => {
      let data = {
        source_name: values?.source_name,
        source_id: "",
      };
      if (sourceState?.isEdit) {
        data = {
          source_id: sourceState?.selectedSource,
          source_name: values?.source_name,
        };
      }
      updateSource(data).then((response) => {
        if (response?.data?.status_code === 200) {
          handleCloseModal();
          toast.success(
            sourceState?.isEdit ? "Source Updated" : "Source added successfully"
          );
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
          refetch();
        } else if (response?.status_code === 422) {
          // displays backend errors
          let errors = response?.errors;
          let errorFields = Object.keys(errors);
          errorFields.forEach((field) => {
            formik.setFieldError(field, errors[field]);
          });
        } else {
          toast.error("Something went wrong");
        }
      });
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddSourceModal = false;
      })
    );
  };

  return { formik, sourceState, getFieldError, handleCloseModal };
};

export default useAddSource;
