//import "../src/assets/scss/app.scss";
import "../../src/assets/scss/app.scss";
import Routes from "../routes";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <div>
      <Routes />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        className={`pro-toast-container`}
        progressClassName={`pro-toast-progress`}
        toastClassName={`pro-toast`}
        style={{ zIndex: 9999 }}
      />
    </div>
  );
}

export default App;
