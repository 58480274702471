import React from "react";
import useCompareTab from "./useCompareTab";
import { TabHeading } from "../../../Global/TabHeading";
import VersionOne from "./VersionOne";
import VersionTwo from "./VersionTwo";
import { Button } from "@wac-ui-dashboard/wac_component_library";
import Style from "./compareTab.module.scss";
import DetailsTable from "./DetailsTable";
const CompareTab = ({ activeCard, reportRef }) => {
  const {
    data,
    activeId,
    showVersionTwoTable,
    handleClick,
    handleAdd,
    handleCompare,
    handleDownload,
    excelCopyPermission
  } = useCompareTab(reportRef);

  return (
    <>
      {showVersionTwoTable ? (
        <div className="pro-p-5">
          <DetailsTable />
        </div>
      ) : (
        <div className={`pro-w-100 ${Style.compare_wrap}`}>
          {activeCard.key !== "Marked" && activeCard.key !== "Rejected" && (
            <TabHeading
              data={data}
              uniqueId={"id"}
              activeId={activeId}
              handleClick={handleClick}
            />
          )}
          <div
            className={`pro-ms-4 pro-d-flex pro-flex-column ${Style.tabs_wrap}`}
          >
            {activeId === 1 ? <VersionOne /> : <VersionTwo />}
          </div>
          {activeCard.key !== "Marked" && activeCard.key !== "Rejected" && (
            <div
              className={`col-12 pro-d-flex pro-justify-end pro-py-3 pro-gap-3 ${Style.footer_btn_wrap} `}
            >
              {activeId === 1 && (
                <>
                  <Button
                    type="button"
                    className={"pro-btn-outline lg pro-px-5"}
                    onClick={handleDownload}
                  >
                    <span className="material-symbols-outlined">
                      download_for_offline
                    </span>
                    Download
                  </Button>
                  <Button
                    className={"pro-btn-outline lg pro-px-5"}
                    onClick={handleCompare}
                  >
                    Compare
                  </Button>
                  {(activeId === 1 && excelCopyPermission) && (
                    <>
                      <Button
                        className={"pro-btn-outline lg "}
                        type="button"
                        onClick={handleAdd}
                      >
                        Create a copy
                      </Button>
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CompareTab;
