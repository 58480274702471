import {
  DatePickerWithInput,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  // SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useInvoices from "./useInvoices";
import { FaSort } from "react-icons/fa";
import EditForm from "./EditForm";
import InvoicePdf from "../Invoice/Print/InvoicePdf";
import ReceiptPdf from "../Invoice/Print/ReceiptPdf";
import Style from "../staffBilling.module.scss";
import OrderColumn from "../../Global/OrderColumn";
import EstimateReport from "./SupportingDoc";
import CardHero from "./CardHero";

const Invoices = () => {
  const {
    paginationOptions,
    showEditModal,
    queryState,
    isFetching,
    queryList,
    tableFields,
    // isLoading,
    date,
    invoiceRef2,
    receiptRef2,
    supportDocRef,
    handleDateChange,
    updateInvoiceHistoryFields,
    refetch,
    handleInvoiceClick,
    handleSlipClick,
    handleSearch,
    handleEditColumnsClick,
    handleSort,
    getRow,
    handleEditAction,
    handlePagination,
    handlePageSize,
    cardData
  } = useInvoices();

  return (
    <div>
      <div>
        <HeadingGroup title={"Invoice History"} className={`pro-mb-4`} />
        <div className="col-auto pro-pt-3 ">
          <div className="row gx-2 gy-2 flex-fill">
            <div className={`col-auto`}>
              <div className="input-wrap">
                <input
                  type="text"
                  onChange={handleSearch}
                  placeholder="Search this table"
                  className={`pro-input icon-r lg ${Style.search_table}`}
                />
                <span className="pro-icon">
                  {<span className="material-symbols-outlined">search</span>}
                </span>
              </div>
            </div>
            <div className={`col-auto`}>
              <button
                className="pro-btn pro-btn-outline lg"
                onClick={handleEditColumnsClick}
              >
                Edit Columns
              </button>
            </div>
            <div className={`col-auto`}>
              <DatePickerWithInput
                CalendarOutlineIcon={
                  <span className="material-symbols-outlined">
                    calendar_month
                  </span>
                }
                range={true}
                initialValue={date}
                onDateChange={handleDateChange}
              ></DatePickerWithInput>
            </div>
          </div>
        </div>
        <div className="pro-mt-4">
        <CardHero data={cardData} />
        <Table
          multiSelect={false}
          data={queryList?.data?.data || []}
          uniqueID={"_id"}
          fields={tableFields}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">print</span>}
          assignIcon={<span className="material-symbols-outlined">print</span>}
          deleteText={"Slip"}
          assignText={"Invoice"}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={queryState?.currentPageSize}
          assignable={true}
          handleEdit={handleEditAction}
          handleAssign={handleInvoiceClick}
          handleDelete={handleSlipClick}
          deletable={true}
          showCheckBox={true}
          editable={queryList?.data?.edit_permission}
          clear={queryState?.clear}
        />
        </div>
        {queryList?.data?.data?.length > 0 && (
          <Pagination
            currentPage={queryState?.currentPage}
            totalPageCount={Math.ceil(
              queryList.data.total_count / queryState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={queryList?.data?.fields}
              moduleId={queryList?.data?.module_id}
              updateData={updateInvoiceHistoryFields}
            />
          </div>
        </ModalLayout>
        <OffCanvasLayout
          show={queryState?.invoiceModal}
          handleClose={handleEditAction}
          title={"Edit"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <>
            <EditForm refetch={refetch} options={[]} />
          </>
        </OffCanvasLayout>
        <div className={Style.pdf_container}>
          <InvoicePdf invoiceRef={invoiceRef2} />
          <ReceiptPdf receiptRef={receiptRef2} />
          <EstimateReport estimateRef={supportDocRef} />
        </div>
      </div>
    </div>
  );
};

export default Invoices;
