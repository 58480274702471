import { AvatarGroup } from "@wac-ui-dashboard/wac_component_library";
import Style from "./orderCard.module.scss";

const Ordercard = ({
  extraClassName,
  ref,
  title = {},
  supplier = "",
  subTitle,
  avatars,
  curretNumber,
  tottalNumber,
  badgeText,
  onClick,
  completed,
  activeCard,
  title2,
  id,
  isOverView = false,
  item = "",
  excelData,
}) => {
  return (
    <div
      // ref={lastElement || null}
      ref={ref}
      onClick={onClick}
      className={`${Style.root} 
       pro-p-4 pro-ps-3 ${extraClassName ? extraClassName : ""} ${
        activeCard?.id === id ? Style.active : ""
      }`}
    >
      {title && (
        <h6 className={`pro-ttl pro-font-regular pro-mb-3 ${Style.title}`}>
          {title}
        </h6>
      )}
      {title2 && (
        <p className={` pro-font-regular pro-mb-0 ${Style.title}`}>{title2}</p>
      )}
      {item?.item && (
        <p className={` pro-font-regular pro-mb-0 ${Style.title}`}>
          {item?.item}
        </p>
      )}
      {subTitle && (
        <p className="pro-mb-0">
          {" "}
          <span className={Style.light_text}>Shop:</span> {subTitle}
        </p>
      )}
      {isOverView ? (
        <>
          {supplier && (
            <p className="pro-mb-0">
              <span className={Style.light_text}>Supplier:</span> {supplier}
            </p>
          )}
          {isOverView && item?.item_type && (
            <p className="pro-mb-0">{item?.item_type}</p>
          )}
        </>
      ) : (
        <>
          {item?.item_type && <p className="pro-mb-0">{item?.item_type}</p>}
          {supplier && (
            <p className="pro-mb-0">
              <span className={Style.light_text}>Supplier:</span> {supplier}
            </p>
          )}
        </>
      )}

      {(avatars ||
        badgeText || excelData ||
        (curretNumber >= 0 && tottalNumber) ||
        completed) && (
        <div className={`pro-d-flex pro-justify-between ${Style.card_footer}`}>
          {avatars ||
            (badgeText && (
              <div className="pro-d-flex pro-flex-column">
                {avatars && <AvatarGroup avatars={avatars} />}
                {badgeText && (
                  <span
                    className={`${
                      badgeText === "Urgent"
                        ? Style.order_badge_urgent
                        : badgeText === "Normal"
                        ? Style.order_badge_normal
                        : Style.order_badge_hold
                    } pro-px-2 pro-d-flex`}
                  >
                    {/* <span>icon</span> */}
                    <span>{badgeText}</span>
                  </span>
                )}
                {}
              </div>
            ))}
            {
              excelData && (
                <span className={`${Style.order_badge_green} pro-px-2 pro-d-flex`}>
                  Excel: {excelData}
                </span>
              )
            }

          {((curretNumber >= 0 && tottalNumber) || completed) && (
            <div className="pro-d-flex pro-ms-auto">
              {curretNumber >= 0 && tottalNumber && (
                <span className={`${Style.number} pro-font-xs`}>
                  {curretNumber}/{tottalNumber}
                </span>
              )}
              {completed && (
                <span
                  className={`${Style.tick} pro-d-flex pro-justify-center pro-rounded-circle`}
                >
                  <span className="material-symbols-outlined pro-align-self-center">
                    check
                  </span>
                </span>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Ordercard;
