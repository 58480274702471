import React from "react";
import Select from "react-select";
import { Input } from "@wac-ui-dashboard/wac_component_library";
import Style from "../addReport.module.scss";
import useItemFieldSS from "./useItemFieldSS";

export const ItemFieldSS = ({
  title,
  basicData,
  formik,
  itmIndex,
  fields,
  tempIds,
  tempPIds,
  isEdit,
  fieldName,
  itemTypeData,
}) => {
  const {
    fieldObj,
    caseText,
    priorityU,
    qcFieldData,
    keyFontCaseObj,
    valueFontCaseObj,
    priorityObj,
    handleRemoveFieldSS,
  } = useItemFieldSS(
    fields,
    itmIndex,
    formik,
    basicData,
    tempIds,
    tempPIds,
    isEdit
  );

  return (
    <>
      <div className={Style.formFields}>
        {formik?.values?.item_fields_SS?.length !== 1 && (
          <span
            className={`${Style.corner_button} btn-close`}
            onClick={() => handleRemoveFieldSS(itmIndex)}
          ></span>
        )}
        <h5>{title}</h5>
        <div className="row gx-2">
          <div className="col-md-6">
            <div className="input-wrap pro-mb-4">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                {fieldName}
              </label>
              <Select
                id={`item_fields_SS.${itmIndex}.field_id`}
                name={`item_fields_SS.${itmIndex}.field_id`}
                placeholder={"Select"}
                className={`pro-input lg  ${
                  formik.touched?.item_fields_SS?.[itmIndex]?.field_id &&
                  formik.errors?.item_fields_SS?.[itmIndex]?.field_id &&
                  "error"
                }`}
                classNamePrefix="pro-input"
                options={qcFieldData}
                getOptionLabel={(option) => option?.name}
                getOptionValue={(option) => option?._id}
                value={fieldObj}
                onBlur={formik.handleBlur(
                  `item_fields_SS.${itmIndex}.field_id`
                )}
                onChange={(value) => {
                  formik?.setFieldValue(
                    `item_fields_SS.${itmIndex}.field_id`,
                    value?._id || null
                  );
                }}
                menuPlacement="auto"
              />
              {formik.touched?.item_fields_SS?.[itmIndex]?.field_id &&
                formik.errors?.item_fields_SS?.[itmIndex]?.field_id && (
                  <span className="error-text">
                    {formik.errors?.item_fields_SS?.[itmIndex]?.field_id}
                  </span>
                )}
            </div>
          </div>
          <div className="col-md-6">
            <Input
              type="text"
              id={`text`}
              name={`text`}
              label={`Short Name`}
              className={`pro-input lg ${
                formik.touched?.item_fields_SS?.[itmIndex]?.text &&
                formik.errors?.item_fields_SS?.[itmIndex]?.text &&
                " error"
              }`}
              {...formik.getFieldProps(`item_fields_SS.${itmIndex}.text`)}
              error={
                formik.touched?.item_fields_SS?.[itmIndex]?.text &&
                formik.errors?.item_fields_SS?.[itmIndex]?.text && (
                  <span className="error-text">
                    {formik.errors?.item_fields_SS?.[itmIndex]?.text}
                  </span>
                )
              }
              errorMessage={
                formik.touched?.item_fields_SS?.[itmIndex]?.text &&
                formik.errors?.item_fields_SS?.[itmIndex]?.text && (
                  <span className="error-text">
                    {formik.errors?.item_fields_SS?.[itmIndex]?.text}
                  </span>
                )
              }
            />
          </div>
        </div>
        <div className="row gx-2">
          <div className="col-md-6">
            <div className="input-wrap pro-mb-4">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Font Case (Key)
              </label>
              <Select
                id={`key_font_case${itmIndex}`}
                name={`key_font_case${itmIndex}`}
                placeholder={"Select"}
                className={`pro-input lg  ${
                  formik.touched?.item_fields_SS?.[itmIndex]?.key_font_case &&
                  formik.errors?.item_fields_SS?.[itmIndex]?.key_font_case &&
                  " error"
                }`}
                classNamePrefix="pro-input"
                options={basicData?.data?.font_case}
                getOptionLabel={(option) => option?.name}
                getOptionValue={(option) => option?.id}
                value={keyFontCaseObj}
                onBlur={formik.handleBlur(`key_font_case${itmIndex}`)}
                onChange={(value) => {
                  formik?.setFieldValue(
                    `item_fields_SS.${itmIndex}.key_font_case`,
                    value?.id || null
                  );
                }}
                menuPlacement="auto"
              />
              {formik.touched?.item_fields_SS?.[itmIndex]?.key_font_case &&
                formik.errors?.item_fields_SS?.[itmIndex]?.key_font_case && (
                  <span className="error-text">
                    {formik.errors?.item_fields_SS?.[itmIndex]?.key_font_case}
                  </span>
                )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="input-wrap pro-mb-4">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Font Case
              </label>
              <Select
                id={`value_font_case${itmIndex}`}
                name={`value_font_case${itmIndex}`}
                placeholder={"Select"}
                className={`pro-input lg  ${
                  formik.touched?.item_fields_SS?.[itmIndex]?.value_font_case &&
                  formik.errors?.item_fields_SS?.[itmIndex]?.value_font_case &&
                  " error"
                }`}
                classNamePrefix="pro-input"
                options={basicData?.data?.font_case}
                getOptionLabel={(option) => option?.name}
                getOptionValue={(option) => option?.id}
                value={valueFontCaseObj}
                onBlur={formik.handleBlur(`value_font_case${itmIndex}`)}
                onChange={(value) => {
                  formik?.setFieldValue(
                    `item_fields_SS.${itmIndex}.value_font_case`,
                    value?.id || null
                  );
                }}
                menuPlacement="auto"
              />
              {formik.touched?.item_fields_SS?.[itmIndex]?.value_font_case &&
                formik.errors?.item_fields_SS?.[itmIndex]?.value_font_case && (
                  <span className="error-text">
                    {formik.errors?.item_fields_SS?.[itmIndex]?.value_font_case}
                  </span>
                )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="input-wrap pro-mb-4">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Order
              </label>
              <Select
                id={`priority${itmIndex}`}
                name={`priority${itmIndex}`}
                placeholder={"Select"}
                className={`pro-input lg  ${
                  formik.touched?.item_fields_SS?.[itmIndex]?.priority &&
                  formik.errors?.item_fields_SS?.[itmIndex]?.priority &&
                  " error"
                }`}
                classNamePrefix="pro-input"
                options={priorityU}
                getOptionLabel={(option) => option?.name}
                getOptionValue={(option) => option?.id}
                value={priorityObj}
                onBlur={formik.handleBlur(`priority${itmIndex}`)}
                onChange={(value) => {
                  formik?.setFieldValue(
                    `item_fields_SS.${itmIndex}.priority`,
                    value?.id || null
                  );
                }}
                menuPlacement="auto"
              />
              {formik.touched?.item_fields_SS?.[itmIndex]?.priority &&
                formik.errors?.item_fields_SS?.[itmIndex]?.priority && (
                  <span className="error-text">
                    {formik.errors?.item_fields_SS?.[itmIndex]?.priority}
                  </span>
                )}
            </div>
          </div>
        </div>
        <div className="row gx-2">
          <div className="col-md-6">
            <Input
              type="text"
              id={`unit`}
              name={`unit`}
              label={`Unit`}
              className={`pro-input lg ${
                formik.touched?.item_fields_SS?.[itmIndex]?.unit &&
                formik.errors?.item_fields_SS?.[itmIndex]?.unit &&
                " error"
              }`}
              {...formik.getFieldProps(`item_fields_SS.${itmIndex}.unit`)}
              error={
                formik.touched?.item_fields_SS?.[itmIndex]?.unit &&
                formik.errors?.item_fields_SS?.[itmIndex]?.unit && (
                  <span className="error-text">
                    {formik.errors?.item_fields_SS?.[itmIndex]?.unit}
                  </span>
                )
              }
              errorMessage={
                formik.touched?.item_fields_SS?.[itmIndex]?.unit &&
                formik.errors?.item_fields_SS?.[itmIndex]?.unit && (
                  <span className="error-text">
                    {formik.errors?.item_fields_SS?.[itmIndex]?.unit}
                  </span>
                )
              }
            />
          </div>
          <div className="col-md-6">
            <Input
              type="prefix"
              id={`prefix`}
              name={`prefix`}
              label={`Prefix`}
              className={`pro-input lg ${
                formik.touched?.item_fields_SS?.[itmIndex]?.prefix &&
                formik.errors?.item_fields_SS?.[itmIndex]?.prefix &&
                " error"
              }`}
              {...formik.getFieldProps(`item_fields_SS.${itmIndex}.prefix`)}
              error={
                formik.touched?.item_fields_SS?.[itmIndex]?.prefix &&
                formik.errors?.item_fields_SS?.[itmIndex]?.prefix && (
                  <span className="error-text">
                    {formik.errors?.item_fields_SS?.[itmIndex]?.prefix}
                  </span>
                )
              }
              errorMessage={
                formik.touched?.item_fields_SS?.[itmIndex]?.prefix &&
                formik.errors?.item_fields_SS?.[itmIndex]?.prefix && (
                  <span className="error-text">
                    {formik.errors?.item_fields_SS?.[itmIndex]?.prefix}
                  </span>
                )
              }
            />
          </div>
        </div>
        <div className="row gx-2">
          <div className="pro-check-box row pro-py-2">
            <div className="col-md-6">
              <input
                type="checkbox"
                className="pro-check"
                id="bold"
                name="pro-checkbox"
                checked={
                  Boolean(formik.values?.item_fields_SS[itmIndex].bold) ?? false
                }
                onChange={(e) => {
                  formik.setFieldValue(
                    `item_fields_SS.${itmIndex}.bold`,
                    !Boolean(formik.values?.item_fields_SS[itmIndex].bold) ??
                      false
                  );
                }}
                disabled={false}
              />
              <label htmlFor="day_check" className="pro-check-label">
                Bold
              </label>
            </div>
            <div className={`col-md-6 ${Style.display_text}`}>
              {"Ex:"}
              {formik.values?.item_fields_SS[itmIndex].bold ? (
                <b>{caseText}</b>
              ) : (
                caseText
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemFieldSS;
