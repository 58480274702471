import { getAxiosInstance } from "../../../api";

export const getDepartmentData = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/departments/edit?department_id=${id}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const addDepartment = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/departments/create`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const editDepartment = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.put(`/departments/update`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteDepartment = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.delete(`/departments/delete`, { data: body });
    return response;
  } catch (error) {
    return error.response.data;
  }
};
export const approveDepartmentRequest = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/departments/approve`, params);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
