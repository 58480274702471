import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import usePassAll from "./usePassAll";

const PassAll = ({ handlePassAll, itemId }) => {
  const {
    packingTypeData,
    packingTypeId,
    formik,
    handlePackingTypeChange,
    handleClose,
    handleRemarkChange,
    handleConfirm,
  } = usePassAll({
    handlePassAll,
    itemId,
  });
  return (
    <>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Packing Type
        </label>
        <Select
          id="packing_type"
          name="packing_type"
          placeholder={"Select"}
          className={`pro-input lg`}
          classNamePrefix="pro-input"
          options={packingTypeData ?? []}
          getOptionLabel={(option) => option?.packing_type}
          getOptionValue={(option) => option?._id}
          value={packingTypeId}
          onBlur={formik?.handleBlur("packing_type")}
          isMulti={false}
          onChange={(value) => handlePackingTypeChange(value)}
          menuPlacement="auto"
          isClearable
        />
      </div>
      <div className="input-wrap">
        <Input
          label={"Remark"}
          type="text"
          id="remark"
          name="remark"
          className={`pro-input lg `}
          onChange={(e) => handleRemarkChange(e)}
        />
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button className={"pro-btn-link lg pro-px-5"} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={handleConfirm}
        >
          {"Confirm"}
        </Button>
      </div>
    </>
  );
};

export default PassAll;
