import { useFormik } from "formik";

const useForm2 = ({ handleConfirm }) => {
  const initialValues = {
    status2: "",
    remark: "",
    rft_weight: "",
    rft_d_no: "",
    rft_percentage: "",
    cvd_weight: "",
    cvd_d_no: "",
    cvd_percentage: "",
    htpt_weight: "",
    htpt_d_no: "",
    htpt_percentage: "",
    cvd_pass_weight: "",
    cvd_pass_d_no: "",
    cvd_pass_percentage: "",
  };

  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const errors = {};
      let isAnySectionCompleted = false;
      if (values.rft_weight && values.rft_d_no && values.rft_percentage) {
        isAnySectionCompleted = true;
      }
      if (values.cvd_weight && values.cvd_d_no && values.cvd_percentage) {
        isAnySectionCompleted = true;
      }
      if (values.htpt_weight && values.htpt_d_no && values.htpt_percentage) {
        isAnySectionCompleted = true;
      }
      if (
        values.cvd_pass_weight &&
        values.cvd_pass_d_no &&
        values.cvd_pass_percentage
      ) {
        isAnySectionCompleted = true;
      }
      if (!values?.status2) {
        errors.status2 = "Select Status";
      }
      if (!values.rft_weight && !values.rft_d_no && !values.rft_percentage) {
        errors.rft_weight = "Enter a valid number";
        errors.rft_d_no = "Enter valid number";
        errors.rft_percentage = "Enter a valid percentage";
      }
      if (!values.cvd_weight && !values.cvd_d_no && !values.cvd_percentage) {
        errors.cvd_weight = "Enter a valid number";
        errors.cvd_d_no = "Enter valid number";
        errors.cvd_percentage = "Enter a valid percentage";
      }
      if (!values.htpt_weight && !values.htpt_d_no && !values.htpt_percentage) {
        errors.htpt_weight = "Enter a valid number";
        errors.htpt_d_no = "Enter valid number";
        errors.htpt_percentage = "Enter a valid percentage";
      }
      if (
        !values.cvd_pass_weight &&
        !values.cvd_pass_d_no &&
        !values.cvd_pass_percentage
      ) {
        errors.cvd_pass_weight = "Enter a valid number";
        errors.cvd_pass_d_no = "Enter valid number";
        errors.cvd_pass_percentage = "Enter a valid percentage";
      }
      // Validation for rft
      if (values.rft_weight || values.rft_d_no || values.rft_percentage) {
        if (!values.rft_weight || !/^(?:\d*\.)?\d+$/.test(values.rft_weight)) {
          errors.rft_weight = "Enter a valid number";
        }
        if (!values.rft_d_no || !/^[0-9]*$/.test(values.rft_d_no)) {
          errors.rft_d_no = "Enter valid number";
        }
        if (!values.rft_percentage) {
          errors.rft_percentage = "This field is required";
        } else if (!/^\d+(\.\d{1,2})?$/.test(values.rft_percentage)) {
          errors.rft_percentage = "Enter a valid percentage";
        } else {
          const numericValue = parseFloat(values.rft_percentage);
          if (numericValue > 100) {
            errors.rft_percentage = "Enter a valid percentage";
          }
        }
      }

      // Validation for cvd
      if (values.cvd_weight || values.cvd_d_no || values.cvd_percentage) {
        if (!values.cvd_weight || !/^(?:\d*\.)?\d+$/.test(values.cvd_weight)) {
          errors.cvd_weight = "Enter a valid number";
        }
        if (!values.cvd_d_no || !/^[0-9]*$/.test(values.cvd_d_no)) {
          errors.cvd_d_no = "Enter valid number";
        }
        if (!values.cvd_percentage) {
          errors.cvd_percentage = "This field is required";
        } else if (!/^\d+(\.\d{1,2})?$/.test(values.cvd_percentage)) {
          errors.cvd_percentage = "Enter a valid percentage";
        } else {
          const numericValue = parseFloat(values.cvd_percentage);
          if (numericValue > 100) {
            errors.cvd_percentage = "Enter a valid percentage";
          }
        }
      }

      // Validation for hpht
      if (values.htpt_weight || values.htpt_d_no || values.htpt_percentage) {
        if (
          !values.htpt_weight ||
          !/^(?:\d*\.)?\d+$/.test(values.htpt_weight)
        ) {
          errors.htpt_weight = "Enter a valid number";
        }
        if (!values.htpt_d_no || !/^[0-9]*$/.test(values.htpt_d_no)) {
          errors.htpt_d_no = "Enter valid number";
        }
        if (!values.htpt_percentage) {
          errors.htpt_percentage = "This field is required";
        } else if (!/^\d+(\.\d{1,2})?$/.test(values.htpt_percentage)) {
          errors.htpt_percentage = "Enter a valid percentage";
        } else {
          const numericValue = parseFloat(values.htpt_percentage);
          if (numericValue > 100) {
            errors.htpt_percentage = "Enter a valid percentage";
          }
        }
      }

      // Validation for cvd_pass
      if (
        values.cvd_pass_weight ||
        values.cvd_pass_d_no ||
        values.cvd_pass_percentage
      ) {
        if (
          !values.cvd_pass_weight ||
          !/^(?:\d*\.)?\d+$/.test(values.cvd_pass_weight)
        ) {
          errors.cvd_pass_weight = "Enter valid number";
        }
        if (!values.cvd_pass_d_no || !/^[0-9]*$/.test(values.cvd_pass_d_no)) {
          errors.cvd_pass_d_no = "Enter valid number";
        }
        if (!values.cvd_pass_percentage) {
          errors.cvd_pass_percentage = "This field is required";
        } else if (!/^\d+(\.\d{1,2})?$/.test(values.cvd_pass_percentage)) {
          errors.cvd_pass_percentage = "Enter a valid percentage";
        } else {
          const numericValue = parseFloat(values.cvd_pass_percentage);
          if (numericValue > 100) {
            errors.cvd_pass_percentage = "Enter a valid percentage";
          }
        }
      }
      // If at least one section is completed, clear errors for other sections
      if (isAnySectionCompleted) {
        clearSectionErrors(errors, "rft");
        clearSectionErrors(errors, "cvd");
        clearSectionErrors(errors, "htpt");
        clearSectionErrors(errors, "cvd_pass");
      }
      return errors;
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      handleConfirm(values);
    },
  });

  // Helper function to clear errors for a section
  function clearSectionErrors(errors, section) {
    delete errors[`${section}_weight`];
    delete errors[`${section}_d_no`];
    delete errors[`${section}_percentage`];
  }

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  return { formik, getFieldError };
};

export default useForm2;
