import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  pendingCardStatus: "idle",
  RecheckPendingCardStatus: "idle",
  ReissuePendingCardStatus: "idle",
  cardData: {
    Pending: [],
  },
  cardRecheckData: {
    Pending: [],
  },
  cardReissueData: {
    Pending: [],
  },
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  currentRecheckPage: 1,
  currentRecheckPageSize: 10,
  sortBy: "",
  sortOrder: "",
  search: "",
  showTable: false,
  selectedId: "",
  total: {
    Pending: "",
  },
  gli_id: "",
  clear: false,
  uploadModal: false,
  bulkUploadModal: false,
  certificateId: "",
  pendingSearch: "",
  pendingOrderSearch: "",
  editData: {
    photo1: {
      name: "",
      file: "",
      id: "",
    },
    photo2: {
      name: "",
      file: "",
      id: "",
    },
    remark: "",
  },

  values: {
    assetsImages: [
      {
        file: "",
        thumb: "",
      },
    ],
  },
  errors: {
    assetsImages: "",
  },
};

export const getPhotoSectionList = createAsyncThunk(
  "card/assigned-list",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `employee-dashboard/order/pending-queue`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getPhotoSectionRecheckList = createAsyncThunk(
  "employee-dashboard/recheck/pending-queue",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `employee-dashboard/recheck/pending-queue`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPhotoSectionReissueList = createAsyncThunk(
  "employee-dashboard/reissue/pending-queue",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `employee-dashboard/reissue/pending-queue`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//slice functions
const photoSectionSlice = createSlice({
  name: "photoSection",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      const payloadFunction = action.payload;
      
      // Ensure state.values is defined
      if (!state.values) {
        state.values = {};
      }
      
      // Apply the payload function to the state
      payloadFunction(state);

    },
  },
  resetValuesAndErrors: (state) => {
    state.values.assetsImages = [{ file: "", thumb: "" }];
    state.errors.assetsImages = "";
  },
  // pending list
  extraReducers: {
    [getPhotoSectionList.pending]: (state) => {
      state.pendingCardStatus = "pending";
    },
    [getPhotoSectionList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data?.data;
      const uniqueItems = {};
      const combinedPending = [];
      // Add existing items with unique _id values to uniqueItems
      state.cardData.Pending.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedPending.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedPending.push(item);
        }
      });

      if(state.search != "") {
        
        if(state.currentPage == 1) {
          state.cardData.Pending = newData;
        }
        else {
          if(state.cardData.Pending?.length <= action.payload.data.data.total_count) {
            state.cardData.Pending = [...state.cardData.Pending, ...newData];
          }  
        }
        
      }
      else {
        state.cardData.Pending = combinedPending;
    } 

      // state.cardData.Pending = combinedPending;
      state.total.Pending = action.payload.data.data.total_count;
      state.pendingCardStatus = "fulfilled";
    },
    [getPhotoSectionList.rejected]: (state, action) => {
      state.pendingCardStatus = "failed";
    },
    //recheck section
    [getPhotoSectionRecheckList.pending]: (state) => {
      state.RecheckPendingCardStatus = "pending";
    },
    [getPhotoSectionRecheckList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data?.data;
      const uniqueItems = {};
      const combinedPending = [];
      // Add existing items with unique _id values to uniqueItems
      state.cardRecheckData.Pending.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedPending.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedPending.push(item);
        }
      });
      state.cardRecheckData.Pending = combinedPending;
      state.total.Pending = action.payload.data.data.total_count;
      state.RecheckPendingCardStatus = "fulfilled";
    },
    [getPhotoSectionRecheckList.rejected]: (state, action) => {
      state.RecheckPendingCardStatus = "failed";
    },
    //reissue
    [getPhotoSectionReissueList.pending]: (state) => {
      state.ReissuePendingCardStatus = "pending";
    },
    [getPhotoSectionReissueList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data?.data;
      const uniqueItems = {};
      const combinedPending = [];
      // Add existing items with unique _id values to uniqueItems
      state.cardReissueData.Pending.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedPending.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedPending.push(item);
        }
      });
      state.cardReissueData.Pending = combinedPending;
      state.total.Pending = action.payload.data.data.total_count;
      state.ReissuePendingCardStatus = "fulfilled";
    },
    [getPhotoSectionReissueList.rejected]: (state, action) => {
      state.ReissuePendingCardStatus = "failed";
    },
  },
});

export const {  updateConfig, resetValuesAndErrors } = photoSectionSlice.actions;

export default photoSectionSlice.reducer;
