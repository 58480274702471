import { useFormik } from "formik";
import { useGetPrivateShopBasicDataQuery } from "../../../../store/queries/Master";
import countryData from "../../../../utils/components/countryCode";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Orders/orderSlice";
import { addShop } from "../api";
import { toast } from "react-toastify";

const useAddShop = () => {
  const dispatch = useDispatch();
  const { data: basicData = {} } = useGetPrivateShopBasicDataQuery();

  const formik = useFormik({
    initialValues: {
      name: "",
      prefix: "",
      contact_number: "",
      serial_number: "",
      billing_address_1: "",
      billing_address_2: "",
      billing_address_postal_code: "",
      billing_country: "",
      billing_state: "",
      billing_city: "",
      add_as: "",
      country_code: {
        name: "India",
        flag: "🇮🇳",
        code: "IN",
        label: "+91",
        value: "+91",
      },
    },
    validate: (values) => {
      let errors = {};

      if (!values.name) {
        errors.name = "Name is required";
      }
      if (!values.contact_number) {
        errors.contact_number = "Contact number is required";
      } else if (!/^\d+$/.test(values.contact_number)) {
        errors.contact_number = "Contact number must be a valid number";
      } else if (
        values.contact_number.length < 7 ||
        values.contact_number.length > 11
      ) {
        errors.contact_number = "Contact number is invalid";
      }

      if (!values.serial_number) {
        errors.serial_number = "Serial number is required";
      } else if (!/^\d+$/.test(values.serial_number)) {
        errors.serial_number = "Serial number should be an integer";
      } else if (
        values.serial_number.length < 3 ||
        values.serial_number.length > 10
      ) {
        errors.serial_number = "Serial number is invalid";
      }

      if (!values.add_as) {
        errors.add_as = "Add as is required";
      }
      if (!values.billing_country) {
        errors.billing_country = "*Required";
      }
      if (!values.billing_state) {
        errors.billing_state = "*Required";
      }

      if (
        values.billing_address_postal_code &&
        !/^\d{6}$/.test(values.billing_address_postal_code)
      ) {
        errors.billing_address_postal_code = "Postal code is invalid";
      }
      if (values?.prefix && values?.prefix?.length > 4) {
        errors.prefix = "Enter Valid Prefix";
      }
      return errors;
    },

    onSubmit: (values) => {
      let formData = new FormData();

      function appendToFormData(key, value) {
        if (value !== "" && value !== null && value !== undefined) {
          formData.append(key, value);
        }
      }
      appendToFormData("name", values?.name);
      appendToFormData("add_as", values?.add_as?.id);
      appendToFormData("serial_number", values?.serial_number);
      appendToFormData("prefix", values?.prefix);
      appendToFormData("country_code", values?.country_code?.value);
      appendToFormData("contact_number", values?.contact_number);
      appendToFormData(
        "billing_address[address_line1]",
        values?.billing_address_1
      );
      appendToFormData(
        "billing_address[address_line2]",
        values?.billing_address_2
      );
      appendToFormData(
        "billing_address[postal_code]",
        values?.billing_address_postal_code
      );
      appendToFormData("billing_address[country]", values?.billing_country?.id);
      appendToFormData("billing_address[state]", values?.billing_state?.id);
      appendToFormData("billing_address[city]", values?.billing_city);

      addShop(formData).then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Shop Added");
          handleClose();
        } else if (response?.status_code === 422) {
          let errors = response?.errors;
          let errorFields = Object.keys(errors);
          errorFields.forEach((field) => {
            formik.setFieldError(field, errors[field]);
          });
        } else {
          toast.error("Something went wrong");
        }
      });
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleClose = () => {
    dispatch(updateConfig((state) => (state.addShopModal = false)));
  };

  return {
    formik,
    handleClose,
    basicData: basicData?.data,
    countryCodeOptions: countryData,
    getFieldError,
  };
};

export default useAddShop;
