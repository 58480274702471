import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Style from "../../invoice.module.scss";

const Form = ({
  formik,
  handleClose,
  getFieldError,
  isChecked,
  handleCheckChange,
}) => {
  return (
    <div className="row ">
      <Input
        label={"Supplier Name"}
        type="text"
        id="supplier_name"
        name="supplier_name"
        className={`pro-input lg ${getFieldError("supplier_name") && " error"}`}
        {...formik.getFieldProps("supplier_name")}
        error={getFieldError("supplier_name")}
        errorMessage={getFieldError("supplier_name")}
      />
      <div className={Style.container}>
        <small className={Style.head}>Shop Details</small>
        <Input
          label={"Shop Name"}
          type="text"
          id="shop_name"
          name="shop_name"
          className={`pro-input lg ${getFieldError("shop_name") && " error"}`}
          {...formik.getFieldProps("shop_name")}
          error={getFieldError("shop_name")}
          errorMessage={getFieldError("shop_name")}
        />
        <Input
          label={"Address Line 1"}
          type="text"
          id="address_line1"
          name="address_line1"
          className={`pro-input lg ${
            getFieldError("address_line1") && " error"
          }`}
          {...formik.getFieldProps("address_line1")}
          error={getFieldError("address_line1")}
          errorMessage={getFieldError("address_line1")}
        />
        <Input
          label={"Address Line 2"}
          type="text"
          id="address_line2"
          name="address_line2"
          className={`pro-input lg ${
            getFieldError("address_line2") && " error"
          }`}
          {...formik.getFieldProps("address_line2")}
          error={getFieldError("address_line2")}
          errorMessage={getFieldError("address_line2")}
        />
        <Input
          label={"City"}
          type="text"
          id="city"
          name="city"
          className={`pro-input lg ${getFieldError("city") && " error"}`}
          {...formik.getFieldProps("city")}
          error={getFieldError("city")}
          errorMessage={getFieldError("city")}
        />
        <Input
          label={"Postal Code"}
          type="text"
          id="postal_code"
          name="postal_code"
          className={`pro-input lg ${getFieldError("postal_code") && " error"}`}
          {...formik.getFieldProps("postal_code")}
          error={getFieldError("postal_code")}
          errorMessage={getFieldError("postal_code")}
        />
        <Input
          label={"GST Number"}
          type="text"
          id="gst_number"
          name="gst_number"
          className={`pro-input lg ${getFieldError("gst_number") && " error"}`}
          {...formik.getFieldProps("gst_number")}
          error={getFieldError("gst_number")}
          errorMessage={getFieldError("gst_number")}
        />
      </div>
      <Input
        label={"Discount Amount"}
        type="text"
        id="discount_amount"
        name="discount_amount"
        className={`pro-input lg ${
          getFieldError("discount_amount") && " error"
        }`}
        {...formik.getFieldProps("discount_amount")}
        error={getFieldError("discount_amount")}
        errorMessage={getFieldError("discount_amount")}
      />
      <div className="pro-check-box">
        <input
          type="checkbox"
          className="pro-check"
          id="courier_charge"
          name="courier_charge"
          onChange={handleCheckChange}
          checked={isChecked}
        />
        <label htmlFor="courier_charge" className="pro-check-label">
          {"Courier Charge "}
        </label>
      </div>
      {isChecked && (
        <div className={Style.courier}>
          <Input
            label={"SAC Number"}
            type="text"
            id="sac_number"
            name="sac_number"
            className={`pro-input lg ${
              getFieldError("sac_number") && " error"
            }`}
            {...formik.getFieldProps("sac_number")}
            error={getFieldError("sac_number")}
            errorMessage={getFieldError("sac_number")}
          />
          <Input
            label={"Courier Charge"}
            type="text"
            id="courier_charge"
            name="amount"
            className={`pro-input lg ${getFieldError("amount") && " error"}`}
            {...formik.getFieldProps("amount")}
            error={getFieldError("amount")}
            errorMessage={getFieldError("amount")}
          />
        </div>
      )}
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button className={"pro-btn-link lg pro-px-5"} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {"Update"}
        </Button>
      </div>
    </div>
  );
};

export default Form;
