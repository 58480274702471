import { getAxiosInstance } from "../../../api";

export const getCertificatePrefixData = async (certificate_prefix_id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `/certificate-prefix/edit?certificate_prefix_id=${certificate_prefix_id}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const approveCertificateData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/certificate-prefix/approve`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
