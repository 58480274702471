import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/MainReport/mainReportSlice";
import { uploadExcelFiles } from "../../api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const useExcelUpload = () => {
  const dispatch = useDispatch();
  const reportState = useSelector((state) => state.mainReport);
  const initialValues = {
    qc_report: "",
    remarks: "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: "",
    onSubmit: async (values) => {
      let newObj = Object.entries(values);
      newObj = newObj
        .filter(
          (item) => item[1] !== undefined && item[1] !== "" && item[1] !== null
        )
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});

      const formData = new FormData();
      Object.keys(newObj).forEach((key) => {
        formData.append(key, values[key]);
      });
      formData.append("receipt_id", reportState?.selectedId);
      try {
        const response = await uploadExcelFiles(formData);
        if (response?.data?.status_code === 200) {
          toast.success(response?.data?.message);
          dispatch(
            updateConfig((state) => {
              state.showExcelUploadModal = false;
            })
          );
        } else if (response?.status_code === 422 && response?.errors) {
          Object.keys(response?.errors).forEach((field) => {
            formik.setFieldError(field, response?.errors[field]);
          });
        } else {
          toast.error(`Unable to upload file, Please try again`);
        }
      } catch (error) {
        console.error(`Unable to upload file:`, error);
      }
    },
  });
  const handleClose = () => {
    dispatch(
      updateConfig((state) => {
        state.showExcelUploadModal = false;
      })
    );
  };
  const handleExcelUpload = async (e) => {
    const selectedFile = e?.target?.files?.[0];
    const fileTypes = [
      ".csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];
    if (selectedFile) {
      if (fileTypes?.includes(selectedFile?.type)) {
        if (selectedFile?.size <= 2 * 1024 * 1024) {
          formik?.setFieldValue("qc_report", e?.target?.files?.[0]);
        } else {
          formik?.setFieldError(
            "qc_report",
            "File size should be less than 2MB"
          );
        }
      } else {
        formik?.setFieldError("qc_report", "Invalid file type");
      }
    }
  };
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  return {
    formik,
    handleClose,
    getFieldError,
    handleExcelUpload,
  };
};

export default useExcelUpload;
