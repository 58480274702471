import { getAxiosInstance } from "../../../api";

export const PrintCertficate = async (body) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`/printing-report/recheck/create`, body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const passAllRecheck = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(
      `/employee-dashboard/recheck/bulk-status-complete`,
      params
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
