import { getAxiosInstance } from "../../../api";

export const getItemGradingData = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/item-grading/edit?item_grading_id=${id}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const updateItemGradingData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/item-grading/save`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteItem = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.delete(`/item-grading/delete`, { data: body });
    return response;
  } catch (error) {
    return error.response.data;
  }
};
export const approveItemGradingRequest = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/item-grading/approve`, params);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
