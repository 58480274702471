import { getAxiosInstance } from "../../../api";

export const UploadRecheckPhoto = async (body) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(
      `/photo-uploading/recheck/designid-image-upload`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const UploadReissuePhoto = async (body) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(
      `/photo-uploading/reissue/designid-image-upload`,
      body
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const editedRecheckData = async (id) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(
      `/photo-uploading/recheck/get-images?recheck_item_details_id=${id}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const editedRecheckData2 = async (id) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(
      `/photo-uploading/recheck/get-designid-images?id=${id}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const BulkUploadPhoto = async (body) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`/photo-uploading/recheck/bulk-image-upload`, body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const deleteRecheckPhoto = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(
      `/photo-uploading/recheck/delete-item-details-image`,
      params
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const deleteRecheckPhoto2 = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(
      `/photo-uploading/recheck/delete-design-id-image`,
      params
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
