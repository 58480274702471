import { Pagination, Table } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import EmptyData from "../../../Global/EmptyData";
import Select from "react-select";
import useEmployeeReport from "./useEmployeeReport";
import { FaSort } from "react-icons/fa";

const EmployeeReport = ({ basicData }) => {
  const {
    formik,
    isFetching,
    isLoading,
    reportState,
    employeeReportData,
    paginationOptions,
    getRow,
    handlePageSize,
    handlePagination,
    handleSearch,
    handleSort,
  } = useEmployeeReport();

  return (
    <div className="col-auto pro-pt-3 pro-pb-6">
      <div>
        <div className="col-12">
          <div className="row gx-2 gy-2 pro-items-end">
            <div className={`col-3`}>
              <div className="input-wrap">
                <input
                  type="text"
                  onChange={(e) => {
                    handleSearch(e);
                  }}
                  placeholder="Search this table"
                  className={`pro-input icon-r lg`}
                  value={reportState?.otherReport?.search}
                />
                <span className="pro-icon">
                  {<span className="material-symbols-outlined">search</span>}
                </span>
              </div>
            </div>
            <div className="col-3">
              <div className="input-wrap ">
                <label
                  htmlFor="branch"
                  className="pro-font-sm pro-mb-1 pro-fw-medium"
                >
                  Branch
                </label>
                <Select
                  id="branch"
                  placeholder={"Select"}
                  className={"pro-input lg"}
                  classNamePrefix="pro-input"
                  name="branch"
                  options={basicData?.branch_list}
                  getOptionValue={(option) => option?._id}
                  getOptionLabel={(option) => option?.branch_name}
                  value={formik?.values?.branch}
                  onChange={(value) => formik.setFieldValue("branch", value)}
                  menuPlacement="auto"
                />
              </div>
            </div>
          </div>
        </div>
        {employeeReportData?.data?.data?.length > 0 ? (
          <div className="pro-mt-4">
            <Table
              multiSelect={false}
              data={employeeReportData?.data?.data || []}
              uniqueID={"id"}
              fields={employeeReportData?.data?.fields}
              SortIcon={<FaSort />}
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching || isLoading}
              perpage={reportState?.otherReport?.currentPageSize}
              assignable={false}
              editable={false}
              deletable={false}
              showCheckBox={false}
            />
            {employeeReportData?.data?.data?.length > 0 && (
              <Pagination
                currentPage={reportState?.otherReport?.currentPage}
                totalPageCount={Math.ceil(
                  employeeReportData?.data?.total_count /
                    reportState?.otherReport?.currentPageSize
                )}
                onPageChange={handlePagination}
                options={paginationOptions}
                onActionChange={handlePageSize}
                center
              />
            )}
          </div>
        ) : (
          <EmptyData />
        )}
      </div>
    </div>
  );
};

export default EmployeeReport;
