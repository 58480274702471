import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Master/Shop/shopSlice";
import countryData from "../../../../utils/components/countryCode";
import { useGetShopBasicDataQuery } from "../../../../store/queries/Master";
import * as Yup from "yup";
import { emailRegExp } from "../../../../utils/functions/table";
import { useEffect, useState } from "react";
import { addShopData, editShopData } from "../api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Basic from "./Basic";
import Billing from "./Billing";
import Contact from "./Contact";

const useAddShop = ({ refetch }) => {
  const [moveToTab, setMoveToTab] = useState(false);
  const dispatch = useDispatch();
  const { data: basicData = {}, isLoading } = useGetShopBasicDataQuery();
  const { shopData, isEdit, selectedShop, activeTab, completedTabs } =
    useSelector((state) => state.shop);
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    setPhoneCodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopData, isEdit]);

  useEffect(() => {
    formik?.resetForm();
    if (shopData && isEdit) {
      Object.keys(shopData || {}).forEach((key) => {
        if (key === "address") {
          const Country = basicData?.data?.country_list.filter(
            (value) => value?.id === shopData?.[key]?.country
          );
          const State = basicData?.data?.state_list.filter(
            (value) => value?.id === shopData?.[key]?.state
          );
          formik?.setFieldValue(
            "address_1",
            shopData?.[key]?.address_line1 ?? ""
          );
          formik?.setFieldValue(
            "address_2",
            shopData?.[key]?.address_line2 ?? ""
          );
          formik?.setFieldValue("city", shopData?.[key]?.city ?? "");
          formik?.setFieldValue(
            "postal_code",
            shopData?.[key]?.postal_code ?? ""
          );
          formik?.setFieldValue("country", Country?.[0] ?? "");
          formik?.setFieldValue("state", State?.[0] ?? "");
        } else if (key === "billing_address") {
          const billingCountry = basicData?.data?.country_list.filter(
            (value) => value?.id === shopData?.[key]?.country
          );
          const billingState = basicData?.data?.state_list.filter(
            (value) => value?.id === shopData?.[key]?.state
          );
          formik?.setFieldValue(
            "billing_address_1",
            shopData?.[key]?.address_line1 ?? ""
          );
          formik?.setFieldValue(
            "billing_address_2",
            shopData?.[key]?.address_line2 ?? ""
          );
          formik?.setFieldValue("billing_city", shopData?.[key]?.city ?? "");
          formik?.setFieldValue(
            "billing_postal_code",
            shopData?.[key]?.postal_code
          );
          formik?.setFieldValue("billing_country", billingCountry?.[0] ?? "");
          formik?.setFieldValue("billing_state", billingState?.[0] ?? "");
        } else if (key === "online_payment") {
          if (shopData?.[key] === 1) {
            setIsChecked(true);
          } else {
            setIsChecked(false);
          }
        } else if (key === "bank_details") {
          formik?.setFieldValue("bank_name", shopData?.[key]?.[0]?.name ?? "");
          formik?.setFieldValue(
            "ac_number",
            shopData?.[key]?.[0]?.account_number
          );
          formik?.setFieldValue("ifsc", shopData?.[key]?.[0]?.ifsc ?? "");
          formik?.setFieldValue(
            "bank_address",
            shopData?.[key]?.[0]?.address ?? ""
          );
        } else if (key === "parent") {
          const parent = basicData?.data?.parent_shop?.filter(
            (value) => value?._id === shopData?.[key]?.parent_id
          );
          formik?.setFieldValue("parent_shop", parent ?? "");
        } 
        else if (key === "status") {
          
          const status = basicData?.data?.status_list?.filter(
            (value) => value?.id === shopData?.[key]
          )?.[0];
          
          formik?.setFieldValue("status", status ?? "");
        } 
        else if (key === "reset_prefix") {
          const prefix = basicData?.data?.reset_prefix?.filter(
            (value) => value?.id === shopData?.[key]
          );
          formik?.setFieldValue("reset_prefix", prefix?.[0] ?? "");
        } else if (key === "secondary_contact") {
          if (!shopData?.[key]?.country_code) {
            formik?.setFieldValue("sec_country_code", {
              name: "India",
              flag: "🇮🇳",
              code: "IN",
              label: "+91",
              value: "+91",
            });
          } else {
            const countryCode = countryData?.filter(
              (value) => value?.value === shopData?.[key]?.country_code
            );
            formik?.setFieldValue(
              "sec_country_code",
              countryCode?.[0] ?? {
                name: "India",
                flag: "🇮🇳",
                code: "IN",
                label: "+91",
                value: "+91",
              }
            );
          }
          formik?.setFieldValue(
            "sec_number",
            shopData?.[key]?.contact_number ?? ""
          );
          formik?.setFieldValue("sec_name", shopData?.[key]?.name ?? "");
          formik?.setFieldValue("sec_email", shopData?.[key]?.email_id ?? "");
          formik?.setFieldValue("sec_address", shopData?.[key]?.address ?? "");
          // } else if (key === "logos") {
          //   const matchedObjects = basicData?.data?.item_type?.filter((obj1) => {
          //     return shopData?.[key]?.some(
          //       (obj2) => obj2.item_type_id === obj1._id
          //     );
          //   });
          // shopData?.[key].map((item, index) =>
          //   formik?.setFieldValue(`items.${index}.image.name`, item?.name)
          // );
          // matchedObjects?.forEach((item, index) =>
          //   formik?.setFieldValue(`items.${index}.item_type`, item)
          // );
        } else if (key === "country_code") {
          const countryCode = countryData.filter(
            (value) => value?.value === shopData?.[key]
          );
          formik?.setFieldValue("country_code", countryCode?.[0]);
        } else if (key === "add_as") {
          const addAss = basicData?.data?.add_as.filter(
            (item) => item?.id === shopData?.[key]
          );
          formik?.setFieldValue("category", addAss?.[0]);
        } else {
          formik.setFieldValue(key, shopData?.[key] ?? "");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopData, isLoading]);

  const validation = Yup.object({
    name: Yup.string()
      .trim()
      .required("Enter Name")
      .max(80, "Enter valid Name"),
    location: Yup.string()
      .trim()
      .required("Enter Location")
      .max(100, "Enter valid location"),
    prefix: Yup.string()
      .trim()
      // .required("Enter Prefix")
      .max(5, "Enter valid Prefix"),
    certification_prefix: Yup.string()
      .trim()
      // .required("Enter Prefix")
      .max(5, "Enter valid Prefix"),
    short_name: Yup.string()
      .trim()
      .required("Enter Short name")
      .max(20, "Enter valid Short name"),
    address_1: Yup.string().trim().required("Enter Address line 1"),
    address_2: Yup.string().trim().required("Enter Address line 2"),
    city: Yup.string().trim().required("Enter city"),
    state: Yup.object().required("Select Sate"),
    country: Yup.object().required("Select Country"),
    postal_code: Yup.string()
      .required("Enter postal code")
      .matches(/^[1-9][0-9]{5}$/, "Enter Valid Postal code"),
    billing_address_1: Yup.string().trim().required("Enter Address line 1"),
    billing_address_2: Yup.string().trim().required("Enter Address line 2"),
    billing_city: Yup.string().trim().required("Enter city"),
    billing_state: Yup.object().required("Select Sate"),
    billing_country: Yup.object().required("Select Country"),
    billing_postal_code: Yup.string()
      .required("Enter postal code")
      .matches(/^[1-9][0-9]{5}$/, "Enter Valid Postal code"),
    contact_number: Yup.string()
      .nullable()
      .min(7, "Enter Valid Number")
      .max(14, "Enter valid Number")
      .matches(/^[0-9]*$/, "Enter Valid Number"),
    email_id: Yup.string()
      // .required("Enter Email")
      .matches(emailRegExp, "Enter valid Email ID"),
    gst_number: Yup.string().matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      "Enter valid GST number"
    ),
    bank_name:
      isChecked &&
      Yup.string().required("Enter bank name").max(70, "Enter valid bank name"),
    ac_number:
      isChecked &&
      Yup.string()
        .required("Enter Account Number")
        .matches(/^\d{9,18}$/, "Enter Valid Account Number"),
    ifsc:
      isChecked &&
      Yup.string()
        .required("Enter IFSC code ")
        .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Enter Valid IFSC code"),
    bank_address:
      isChecked &&
      Yup.string()
        .required("Enter Bank address")
        .max(100, "Not a Valid Address"),
    category: Yup.object().required("Select One"),
    status: Yup.object().required("Select One"),
    reset_prefix: Yup.object().required("Select One"),
    serial_number:
      !isEdit &&
      Yup.string()
        .required("Enter Serial Number")
        .matches(/^[0-9]*$/, "Enter valid serial number"),
    user_name:
      !isEdit &&
      Yup.string()
        .required("Enter Username")
        .max(40, "Not a Valid Username")
        .min(5, "Please Enter atleast 5 letters"),
    password:
      !isEdit &&
      Yup.string()
        .required("Enter Password")
        .min(5, "Please Enter atleast 5 letters"),
    sec_email: Yup.string().matches(emailRegExp, "Enter valid Email ID"),
    sec_number: Yup.string()
      .nullable()
      .min(7, "Enter Valid Number")
      .max(14, "Enter valid Number")
      .matches(/^[0-9]*$/, "Enter Valid Number"),
  });
  const initialValues = {
    name: "",
    location: "",
    prefix: "",
    certification_prefix: "",
    short_name: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
    billing_address_1: "",
    billing_address_2: "",
    billing_city: "",
    billing_state: "",
    billing_country: "",
    billing_postal_code: "",
    country_code: {
      name: "India",
      flag: "🇮🇳",
      code: "IN",
      label: "+91",
      value: "+91",
    },
    contact_number: "",
    email_id: "",
    gst_number: "",
    is_online: "",
    bank_name: "",
    ac_number: "",
    ifsc: "",
    bank_address: "",
    parent_shop: "",
    category: "",
    status: "",
    reset_prefix: "",
    serial_number: "",
    user_name: "",
    password: "",
    sec_name: "",
    sec_number: "",
    sec_email: "",
    sec_address: "",
    sec_country_code: {
      name: "India",
      flag: "🇮🇳",
      code: "IN",
      label: "+91",
      value: "+91",
    },
    // items: [
    //   {
    //     item_type: "",
    //     image: "",
    //   },
    // ],
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validation,
    onSubmit: (values) => {
      // values?.items.map((value, index) => {
      //   if (value?.item_type !== "" && value?.image === "") {
      //     formik?.setFieldError(`items.${index}.image`, "Select one image");
      //   }
      //   return "";
      // });
      const data = {
        name: values?.name,
        location: values?.location,
        prefix: values?.prefix,
        certification_prefix: values?.certification_prefix,
        short_name: values?.short_name,
        "address[address_line1]": values?.address_1,
        "address[address_line2]": values?.address_2,
        "address[city]": values?.city,
        "address[state]": values?.state.id,
        "address[country]": values?.country?.id,
        "address[postal_code]": values?.postal_code,
        "billing_address[address_line1]": values?.billing_address_1,
        "billing_address[address_line2]": values?.billing_address_2,
        "billing_address[city]": values?.billing_city,
        "billing_address[state]": values?.billing_state.id,
        "billing_address[country]": values?.billing_country?.id,
        "billing_address[postal_code]": values?.billing_postal_code,
        contact_number: values?.contact_number,
        email_id: values?.email_id,
        gst_number: values?.gst_number,
        online_payment: isChecked ? "1" : "0",
        "bank_details[0][name]": isChecked ? values?.bank_name : "",
        "bank_details[0][account_number]": isChecked ? values?.ac_number : "",
        "bank_details[0][ifsc]": isChecked ? values?.ifsc : "",
        "bank_details[0][address]": isChecked ? values?.bank_address : "",
        parent_id: values?.parent_shop?._id,
        add_as: values?.category?.id,
        status: values?.status?.id,
        reset_prefix: values?.reset_prefix?.id,
        serial_number: values?.serial_number,
        user_name: values?.user_name,
        password: values?.password,
        country_code: values?.contact_number ? values?.country_code?.value : "",
        "secondary_contact[name]": values?.sec_name,
        "secondary_contact[email_id]": values?.sec_email,
        "secondary_contact[country_code]": values?.sec_number
          ? values?.sec_country_code?.value
          : "",
        "secondary_contact[contact_number]": values?.sec_number,
        "secondary_contact[address]": values?.sec_address,
      };

      let newObj = Object.entries(data);
      newObj = newObj
        .filter(
          (item) => item[1] !== undefined && item[1] !== "" && item[1] !== null
        )
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
      let formData = new FormData();
      for (let key in newObj) {
        formData.append(key, data[key]);
      }
      // values?.items.forEach((item, index) => {
      //   if (
      //     item?.item_type?._id !== undefined &&
      //     item?.item_type?._id !== null &&
      //     item?.item_type?._id !== ""
      //   )
      //     return formData.append(
      //       `logos[${index}][item_type_id]`,
      //       item.item_type?._id
      //     );
      // });
      // values?.items.forEach((item, index) => {
      //   if (item?.image?.type !== undefined)
      //     return formData?.append(`logos[${index}][file]`, item?.image);
      // });

      if (isEdit) {
        formData.append("supplier_shop_id", selectedShop);
        editShopData(formData).then((response) => {
          if (response?.data?.status_code === 200) {
            toast.success("Item Edited Successfully");
            dispatch(updateConfig((state) => (state.showAddShopModal = false)));
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
            refetch();
          } else if (response?.status_code === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
            handleMoveToErrorTab();
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        addShopData(formData).then((response) => {
          if (response?.data?.status_code === 200) {
            toast.success("Item Added Successfully");
            dispatch(updateConfig((state) => (state.showAddShopModal = false)));
            refetch();
          } else if (response?.status_code === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.showAddShopModal = false)));
  };

  function calculateCompletionStatus(fields) {
    let finalArray = [];
    finalArray = fields.filter(
      (value) => value !== "country_code" && value !== "sec_country_code"
    );
    // eslint-disable-next-line array-callback-return
    var completedFields = finalArray?.filter((field) => {
      if (Array.isArray(formik?.values[field])) {
        return formik?.values[field]?.length > 0;
      } else {
        return !!formik?.values[field];
      }
    });

    return (completedFields.length / fields.length) * 120;
  }

  const tabs = [
    {
      label: "Basic",
      completed: calculateCompletionStatus([
        "name",
        "location",
        "prefix",
        "certification_prefix",
        "short_name",
        "address_1",
        "address_2",
        "city",
        "state",
        "country",
        "postal_code",
        "status"
      ]),
    },
    {
      label: "Billing",
      completed: calculateCompletionStatus([
        "billing_address_1",
        "billing_address_2",
        "billing_city",
        "billing_state",
        "billing_country",
        "billing_postal_code",
        "country_code",
        "contact_number",
        "email_id",
        "gst_number",
        "is_online",
        "bank_name",
        "ac_number",
        "ifsc",
        "bank_address",
      ]),
    },
    {
      label: "Contact",
      completed: calculateCompletionStatus([
        "parent_shop",
        "category",
        "reset_prefix",
        "serial_number",
        "user_name",
        "password",
        "sec_name",
        "sec_number",
        "sec_email",
        "sec_address",
        "sec_country_code",
      ]),
    },
  ];

  const tabsAndFields = [
    {
      label: "Basic",
      fields: [
        "name",
        "location",
        "prefix",
        "certification_prefix",
        "short_name",
        "address_1",
        "address_2",
        "city",
        "state",
        "country",
        "postal_code",
        "status"
      ],
    },
    {
      label: "Billing",
      fields: [
        "billing_address_1",
        "billing_address_2",
        "billing_city",
        "billing_state",
        "billing_country",
        "billing_postal_code",
        "country_code",
        "contact_number",
        "email_id",
        "gst_number",
        "is_online",
        "bank_name",
        "ac_number",
        "ifsc",
        "bank_address",
      ],
    },
    {
      label: "Contact",
      fields: [
        "parent_shop",
        "category",
        "reset_prefix",
        "serial_number",
        "user_name",
        "password",
        "sec_name",
        "sec_number",
        "sec_email",
        "sec_address",
        "sec_country_code",
      ],
    },
  ];

  useEffect(() => {
    if (moveToTab === true) {
      handleMoveToErrorTab();
    }
    setMoveToTab(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.errors, moveToTab]);

  const handleTabClick = (tab) => {
    dispatch(
      updateConfig((state) => {
        state.activeTab = tab?.label;
      })
    );
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "Basic":
        return (
          <Basic
            formik={formik}
            tabs={tabs}
            basicData={basicData?.data}
            getFieldError={getFieldError}
          />
        );
      case "Billing":
        return (
          <Billing
            formik={formik}
            basicData={basicData?.data}
            tabs={tabs}
            getFieldError={getFieldError}
            handleChecked={handleChecked}
            isChecked={isChecked}
          />
        );
      case "Contact":
        return (
          <Contact
            formik={formik}
            basicData={basicData?.data}
            getFieldError={getFieldError}
            tabs={tabs}
            checkError={handleMoveToErrorTab}
          />
        );
      default:
        return null;
    }
  };

  const handleChecked = () => {
    setIsChecked(!isChecked);
  };

  function setActiveTabByName(active) {
    setMoveToTab(false);
    const updatedTab = tabs.find((tab) => tab?.label === active);
    dispatch(
      updateConfig((state) => {
        state.activeTab = updatedTab?.label;
      })
    );
  }

  const handleMoveToErrorTab = () => {
    // Move activeTab to the first tab with errors
    const firstErrorTab = Object.keys(formik.errors)?.[0];

    if (firstErrorTab === "group") {
      setActiveTabByName("Item");
      return "";
    }
    if (firstErrorTab && firstErrorTab !== activeTab) {
      const errorTab = tabsAndFields.find((tab) =>
        tab.fields.includes(firstErrorTab)
      );

      setActiveTabByName(errorTab?.label);
    }
    setMoveToTab(true);
  };

  const setPhoneCodes = () => {
    if (!isEdit) {
      formik?.setFieldValue("sec_country_code", {
        name: "India",
        flag: "🇮🇳",
        code: "IN",
        label: "+91",
        value: "+91",
      });
      formik?.setFieldValue("country_code", {
        name: "India",
        flag: "🇮🇳",
        code: "IN",
        label: "+91",
        value: "+91",
      });
    } else {
      Object.keys(shopData || {}).forEach((key) => {
        if (key === "country_code") {
          if (
            shopData?.[key] === "" ||
            shopData?.[key] === null ||
            shopData?.[key] === undefined
          ) {
            formik?.setFieldValue("country_code", {
              name: "India",
              flag: "🇮🇳",
              code: "IN",
              label: "+91",
              value: "+91",
            });
          }
        }
      });
    }
  };
  return {
    formik,
    isChecked,
    completedTabs,
    isEdit,
    tabs,
    activeTab,
    getFieldError,
    handleCloseModal,
    countryData,
    handleChecked,
    renderTabContent,
    handleTabClick,
  };
};

export default useAddShop;
