import { getAxiosInstance } from "../../../../api";

export const PrintCertficate = async (body) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`/printing-report/create`, body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};
