import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../store/slices/Master/Shop/shopSlice";

const useBasic = () => {
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddShopModal = false;
      })
    );
  };

  const handleNext = () => {
    dispatch(
      updateConfig((state) => {
        state.activeTab = "Billing";
      })
    );
    document.getElementById('element')?.scrollIntoView({ behavior: 'smooth' });
  };
  return { handleCloseModal, handleNext };
};

export default useBasic;
