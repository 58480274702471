import { useEffect, useRef, useState } from "react";

const useGliCardListing = ({
  data,
  handleLoadMore,
  setIsLoading,
  totalLength,
  scrollRef,
  isOverView,
}) => {
  let debounceTimeout;
  const [mouseHover, setMouseHover] = useState(isOverView ? "overView" : "");
  const scrollRefs = useRef([]);
  useEffect(() => {
    function isScrollAtEnd(element) {
      return (
        element?.scrollTop + element?.clientHeight + 1 >= element?.scrollHeight
      );
    }
    function handleScroll() {
      const element = isOverView
        ? scrollRef?.current
        : scrollRefs.current[mouseHover];
      if (
        isScrollAtEnd(element) &&
        data?.[mouseHover]?.length !== totalLength?.[`${mouseHover}`]
      ) {
        setIsLoading(true);
        clearTimeout(debounceTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        debounceTimeout = setTimeout(() => {
          handleLoadMore(mouseHover);
          
        }, 100);
      }
    }
    const element = isOverView
      ? scrollRef?.current
      : scrollRefs.current[mouseHover];

    element?.addEventListener("scroll", handleScroll);

    return () => {
      element?.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOverView ? scrollRef : scrollRefs, mouseHover, handleLoadMore]);

  const handleMouseOver = (e) => {
    setMouseHover(e);
  };

  return { scrollRefs, mouseHover, handleMouseOver };
};

export default useGliCardListing;
