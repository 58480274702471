import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getApprovedList,
  getPendingList,
  getPendingListById,
} from "../../../store/slices/Verification/verificationSlice";
import { toast } from "react-toastify";
import { approveStockData, rejectStockData } from "../../Orders/Order/api";
import { updateConfig } from "../../../store/slices/Verification/verificationSlice";
import { updateConfig as updateConfigApproved } from "../../../store/slices/Verification/approvedSlice";
import { debounce } from "../../../utils/hooks/useDebounce";

const useVerification = () => {
  const data = [{ title: "Pending Queue" }, { title: "Approved" }];
  const state = useSelector((state) => state.verification);
  const [pendingPage, setPendingPage] = useState(1);
  const [approvedPage, setApprovedPage] = useState(1);
  const [showDetail, setShowDetail] = useState(false);
  const [filterShow, setFilterShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [gliNumber, setGliNumber] = useState("");
  const [showApproved, setShowApproved] = useState(false);
  const [activeCard, setActiveCard] = useState({ id: "", key: "" });
  const barCodeRef = useRef(null);
  const officeSlipRef = useRef(null);
  const StockReceiptRef = useRef(null);
  const states = state?.verificationData;
  const dispatch = useDispatch();
  const handleApproveRequest = () => {
    handleApprove();
    approveStockData(state?.pendingData?._id).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Stock verified successfully");
        dispatch(
          updateConfig((state) => {
            state.verificationData.Pending = [];
            state.verificationData.Approved = [];
          })
        );
        dispatch(
          getPendingList({
            page: 1,
            per_page: 10,
          })
        );
        dispatch(
          getApprovedList({
            page: 1,
            per_page: 10,
          })
        );
        setShowDetail(false);
      } else {
        toast.error(response?.message);
        setShowDetail(false);
      }
    });
  };

  const handleLoadMore = (mouseHover) => {
    if (
      mouseHover === "Pending" &&
      states?.Pending?.length !== state?.PendingTotal
    ) {
      setPendingPage(pendingPage + 1);
    }
    if (
      mouseHover === "Approved" &&
      states?.Approved?.length !== state?.ApprovedTotal
    ) {
      setApprovedPage(approvedPage + 1);
    }
  };

  useEffect(() => {
    dispatch(
      getPendingList({
        page: pendingPage,
        per_page: 10,
      })
    );
    // eslint-disable-next-line
  }, [pendingPage]);

  useEffect(() => {
    dispatch(
      getApprovedList({
        page: approvedPage,
        per_page: 10,
      })
    );
    // eslint-disable-next-line
  }, [approvedPage]);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.approvedStatus, state?.pendingStatus]);

  const handleSearchInput = (e) => {
    const searchValue = e.target.value;
    dispatch(
      updateConfig((state) => {
        state.verificationData.Pending = [];
        state.verificationData.Approved = [];
      })
    );
    dispatch(
      getPendingList({
        search: searchValue,
        page: 1,
      })
    );
    dispatch(
      getApprovedList({
        search: searchValue,
        page: 1,
      })
    );
  };

  // Inside your component
  const handleSearch = debounce(handleSearchInput, 1000);
  const formik = useFormik({
    initialValues: {
      Sl_no: "",
      GLI_no: "",
      Received_from: "",
      For_shop: "",
      Fresh_or_recheck: "",
      Item_type: "",
      Total_wt: "",
      Source: "",
      Date: "",
      Packing_type: "",
      Services: "",
      Remarks: "",
    },
  });
  const handleChange = (item, key, index) => {
    setGliNumber(item?.gli_number);
    setActiveCard({ id: item?._id, key: key });
    if (key === "Pending") {
      setShowDetail(true);
      setShowApproved(false);
      dispatch(getPendingListById(item?._id));
    } else {
      setShowApproved(true);
      setShowDetail(false);
      dispatch(
        updateConfigApproved((state) => {
          state.receipt_id = item?._id;
          state.currentPage = 1;
          state.item_type_id = item?.item_type_id;
        })
      );
    }
  };

  const handleReject = () => {
    dispatch(updateConfig((state) => (state.rejectModal = !state.rejectModal)));
  };

  const handleApprove = () => {
    dispatch(
      updateConfig((state) => (state.approveModal = !state.approveModal))
    );
  };

  const getFieldError = () => {};

  const handleRejectRequest = () => {
    handleReject();
    rejectStockData(state?.pendingData?._id).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Stock rejected successfully");
        dispatch(
          updateConfig((state) => {
            state.verificationData.Pending = [];
            state.verificationData.Approved = [];
          })
        );
        dispatch(
          getPendingList({
            page: 1,
            per_page: 10,
          })
        );
        dispatch(
          getApprovedList({
            page: 1,
            per_page: 10,
          })
        );
        setShowDetail(false);
      } else {
        toast.error(response?.message);
        setShowDetail(false);
      }
    });
  };

  return {
    data,
    states,
    state,
    formik,
    gliNumber,
    showDetail,
    activeCard,
    filterShow,
    showApproved,
    isLoading,
    barCodeRef,
    officeSlipRef,
    StockReceiptRef,
    totalLength: {
      Pending: state?.PendingTotal,
      Approved: state?.ApprovedTotal,
    },
    apiStatus: {
      Pending: state?.pendingStatus,
      Approved: state?.approvedStatus,
    },
    handleLoadMore,
    setIsLoading,
    handleReject,
    handleApprove,
    getFieldError,
    handleSearch,
    setFilterShow,
    handleChange,
    setShowDetail,
    handleApproveRequest,
    handleRejectRequest,
  };
};

export default useVerification;
