import { getAxiosInstance } from "../../../api";

export const getCVDFormData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `/cvd/fields?item_details_id=${body?.item_details_id}&item_type_id=${body?.item_type_id}&process_slug=${body?.process_slug}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const submitForm = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/cvd/save`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const startTimmer = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      `/employee-dashboard/order/start-timer`,
      body
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getFields = async (id, slug) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `/cvd/edit?item_details_id=${id}&process_slug=${slug}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getPercentage = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `/cvd/percentage?item_details_id=${params?.id}&diamond_number=${params?.d_no}&process_slug=${params?.slug}`
    );
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getCVDReport = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/cvd/report?receipt_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const passAll = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(
      `/employee-dashboard/order/bulk-status-complete`,
      params
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
