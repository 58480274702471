import React, { useRef } from "react";
import html2canvas from "html2canvas";
import { StaticHtmlTemplateC } from "../../Employees/Printing/CertificateTypes/VisitingCardOne/staticHtmlTemplateC";
import { StaticHtmlTemplateD } from "../../Employees/Printing/CertificateTypes/VisitingCardOne/staticHtmlTemplateD";
import { useSelector } from "react-redux";
import { StaticHtmlTemplate } from "../../Employees/Printing/CertificateTypes/VisitingCardOne/staticHtmlTemplate";
import { StaticHtmlTemplateE } from "../../Employees/Printing/CertificateTypes/VisitingCardOne/staticHtmlTemplateE";
import Assets from "../../../assets/Assets";
import Style from "../VerifyCertificate.module.scss";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/Printing/printingSlice";
import { toast } from "react-toastify";

const VisitingCard = ({ data: value }) => {
  const { card_size, verifyCertificateBtn } = useSelector(
    (state) => state.printing
  );

  const data = value?.data;

  const cardRef = useRef(null);
  const dispatch = useDispatch();

  const handleDownload = () => {
    dispatch(updateConfig((state) => (state.verifyCertificateBtn = true)));
    html2canvas(cardRef.current, { scale: 4, useCORS: true,})
      .then((canvas) => {
        const link = document.createElement("a");
        link.download = `${data?.certificate_number}.png`;
        link.href = canvas.toDataURL("image/png");
        link.click();
        dispatch(updateConfig((state) => (state.verifyCertificateBtn = false)));
      })
      .catch(() => {
        dispatch(updateConfig((state) => (state.verifyCertificateBtn = false)));
        toast.error("Something went wrong")
      });
  };

  let template;
  if (data?.center_stone_side_stone) {
    template = <StaticHtmlTemplateC value={data} />;
  } else if (parseInt(data?.qr_and_image_format) === 3) {
    template = <StaticHtmlTemplateD value={data} />;
  } else {
    card_size === 1
      ? (template = <StaticHtmlTemplate value={data} />)
      : (template = <StaticHtmlTemplateE value={data} />);
  }

  return (
    <div className="pro-d-flex pro-flex-column pro-items-center pro-py-3">
      <div ref={cardRef}>{template}</div>
      <div className="pro-d-flex pro-justify-center pro-pt-3 pro-gap-3">
        <button
          className="pro-btn pro-btn-primary pro-px-5"
          onClick={() => handleDownload()}
          type="button"
          disabled={verifyCertificateBtn}
        >
          { verifyCertificateBtn ? "Loading..." : "Download"}
        </button>
      </div>
      <p className="pro-pt-3">
        For more details, <a href="https://dhcinternational.com">click here</a>
      </p>
      <a href="https://dhcinternational.com" className={Style.logo_link}>
        <figure>
          <img src={Assets.LOGONEW} alt="DHC" />
        </figure>
      </a>
    </div>
  );
};

export default VisitingCard;
