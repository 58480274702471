import { useSelector } from "react-redux";
import {
  useDeleteBranchMutation,
  useGetBranchesListDataQuery,
  useUpdateBranchTableHeadDataMutation,
} from "../../../store/queries/Master";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Master/Branches/branchesSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { approveRequest, getBranchData } from "./api";
import { Button, IconText } from "@wac-ui-dashboard/wac_component_library";

const useBranches = () => {
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];
  const [imageData, setImageData] = useState();
  const [showImageModal, setShowImageModal] = useState(false);

  const dispatch = useDispatch();

  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const branchesState = useSelector((state) => state.branches);
  const {
    data: branchesList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetBranchesListDataQuery({
    page_size: branchesState.currentPageSize,
    page: branchesState.currentPage,
    sort_by: branchesState.sortBy,
    sort_order: branchesState.sortOrder,
    start: branchesState.startDate,
    end: branchesState.endDate,
    search: branchesState.search,
  });

  const [deleteBranch] = useDeleteBranchMutation();
  const [updateBranchFields] = useUpdateBranchTableHeadDataMutation();

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = branchesList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [branchesList]);
  useEffect(() => {
    if (branchesState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [branchesState.clearSelection]);

  useEffect(() => {
    if (branchesState?.selectedBranch && branchesState?.isEdit) {
      fillBranchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchesState?.isEdit, branchesState?.showAddBranchModal]);

  const handleModalClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddBranchModal = !state.showAddBranchModal;
        state.activeTab = "Basic";
        state.logoPreview = "";
        state.branchData = [];
        state.isEdit = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      branch_logo: (feild, data) =>
        data?.[feild] ? (
          <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Photo"}`}
            onClick={() => handleViewImage(data?.[feild])}
          />
        ) : (
          ""
        ),
      status: (field, data) =>
        data?.status === "Pending" && branchesList?.data?.approve_permission ? (
          <Button
            className={"pro-btn-primary sm"}
            type="button"
            onClick={() => handleApprovePermission(data?._id)}
          >
            {"Approve"}
          </Button>
        ) : data?.status === "Deleted" ? (
          <span className={"outline-danger pro-badge"}>{"Deleted"}</span>
        ) : data?.status === "Active" ? (
          <span className={"outline-success pro-badge"}>{"Active"}</span>
        ) : (
          data?.status
        ),
      contact_number_1: (field, data) =>
        data[field]?.map((value, index) => (
          <p key={index} className="pro-mb-0">
            {value}
            {index !== data[field]?.length - 1 ? "," : ""}
          </p>
        )),
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleApprovePermission = (approveId) => {
    let params = {
      branch_id: approveId,
    };
    approveRequest(params).then((response) => {
      if (response?.data?.status_code === 200) {
        refetch();
        toast.success("Approved successfully");
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const handleViewImage = (logoImage) => {
    setImageData(logoImage);
    setShowImageModal(true);
  };
  const closeModal = () => {
    setShowImageModal(false);
  };

  const handleDelete = () => {
    deleteBranch(branchesState?.selectedBranch).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Branch deleted successfully");
        handleDeleteModal();
        refetch();
      } else {
        toast.error("Something went wrong");
        handleDeleteModal();
      }
    });
  };

  const fillBranchData = () => {
    getBranchData(branchesState?.selectedBranch).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => (state.branchData = response?.data?.data))
        );
      } else {
        toast.error("Something went wrong to fetch branch data");
      }
    });
  };

  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedBranch = e?.[0];
      })
    );
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.activeTab = "Basic";
        state.showAddBranchModal = true;
        state.selectedBranch = e?.[0];
        state.isEdit = true;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (branchesState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = branchesState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  return {
    branchesState,
    showEditModal,
    tableFields,
    branchesList,
    isLoading,
    isFetching,
    imageData,
    showImageModal,
    paginationOptions,
    getRow,
    closeModal,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    handleDelete,
    handleDeleteModal,
    handleEditAction,
    handleSearch,
    updateBranchFields,
    handleClearClick,
    handlePageSize,
    refetch,
  };
};

export default useBranches;
