import { useEffect, useRef, useState } from "react";
import {
  getOverViewRecheck,
  updateConfig,
} from "../../../store/slices/OverSlice/overViewSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { subDays } from "date-fns";
import { debounce } from "../../../utils/hooks/useDebounce";
import { toast } from "react-toastify";
import { recheckEmpDetails } from "./api";
import { subCardsDetailsRecheck } from "../OverView/api";

const useOverViewRecheck = () => {
  const state = useSelector((state) => state.overview);
  const [changedDate, setChangedDate] = useState("");
  const [isLoading, setIsLoading] = useState();
  const [page, setPage] = useState(1);
  const [date, setDate] = useState({
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
    key: "selection",
  });
  const scrollRef = useRef(null);
  const states = state?.overViewRecheckData;
  let qcData = states?.map((item) => item);
  let photoData = states?.map((item) => item);
  let finalData = states?.map((item) => item);
  let metalData = states?.map((item) => item);
  let ogData = states?.map((item) => item);
  let cvdData = states?.map((item) => item);
  let engravingData = states?.map((item) => item);
  let billingData = states?.map((item) => item);
  let packingData = states?.map((item) => item);
  let deliveryData = states?.map((item) => item);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (date) {
      initialCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  function initialCall() {
    const startDate = new Date(date.startDate).toISOString().split("T")[0];
    const endDate = new Date(date.endDate).toISOString().split("T")[0];
    dispatch(getOverViewRecheck({ date: [startDate, endDate] }));
  }

  const handleInputSearch = (e) => {
    dispatch(updateConfig((state) => (state.overViewData = [])));
    dispatch(
      getOverViewRecheck({
        search: e?.target?.value,
        date: changedDate,
        page: 1,
        per_page: 10,
      })
    );
  };
  const handleSearch = debounce(handleInputSearch, 1000);

  let dataArray = [
    { data: qcData, key: "qc", title: "QC" },
    { data: metalData, key: "metal_qc", title: "Metal QC" },
    { data: ogData, key: "og_qc", title: "Og QC" },
    { data: finalData, key: "final_qc", title: "Final QC" },
    { data: cvdData, key: "cvd_rft", title: "CVD" },
    { data: engravingData, key: "engraving", title: "Engraving" },
    { data: photoData, key: "photo_section", title: "Photo" },
    { data: billingData, key: "printing", title: "Printing" },
    { data: packingData, key: "packing", title: "Packing" },
    { data: billingData, key: "billing", title: "Billing" },
    { data: deliveryData, key: "delivery", title: "Delivery" },
  ];

  const handleDateChange = (date) => {
    const startDateUTC = new Date(date.startDate);
    const endDateUTC = new Date(date.endDate);

    const timeZoneOffset = 5.5 * 60 * 60 * 1000;
    const startDateIST = new Date(startDateUTC.getTime() + timeZoneOffset);
    const endDateIST = new Date(endDateUTC.getTime() + timeZoneOffset);

    const formatDate = (date) => {
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    const startDateFormatted = formatDate(startDateIST);
    const endDateFormatted = formatDate(endDateIST);
    const dateArray = [startDateFormatted, endDateFormatted];

    dispatch(getOverViewRecheck({ date: dateArray }));
    setChangedDate(dateArray);
    dispatch(updateConfig((state) => (state.recheckDate = changedDate)));
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.recheckSearch = "";
        state.recheckDate = [];
        state.filter.itemType = [];
        state.filter.shop = [];
        state.filter.priority = [];
      })
    );
    initialCall();
    setDate({
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    });
  };

  const handleFilterModal = () => {
    dispatch(updateConfig((state) => (state.filterModal = !state.filterModal)));
  };

  useEffect(() => {
    if (page !== 1) {
      let itemTypesIds = state?.filter?.itemType?.map((values) => values?._id);
      let shopIds = state?.filter?.shop?.map((values) => values?._id);
      let statusIds = state?.filter?.priority?.map((values) => values?.id);
      dispatch(
        getOverViewRecheck({
          date: changedDate,
          page: page,
          per_page: 10,
          item_type: itemTypesIds,
          shop: shopIds,
          status: statusIds,
          search: state.search,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleLoadMore = () => {
    if (state?.total?.overViewRecheck !== states?.length) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.overViewRecheckStatus]);

  const handleCardView = (item, title) => {
    const titleToSlugMap = {
      QC: "qc",
      "Final QC": "final_qc",
      Photo: "photo_section",
      "Metal QC": "metal_qc",
      "Og QC": "og_qc",
      CVD: "cvd_rft",
      Engraving: "engraving",
      Billing: "billing",
      Packing: "packing",
      Delivery: "delivery",
      Printing: "printing",
    };
    let data = {
      id: item?.id,
      slug: titleToSlugMap[title] || "",
    };
    dispatch(updateConfig((state) => (state.viewModal = !state.viewModal)));
    if (!state.viewModal) {
      recheckEmpDetails({ data }).then((response) => {
        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig((state) => (state.empDetails = response?.data?.data))
          );
        } else {
          toast.error("Cant fetch Details");
        }
      });
    }
  };

  const handleClick = (id) => {
    dispatch(
      updateConfig((state) => (state.viewSubModal = !state.viewSubModal))
    );
    if (!state?.viewSubModal) {
      subCardsDetailsRecheck(id).then((response) => {
        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig(
              (state) => (state.subCardsDetails = response?.data?.data)
            )
          );
        }
      });
    }
  };

  return {
    state,
    date,
    states,
    dataArray,
    isLoading,
    scrollRef,
    totalLength: {
      overView: state?.total?.overViewRecheck,
    },
    apiStatus: {
      overView: state?.overViewRecheckStatus,
    },
    handleCardView,
    handleClick,
    setIsLoading,
    handleLoadMore,
    handleFilterModal,
    handleSearch,
    handleDateChange,
    handleClearClick,
  };
};

export default useOverViewRecheck;
