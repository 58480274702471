import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../store/slices/Billing/staffBillingSlice";
import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";
import {
  useGetRecheckGliListDataQuery,
  useUpdateRecheckBillingTableHeadDataMutation,
} from "../../../../store/queries/Billing";

export const useRecheckStaffBilling = () => {
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];
  const [filterShow, setFilterShow] = useState(false);
  const billingState = useSelector((state) => state.staffBilling);
  const { showEditModal } = useSelector((state) => state.global);
  const [updateRecheckBillingTableHeadData] = useUpdateRecheckBillingTableHeadDataMutation();
  const dispatch = useDispatch();
  const { data: billingList = {}, refetch } = useGetRecheckGliListDataQuery({
    per_page: billingState?.currentRecheckPageSize,
    page: billingState?.currentRecheckPage,
    sort_by: billingState?.sortBy,
    sort_order: billingState?.sortOrder,
    start: billingState?.startDate,
    end: billingState?.endDate,
    search: billingState?.search,
    date: billingState.date ? billingState.date : [],
    item_type: billingState.item_type ? billingState.item_type : [],
    shop: billingState.shop ? billingState.shop : [],
    status: billingState.status ? billingState.status : [],
  });
  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      service: (feild, data) => (
        <ul>
          {data[feild]?.map(({ name }, index) => (
            <li key={index}>{name}</li>
          ))}
        </ul>
      ),
      work_status: (_, data) =>
        data?.work_status === "Rejected" ? (
          <span className={"pro-badge badge-danger-outline pro-d-inline-block"}>
            {"Rejected"}
          </span>
        ) : data?.work_status === "Marked" ? (
          <span className={"outline-primary pro-badge pro-d-inline-block"}>
            {"Marked"}
          </span>
        ) : data?.work_status === "Completed" ? (
          <span className={"outline-success pro-badge pro-d-inline-block"}>
            {"Completed"}
          </span>
        ) : data?.work_status === "Pending" ? (
          <span className={"outline-warning pro-badge pro-d-inline-block"}>
            {"Pending"}
          </span>
        ) : (
          <span className={"outline pro-badge pro-d-inline-block"}>
            {data?.work_status}
          </span>
        ),
        priority: (_, data) =>
          data?.priority === "Urgent" ? (
            <span className={"pro-badge badge-danger-outline pro-d-inline-block"}>
              {"Urgent"}
            </span>
          ) : data?.priority === "Normal" ? (
            <span className={"outline-primary pro-badge pro-d-inline-block"}>
              {"Normal"}
            </span>
          ) : data?.priority === "Hold" ? (
            <span className={"outline-warning pro-badge pro-d-inline-block"}>
              {"Hold"}
            </span>
          ) : (
            <span className={"outline pro-badge pro-d-inline-block"}>
              {data?.priority}
            </span>
          ),
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentRecheckPage = 1;
      })
    );
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentRecheckPage = page;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentRecheckPageSize = page_size;
        state.currentRecheckPage = 1;
      })
    );
  };
  const handleSort = (label) => {
    if (billingState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentRecheckPage = 1;
          state.sortOrder = billingState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentRecheckPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleGenerateEstimate = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showAddBillingModal = !state.showAddBillingModal;
        state.selectedBilling = e;
      })
    );
  };

  return {
    filterShow,
    billingState,
    billingList,
    showEditModal,
    paginationOptions,
    getRow,
    refetch,
    handleSort,
    handleSearch,
    setFilterShow,
    handlePageSize,
    handlePagination,
    handleGenerateEstimate,
    handleEditColumnsClick,
    updateRecheckBillingTableHeadData,
  };
};

export default useRecheckStaffBilling;
