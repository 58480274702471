import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import BillingFilterForm from "./BillingFilterForm";
import {
  updateConfig,
  resetConfig,
} from "../../../../store/slices/Billing/staffBillingSlice";
import { useGetFilterListDataQuery } from "../../../../store/queries/Billing";

const useFilter = (setShowForm) => {
  const dispatch = useDispatch();
  const billingState = useSelector((state) => state.staffBilling);
  const { data: FilterData = {} } = useGetFilterListDataQuery();

  const validationSchema = Yup.object({
    from_date: Yup.date().nullable(),
    to_date: Yup.date().nullable(),
    item_type: Yup.array().nullable(),
    shop: Yup.array().nullable(),
    status: Yup.array().nullable(),
  });

  // Set the initial values based on whether edit is true or false
  const initialValues = {
    from_date: billingState.from_date,
    to_date: billingState.to_date,
    item_type: billingState.item_type,
    shop: billingState.shop,
    status: billingState.status,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const { from_date, to_date, item_type, shop, status } = values;

      if (
        from_date === "" &&
        to_date === "" &&
        item_type === "" &&
        shop === "" &&
        status === ""
      ) {
        toast.error("All fields are empty!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        let date = [];
        if (values?.from_date && values?.to_date) {
          date.push(...[values?.from_date, values?.to_date]);
        }
        dispatch(
          updateConfig((state) => {
            state.date = date;
            state.item_type = values?.item_type;
            state.shop = values?.shop;
            state.status = values?.status;
            state.from_date = values?.from_date;
            state.to_date = values?.to_date;
          })
        );
        setShowForm(false);
      }
    },
  });

  const renderTabContent = () => {
    return (
      <BillingFilterForm
        FilterData={FilterData}
        formik={formik}
        handleReset={handleReset}
      />
    );
  };
  const handleReset = () => {
    dispatch(resetConfig());
    setShowForm(false);
  };

  return {
    renderTabContent,
  };
};

export default useFilter;
