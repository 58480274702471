import React from "react";
import Style from "./tabHeading.module.scss";

export const TabHeading = ({ data, uniqueId, activeId, handleClick }) => {
  return (
    <div className={`${Style.root} pro-mb-5`}>
      <ul className={`pro-d-flex pro-items-center`}>
        {data.map((item, index) => {
          return (
            <li
              key={item?.[uniqueId] || index}
              className={index + 1 === activeId ? Style.active : ""}
              //   style={activeId === index + 1 ? { color: "red" } : ""}
            >
              <button
                className={`pro-btn pro-btn-outline pro-items-center`}
                onClick={() => handleClick?.(item, index)}
              >
                <span className={`pro-d-flex pro-items-center`}>
                  {item?.image && (
                    <div className="pro-avatar">
                      {item?.image?.length === 0 || !item?.image ? (
                        <span className="letter shine col-2"></span>
                      ) : (
                        <img src={item.image} alt="" />
                      )}
                    </div>
                  )}
                  <p className="pro-fw-medium pro-mb-0">
                    {item.name || (
                      <span
                        className="letter shine"
                        style={{
                          width: 80 /**#FIXME Aneesh change inline stylr if neccessory */,
                        }}
                      ></span>
                    )}
                  </p>
                </span>
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
