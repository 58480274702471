import Style from "../../view.module.scss";

const Services = ({ state }) => {
  return (
    <div className={`${Style.multicolumn_table}`}>
      <h6 className="pro-ttl h6 pro-mb-2">Services</h6>
      <table>
        <tbody>
          <tr>
            <td style={{ verticalAlign: "top" }}>Service Type</td>
            <td>
              <div className="pro-d-flex pro-flex-wrap pro-gap-2">
                {state?.service?.map((item, index) => (
                  <p key={index} className="pro-mb-0">
                    {item?.name ?? "--"}
                    {index !== state?.service.length - 1 && (
                      <span>,</span>
                    )}
                  </p>
                ))}
              </div>
            </td>
          </tr>
          <tr>
            <td>Color</td>
            <td>{state?.item_grading?.[0]?.name ?? "--"}</td>
          </tr>
          <tr>
            <td>Clarity</td>
            <td>{state?.item_grading?.[1]?.name ?? "--"}</td>
          </tr>
          <tr>
            <td>Cut</td>
            <td>{state?.item_grading?.[2]?.name ?? "--"}</td>
          </tr>
          <tr>
            <td>Finish</td>
            <td>{state?.item_grading?.[3]?.name ?? "--"}</td>
          </tr>
          <tr>
            <td>Double Grade</td>
            <td>
              {state?.double_grade ? "Needed" : "Not Needed"}
            </td>
          </tr>
          <tr>
            <td>Packaging Type</td>
            <td>{state?.packing_type?.packing_type ?? "--"}</td>
          </tr>
          <tr>
            <td>Box Weight</td>
            <td>{state?.box_weight ?? "--"}</td>
          </tr>
          <tr>
            <td>Certificate Type</td>
            <td>{state?.certificate_type ?? "--"}</td>
          </tr>
          <tr>
            <td>Prepared By</td>
            <td>{state?.prepared_by ?? "--"}</td>
          </tr>
          <tr>
            <td>Payment Mode</td>
            <td>{state?.payment_mode ?? "--"}</td>
          </tr>
          <tr>
            <td>Additional Document</td>
            <td>{state?.additional_document ?? "--"}</td>
          </tr>

          <tr>
            <td>Remarks</td>
            <td>{state?.remarks ?? "--"}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Services;
