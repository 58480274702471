import React from "react";
import Style from "./CardShimmer.module.scss";
const CardShimmer = ({ theKey }) => {
  return (
    <div
      className={`${Style.root} pro-p-4 pro-rounded-3 pro-d-flex pro-flex-column pro-items-start`}
      key={theKey}
    >
      <div className={`shimmer pro-pb-3 pro-pt-1 pro-mb-4 ${Style.header}`}></div>
      <div className={`shimmer pro-pb-2 pro-mb-3 ${Style.content} `}></div>
      <div className="pro-mt-4 shimmer pro-d-inline-flex">
        <span className="pro-px-5 pro-py-2"></span>
        <span className="pro-px-1 pro-py-2"></span>
      </div>
    </div>
  );
};
export default CardShimmer;
