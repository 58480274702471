import React from "react";
import EmptyData from "../../../Global/EmptyData";
import {
  Pagination,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import Select from "react-select";
import useDepartmentReport from "./useDepartmentReport";

const DepartmentReport = () => {
  const {
    formik,
    isFetching,
    isLoading,
    basicData,
    departmentData,
    getRow,
    handlePageSize,
    handlePagination,
    // handleSearch,
    handleSort,
    paginationOptions,
    reportState,
  } = useDepartmentReport();

  return (
    <div>
      {/* <HeadingGroup title={"Department Report"} className={`pro-mb-4`} /> */}
      <div className="col-12">
        <div className="row gx-2 gy-2 pro-items-end pro-mt-2 ">
          {/* <div className={`col-3`}>
            <div className="input-wrap">
              <input
                type="text"
                onChange={(e) => {
                  handleSearch(e);
                }}
                placeholder="Search this table"
                className={`pro-input icon-r lg`}
                value={reportState?.otherReport?.search}
              />
              <span className="pro-icon">
                {<span className="material-symbols-outlined">search</span>}
              </span>
            </div>
          </div> */}
          <div className="col-3">
            <div className="input-wrap ">
              <label
                htmlFor="department"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Department
              </label>
              <Select
                id="department"
                placeholder={"Select"}
                className={"pro-input lg"}
                classNamePrefix="pro-input"
                name="department"
                options={basicData?.departments}
                getOptionValue={(option) => option?._id}
                getOptionLabel={(option) => option?.name}
                value={formik?.values?.department}
                onChange={(value) => formik.setFieldValue("department", value)}
                menuPlacement="auto"
              />
            </div>
          </div>
          <div className="col-3">
            <div className="input-wrap ">
              <label
                htmlFor="branch"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Branch
              </label>
              <Select
                id="branch"
                placeholder={"Select"}
                className={"pro-input lg"}
                classNamePrefix="pro-input"
                name="branch"
                options={basicData?.branch_list}
                getOptionValue={(option) => option?._id}
                getOptionLabel={(option) => option?.branch_name}
                value={formik?.values?.branch}
                onChange={(value) => formik.setFieldValue("branch", value)}
                menuPlacement="auto"
              />
            </div>
          </div>
          <div className="col-3">
              <div className="input-wrap">
                <label
                  htmlFor="order_type"
                  className="pro-font-sm pro-mb-1 pro-fw-medium"
                >
                  Order Type
                </label>
                <Select
                  id="order_type"
                  placeholder={"Select"}
                  className={"pro-input lg"}
                  classNamePrefix="pro-input"
                  name="order_type"
                  options={basicData?.order_types}
                  getOptionValue={(option) => option?.id}
                  getOptionLabel={(option) => option?.name}
                  value={formik?.values?.order_type}
                  onChange={(value) =>
                    formik.setFieldValue("order_type", value)
                  }
                  menuPlacement="auto"
                />
              </div>
            </div>
        </div>
      </div>
      {departmentData?.data?.data?.length > 0 ? (
        <div className="pro-mt-4">
          <Table
            multiSelect={false}
            data={departmentData?.data?.data || []}
            uniqueID={"id"}
            fields={departmentData?.data?.fields}
            SortIcon={<FaSort />}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching || isLoading}
            perpage={reportState?.otherReport?.details?.currentPageSize}
            assignable={false}
            editable={false}
            deletable={false}
            showCheckBox={false}
          />
          {departmentData?.data?.data?.length > 0 && (
            <Pagination
              currentPage={reportState?.otherReport?.details?.currentPage}
              totalPageCount={Math.ceil(
                departmentData?.data?.total_count /
                  reportState?.otherReport?.details?.currentPageSize
              )}
              onPageChange={handlePagination}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          )}
        </div>
      ) : (
        <EmptyData />
      )}
    </div>
  );
};

export default DepartmentReport;
