import {
    HeadingGroup,
    Pagination,
    Table,
  } from "@wac-ui-dashboard/wac_component_library";
  import React from "react";
  import { FaSort } from "react-icons/fa";
  import useShowFormTable from "./useShowFormTable";
  
  const ShowFormTable = () => {
    const {
      paginationOptions,
      tableFields,
      queryList,
      queryState,
      isFetching,
      getRow,
      handlePageSize,
      handleAddClick,
      handlePagination,
      handleSort,
    } = useShowFormTable();
    return (
      <div>
        <div className="col-auto pro-pt-3 pro-pb-6">
        <HeadingGroup
        title={"Extra Details"}
        className={`pro-mb-4`}
        buttonTitle={"Add new"}
        handleClick={handleAddClick}
      />
          <Table
            multiSelect={false}
            data={queryList?.data?.data || []}
            uniqueID={"_id"}
            fields={tableFields}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={<span className="material-symbols-outlined">delete</span>}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={queryState?.formtable?.currentPageSize}
            assignable={false}
            deletable={false}
            editable={false}
            showCheckBox={false}
          />
          {queryList?.data?.data?.length > 0 && (
            <Pagination
              currentPage={queryState?.formtable?.currentPage}
              totalPageCount={Math.ceil(
                queryList?.data?.total_count /
                  queryState?.formtable?.currentPageSize
              )}
              onPageChange={handlePagination}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          )}
        </div>
      </div>
    );
  };
  
  export default ShowFormTable;
  