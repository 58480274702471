import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Billing/staffBillingSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { getEditedData, updateInvoice } from "../../Invoice/api";
import { useGetInvoiceHistoryBasicDataQuery } from "../../../../store/queries/Billing";

const useEditForm = ({ refetch }) => {
  const dispatch = useDispatch();
  const { slectedInvoice, invoiceData, invoiceModal } = useSelector(
    (state) => state.staffBilling
  );

  const { data: basicData = {} } = useGetInvoiceHistoryBasicDataQuery();
  const [isChecked, setIsChecked] = useState(false);
  const formik = useFormik({
    initialValues: {
      shop_or_supplier_type: "",
      shop_or_supplier_id: "",
      billing_address_type: "",
      billing_address_id: "",
      supplier_name: "",
      shop_name: "",
      address_line1: "",
      address_line2: "",
      city: "",
      postal_code: "",
      gst_number: "",
      discount_amount: "",
      courier_charge: "",
      sac_number: "",
      amount: "",
      updated_service_data: [],
      unit_price: "",
    },
    validate: (values) => {
      let errors = {};
      // Validate shop_name
      if (!values.shop_name) {
        errors.shop_name = "Please enter shop name";
      }

      // Validate address_line1
      if (!values.address_line1) {
        errors.address_line1 = "Please enter address";
      }

      // Validate address_line2
      if (!values.address_line2) {
        errors.address_line2 = "Please enter address";
      }

      // Validate city
      if (!values.city) {
        errors.city = "Please enter city name";
      }

      if (invoiceData?.invoice_type === 2) {
        if (!values?.unit_price) {
          errors.unit_price = "Enter Grand Total";
        } else if (!/^\d+(\.\d+)?$/.test(values?.unit_price)) {
          errors.unit_price = "Enter Valid Amount";
        }
      }
      // Validate postal_code
      if (!values.postal_code || !/^[1-9][0-9]{5}$/.test(values.postal_code)) {
        errors.postal_code = "Enter valid postal code";
      }

      // Validate gst_number
      if (
        !!values.gst_number &&
        !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(
          values.gst_number
        )
      ) {
        errors.gst_number = "Enter Valid GST Number";
      }

      // Validate sac_number if isChecked is true
      if (
        values.isChecked &&
        (!values.sac_number ||
          !/^[0-9]*$/.test(values.sac_number) ||
          values.sac_number.length < 6 ||
          values.sac_number.length > 20)
      ) {
        errors.sac_number = "Enter Valid SAC Number";
      }

      // Validate amount if isChecked is true
      if (
        values.isChecked &&
        (!values.amount || !/^[0-9]*$/.test(values.amount))
      ) {
        errors.amount = "Enter Valid Amount";
      }
      if (!!values?.updated_service_data) {
        let updated_service_errors = {};
        values?.updated_service_data?.forEach((updated_data, index) => {
          const keysToCheck = [
            "id",
            "item_type",
            "item_type_category_slug",
            "service_type",
            "invoice_description",
          ];
          const missingKeys = keysToCheck.filter((key) => key in updated_data);
          if (missingKeys.length > 0) {
            Object.keys(updated_data).forEach((updated_data_key) => {
              if (
                !keysToCheck.includes(updated_data_key) &&
                (!updated_data[updated_data_key] ||
                  !/^(?:\d+)?(?:\.\d+)?$/.test(updated_data[updated_data_key]))
              ) {
                updated_service_errors[index] = {
                  ...updated_service_errors[index],
                  [updated_data_key]: "Enter Valid Amount",
                };
              }
            });
          }
        });
        if (Object.keys(updated_service_errors).length > 0) {
          errors.updated_service_data = updated_service_errors;
        }
      }

      return errors;
    },
    onSubmit: (values, { resetForm }) => {
      let data = {
        invoice_id: slectedInvoice,
        shop_or_supplier_type: values?.shop_or_supplier_type,
        shop_or_supplier_id: values?.shop_or_supplier_id,
        billing_address_type: values?.billing_address_type,
        billing_address_id: values?.billing_address_id  ,
        supplier_name: values?.supplier_name,
        is_courier_charge: isChecked ? 1 : 2,
        sac_number: isChecked ? values?.sac_number : "",
        courier_charge: isChecked ? values?.amount : "",
        discount_amount: values?.discount_amount,
        gst_number: values?.gst_number,
        billing_address: {
          shop_name: values?.shop_name,
          address_line1: values?.address_line1,
          address_line2: values?.address_line2,
          city: values?.city,
          postal_code: values?.postal_code,
        },
        updated_service_data:
          values?.updated_service_data?.length > 0
            ? [...(values?.updated_service_data ?? "")]
            : "",
      };
      if (invoiceData?.invoice_type === 2) {
        data.unit_price = values?.unit_price;
      }
      let newObj = Object.entries(data);
      newObj = newObj
        .filter(
          (item) => item[1] !== undefined && item[1] !== "" && item[1] !== null
        )
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
      updateInvoice(newObj).then((response) => {
        if (response?.data?.status_code === 200) {
          resetForm();
          dispatch(updateConfig((state) => (state.invoiceData = "")));
          toast.success("Updated");
          handleCloseModal();
          refetch();
        } else if (response?.status_code === 403) {
          formik?.setFieldError("discount_amount", response?.message);
        } else if (response?.status_code === 422) {
          let errors = response?.errors;
          let errorFields = Object.keys(errors);
          errorFields.forEach((field) => {
            formik.setFieldError(field, errors[field]);
          });
        } else {
          toast.error("Something went wrong");
        }
      });
    },
  });

  useEffect(() => {
    setValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceData]);

  const setValues = () => {
    if (invoiceData?.is_courier_charge === 1) {
      setIsChecked(true);
      formik?.setFieldValue("sac_number", invoiceData?.sac_number ?? "");
      formik?.setFieldValue("amount", invoiceData?.courier_charge ?? "");
    } else {
      setIsChecked(false);
      formik?.setFieldValue("sac_number", "");
      formik?.setFieldValue("amount", "");
    }
    formik?.setFieldValue(
      "discount_amount",
      invoiceData?.discount_amount ?? ""
    );
    formik?.setFieldValue("shop_or_supplier_type", invoiceData?.shop_or_supplier_type ?? "");
    formik?.setFieldValue("shop_or_supplier_id", invoiceData?.shop_or_supplier_id ?? "");
    formik?.setFieldValue("supplier_name", invoiceData?.supplier_name ?? "");
    formik?.setFieldValue("billing_address_type", invoiceData?.billing_address_type ?? "");
    formik?.setFieldValue("billing_address_id", invoiceData?.billing_address_id ?? "");
    formik?.setFieldValue(
      "shop_name",
      invoiceData?.billing_address?.shop_name ?? ""
    );
    formik?.setFieldValue(
      "address_line1",
      invoiceData?.billing_address?.address_line1 ?? ""
    );
    formik?.setFieldValue(
      "address_line2",
      invoiceData?.billing_address?.address_line2 ?? ""
    );
    formik?.setFieldValue("city", invoiceData?.billing_address?.city ?? "");
    formik?.setFieldValue(
      "postal_code",
      invoiceData?.billing_address?.postal_code ?? ""
    );
    formik?.setFieldValue("gst_number", invoiceData?.gst_number ?? "");
    formik?.setFieldValue(
      "updated_service_data",
      invoiceData?.updated_service_data ?? []
    );
    if (invoiceData?.invoice_type === 2) {
      formik?.setFieldValue("unit_price", invoiceData?.unit_price);
    }
  };
  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => (state.invoiceModal = !state.invoiceModal))
    );
  };
  const handleCheckChange = () => {
    setIsChecked(!isChecked);
  };
  const getEditedValue = () => {
    if (slectedInvoice) {
      getEditedData(slectedInvoice)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            setValues();
            dispatch(
              updateConfig(
                (state) => (state.invoiceData = response?.data?.data)
              )
            );
          }
        })
        .catch((error) => toast.error(error));
    }
  };

  useEffect(() => {
    if (invoiceModal) {
      getEditedValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceModal]);

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  return {
    formik,
    isChecked,
    invoiceData,
    handleCheckChange,
    getFieldError,
    handleCloseModal,
    basicData
  };
};

export default useEditForm;
