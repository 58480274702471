import { getAxiosInstance } from "../../../api";

export const getBranchData = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/branch/edit?branch_id=${id}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const approveRequest = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/branch/approve`, params);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
