import { useSelector } from "react-redux";
import {
  useGetAssignedTableDataQuery,
  useUpdateAssignedTableHeadDataMutation,
} from "../../../../store/queries/Staff";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";
import { updateConfig } from "../../../../store/slices/Staff/staffSlice";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const useAssignedTable = () => {
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];
  const dispatch = useDispatch();
  const staffState = useSelector((state) => state.staff);
  const { tableFields, showEditModal, globalRemarkModal } = useSelector(
    (state) => state.global
  );
  const slug = localStorage?.getItem("slug");
  const {
    data: assignedTableData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetAssignedTableDataQuery({
    receipt_id: staffState.gliData?.id,
    search: staffState.assignedSearch,
    page_size: staffState.currentPageSize,
    page: staffState.currentPage,
    process_slug: slug,
  });
  const [updateAssignedFields] = useUpdateAssignedTableHeadDataMutation();

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = assignedTableData?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [assignedTableData]);

  const handleRemarksClick = (data) => {
    dispatch(
      globalUpdateConfig((state) => {
        state.globalRemarkId = data?.id;
        state.globalRemarkModal = true;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      status: (_, data) =>
        data?.status === "Rejected" ? (
          <span className={"pro-badge badge-danger-outline pro-d-inline-block"}>
            {"Rejected"}
          </span>
        ) : data?.status === "Marked" ? (
          <span className={"outline-primary pro-badge pro-d-inline-block"}>
            {"Marked"}
          </span>
        ) : data?.status === "Completed" ? (
          <span className={"outline-success pro-badge pro-d-inline-block"}>
            {"Completed"}
          </span>
        ) : data?.status === "Pending" ? (
          <span className={"outline-warning pro-badge pro-d-inline-block"}>
            {"Pending"}
          </span>
        ) : (
          <span className={"outline pro-badge pro-d-inline-block"}>
            {data?.status}
          </span>
        ),
      action: (_, data) => (
        <p className="pro-mb-0">
          {
            <Button
              className={"pro-btn-primary"}
              onClick={() => handleRemarksClick(data)}
            >
              Remarks
            </Button>
          }
        </p>
      ),
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => (state.assignedSearch = e?.target?.value))
    );
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleModalClose = () => {
    dispatch(globalUpdateConfig((state) => (state.globalRemarkModal = false)));
  };

  return {
    staffState,
    assignedTableData,
    isFetching,
    tableFields,
    globalRemarkModal,
    paginationOptions,
    isLoading,
    showEditModal,
    getRow,
    refetch,
    handlePageSize,
    handlePagination,
    handleModalClose,
    updateAssignedFields,
    handleEditColumnsClick,
    handleSearch,
  };
};

export default useAssignedTable;
