import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Style from "../addShop.module.scss";
import Select from "react-select";
import useBilling from "./useBilling";

const Billing = ({
  formik,
  getFieldError,
  basicData,
  handleChecked,
  isChecked,
}) => {
  const { countryCodeOptions, handleCloseModal, handleNext } = useBilling();
  
  return (
    <div className="row">
      <div>
        <Input
          label={"Billing Address Line 1"}
          type="text"
          id="billing_address_1"
          name="billing_address_1"
          className={`pro-input lg ${
            getFieldError("billing_address_1") && " error"
          }`}
          {...formik.getFieldProps("billing_address_1")}
          error={getFieldError("billing_address_1")}
          errorMessage={getFieldError("billing_address_1")}
        />
        <Input
          label={"Billing Address Line 2"}
          type="text"
          id="billing_address_2"
          name="billing_address_2"
          className={`pro-input lg ${
            getFieldError("billing_address_2") && " error"
          }`}
          {...formik.getFieldProps("billing_address_2")}
          error={getFieldError("billing_address_2")}
          errorMessage={getFieldError("billing_address_2")}
        />
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Country
          </label>
          <Select
            id="billing_country"
            name="billing_country"
            placeholder={"Select"}
            className={`pro-input lg  ${
              getFieldError("billing_country") && " error"
            }`}
            classNamePrefix="pro-input"
            options={basicData?.country_list ?? []}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
            value={formik?.values?.billing_country}
            onBlur={formik.handleBlur("billing_country")}
            onChange={(value) =>
              formik?.setFieldValue("billing_country", value || null)
            }
            menuPlacement="auto"
          />
          {getFieldError("billing_country") && (
            <span className="error-text">
              {getFieldError("billing_country")}
            </span>
          )}
        </div>
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            State
          </label>
          <Select
            id="billing_state"
            name="billing_state"
            placeholder={"Select"}
            className={`pro-input lg  ${
              getFieldError("billing_state") && " error"
            }`}
            classNamePrefix="pro-input"
            options={basicData?.state_list?.filter(item => (item?.country_id === formik?.values?.billing_country?.id))}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
            value={formik?.values?.billing_state}
            onBlur={formik.handleBlur("billing_state")}
            onChange={(value) =>
              formik?.setFieldValue("billing_state", value || null)
            }
            menuPlacement="auto"
          />
          {getFieldError("billing_state") && (
            <span className="error-text">{getFieldError("billing_state")}</span>
          )}
        </div>
        <Input
          label={"City"}
          type="text"
          id="billing_city"
          name="billing_city"
          className={`pro-input lg ${
            getFieldError("billing_city") && " error"
          }`}
          {...formik.getFieldProps("billing_city")}
          error={getFieldError("billing_city")}
          errorMessage={getFieldError("billing_city")}
        />
        <Input
          label={"Postal Code"}
          type="text"
          id="billing_postal_code"
          name="billing_postal_code"
          className={`pro-input lg ${
            getFieldError("billing_postal_code") && " error"
          }`}
          {...formik.getFieldProps("billing_postal_code")}
          error={getFieldError("billing_postal_code")}
          errorMessage={getFieldError("billing_postal_code")}
        />
      </div>
      <div className="input-wrap pro-mb-4 ">
        <div className="input-country-wrap ">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Contact Number
          </label>
          <div className="input-main-wrap verify-btn-wrap">
            <div className="code-wrap pro-mb-4 ">
              <Select
                id="country_code"
                options={countryCodeOptions}
                getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => `${option.value}`}
                value={formik?.values?.country_code}
                classNamePrefix="pro-input"
                onBlur={formik.handleBlur("country_code")}
                onChange={(value) =>
                  formik.setFieldValue("country_code", value)
                }
                menuPlacement="auto"
              ></Select>
            </div>
            <input
              type="text"
              id="contact_number"
              name="contact_number"
              className={`pro-input lg ${
                formik.errors.contact_number &&
                formik.touched.contact_number &&
                " error"
              }`}
              {...formik.getFieldProps("contact_number")}
            />
          </div>
          {formik.errors.contact_number && formik.touched.contact_number && (
            <>
              <span className="error-text">{formik.errors.contact_number}</span>
            </>
          )}
        </div>
      </div>

      <Input
        label={"Email"}
        type="text"
        id="email_id"
        name="email_id"
        className={`pro-input lg ${getFieldError("email_id") && " error"}`}
        {...formik.getFieldProps("email_id")}
        error={getFieldError("email_id")}
        errorMessage={getFieldError("email_id")}
      />
      <Input
        label={"GST Number"}
        type="text"
        id="gst_number"
        name="gst_number"
        className={`pro-input lg ${getFieldError("gst_number") && " error"}`}
        {...formik.getFieldProps("gst_number")}
        error={getFieldError("gst_number")}
        errorMessage={getFieldError("gst_number")}
      />
      <div className="pro-check-box">
        <input
          type="checkbox"
          className="pro-check"
          id="is_online"
          name="pro-checkbox"
          onChange={handleChecked}
          checked={isChecked}
        />
        <label htmlFor="is_online" className="pro-check-label">
          {" Is online Payment "}
        </label>
      </div>
      {isChecked && (
        <div className={Style.address}>
          <Input
            label={"Bank name"}
            type="text"
            id="bank_name"
            name="bank_name"
            className={`pro-input lg ${getFieldError("bank_name") && " error"}`}
            {...formik.getFieldProps("bank_name")}
            error={getFieldError("bank_name")}
            errorMessage={getFieldError("bank_name")}
          />
          <Input
            label={"Account Number"}
            type="text"
            id="ac_number"
            name="ac_number"
            className={`pro-input lg ${getFieldError("ac_number") && " error"}`}
            {...formik.getFieldProps("ac_number")}
            error={getFieldError("ac_number")}
            errorMessage={getFieldError("ac_number")}
          />
          <Input
            label={"IFSC"}
            type="text"
            id="ifsc"
            name="ifsc"
            className={`pro-input lg ${getFieldError("ifsc") && " error"}`}
            {...formik.getFieldProps("ifsc")}
            error={getFieldError("ifsc")}
            errorMessage={getFieldError("ifsc")}
          />
          <Input
            label={"Bank Address"}
            type="text"
            id="bank_address"
            name="bank_address"
            className={`pro-input lg ${
              getFieldError("bank_address") && " error"
            }`}
            {...formik.getFieldProps("bank_address")}
            error={getFieldError("bank_address")}
            errorMessage={getFieldError("bank_address")}
          />
        </div>
      )}

      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button className={"pro-btn-primary lg pro-ms-3"} onClick={handleNext}>
          {"Next"}
        </Button>
      </div>
    </div>
  );
};

export default Billing;
