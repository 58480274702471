import React from "react";
import useDisabledTabs from "./useDisabledTabs";
import Style from "../../qc.module.scss";

const DisabledTabs = () => {
  const { items } = useDisabledTabs();
  return (
    <div>
      <b>
        <p>{items?.gli_number}</p>
      </b>
      {items?.qc_fields?.map((val, index) => {
        return (
          <div className="input-wrap pro-mb-5" key={index}>
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              {val?.name}
            </label>
            <input
              id={val?.slug}
              name={val?.slug}
              value={val?.value}
              type="text"
              className={`pro-input lg`}
              disabled
            />
          </div>
        );
      })}
      {items?.marked?.map((val, index) => {
        return (
          <div key={index} className={Style.disabled_container}>
            <p>Marked Items</p>
            <div className="input-wrap pro-mb-5">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                {"Type"}
              </label>
              <input
                id={val?.type}
                name={val?.type}
                value={val?.type}
                type="text"
                className={`pro-input lg pro-mb-4 `}
                disabled
              />
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                {"Count"}
              </label>
              <input
                id={val?.count}
                name={val?.count}
                value={val?.count}
                type="text"
                className={`pro-input lg`}
                disabled
              />
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                {"percentage"}
              </label>
              <input
                id={val?.percentage}
                name={val?.percentage}
                value={val?.percentage}
                type="text"
                className={`pro-input lg`}
                disabled
              />
            </div>
          </div>
        );
      })}
      {items?.rejected?.map((val, index) => {
        return (
          <div key={index} className={Style.disabled_container}>
            <p>Rejected Items</p>
            <div className="input-wrap pro-mb-5">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                {"Type"}
              </label>
              <input
                id={val?.type}
                name={val?.type}
                value={val?.type}
                type="text"
                className={`pro-input lg pro-mb-4 `}
                disabled
              />
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                {"Count"}
              </label>
              <input
                id={val?.count}
                name={val?.count}
                value={val?.count}
                type="text"
                className={`pro-input lg`}
                disabled
              />
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                {"percentage"}
              </label>
              <input
                id={val?.percentage}
                name={val?.percentage}
                value={val?.percentage}
                type="text"
                className={`pro-input lg`}
                disabled
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DisabledTabs;
