import React from "react";
import { useSelector } from "react-redux";

const ReceiptPdf = React.forwardRef(({ receiptRef }, ref) => {
  const { receiptPrintData } = useSelector((state) => state.staffBilling);
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();
  const todayDate = `${dd}.${mm}.${yyyy}`;

  return (
    <div ref={receiptRef}>
      <table
        border={0}
        cellSpacing={0}
        role="presentation"
        cellPadding={0}
        style={{
          backgroundColor: "#ffffff",
          maxWidth: "483px",
          width: "100%",
          borderCollapse: "collapse",
          margin: "0 auto",
          // border: "1px solid #000000",
          fontSize: 8,
        }}
      >
        <tbody>
          <tr>
            <td>
              <table
                border={0}
                cellSpacing={0}
                role="presentation"
                cellPadding={0}
                style={{
                  width: "100%",
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ width: 48 }}>
                      <img
                        src={receiptPrintData?.branch_logo}
                        alt="logo"
                        width={48}
                        height={48}
                        style={{
                          width: 48,
                          height: 48,
                          minWidth: 48,
                          maxWidth: 48,
                        }}
                      />
                    </td>
                    <td
                      style={{
                        paddingRight: 50,
                      }}
                    >
                      <table
                        align="center"
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        width="100%"
                        style={{
                          borderCollapse: "collapse",
                          backgroundColor: "#ffffff",
                          boxSizing: "border-box",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                fontSize: 18,
                                fontWeight: 800,
                                textAlign: "center",
                                lineHeight: "28px",
                              }}
                            >
                              {receiptPrintData?.branch_name}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontSize: 8,
                                fontWeight: 600,
                                textAlign: "center",
                              }}
                            >
                              {receiptPrintData?.branch_address?.address_line1}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontSize: 8,
                                fontWeight: 600,
                                textAlign: "center",
                                paddingBottom: 10,
                              }}
                            >
                              {receiptPrintData?.branch_address?.address_line2},
                              Ph: {receiptPrintData?.branch_country_code}-{" "}
                              {receiptPrintData?.branch_contact_number_1},
                              {receiptPrintData?.branch_contact_number_2},
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td style={{ padding: "0px 190px 8px" }}>
              <table
                align="center"
                border={0}
                cellSpacing={0}
                role="presentation"
                cellPadding={0}
                width="100%"
                style={{
                  borderCollapse: "collapse",
                  backgroundColor: "#000000",
                  boxSizing: "border-box",
                  color: "#ffffff",
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                        fontSize: 8,
                        textTransform: "uppercase",
                        fontWeight: 600,
                        padding: "3px 0",
                      }}
                    >
                      PAYMENT RECIPT
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td
              style={{
                paddingTop: 2,
                paddingBottom: 5,
              }}
            >
              <table
                border={0}
                cellSpacing={0}
                role="presentation"
                cellPadding={0}
                style={{
                  width: "100%",
                }}
              >
                <tbody>
                  <tr>
                    <td>
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          border: "0.688px solid #000",
                          fontWeight: 600,
                          fontSize: 9,
                        }}
                      >
                        <tbody>
                          <tr>
                            <td style={{ paddingLeft: 10 }}>Receipt No.</td>
                            <td style={{ padding: "3px 3px" }}>:</td>
                            <td style={{ paddingRight: 10 }}>
                              {receiptPrintData?.receipt_number}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td>
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          marginRight: 0,
                          marginLeft: "auto",
                          border: "0.688px solid #000",
                          fontWeight: 600,
                          fontSize: 9,
                        }}
                      >
                        <tbody>
                          <tr>
                            <td style={{ paddingLeft: 10 }}>Date</td>
                            <td style={{ padding: "3px 2px" }}>:</td>
                            <td style={{ paddingRight: 10 }}>{todayDate}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td>
              <table
                border={0}
                cellSpacing={0}
                role="presentation"
                cellPadding={0}
                style={{
                  width: "100%",
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ padding: "6px 0" }}>
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                          fontSize: 9,
                        }}
                      >
                        <tbody>
                          <tr>
                            <td style={{ width: 106 }}>
                              Received with thanks from
                            </td>
                            <td
                              style={{
                                width: 20,
                                padding: "0px 2px",
                                paddingRight: "18px",
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                                borderBottom: "0.344px dashed #000000",
                              }}
                            >
                              {receiptPrintData?.billing_address?.shop_name},
                              {receiptPrintData?.billing_address?.address_line2}
                              , {receiptPrintData?.billing_address?.city}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td style={{ padding: "6px 0" }}>
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                          fontSize: 9,
                        }}
                      >
                        <tbody>
                          <tr>
                            <td style={{ width: 80 }}>towards Invoice No.</td>
                            <td
                              style={{
                                width: 6,
                                padding: "0px 2px",
                                paddingRight: "18px",
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                                borderBottom: "0.344px dashed #000000",
                              }}
                            >
                              {receiptPrintData?.invoice_number}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td style={{ padding: "6px 0" }}>
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                          fontSize: 9,
                        }}
                      >
                        <tbody>
                          <tr>
                            <td style={{ width: 115 }}>
                              the sum of rupees (in words)
                            </td>
                            <td
                              style={{
                                width: 6,
                                padding: "0px 2px",
                                paddingRight: "18px",
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                                borderBottom: "0.344px dashed #000000",
                              }}
                            >
                              {receiptPrintData?.total_in_words}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                          fontSize: 9,
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                paddingTop: 8,
                                paddingBottom: 5,
                              }}
                            >
                              <table
                                border={0}
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                style={{
                                  width: "100%",
                                  fontSize: 9,
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        width: " 50%",
                                        paddingRight: 5,
                                      }}
                                    >
                                      <table
                                        border={0}
                                        cellSpacing={0}
                                        role="presentation"
                                        cellPadding={0}
                                        style={{
                                          width: "100%",
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              style={{
                                                borderBottom:
                                                  "0.344px dashed #000000",
                                              }}
                                            ></td>
                                            <td
                                              style={{
                                                width: 15,
                                                paddingLeft: 5,
                                              }}
                                            >
                                              by
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td
                                      style={{
                                        width: " 50%",
                                        paddingLeft: 5,
                                      }}
                                    >
                                      <table
                                        border={0}
                                        cellSpacing={0}
                                        role="presentation"
                                        cellPadding={0}
                                        style={{
                                          width: "100%",
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              style={{
                                                paddingRight: 10,
                                              }}
                                            >
                                              <table
                                                border={0}
                                                cellSpacing={0}
                                                role="presentation"
                                                cellPadding={0}
                                                style={{
                                                  width: "100%",
                                                }}
                                              >
                                                <td style={{ paddingRight: 6 }}>
                                                  Cash
                                                </td>
                                                <td
                                                  style={{
                                                    width: 12,
                                                    height: 12,
                                                    border:
                                                      "0.75px solid #000000",
                                                  }}
                                                ></td>
                                              </table>
                                            </td>
                                            <td
                                              style={{
                                                paddingLeft: 10,
                                                paddingRight: 10,
                                              }}
                                            >
                                              <table
                                                border={0}
                                                cellSpacing={0}
                                                role="presentation"
                                                cellPadding={0}
                                                style={{
                                                  width: "100%",
                                                }}
                                              >
                                                <td style={{ paddingRight: 6 }}>
                                                  *Cheque
                                                </td>
                                                <td
                                                  style={{
                                                    width: 12,
                                                    height: 12,
                                                    border:
                                                      "0.75px solid #000000",
                                                  }}
                                                ></td>
                                              </table>
                                            </td>
                                            <td
                                              style={{
                                                paddingLeft: 10,
                                                paddingRight: 10,
                                              }}
                                            >
                                              <table
                                                border={0}
                                                cellSpacing={0}
                                                role="presentation"
                                                cellPadding={0}
                                                style={{
                                                  width: "100%",
                                                }}
                                              >
                                                <td style={{ paddingRight: 6 }}>
                                                  DD
                                                </td>
                                                <td
                                                  style={{
                                                    width: 12,
                                                    height: 12,
                                                    border:
                                                      "0.75px solid #000000",
                                                  }}
                                                ></td>
                                              </table>
                                            </td>
                                            <td
                                              style={{
                                                paddingLeft: 10,
                                              }}
                                            >
                                              <table
                                                border={0}
                                                cellSpacing={0}
                                                role="presentation"
                                                cellPadding={0}
                                                style={{
                                                  width: "100%",
                                                }}
                                              >
                                                <td style={{ paddingRight: 6 }}>
                                                  *Online
                                                </td>
                                                <td
                                                  style={{
                                                    width: 12,
                                                    height: 12,
                                                    border:
                                                      "0.75px solid #000000",
                                                  }}
                                                ></td>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>

                          <tr>
                            <td style={{ padding: "6px 0" }}>
                              <table
                                border={0}
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                style={{
                                  width: "45%",
                                  fontSize: 9,
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td style={{ width: 16 }}>No.</td>
                                    <td
                                      style={{ width: 6, padding: "0px 2px" }}
                                    >
                                      :
                                    </td>
                                    <td
                                      style={{
                                        fontWeight: 600,
                                        borderBottom: "0.344px dashed #000000",
                                      }}
                                    ></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        paddingTop: 10,
                      }}
                    >
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <table
                                border={0}
                                cellSpacing={0}
                                role="presentation"
                                cellPadding={0}
                                style={{
                                  border: "0.688px solid #000",
                                }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        fontSize: 17,
                                        fontWeight: 700,
                                        minWidth: 161,
                                        padding: "7px 9px",
                                      }}
                                    >
                                      <table
                                        border={0}
                                        cellSpacing={0}
                                        role="presentation"
                                        cellPadding={0}
                                        style={{
                                          width: "100%",
                                        }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              style={{
                                                borderBottom:
                                                  "0.68px dashed #000000",
                                              }}
                                            >
                                              &#8377; &nbsp;
                                              {receiptPrintData?.total_amount}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        paddingTop: 12,
                        paddingBottom: 8,
                        fontWeight: 500,
                      }}
                    >
                      *Subject to realisation
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          marginRight: 0,
                          marginLeft: "auto",
                        }}
                      >
                        <tbody>
                          <td
                            style={{
                              fontWeight: 500,
                            }}
                          >
                            Authorised Signatory
                          </td>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});

export default ReceiptPdf;
