import { getAxiosInstance } from "../../../api";

export const getEngravingCertificate = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(`process-engraving/list`, { params: params });
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const approveCertificate = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`process-engraving/submit`, params);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getStatusData = async () => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(`/common-process-status`);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const passAll = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(
      `/employee-dashboard/order/bulk-status-complete`,
      params
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
