import LOGO from "./images/logo-dhc.svg";
import LOGONEW from "./images/DHCNEW.jpg";
import LOGONEWHEADER from "./images/header/logo_new.svg";
import USER from "./images/header/avatar.png";
import USER2 from "./images/header/user.jpg";
import USER3 from "./images/header/user3.png";
import HEADERLOGO from "./images/header/logo.svg";
import PERSON from "./images/profile.jpg";
import SYROLOGO from "./images/Logo.png";
import PROFILEBG from "./images/user-img-design.png";
import PROFILE from "./images/user-img.png";
import TABLEBG from "./images/bg.png";
import ABOUTICON from "./images/about-icon.png";
import FAMILYICON from "./images/family_icon.png";
import EDUICON from "./images/edu.png";
import JOBICON from "./images/job.png";
import CONTACTICON from "./images/contact.png";
import FEMALEPROFILE from "./images/female.png";
import MALEPROFILE from "./images/male.png";
import IMAGE_APPEARANCE from "./images/image-appearance.png";

import THEME_BLACK from "./images/black.png";
import THEME_LIGHT from "./images/light.png";
import THEME_CLOSED from "./images/closed.png";
import THEME_COMPACT from "./images/compact.png";
import THEME_DARK from "./images/dark.png";
import THEME_DETAILED from "./images/detailed.png";
import THEME_EXPANDED from "./images/expanded.png";
import NO_DATA from "./images/no_data.svg";

import PRINT_LOGO from "./images/print/dhc_logo.svg";
import URGENT_IMG from "./images/print/urgent.svg";
import PRINT_DIAMOND from "./images/print/diamond.svg";
import STOCK_PRINT_LOGO from "./images/print/stock_print_logo.svg";
import STOCK_DIAMOND from "./images/print/stock_diamond.svg";
import HOME from "./images/dhchome.jpg";
import NORMAL_USER from "./images/header/normal_user.png";
import ADMIN_USER from "./images/header/admin_user.png";
import QR_CODE from "./images/QR_code.png";

import FOLD_FRONT from "./images/fold-front.png";
import FOLD_HEAD from "./images/fold-header.png";
import FOLD_NECKLACE from "./images/fold-necklace.png";
import FOLD_FOOTER from "./images/fold-footer.png";

import PRINT_FOOTER from "./images/print/footer.png";
import DHC_LOGO from "./images/DHC-NEW-LOGO.svg";
import RULER from "./images/rulers.png";
import RULERH from "./images/ruler-h.png";
import GRID from "./images/grid.png";
import BOX from "./images/box.png";
import PDF_FOOTER from "./images/pdf_footer.png";
import CLIENT_LOGO from "./images/bhima-logo.png";
import PDF_BACK from "./images/pdf_back.png";

const Assets = {
  NORMAL_USER,
  ADMIN_USER,
  LOGO,
  LOGONEW,
  LOGONEWHEADER,
  USER,
  USER2,
  USER3,
  HEADERLOGO,
  PERSON,
  SYROLOGO,
  PROFILEBG,
  PROFILE,
  TABLEBG,
  ABOUTICON,
  FAMILYICON,
  EDUICON,
  JOBICON,
  CONTACTICON,
  FEMALEPROFILE,
  MALEPROFILE,
  IMAGE_APPEARANCE,
  THEME_BLACK,
  THEME_LIGHT,
  THEME_CLOSED,
  THEME_COMPACT,
  THEME_DARK,
  THEME_DETAILED,
  THEME_EXPANDED,
  NO_DATA,
  HOME,
  PRINT_LOGO,
  URGENT_IMG,
  PRINT_DIAMOND,
  STOCK_PRINT_LOGO,
  STOCK_DIAMOND,
  QR_CODE,

  FOLD_FRONT,
  FOLD_HEAD,
  FOLD_NECKLACE,
  FOLD_FOOTER,

  PRINT_FOOTER,
  DHC_LOGO,
  RULER,
  RULERH,
  GRID,
  BOX,
  PDF_FOOTER,
  CLIENT_LOGO,
  PDF_BACK,
};

export default Assets;
