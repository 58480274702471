import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getMyQueueList,
  updateConfig,
} from "../../../store/slices/Packings/packingsSlice";
import { startTimer } from "../api";
import { toast } from "react-toastify";

const usePacking = () => {
  const packingState = useSelector((state) => state.packings);
  const states = packingState?.myQueueList;
  const [showDetail, setShowDetail] = useState(false);
  const [detailId, setDetailId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pendingPage, setPendingPage] = useState(1);
  const [activeCard, setActiveCard] = useState({ id: "", key: "" });
  const dispatch = useDispatch();
  const slug = localStorage?.getItem("slug");

  useEffect(() => {
    
    if(packingState?.search != "") {
      
      dispatch(
        updateConfig((state) => {
          state.search = "";
        })
      );
    }
    
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(
      getMyQueueList({
        page: pendingPage,
        per_page: 10,
        process_slug: slug,
        search: packingState?.search
      })
    );
    //eslint-disable-next-line
  }, [pendingPage, slug]);

  const startTimerPacking = (item) => {
    let body = {
      receipt_id: item,
      process_slug: slug,
    };
    startTimer(body).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Timer Started");
      } else {
        toast.error("Can't Start Timer");
      }
    });
  };

  const handleChange = (id) => {
    setActiveCard({ id: id, key: "Pending" });
    startTimerPacking(id);
    setDetailId(id);
    setShowDetail(true);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.selectedId = id;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.myQueueList.Pending = [];
        state.search = e.target.value
      })
    );
    dispatch(
      getMyQueueList({ 
        search: e.target.value,
        process_slug: slug 
      })
    );
  };

  const handleLoadMore = (mouseHover) => {
    if (
      mouseHover === "Pending" &&
      packingState?.myQueueList?.Pending?.length !== packingState?.total?.Pending
    ) {
      setPendingPage(pendingPage + 1);
      // dispatch(
      //   updateConfig((state) => {
      //     state.search = packingState?.search;
      //   })
      // );
    }
  };

  return {
    packingState,
    states,
    showDetail,
    isLoading,
    totalLength: {
      Pending: packingState.total.Pending,
    },
    apiStatus: {
      Pending: packingState.queueStatus,
    },
    activeCard,
    itemId: packingState.selectedPacking?.[0]?.[0],
    detailId,
    handleSearch,
    setIsLoading,
    handleChange,
    handleLoadMore,
  };
};

export default usePacking;
