import React from "react";
import { useSelector } from "react-redux";
import { ToWords } from "to-words";

const DeliveryChallanPrint = React.forwardRef(({ chellanRef }, ref) => {
  const { printChellanData } = useSelector((state) => state.delivery);
  const toWords = new ToWords();
  const currentDate = new Date();

  const day = currentDate.getDate().toString().padStart(2, "0");
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const year = currentDate.getFullYear().toString();

  const formattedDate = `${day}-${month}-${year}`;

  return (
    <table
      ref={chellanRef}
      border={0}
      cellSpacing={0}
      role="presentation"
      cellPadding={0}
      style={{
        backgroundColor: "#ffffff",
        maxWidth: "364px",
        width: "100%",
        borderCollapse: "collapse",
        margin: "0 auto",
        // border: "1px solid #000000",
        fontSize: 8,
      }}
    >
      <tbody>
        <tr>
          <td>
            <table
              border={0}
              cellSpacing={0}
              role="presentation"
              cellPadding={0}
              style={{
                width: "100%",
              }}
            >
              <tbody>
                <tr>
                  <td style={{ width: 48 }}>
                    <img
                      src={printChellanData?.branch?.logo}
                      alt="logo"
                      width={48}
                      height={48}
                      style={{
                        width: 48,
                        height: 48,
                        minWidth: 48,
                        maxWidth: 48,
                      }}
                    />
                  </td>
                  <td>
                    <table
                      align="center"
                      border={0}
                      cellSpacing={0}
                      role="presentation"
                      cellPadding={0}
                      width="100%"
                      style={{
                        borderCollapse: "collapse",
                        backgroundColor: "#ffffff",
                        boxSizing: "border-box",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              fontSize: 18,
                              fontWeight: 800,
                              textAlign: "center",
                              lineHeight: "18px",
                            }}
                          >
                            {printChellanData?.branch?.name}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: 8,
                              fontWeight: 600,
                              textAlign: "center",
                            }}
                          >
                            {printChellanData?.branch?.address?.address_line1}
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: 8,
                              fontWeight: 600,
                              textAlign: "center",
                              paddingBottom: 10,
                            }}
                          >
                            {printChellanData?.branch?.address?.address_line1},
                            {printChellanData?.branch?.address?.postal_code}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>

        <tr>
          <td style={{ padding: "8px 0px" }}>
            <table
              align="center"
              border={0}
              cellSpacing={0}
              role="presentation"
              cellPadding={0}
              width="100%"
              style={{ width: "100%", border: "0.73px solid #000000" }}
            >
              <tbody>
                <tr>
                  <td>
                    <table
                      align="center"
                      border={0}
                      cellSpacing={0}
                      role="presentation"
                      cellPadding={0}
                      style={{ width: "100%", marginLeft: 0 }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              fontWeight: 700,
                              padding: "4px 5px",
                            }}
                          >
                            GST IN: {printChellanData?.branch?.gst_number}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table
                      align="center"
                      border={0}
                      cellSpacing={0}
                      role="presentation"
                      cellPadding={0}
                      style={{
                        marginRight: 0,
                        marginLeft: "auto",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              fontWeight: 700,
                              padding: "4px 5px",
                            }}
                          >
                            PAN No: {printChellanData?.branch?.pan_number}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>

        <tr>
          <td style={{ padding: "0px 142px 8px" }}>
            <table
              align="center"
              border={0}
              cellSpacing={0}
              role="presentation"
              cellPadding={0}
              width="100%"
              style={{
                borderCollapse: "collapse",
                backgroundColor: "#000000",
                boxSizing: "border-box",
                color: "#ffffff",
              }}
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      fontSize: 8,
                      textTransform: "uppercase",
                      fontWeight: 600,
                      padding: "3px 0",
                    }}
                  >
                    DELIVERY CHALLAN
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>

        <tr>
          <td>
            <table
              border={0}
              cellSpacing={0}
              role="presentation"
              cellPadding={0}
              style={{
                width: "100%",
              }}
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      width: "50%",
                      verticalAlign: "top",
                    }}
                  >
                    <table
                      border={0}
                      cellSpacing={0}
                      role="presentation"
                      cellPadding={0}
                      style={{
                        width: "100%",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              width: 72,
                              verticalAlign: "top",
                            }}
                          >
                            Customer Name & Address
                          </td>
                          <td
                            style={{
                              width: 10,
                              padding: "0px 4px",
                              verticalAlign: "top",
                            }}
                          >
                            :
                          </td>
                          <td
                            style={{
                              width: 100,
                              fontWeight: 500,
                            }}
                          >
                            {printChellanData?.supplier?.name}
                            <br />
                            {printChellanData?.supplier?.address?.address_line1}
                            <br />
                            {printChellanData?.supplier?.address?.address_line2}
                            <br />
                            {printChellanData?.supplier?.address?.city} <br />
                            {printChellanData?.supplier?.contact_number}
                          </td>
                        </tr>

                        {/* <tr>
                          <td
                            style={{
                              width: 72,
                              verticalAlign: "top",
                              paddingTop: "3px",
                            }}
                          >
                            Pin
                          </td>
                          <td
                            style={{
                              width: 10,
                              padding: "0px 4px",
                              verticalAlign: "top",
                              paddingTop: "3px",
                            }}
                          >
                            :
                          </td>
                          <td
                            style={{
                              width: 100,
                              fontWeight: 500,
                              paddingTop: "3px",
                            }}
                          >
                            680 002.
                          </td>
                        </tr> */}

                        <tr>
                          <td
                            style={{
                              width: 72,
                              verticalAlign: "top",
                              paddingTop: "3px",
                            }}
                          >
                            GST NO
                          </td>
                          <td
                            style={{
                              width: 10,
                              padding: "0px 4px",
                              verticalAlign: "top",
                              paddingTop: "3px",
                            }}
                          >
                            :
                          </td>
                          <td
                            style={{
                              width: 100,
                              fontWeight: 500,
                              paddingTop: "3px",
                            }}
                          >
                            {printChellanData?.gst_number}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>

                  <td
                    style={{
                      width: "50%",
                      verticalAlign: "top",
                    }}
                  >
                    <table
                      border={0}
                      cellSpacing={0}
                      role="presentation"
                      cellPadding={0}
                      style={{
                        marginRight: 0,
                        marginLeft: "auto",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              verticalAlign: "top",
                            }}
                          >
                            Date
                          </td>
                          <td
                            style={{
                              width: 10,
                              padding: "0px 4px",
                              verticalAlign: "top",
                            }}
                          >
                            :
                          </td>
                          <td
                            style={{
                              fontWeight: 500,
                            }}
                          >
                            {/* {printChellanData?.date} */}
                            {formattedDate}
                          </td>
                        </tr>
                        {/* removed as per client */}
                        {/* <tr>
                          <td
                            style={{
                              verticalAlign: "top",
                              paddingTop: "4px",
                            }}
                          >
                            Time
                          </td>
                          <td
                            style={{
                              width: 10,
                              padding: "0px 4px",
                              verticalAlign: "top",
                              paddingTop: "4px",
                            }}
                          >
                            :
                          </td>
                          <td
                            style={{
                              fontWeight: 500,
                              paddingTop: "4px",
                            }}
                          >
                            {printChellanData?.time}
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>

        <tr>
          <td>
            <table
              border={0}
              cellSpacing={0}
              role="presentation"
              cellPadding={0}
              style={{
                width: "100%",
              }}
            >
              <tbody>
                <tr>
                  <td style={{ padding: "15px 0px" }}>
                    <table
                      cellSpacing={0}
                      role="presentation"
                      cellPadding={0}
                      style={{
                        width: "100%",
                        border: "0.5px solid #C8C8C8",
                        borderRadius: 1,
                      }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              padding: "3.5px 5px",
                              fontWeight: 500,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderBottom: "0.5px solid #C8C8C8",
                              borderRight: "0.5px solid #C8C8C8",
                              textAlign: "left",
                            }}
                          >
                            Sl. No.
                          </th>
                          <th
                            style={{
                              padding: "3.5px 5px",
                              fontWeight: 500,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderBottom: "0.5px solid #C8C8C8",
                              borderRight: "0.5px solid #C8C8C8",
                              textAlign: "left",
                            }}
                          >
                            Description of goods
                          </th>
                          <th
                            style={{
                              padding: "3.5px 5px",
                              fontWeight: 500,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderBottom: "0.5px solid #C8C8C8",
                              borderRight: "0.5px solid #C8C8C8",
                              textAlign: "center",
                            }}
                          >
                            SAC CODE
                          </th>
                          <th
                            style={{
                              padding: "3.5px 5px",
                              fontWeight: 500,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderBottom: "0.5px solid #C8C8C8",
                              textAlign: "right",
                            }}
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {printChellanData?.challan_details?.map(
                          (value, index) => {
                            return (
                              <tr key={index}>
                                <td
                                  style={{
                                    padding: "5.5px 5px",
                                    borderRight: "0.5px solid #C8C8C8",
                                    fontSize: 7,
                                    fontWeight: 400,
                                    textAlign: "left",
                                    verticalAlign: "top",
                                  }}
                                >
                                  {value?.serial_number}
                                </td>

                                {value?.serial_number ? (
                                  <td
                                    style={{
                                      padding: "5.5px 5px",
                                      borderRight: "0.5px solid #C8C8C8",
                                      fontSize: 7,
                                      fontWeight: 400,
                                      textAlign: "left",
                                    }}
                                  >
                                    {value?.description_of_goods}
                                  </td>
                                ) : (
                                  <p
                                    style={{
                                      marginBottom: 0,
                                      borderRight: "0.5px solid #C8C8C8",
                                      textAlign: "left",
                                      padding: "0px 5px",
                                      fontSize: 6,
                                    }}
                                  >
                                    {value?.description_of_goods}
                                  </p>
                                )}
                                <td
                                  style={{
                                    padding: "5.5px 5px",
                                    borderRight: "0.5px solid #C8C8C8",
                                    fontSize: 7,
                                    fontWeight: 400,
                                    textAlign: "center",
                                  }}
                                >
                                  {value?.sac_code}
                                </td>
                                <td
                                  style={{
                                    padding: "5.5px 5px",
                                    borderRight: "0.5px solid #C8C8C8",
                                    fontSize: 7,
                                    fontWeight: 400,
                                    textAlign: "right",
                                  }}
                                >
                                  {value?.amount}
                                </td>
                              </tr>
                            );
                          }
                        )}

                        {/* <tr>
                          <td
                            style={{
                              padding: "5.5px 5px",
                              borderRight: "0.5px solid #C8C8C8",
                              fontSize: 7,
                              fontWeight: 400,
                              textAlign: "left",
                              verticalAlign: "top",
                            }}
                          ></td>
                          <td
                            style={{
                              padding: "5.5px 5px",
                              borderRight: "0.5px solid #C8C8C8",
                              fontSize: 7,
                              fontWeight: 400,
                              textAlign: "left",
                            }}
                          >
                            Value of Goods
                          </td>
                          <td
                            style={{
                              padding: "5.5px 5px",
                              borderRight: "0.5px solid #C8C8C8",
                              fontSize: 7,
                              fontWeight: 400,
                              textAlign: "center",
                            }}
                          >
                            {printChellanData?.value_of_goods?.sac_code}
                          </td>
                          <td
                            style={{
                              padding: "5.5px 5px",
                              borderRight: "0.5px solid #C8C8C8",
                              fontSize: 7,
                              fontWeight: 400,
                              textAlign: "right",
                            }}
                          >
                            {printChellanData?.value_of_goods?.amount}
                          </td>
                        </tr> */}

                        {/* remarks tetx  */}
                        <tr>
                          <td
                            style={{
                              padding: "5.5px 5px",
                              borderRight: "0.5px solid #C8C8C8",
                              fontSize: 7,
                              fontWeight: 400,
                              textAlign: "left",
                              verticalAlign: "top",
                              paddingTop: "30px",
                            }}
                          ></td>
                          <td
                            style={{
                              padding: "5.5px 5px",
                              borderRight: "0.5px solid #C8C8C8",
                              fontSize: 7,
                              fontWeight: 400,
                              textAlign: "left",
                              paddingTop: "30px",
                            }}
                          >
                            <p
                              style={{
                                marginBottom: 0,
                              }}
                            >
                              {printChellanData?.remarks && "Remarks:"}
                            </p>
                            <p
                              style={{
                                marginBottom: 0,
                              }}
                            >
                              {printChellanData?.remarks}
                            </p>
                          </td>
                          <td
                            style={{
                              padding: "5.5px 5px",
                              borderRight: "0.5px solid #C8C8C8",
                              fontSize: 7,
                              fontWeight: 400,
                              textAlign: "center",
                              paddingTop: "30px",
                            }}
                          ></td>
                          <td
                            style={{
                              padding: "5.5px 5px",
                              borderRight: "0.5px solid #C8C8C8",
                              fontSize: 7,
                              fontWeight: 400,
                              textAlign: "right",
                              paddingTop: "30px",
                            }}
                          ></td>
                        </tr>
                      </tbody>
                      <thead>
                        <tr>
                          <th
                            colSpan={3}
                            style={{
                              padding: "3.5px 5px",
                              fontWeight: 400,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderTop: "0.5px solid #C8C8C8",
                              borderRight: "0.5px solid #C8C8C8",
                              textAlign: "center",
                            }}
                          >
                            Rupees :{" "}
                            {toWords.convert(
                              printChellanData?.total_amount ?? 0,
                              {
                                currency: true,
                              }
                            )}
                          </th>
                          <th
                            style={{
                              padding: "3.5px 5px",
                              fontWeight: 400,
                              fontSize: 7,
                              backgroundColor: "#FDFEFF",
                              borderTop: "0.5px solid #C8C8C8",
                              textAlign: "right",
                            }}
                          >
                            {printChellanData?.total_amount}
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>

        <tr>
          <td
            style={{
              paddingTop: 40,
              paddingBottom: 30,
            }}
          >
            <table
              border={0}
              cellSpacing={0}
              role="presentation"
              cellPadding={0}
              style={{
                marginLeft: "auto",
                marginRight: 0,
              }}
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      fontSize: 8,
                      fontWeight: 500,
                    }}
                  >
                    For DHC Gem Lab & Institute Pvt Ltd
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>

        <tr>
          <td>
            <table
              border={0}
              cellSpacing={0}
              role="presentation"
              cellPadding={0}
              style={{
                marginLeft: "auto",
                marginRight: 0,
              }}
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      fontSize: 8,
                      fontWeight: 500,
                    }}
                  >
                    Authorized Signatory
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
});

export default DeliveryChallanPrint;
