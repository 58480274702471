import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Reports/reportsSlice";
import { useSelector } from "react-redux";
import {
  useGetOtherReportBasicDataQuery,
  useGetOtherReportDepartmentTableDataQuery,
} from "../../../../store/queries/Reports";
import { useFormik } from "formik";

const useDepartmentReport = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const dispatch = useDispatch();
  const reportState = useSelector((state) => state.reports);

  const formik = useFormik({
    initialValues: {
      department: "",
      branch: "",
      order_type: {
        "id": 1,
        "name": "Fresh"
    }
    },
  });
  const { data: basicData = {} } = useGetOtherReportBasicDataQuery({});

  const {
    data: departmentData = {},
    isFetching,
    isLoading,
  } = useGetOtherReportDepartmentTableDataQuery(
    {
      department_id: formik?.values?.department?._id,
      per_page: reportState?.otherReport?.currentPageSize,
      page: reportState?.otherReport?.currentPage,
      sort_by: reportState?.otherReport?.sortBy,
      sort_order: reportState?.otherReport?.sortOrder,
      search: reportState?.otherReport?.search,
      branch_id: formik?.values?.branch?._id,
      order_type: formik?.values?.order_type?.id,
    },
    {
      skip: !(formik?.values?.department?._id && formik?.values?.branch),
    }
  );

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.otherReport.search = e.target.value;
        state.otherReport.currentPage = 1;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.otherReport.currentPageSize = page_size;
        state.otherReport.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.otherReport.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (reportState.otherReport.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.otherReport.currentPage = 1;
          state.otherReport.sortOrder =
            reportState.otherReport.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.otherReport.currentPage = 1;
          state.otherReport.sortBy = label;
          state.otherReport.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  return {
    formik,
    isFetching,
    isLoading,
    reportState,
    departmentData,
    paginationOptions,
    basicData: basicData?.data,
    getRow,
    handleSort,
    handleSearch,
    handlePageSize,
    handlePagination,
  };
};

export default useDepartmentReport;
