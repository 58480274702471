import { getAxiosInstance } from "../../../api";

export const updatePrintingForm = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/reissue/save-updates`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getFormData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `/reissue/edit-updated-data?item_details_id=${body?.item_details_id}&item_type_id=${body?.item_type_id}&process_slug=${body?.process_slug}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};
