import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useEnterMoreForm from "./useEnterMoreForm";

const EnterMoreForm = () => {
  const { formik, basicData, getFieldError, handleCloseModal } =
    useEnterMoreForm();
  return (
    <div className="row">
      <div className="input-wrap pro-mb-4">
        <label htmlFor="color" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Color
        </label>
        <Select
          id="color"
          name="color"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("color") && " error"}`}
          classNamePrefix="pro-input"
          options={basicData?.data?.colour_list ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?._id}
          value={formik?.values?.color}
          onBlur={formik.handleBlur("color")}
          onChange={(value) => formik?.setFieldValue("color", value || null)}
          menuPlacement="auto"
        />
        {getFieldError("color") && (
          <span className="error-text">{getFieldError("color")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="clarity" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Clarity
        </label>
        <Select
          id="clarity"
          name="clarity"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("clarity") && " error"}`}
          classNamePrefix="pro-input"
          options={basicData?.data?.clarity_list ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?._id}
          value={formik?.values?.clarity}
          onBlur={formik.handleBlur("clarity")}
          onChange={(value) => formik?.setFieldValue("clarity", value || null)}
          menuPlacement="auto"
        />
        {getFieldError("clarity") && (
          <span className="error-text">{getFieldError("clarity")}</span>
        )}
      </div>
      <Input
        label={"Seive size"}
        type="text"
        id="seive_size"
        name="seive_size"
        className={`pro-input lg ${getFieldError("seive_size") && " error"}`}
        {...formik.getFieldProps("seive_size")}
        error={getFieldError("seive_size")}
        errorMessage={getFieldError("seive_size")}
      />
      <Input
        label={"Diamond Number"}
        type="text"
        id="diamond_number"
        name="diamond_number"
        className={`pro-input lg ${
          getFieldError("diamond_number") && " error"
        }`}
        {...formik.getFieldProps("diamond_number")}
        error={getFieldError("diamond_number")}
        errorMessage={getFieldError("diamond_number")}
      />
      <Input
        label={"Weight"}
        type="text"
        id="weight"
        name="weight"
        className={`pro-input lg ${getFieldError("weight") && " error"}`}
        {...formik.getFieldProps("weight")}
        error={getFieldError("weight")}
        errorMessage={getFieldError("weight")}
      />
      <Input
        label={"LOT Number"}
        type="text"
        id="lot_number"
        name="lot_number"
        className={`pro-input lg ${getFieldError("lot_number") && " error"}`}
        {...formik.getFieldProps("lot_number")}
        error={getFieldError("lot_number")}
        errorMessage={getFieldError("lot_number")}
      />
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default EnterMoreForm;
