import {
  DatePickerWithInput,
  HeadingGroup,
  NavGroup,
  Pagination,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import EmptyData from "../../../Global/EmptyData";
import useGLIDetails from "./useGLIDetails";
import { FaSort } from "react-icons/fa";
import { Link } from "react-router-dom";
import Style from "../../report.module.scss";

const GLIDetails = () => {
  const {
    date,
    tabNavs,
    reportState,
    isFetching,
    reportDetailData,
    paginationOptions,
    getRow,
    handleSort,
    handleSearch,
    handlePageSize,
    handleDateChange,
    handlePagination,
  } = useGLIDetails();

  return (
    <div>
      <HeadingGroup title={"GLI Report Detail"} extraClassName={`pro-mb-1`} />
      <div className={`${Style.main_container}`}>
            <NavGroup navigations={tabNavs} type={"type-2"} Link={Link} />
      </div>
      <div className="row gx-2 gy-2 flex-fill">
            <div className={`col-auto`}>
              <div className="input-wrap">
                <input
                  type="text"
                  onChange={(e) => {
                    handleSearch(e);
                  }}
                  placeholder="Search this table"
                  className={`pro-input icon-r lg`}
                  value={reportState?.GLIReport?.details.search}
                />
                <span className="pro-icon">
                  {<span className="material-symbols-outlined">search</span>}
                </span>
              </div>
            </div>
            <div className={`col-auto`}>
              <DatePickerWithInput
                CalendarOutlineIcon={
                  <span className="material-symbols-outlined">
                    calendar_month
                  </span>
                }
                range={true}
                initialValue={date}
                onDateChange={handleDateChange}
              ></DatePickerWithInput>
            </div>
          </div>
      <div className="col-auto pro-pt-3 pro-pb-6">
        <div>
          {reportDetailData?.data?.data?.length > 0 ? (
            <>
              <Table
                multiSelect={false}
                data={reportDetailData?.data?.data || []}
                uniqueID={"id"}
                fields={reportDetailData?.data?.fields}
                SortIcon={<FaSort />}
                handleSort={handleSort}
                getRow={getRow}
                loading={isFetching}
                perpage={reportState?.GLIReport?.currentPageSize}
                assignable={false}
                editable={false}
                deletable={false}
                showCheckBox={false}
              />
              {reportDetailData?.data?.data?.length > 0 && (
                <Pagination
                  currentPage={reportState?.GLIReport?.currentPage}
                  totalPageCount={Math.ceil(
                    reportDetailData?.data?.total_count /
                      reportState?.GLIReport?.currentPageSize
                  )}
                  onPageChange={handlePagination}
                  options={paginationOptions}
                  onActionChange={handlePageSize}
                  center
                />
              )}
            </>
          ) : (
            <EmptyData />
          )}
        </div>
      </div>
    </div>
  );
};

export default GLIDetails;
