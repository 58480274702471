const useFontCases = () => {
  const fontCases = [
    {
      Uppercase: 1,
    },
    {
      Lowercase: 2,
    },
    {
      Title_Case: 3,
    },
    {
      Sentence_Case: 4,
    },
    {
      Camel_Case: 5,
    },
  ];

  const getFontCase = (fontCaseValue) => {
    const matchingFontCase = fontCases.find((caseObj) =>
      Object.values(caseObj).includes(fontCaseValue)
    );
    return matchingFontCase ? Object.keys(matchingFontCase)[0] : null;
  };

  return { fontCases, getFontCase };
};

export default useFontCases;
