import { getAxiosInstance } from "../../../api";

export const getDeliveryCertificate = async (id, slug) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(
      `delivery/recheck/list?receipt_id=${id}&process_slug=${slug}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const approveCertificate = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`delivery/recheck/submit`, params);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getPrintSlip = async (id) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(`/delivery/recheck/slip?stock_receipt_id=${id}`);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getPrintChellan = async (id) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(
      `/delivery/recheck/create-challan?stock_receipt_id=${id}`
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const passRecheckAll = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(
      `/employee-dashboard/recheck/bulk-status-complete`,
      params
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const updateRecheckPrintData = async (body) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`/delivery/recheck/update`, body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};
