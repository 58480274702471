import { useSelector } from "react-redux";
import {
  useGetItemTypeListDataQuery,
  useUpdateItemTypeTableHeadDataMutation,
} from "../../../store/queries/Master";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Master/ItemType/itemTypeSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  approveItemTypeRequest,
  deleteItemTypeData,
  getExportedData,
  getItemTypeData,
} from "./api";
import Style from "./itemType.module.scss";
import moment from "moment";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const useItemType = () => {
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];

  const dispatch = useDispatch();
  const [isCenter, setIsCenter] = useState(false);
  const [isCVDChecked, setIsCVDChecked] = useState(false);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const itemTypeState = useSelector((state) => state.itemType);
  const {
    data: itemTypeList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetItemTypeListDataQuery({
    page_size: itemTypeState.currentPageSize,
    page: itemTypeState.currentPage,
    sort_by: itemTypeState.sortBy,
    sort_order: itemTypeState.sortOrder,
    start: itemTypeState.startDate,
    end: itemTypeState.endDate,
    search: itemTypeState.search,
  });

  const [updateItemTypeFields] = useUpdateItemTypeTableHeadDataMutation();

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = itemTypeList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [itemTypeList]);

  useEffect(() => {
    if (itemTypeState?.selectedItem && itemTypeState?.isEdit) {
      fillItemTypeData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemTypeState?.isEdit, itemTypeState?.showAddItemTypeModal]);
  useEffect(() => {
    if (itemTypeState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [itemTypeState.clearSelection]);

  const handleModalClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddItemTypeModal = !state.showAddItemTypeModal;
        state.isEdit = false;
      })
    );
    setIsCVDChecked(false);
    setIsCenter(false);
  };

  const handleCVDChecked = () => {
    setIsCVDChecked(!isCVDChecked);
  };
  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      services: (feild, data) => (
        <ul>
          {data?.[feild]?.map((item, index) => {
            return <li key={index}>{item?.name}</li>;
          })}
        </ul>
      ),
      qc_fields: (feild, data) => (
        <ul>
          {data?.[feild]?.map((item, index) => {
            return (
              <li className={Style.fields} key={index}>
                {item?.name}
              </li>
            );
          })}
        </ul>
      ),
      center_stone_fields: (feild, data) => (
        <ul>
          {data?.[feild]?.map((item, index) => {
            return (
              <li className={Style.fields} key={index}>
                {item?.name}
              </li>
            );
          })}
        </ul>
      ),
      side_stone_fields: (feild, data) => (
        <ul>
          {data?.[feild]?.map((item, index) => {
            return (
              <li className={Style.fields} key={index}>
                {item?.name}
              </li>
            );
          })}
        </ul>
      ),
      cvd_fields: (feild, data) => (
        <ul>
          {data?.[feild]?.map((item, index) => {
            return <li key={index}>{item?.name}</li>;
          })}
        </ul>
      ),
      status: (_, data) =>
        data?.status === "Pending" && itemTypeList?.data?.approve_permission ? (
          <Button
            className={"pro-btn-primary sm"}
            type="button"
            onClick={() => handleApprovePermission(data?._id)}
          >
            Approve
          </Button>
        ) : data?.status === "Deleted" ? (
          <span className={"pro-badge badge-danger-outline"}>{"Deleted"}</span>
        ) : data?.status === "Active" ? (
          <span className={"pro-badge badge-success-outline"}>{"Active"}</span>
        ) : (
          data?.status
        ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };
  const handleApprovePermission = (approveId) => {
    let params = {
      item_type_id: approveId,
    };
    approveItemTypeRequest(params).then((response) => {
      if (response?.data?.status_code === 200) {
        refetch();
        toast.success("Approved successfully");
      } else {
        toast.error("Something went wrong");
      }
    });
  };
  const handleDelete = () => {
    let data = {
      item_type_id: itemTypeState?.selectedItem,
    };
    deleteItemTypeData(data).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Deleted successfully");
        handleDeleteModal();
        refetch();
      } else {
        toast.error("Something went wrong");
        handleDeleteModal();
      }
    });
  };

  const fillItemTypeData = () => {
    getItemTypeData(itemTypeState?.selectedItem).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => (state.itemData = response?.data?.data))
        );
      } else {
        toast.error("Something went wrong to fetch data");
      }
    });
  };

  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedItem = e?.[0];
      })
    );
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showAddItemTypeModal = true;
        state.selectedItem = e?.[0];
        state.isEdit = true;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (itemTypeState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = itemTypeState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleExcelDownload = (e) => {
    let downloadData = itemTypeList?.data?.data?.filter(
      (item) => item?._id === e?.[0]
    );
    let downloadId = e?.[0];
    getExportedData(downloadId).then((response) => {
      let dataType = response?.data?.type;
      let binaryData = [];
      binaryData.push(response?.data);
      let downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(
        new Blob(binaryData, { type: dataType })
      );
      downloadLink.setAttribute(
        "download",
        `${moment(Date.now()).format("MM_DD_YYYY_")}${
          downloadData?.[0]?.name
        }.xlsx`
      );
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  };
  return {
    itemTypeState,
    showEditModal,
    tableFields,
    itemTypeList,
    isLoading,
    isCenter,
    isFetching,
    paginationOptions,
    isCVDChecked,
    setIsCVDChecked,
    setIsCenter,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    handleDelete,
    handleDeleteModal,
    handleEditAction,
    handleSearch,
    updateItemTypeFields,
    handleExcelDownload,
    handleClearClick,
    handlePageSize,
    handleCVDChecked,
    refetch,
  };
};

export default useItemType;
