import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useGetBasicDataQuery } from "../../../../../../store/queries/QC";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../../store/slices/QC/QCSlice";
import { getEditDetails, updateFields } from "./api";
import { useEffect } from "react";

const useEdit = () => {
  const dispatch = useDispatch();
  const slug = localStorage?.getItem("slug");
  const { editId } = useSelector((state) => state.qc);
  const { data: basicData } = useGetBasicDataQuery({
    process_slug: slug,
  });

  const formik = useFormik({
    initialValues: {
      color: "",
      clarity: "",
      seive_size: "",
      diamond_number: "",
      // lot_number: "",
      weight: "",
    },
    validate: (values) => {
      const errors = {};
      Object.keys(values).forEach((key) => {
        if (key !== "seive_size") {
          if (!values[key]) {
            errors[key] = `${key} is required`;
          }
        }
      });
      if (values.diamond_number && isNaN(values.diamond_number)) {
        errors.diamond_number = "Diamond number must be a number";
      }
      if (values.weight && isNaN(values.weight)) {
        errors.weight = "Weight must be a number";
      }
      return errors;
    },
    onSubmit: (values) => {
      let data = {
        process_slug: slug,
        id: editId,
        // lot_number: values?.lot_number,
        color_id: values?.color?._id,
        clarity_id: values?.clarity?._id,
        seive_size: values?.seive_size,
        number_of_diamonds: values?.diamond_number,
        weight: values?.weight,
      };
      updateFields(data).then((response) => {
        if (response?.data?.status_code === 200) {
          formik?.resetForm();
          toast.success("Success");
        } else if (response?.status_code === 422) {
          let errors = response?.errors;
          let errorFields = Object.keys(errors);
          errorFields.forEach((field) => {
            formik.setFieldError(field, errors[field]);
          });
        } else {
          toast.error("Something went wrong");
        }
      });
    },
  });

  const fillData = (data) => {
    let color = basicData?.data?.colour_list?.filter(
      (val) => val?._id === data?.color_id
    );
    let clarity = basicData?.data?.clarity_list?.filter(
      (val) => val?._id === data?.clarity_id
    );
    formik?.setFieldValue("color", color?.[0]);
    formik?.setFieldValue("clarity", clarity?.[0]);
    formik?.setFieldValue("seive_size", data?.seive_size);
    formik?.setFieldValue("diamond_number", data?.number_of_diamonds);
    formik?.setFieldValue("weight", data?.weight);
  };

  useEffect(() => {
    let data = {
      process_slug: slug,
      id: editId,
    };
    getEditDetails(data).then((response) => {
      if (response?.data?.status_code === 200) {
        fillData(response?.data?.data);
      } else {
        toast.error("Something went wrong");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.editForm = false)));
  };

  return {
    getFieldError,
    handleCloseModal,
    formik,
    basicData,
  };
};

export default useEdit;
