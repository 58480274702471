import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getMyQueueRecheckList,
  updateConfig,
} from "../../../store/slices/Packings/packingsSlice";
import { startTimerRecheck } from "../api";
import { toast } from "react-toastify";

const usePacking = () => {
  const packingState = useSelector((state) => state.packings);
  const states = packingState?.myQueueRecheckList;
  const [showDetail, setShowDetail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pendingPage, setPendingPage] = useState(1);
  const [activeCard, setActiveCard] = useState({ id: "", key: "" });
  const [detailId, setDetailId] = useState("");
  const dispatch = useDispatch();
  const slug = localStorage?.getItem("slug");

  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.searchRecheck = "";
      })
    );
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    dispatch(
      getMyQueueRecheckList({
        page: pendingPage,
        process_slug: slug,
        search: packingState?.searchRecheck
      })
    );
    // eslint-disable-next-line
  }, [pendingPage, slug]);

  const startTimerPacking = (item) => {
    let body = {
      receipt_id: item,
      process_slug: slug,
    };
    startTimerRecheck(body).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Timer Started");
      } else {
        toast.error("Can't Start Timer");
      }
    });
  };

  const handleChange = (id) => {
    setActiveCard({ id: id, key: "Pending" });
    startTimerPacking(id);
    setDetailId(id);
    setShowDetail(true);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.selectedId = id;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.myQueueRecheckList.Pending = [];
        state.searchRecheck = e?.target?.value;
      })
    );
    dispatch(
      getMyQueueRecheckList({ 
        search: e.target.value, 
        process_slug: slug 
      })
    );
  };

  const handleLoadMore = (mouseHover) => {
    if (
      mouseHover === "Pending" &&
      packingState?.Pending?.length !== packingState?.total?.Pending
    ) {
      setPendingPage(pendingPage + 1);
      // dispatch(
      //   updateConfig((state) => {
      //     state.search = packingState?.search;
      //   })
      // );
    }
  };

  return {
    packingState,
    states,
    showDetail,
    activeCard,
    isLoading,
    totalLength: {
      Pending: packingState.total.Pending,
    },
    apiStatus: {
      Pending: packingState.RecheckQueueStatus,
    },
    itemId: packingState.selectedPacking?.[0]?.[0],
    detailId,
    handleSearch,
    handleChange,
    setIsLoading,
    handleLoadMore,
  };
};

export default usePacking;
