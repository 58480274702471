import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../store/slices/Orders/orderSlice";
import { updateOrderData } from "../../../../../store/slices/Orders/addOrderSlice";
import { getAgentData } from "../../api";

const useCollection = ({ formik, setCollectionAgentList, collectionAgentList }) => {
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddOrderModal = false;
      })
    );
  };

  const handleNext = () => {
    dispatch(
      updateOrderData((state) => {
        state.activeTab = "Item";
      })
    );
  };

  const searchAgent = (value) => {
    formik.setFieldValue("collection_agent", value || "")

    const matchedObject = collectionAgentList.find(
      item => item._id === value?.value
  );
if(matchedObject) {
  formik.setFieldValue("address", matchedObject?.delivery_details?.address);
  formik.setFieldValue("phone_number", matchedObject?.delivery_details?.contact_number);
}
      
  }

  const fetchCollectionAgents = (searchTerm) => {
    getAgentData(searchTerm).then((response) => {
      if (response?.data?.status_code === 200) {
        setCollectionAgentList(response?.data?.data)
      }
    });
    
  };
  

  return { handleCloseModal, handleNext, searchAgent, fetchCollectionAgents };
};

export default useCollection;
