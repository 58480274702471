import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useBranches from "./useBranches";
import { FaSort } from "react-icons/fa";
import AddBranch from "./AddBranch";
import OrderColumn from "../../../components/Global/OrderColumn";
import { ProfileCard } from "./ProfileCard";

const Branches = () => {
  const {
    branchesState,
    branchesList,
    isFetching,
    isLoading,
    tableFields,
    showEditModal,
    imageData,
    showImageModal,
    paginationOptions,
    refetch,
    getRow,
    closeModal,
    handleDelete,
    handleModalClick,
    handleDeleteModal,
    handleEditAction,
    handleSearch,
    handleEditColumnsClick,
    updateBranchFields,
    handleClearClick,
    handlePageSize,
    handlePagination,
    handleSort,
  } = useBranches();
  return (
    <div>
      <HeadingGroup
        title={"All Branches"}
        className={`pro-mb-4`}
        buttonTitle={branchesList?.data?.create_permission && "Add new"}
        handleClick={handleModalClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          showActions={true}
          handleActionClick={handleEditColumnsClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: branchesState?.search }}
        />
        <Table
          multiSelect={false}
          data={branchesList?.data?.data || []}
          uniqueID={"_id"}
          fields={tableFields}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          clear={branchesState.clearSelection}
          getRow={getRow}
          loading={isFetching}
          perpage={branchesState?.currentPageSize}
          assignable={false}
          handleEdit={handleEditAction}
          handleDelete={handleDeleteModal}
          editable={branchesList?.data?.edit_permission}
          deletable={branchesList?.data?.delete_permission}
          showCheckBox={true}
        />
        {branchesList?.data?.data?.length > 0 && (
          <Pagination
            currentPage={branchesState?.currentPage}
            totalPageCount={Math.ceil(
              branchesList?.data?.total_count / branchesState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          show={branchesState?.showDeleteModal}
          handleClose={handleDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure delete the selected branch ?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteModal}
              submitText={"Yes"}
              submitAction={handleDelete}
              isRight={true}
            />
          </div>
        </ModalLayout>

        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={branchesList?.data?.fields}
              moduleId={branchesList?.data?.module_id}
              updateData={updateBranchFields}
            />
          </div>
        </ModalLayout>
        <ModalLayout show={showImageModal} handleClose={closeModal}>
          <ProfileCard data={imageData} handleClose={closeModal} />
        </ModalLayout>
        <OffCanvasLayout
          show={branchesState?.showAddBranchModal}
          handleClose={handleModalClick}
          title={branchesState?.isEdit ? "Update Branch" : "Add Branch"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
        >
          <AddBranch refetch={refetch} />
        </OffCanvasLayout>
      </div>
    </div>
  );
};

export default Branches;
