import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useAddStatus from "./useAddStatus";

export const AddStatus = ({ handleConfirmModal, handleConfirm, error }) => {
  const { formik, statusData, statusObj, getFieldError } = useAddStatus({
    error,
    handleConfirm,
  });
  return (
    <>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Add Status
        </label>
        <Select
          id="status"
          name="status"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("status") && " error"}`}
          classNamePrefix="pro-input"
          options={statusData ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          value={statusObj}
          onBlur={formik.handleBlur("status")}
          isMulti={false}
          onChange={(value) => {
            formik?.setFieldValue("status", value?.id || "");
          }}
          menuPlacement="auto"
        />
        {getFieldError("status") && (
          <span className="error-text">{getFieldError("status")}</span>
        )}
      </div>
      {/* remarks added in all status    */}
      {/* {formik?.values?.status !== 2 && ( */}
      <div className="input-wrap">
        <Input
          label={"Remarks"}
          type="text"
          id="remarks"
          name="remarks"
          className={`pro-input lg ${getFieldError("remarks") && " error"}`}
          {...formik.getFieldProps("remarks")}
          error={getFieldError("remarks")}
          errorMessage={getFieldError("remarks")}
        />
      </div>
      {/* )} */}
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleConfirmModal}
        >
          Cancel
        </Button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik?.handleSubmit}
        >
          {"Confirm"}
        </Button>
      </div>
    </>
  );
};

export default AddStatus;
