import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import useFontCases from "../../../../../utils/hooks/useFontCases";
import Barcode from "react-barcode";
import { PaperStaticA } from "./PaperStaticA";
import { PaperStaticC } from "./PaperStaticC";
import { PaperStaticD } from "./PaperStaticD";
import { PaperStaticE } from "./PaperStaticE";

const PapperFront = React.forwardRef(({ papperFrontPrintRef }, ref) => {
  const { getFontCase } = useFontCases();
  const { printingData } = useSelector((state) => state.printing);

  const hasCenterStone = !!printingData?.[0]?.center_stone;

  // eslint-disable-next-line no-unused-vars
  const lengthOfArray =
    (!hasCenterStone && printingData?.[0]?.data?.graded_values?.length) ?? null;
  return (
    <div ref={papperFrontPrintRef} style={{ width: "100%", height: "100%" }}>
      <title>DHC PDF</title>
      {printingData.map((value, mainIndex) => {
        return (
          <div
            style={{
              height: "1122px",
              overflow: "hidden",
              // backgroundColor: "red",
            }}
            key={mainIndex}
          >
            {/* <PaperStaticA value={value}/>
              <PaperStaticC  value={value}/>
              <PaperStaticD  value={value}/>   */}
            <Fragment key={mainIndex}>
              {!value?.shop_logo && value?.center_stone_side_stone ? (
                <PaperStaticC value={value} />
              ) : !!value?.shop_logo && value?.center_stone_side_stone ? (
                <PaperStaticE value={value} />
              ) : !!value?.shop_logo && !value?.center_stone_side_stone ? (
                <PaperStaticD value={value} />
              ) : (
                <PaperStaticA value={value} />
              )}
            </Fragment>
          </div>
        );
      })}
    </div>
  );
});

export default PapperFront;
