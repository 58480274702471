import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../store/slices/Master/Engraving/engravingSlice";
import { updateEngravingData } from "../api";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useGetEngravingBasicDataQuery } from "../../../../store/queries/Master";

const useAddForm = ({ refetch }) => {
  const dispatch = useDispatch();
  const queryState = useSelector((state) => state.engraving);

  const { data: basicData = {}, isLoading } = useGetEngravingBasicDataQuery();

  useEffect(() => {
    if (queryState?.itemData && queryState?.isEdit && !isLoading) {
      Object.keys(queryState?.itemData || {}).forEach((key) => {
        if (key === "engraving_type_name") {
          formik.setFieldValue("name", queryState?.itemData?.[key]);
        } else if (key === "shop_id") {
          const shop = basicData?.data?.shop_list?.filter(
            // eslint-disable-next-line eqeqeq
            (value) => value?._id == queryState?.itemData?.[key]
          );
          formik.setFieldValue("shop", shop?.[0]);
        } else if (key === "engraving_fields_order") {
          let order = [];
          queryState.itemData?.[key]?.forEach((item) => order?.push(item));
          formik?.setFieldValue("engraving", order);
        } else {
          formik.setFieldValue(key, queryState?.itemData?.[key]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryState?.itemData, isLoading]);

  const validation = Yup.object({
    name: Yup.string().trim().required("Enter  Name").max(50, "Enter  Name"),
    shop: !queryState?.isEdit && queryState?.itemData?.format_type !== 1 && Yup.object().required("Select one"),
    engraving: Yup.array().required("Select engraving fields"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      shop: "",
      engraving: "",
    },
    validationSchema: validation,

    onSubmit: (values) => {
      const engravings = values?.engraving?.map((item) => item?._id);
      let data = {
        engraving_type_name: values?.name,
        shop_id: values?.shop?._id,
        engraving_fields_order: engravings,
      };
      if (queryState?.isEdit) {
        data = {
          engraving_type_id: queryState?.selectedItem,
          engraving_type_name: values?.name,
          shop_id: values?.shop?._id,
          engraving_fields_order: engravings,
        };
      }
      updateEngravingData(data).then((response) => {
        if (response?.data?.status_code === 200) {
          handleCloseModal();
          toast.success(
            queryState?.isEdit ? "Data Updated" : "Added successfully"
          );
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
          refetch();
        } else {
          toast.error("Something went wrong");
        }
      });
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddModal = false;
      })
    );
  };

  return {
    formik,
    queryState,
    basicData: basicData?.data,
    getFieldError,
    handleCloseModal,
  };
};

export default useAddForm;
