import React from "react";
// import Table from "react-bootstrap/Table";
import Style from './tinyTable.module.scss';

const TinyTable = ({ data = {} }) => {
  const workDetailsArray = Object.entries(data).map(
    ([task, details], index) => ({
      task,
      duration: details.duration,
      itemDetailsCount: details.item_details_count,
    })
  );
  return (
    <table className={Style.inner_table}>
      <thead>
        <tr>
          <th>Department</th>
          <th>Productive Time</th>
          <th>Certificate Count</th>
        </tr>
      </thead>
      <tbody>
        {workDetailsArray?.map((work, index) => (
          <tr key={index}>
            <td>{work.task}</td>
            <td>{work.duration}</td>
            <td>{work.itemDetailsCount}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TinyTable;
