import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useGetStockListDataQuery } from "../../../../store/queries/Orders";
import { updateStatus } from "../api";
import { updateConfig } from "../../../../store/slices/Reassign/reassignSlice";

const useStockUpdateModel = ({ refetch, activeTab }) => {
  const dispatch = useDispatch();
  const { data:  statusList } = useGetStockListDataQuery();
  const reassignState = useSelector((state) => state.reassign);


  const initialValues = {
    status: "",
    
  };
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      const params = {
        status: values?.status ?? "",
        stock_receipt_id: reassignState?.selectedId
      };
      try {
        let response = await updateStatus(params);
        
        if (response?.data?.status_code === 200) {
          toast.success(
            `Status Updated successfully`
          );
          handleCancel();
          refetch();
        } else {
          toast.error(response?.message);
          handleCancel();
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Something went wrong");
      }
    },
  });

  const getFieldError = (fieldName) => {
    if (formik?.touched[fieldName] && formik?.errors[fieldName]) {
      return formik?.errors[fieldName];
    }
    return "";
  };

  const handleCancel = () => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = false;
      })
    );
  };

  const statusObj = statusList?.data?.filter(
    (item) => item?._id === formik?.values?.status
  );

  
  return {
    formik,
    statusList: statusList?.data,
    statusObj,
    getFieldError,
    handleCancel,
  };
};

export default useStockUpdateModel;
