import React from "react";
import { useSelector } from "react-redux";
import Barcode from "react-barcode";

const StockReceipt = React.forwardRef(({ stockPrintRef }, ref) => {
  const { stockPrintData } = useSelector((state) => state.approved);

  const items = stockPrintData?.item?.slice(0, -1);
  const total = stockPrintData?.item?.[stockPrintData?.item?.length - 1];

  // const today = new Date();
  // const options = {
  //   day: "2-digit",
  //   month: "2-digit",
  //   year: "numeric",
  //   timeZone: "Asia/Kolkata",
  // };

  // const currentDate = today
  //   .toLocaleDateString("en-IN", options)
  //   .replace(/\//g, "-");

  // const currentTime = new Date()
  //   .toLocaleTimeString("en-IN", {
  //     hour: "2-digit",
  //     minute: "2-digit",
  //     hour12: true,
  //     timeZone: "Asia/Kolkata",
  //   })
  //   .toUpperCase();

  return (
    <div ref={stockPrintRef}>
      <table
        border={0}
        cellSpacing={0}
        role="presentation"
        cellPadding={0}
        style={{
          backgroundColor: "#ffffff",
          maxWidth: "450px",
          width: "100%",
          borderCollapse: "collapse",
          margin: "0 auto",
          // border: "1px solid #000000",
          fontSize: 10,
        }}
      >
        <tbody>
          <tr>
            <td>
              <table
              border={0}
              cellSpacing={0}
              role="presentation"
              cellPadding={0}
              style={{
                width : "100%",
              }}
              >
                <tbody>
                  <tr>
                    <td style={{ width: 55, paddingRight: 20 }}>
                      <img
                        src={stockPrintData?.branch_logo}
                        alt="logo"
                        width={55}
                        height={55}
                        style={{
                          width: 55,
                          minWidth: 55,
                          maxWidth: 55,
                        }}
                      />
                    </td>
                    <td>
                      <table
                        align="center"
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        width="100%"
                        style={{
                          borderCollapse: "collapse",
                          backgroundColor: "#ffffff",
                          boxSizing: "border-box",
                          textAlign :"center",
                          width : "100%",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                fontSize: 15,
                                fontWeight: 800,
                                textAlign: "center",
                                lineHeight: "28px",
                              }}
                            >
                              {stockPrintData?.branch_name}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontSize: 10,
                                fontWeight: 600,
                                textAlign: "center",
                              }}
                            >
                              {stockPrintData?.branch_address?.address_line1}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontSize: 10,
                                fontWeight: 600,
                                textAlign: "center",
                                paddingBottom: 10,
                              }}
                            >
                              {stockPrintData?.branch_address?.address_line2}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td style={{ padding: "0px 15px 8px", textAlign : "center" }}>
              <table
                align="center"
                border={0}
                cellSpacing={0}
                role="presentation"
                cellPadding={0}
                style={{
                  borderCollapse: "collapse",
                  backgroundColor: "#000000",
                  boxSizing: "border-box",
                  color: "#ffffff",
                  margin : "0px auto",
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                        fontSize: 12,
                        textTransform: "uppercase",
                        fontWeight: 600,
                        padding: "3px 20px",
                      }}
                    >
                      STOCK RECEIPT FOR INSPECTION
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td>
              <table
                border={0}
                cellSpacing={0}
                role="presentation"
                cellPadding={0}
                style={{
                  width: "100%",
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        verticalAlign: "top",
                      }}
                    >
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                      >
                        <tbody>
                          {/* <tr>
                            <td>R.No</td>
                            <td
                              style={{
                                width: 6,
                                padding: "0px 2px",
                                fontWeight: 500,
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                              }}
                            >
                              {stockPrintData?.gli_number}
                            </td>
                          </tr> */}
                          <tr>
                            <td>GLI</td>
                            <td
                              style={{
                                width: 6,
                                padding: "0px 2px",
                                fontWeight: 500,
                                paddingLeft: 65,
                              }}
                            >
                              :
                            </td>
                            <td style={{ paddingLeft: "2px" }}>
                              {stockPrintData?.gli_number && (
                                <Barcode
                                  value={stockPrintData?.gli_number}
                                  width={0.7}
                                  height={17}
                                  fontSize={9}
                                  fontOptions="bold"
                                />
                              )}
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                              }}
                            ></td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td>
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          marginLeft: "auto",
                          marginRight: 0,
                        }}
                      >
                        <tbody>
                          <tr>
                            <td>Date</td>
                            <td
                              style={{
                                width: 6,
                                padding: "2px 2px",
                                fontWeight: 500,
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                              }}
                            >
                              {stockPrintData?.created_date}
                            </td>
                          </tr>
                          <tr>
                            <td>Time</td>
                            <td
                              style={{
                                width: 6,
                                padding: "2px 2px",
                                fontWeight: 500,
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                              }}
                            >
                              {stockPrintData?.created_time}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td>
              <table
                border={0}
                cellSpacing={0}
                role="presentation"
                cellPadding={0}
                style={{
                  width: "100%",
                  verticalAlign: "top",
                }}
              >
                <tbody>
                  <tr>
                    <td style={{
                        width: "50%",
                        paddingRight: 8,
                        paddingTop: 2,
                        paddingBottom: 2,
                      }}>
                      <table
                      border={0}
                      cellSpacing={0}
                      role="presentation"
                      cellPadding={0}
                      style={{
                        width: "100%",
                      }}>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                width: 85,
                              }}
                            >
                              Supplier Name
                            </td>
                            <td
                              style={{
                                padding: "0px 4px",
                                fontWeight: 500,
                                width: 10,
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                              }}
                            >
                            {stockPrintData?.supplier?.name} {stockPrintData?.supplier?.location && `, ${stockPrintData?.supplier?.location}`}
                          </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td style={{
                        width: "50%",
                        paddingLeft: 8,
                        paddingTop: 2,
                        paddingBottom: 2,
                      }}>
                      <table border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                        }}>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                width: 85,
                                verticalAlign: "top",
                              }}
                            >
                              Total Weight
                            </td>
                            <td
                              style={{
                                padding: "0px 4px",
                                fontWeight: 500,
                                width: 10,
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                              }}
                            >
                            {total?.total_weight}
                          </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td style={{
                        width: "50%",
                        paddingRight: 8,
                        paddingTop: 2,
                        paddingBottom: 2,
                      }}>
                      <table border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                        }}>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                width: 85,
                              }}
                            >
                              Shop Name
                            </td>
                            <td
                              style={{
                                padding: "0px 4px",
                                fontWeight: 500,
                                width: 10,
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                              }}
                            >
                              {stockPrintData?.shop?.name} {stockPrintData?.shop?.location && `, ${stockPrintData?.shop?.location}`}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td style={{
                        width: "50%",
                        paddingLeft: 8,
                        paddingTop: 2,
                        paddingBottom: 2,
                      }}>
                      <table border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                        }}>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                width: 85,
                              }}
                            >
                              Total Quantity 
                            </td>
                            <td
                              style={{
                                padding: "0px 4px",
                                fontWeight: 500,
                                width: 10,
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                              }}
                            >
                              {total?.item_quantity}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td>
              <table
                border={0}
                cellSpacing={0}
                role="presentation"
                cellPadding={0}
                style={{
                  width: "100%",
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        width: "50%",
                        paddingRight: 8,
                        paddingTop: 2,
                        paddingBottom: 2,
                      }}
                    >
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                width: 85,
                              }}
                            >
                              Bill To
                            </td>
                            <td
                              style={{
                                padding: "0px 4px",
                                width: 10,
                                fontWeight: 500,
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                              }}
                            >
                              {stockPrintData?.bill_to}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td
                      style={{
                        width: "50%",
                        paddingLeft: 8,
                        paddingTop: 2,
                        paddingBottom: 2,
                      }}
                    >
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                width: 85,
                              }}
                            >
                              Delivery Person
                            </td>
                            <td
                              style={{
                                padding: "0px 4px",
                                width: 10,
                                fontWeight: 500,
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                              }}
                            >
                              {stockPrintData?.delivery_details?.person},{" "}
                              {stockPrintData?.delivery_details?.contact_number}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "50%",
                        paddingRight: 8,
                        paddingBottom: 2,
                        paddingTop: 2,
                      }}
                    >
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                width: 85,
                              }}
                            >
                              Service
                            </td>
                            <td
                              style={{
                                padding: "0px 4px",
                                width: 10,
                                fontWeight: 500,
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                              }}
                            >
                              {stockPrintData?.service?.map(
                                (value, index, array) => (
                                  <React.Fragment key={index}>
                                    <label>{value}</label>
                                    {index !== array.length - 1 && ", "}
                                  </React.Fragment>
                                )
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    {stockPrintData?.grade && (
                      <td
                        style={{
                          width: "50%",
                          paddingLeft: 8,
                          paddingBottom: 2,
                          paddingTop: 2,
                        }}
                      >
                        <table
                          border={0}
                          cellSpacing={0}
                          role="presentation"
                          cellPadding={0}
                          style={{
                            width: "100%",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  width: 85,
                                }}
                              >
                                Grade
                              </td>
                              <td
                                style={{
                                  padding: "0px 4px",
                                  width: 10,
                                  fontWeight: 500,
                                }}
                              >
                                :
                              </td>
                              <td
                                style={{
                                  fontWeight: 600,
                                }}
                              >
                                {stockPrintData?.grade}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "50%",
                        paddingRight: 8,
                        paddingTop: 2,
                        paddingBottom: 2,
                      }}
                    >
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                width: 85,
                              }}
                            >
                              Item Category
                            </td>
                            <td
                              style={{
                                padding: "0px 4px",
                                width: 10,
                                fontWeight: 500,
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                              }}
                            >
                              {stockPrintData?.item_type}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td
                      style={{
                        width: "50%",
                        paddingLeft: 8,
                        paddingTop: 2,
                        paddingBottom: 2,
                      }}
                    >
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                width: 85,
                              }}
                            >
                              Due Date & Time
                            </td>
                            <td
                              style={{
                                padding: "0px 4px",
                                width: 10,
                                fontWeight: 500,
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                              }}
                            >
                              {stockPrintData?.date}| {stockPrintData?.time}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "50%",
                        paddingRight: 8,
                        paddingBottom: 2,
                        paddingTop: 2,
                      }}
                    >
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                width: 85,
                              }}
                            >
                              Changable Stones
                            </td>
                            <td
                              style={{
                                padding: "0px 4px",
                                width: 10,
                                fontWeight: 500,
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                              }}
                            >
                              {stockPrintData?.no_changeable_stone ?? "--"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td
                      style={{
                        width: "50%",
                        paddingLeft: 8,
                        paddingBottom: 2,
                        paddingTop: 2,
                      }}
                    >
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                width: 85,
                              }}
                            >
                              Job Work
                            </td>
                            <td
                              style={{
                                padding: "0px 4px",
                                width: 10,
                                fontWeight: 500,
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                              }}
                            >
                              {stockPrintData?.is_job_work === "1"
                                ? "Yes"
                                : "2"
                                ? "No"
                                : "--"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "50%",
                        paddingRight: 8,
                        paddingTop: 2,
                        paddingBottom: 2,
                      }}
                    >
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                width: 85,
                              }}
                            >
                              Packing Type
                            </td>
                            <td
                              style={{
                                padding: "0px 4px",
                                width: 10,
                                fontWeight: 500,
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                              }}
                            >
                              {stockPrintData?.packing_type}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td
                      style={{
                        width: "50%",
                        paddingLeft: 8,
                        paddingTop: 2,
                        paddingBottom: 9,
                      }}
                    >
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                width: 85,
                              }}
                            >
                              Certificate Type
                            </td>
                            <td
                              style={{
                                padding: "0px 4px",
                                width: 10,
                                fontWeight: 500,
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                              }}
                            >
                              {stockPrintData?.certificate_type}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  {/* box weight */}
                  {stockPrintData?.box_weight && (
                    <tr>
                      <td
                        style={{
                          width: "50%",
                          paddingRight: 8,
                          paddingTop: 2,
                          paddingBottom: 2,
                        }}
                      >
                        <table
                          border={0}
                          cellSpacing={0}
                          role="presentation"
                          cellPadding={0}
                          style={{
                            width: "100%",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  width: 85,
                                }}
                              >
                                Box Weight
                              </td>
                              <td
                                style={{
                                  padding: "0px 4px",
                                  width: 10,
                                  fontWeight: 500,
                                }}
                              >
                                :
                              </td>
                              <td
                                style={{
                                  fontWeight: 600,
                                }}
                              >
                                {stockPrintData?.box_weight ?? ""}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td>
              <table
                border={0}
                cellSpacing={0}
                role="presentation"
                cellPadding={0}
                style={{
                  width: "100%",
                }}
              >
                <tbody>
                  {/* <tr>
                    <td
                      style={{
                        width: "50%",
                        paddingRight: 8,
                        paddingTop: 3,
                        paddingBottom: 3,
                      }}
                    >
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                width: 85,
                              }}
                            >
                              Recheck Changes in
                            </td>
                            <td
                              style={{
                                padding: "0px 4px",
                                width: 10,
                                fontWeight: 500,
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                                borderBottom: "0.5px dashed #000000",
                              }}
                            >
                              G.wt, Image.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td
                      style={{
                        width: "50%",
                        paddingLeft: 8,
                        paddingTop: 3,
                        paddingBottom: 3,
                      }}
                    >
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                width: 85,
                              }}
                            ></td>
                            <td
                              style={{
                                padding: "0px 4px",
                                width: 10,
                                fontWeight: 500,
                              }}
                            ></td>
                            <td
                              style={{
                                fontWeight: 600,
                              }}
                            ></td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr> */}

                  <tr>
                    <td
                      style={{
                        width: "50%",
                        paddingRight: 8,
                        paddingTop: 3,
                        paddingBottom: 12,
                      }}
                      colSpan={2}
                    >
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                width: 85,
                              }}
                            >
                              Remarks
                            </td>
                            <td
                              style={{
                                padding: "0px 4px",
                                width: 10,
                                fontWeight: 500,
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                                borderBottom: "0.5px dashed #000000",
                              }}
                            >
                              {stockPrintData?.remarks}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  {stockPrintData?.engraving && (
                    <>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            paddingRight: 8,
                            paddingTop: 3,
                            paddingBottom: 3,
                          }}
                        >
                          <table
                            border={0}
                            cellSpacing={0}
                            role="presentation"
                            cellPadding={0}
                            style={{
                              width: "100%",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    width: 85,
                                  }}
                                >
                                  Engraving Type
                                </td>
                                <td
                                  style={{
                                    padding: "0px 4px",
                                    width: 10,
                                    fontWeight: 500,
                                  }}
                                >
                                  :
                                </td>
                                <td
                                  style={{
                                    fontWeight: 600,
                                    borderBottom: "0.5px dashed #000000",
                                  }}
                                >
                                  {stockPrintData?.engraving?.engraving_type}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td
                          style={{
                            width: "50%",
                            paddingLeft: 8,
                            paddingTop: 3,
                            paddingBottom: 3,
                          }}
                        >
                          <table
                            border={0}
                            cellSpacing={0}
                            role="presentation"
                            cellPadding={0}
                            style={{
                              width: "100%",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    width: 85,
                                  }}
                                >
                                  Engraving Font
                                </td>
                                <td
                                  style={{
                                    padding: "0px 4px",
                                    width: 10,
                                    fontWeight: 500,
                                  }}
                                >
                                  :
                                </td>
                                <td
                                  style={{
                                    fontWeight: 600,
                                    borderBottom: "0.5px dashed #000000",
                                  }}
                                >
                                  {
                                    stockPrintData?.engraving
                                      ?.engraving_text_font
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            paddingRight: 8,
                            paddingBottom: 2,
                            paddingTop: 2,
                          }}
                        >
                          <table
                            border={0}
                            cellSpacing={0}
                            role="presentation"
                            cellPadding={0}
                            style={{
                              width: "100%",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    width: 85,
                                  }}
                                >
                                  Engraving Text
                                </td>
                                <td
                                  style={{
                                    padding: "0px 4px",
                                    width: 10,
                                    fontWeight: 500,
                                  }}
                                >
                                  :
                                </td>
                                <td
                                  style={{
                                    fontWeight: 600,
                                    borderBottom: "0.5px dashed #000000",
                                  }}
                                >
                                  {stockPrintData?.engraving?.engraving_text}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td
                          style={{
                            width: "50%",
                            paddingLeft: 8,
                            paddingBottom: 2,
                            paddingTop: 2,
                          }}
                          rowSpan={2}
                        >
                          <table
                            border={0}
                            cellSpacing={0}
                            role="presentation"
                            cellPadding={0}
                            style={{
                              width: "100%",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    width: 85,
                                    verticalAlign: "top",
                                  }}
                                >
                                  Engraving Image
                                </td>
                                <td
                                  style={{
                                    padding: "0px 4px",
                                    width: 10,
                                    fontWeight: 500,
                                    verticalAlign: "top",
                                  }}
                                >
                                  :
                                </td>
                                <td
                                  style={{
                                    fontWeight: 600,
                                    height: 30,
                                    border: "0.5px dashed #D0DAE4",
                                    backgroundColor: "#FDFEFF",
                                    textAlign: "center",
                                  }}
                                >
                                  <img
                                    alt="engraving-pic"
                                    src={
                                      stockPrintData?.engraving?.engraving_logo
                                    }
                                    style={{
                                      maxWidth: "100%",
                                    }}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            paddingRight: 8,
                            paddingTop: 2,
                            paddingBottom: 2,
                          }}
                        >
                          <table
                            border={0}
                            cellSpacing={0}
                            role="presentation"
                            cellPadding={0}
                            style={{
                              width: "100%",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    width: 85,
                                  }}
                                >
                                  Engraving Remarks
                                </td>
                                <td
                                  style={{
                                    padding: "0px 4px",
                                    width: 10,
                                    fontWeight: 500,
                                  }}
                                >
                                  :
                                </td>
                                <td
                                  style={{
                                    fontWeight: 600,
                                    borderBottom: "0.5px dashed #000000",
                                  }}
                                >
                                  .
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table
                border={0}
                cellSpacing={0}
                role="presentation"
                cellPadding={0}
                style={{
                  width: "100%",
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ padding: 2 }}>
                      <table
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          width: "100%",
                          border: "0.5px solid #C8C8C8",
                          borderRadius: 1,
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                fontWeight: 700,
                                fontSize: 10,
                                backgroundColor: "#FDFEFF",
                                borderBottom: "0.5px solid #C8C8C8",
                                borderRight: "0.5px solid #C8C8C8",
                                textAlign: "left",
                              }}
                            >
                              Sl. No.
                            </th>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                fontWeight: 700,
                                fontSize: 10,
                                backgroundColor: "#FDFEFF",
                                borderBottom: "0.5px solid #C8C8C8",
                                borderRight: "0.5px solid #C8C8C8",
                                textAlign: "left",
                              }}
                            >
                              Item
                            </th>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                fontWeight: 700,
                                fontSize: 10,
                                backgroundColor: "#FDFEFF",
                                borderBottom: "0.5px solid #C8C8C8",
                                borderRight: "0.5px solid #C8C8C8",
                                textAlign: "center",
                              }}
                            >
                              Quantity
                            </th>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                fontWeight: 700,
                                fontSize: 10,
                                backgroundColor: "#FDFEFF",
                                borderBottom: "0.5px solid #C8C8C8",
                                textAlign: "right",
                              }}
                            >
                              Weight
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {items?.map((value, index) => {
                            return (
                              <tr key={index}>
                                <td
                                  style={{
                                    padding: "2px 5px",
                                    borderRight: "0.5px solid #C8C8C8",
                                    fontSize: 10,
                                    fontWeight: 400,
                                    textAlign: "left",
                                  }}
                                >
                                  {index + 1}
                                </td>
                                <td
                                  style={{
                                    padding: "2px 5px",
                                    borderRight: "0.5px solid #C8C8C8",
                                    fontSize: 10,
                                    fontWeight: 400,
                                    textAlign: "left",
                                  }}
                                >
                                  {value?.name}
                                </td>
                                <td
                                  style={{
                                    padding: "2px 5px",
                                    borderRight: "0.5px solid #C8C8C8",
                                    fontSize: 10,
                                    fontWeight: 400,
                                    textAlign: "center",
                                  }}
                                >
                                  {value?.item_quantity}
                                </td>
                                <td
                                  style={{
                                    padding: "2px 5px",
                                    borderRight: "0.5px solid #C8C8C8",
                                    fontSize: 10,
                                    fontWeight: 400,
                                    textAlign: "right",
                                  }}
                                >
                                  {value?.total_weight}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <thead>
                          <tr>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                fontWeight: 800,
                                fontSize: 11,
                                backgroundColor: "#FDFEFF",
                                borderTop: "0.5px solid #C8C8C8",
                                borderRight: "0.5px solid #C8C8C8",
                                textAlign: "left",
                              }}
                            ></th>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                fontWeight: 800,
                                fontSize: 11,
                                backgroundColor: "#FDFEFF",
                                borderTop: "0.5px solid #C8C8C8",
                                borderRight: "0.5px solid #C8C8C8",
                                textAlign: "left",
                              }}
                            >
                              {total?.name}
                            </th>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                fontWeight: 800,
                                fontSize: 11,
                                backgroundColor: "#FDFEFF",
                                borderTop: "0.5px solid #C8C8C8",
                                borderRight: "0.5px solid #C8C8C8",
                                textAlign: "center",
                              }}
                            >
                              {total?.item_quantity}
                            </th>
                            <th
                              style={{
                                padding: "3.5px 5px",
                                fontWeight: 800,
                                fontSize: 11,
                                backgroundColor: "#FDFEFF",
                                borderTop: "0.5px solid #C8C8C8",
                                textAlign: "right",
                              }}
                            >
                              {total?.total_weight}
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td
              style={{
                paddingTop: 20,
                paddingBottom: 2,
              }}
            >
              <table
                border={0}
                cellSpacing={0}
                role="presentation"
                cellPadding={0}
                style={{
                  width: "100%",
                }}
              >
                <tbody>
                  <tr>
                    <td>
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                      >
                        <tbody>
                          <tr>
                            <td style={{ fontWeight: 500 }}>
                              Vendor Name & Signature
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td>
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          marginRight: 0,
                          marginLeft: "auto",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td style={{ fontWeight: 500 }}>Lab Signature</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});

export default StockReceipt;
