import {
  ModalLayout,
  SearchFilters,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import usePrinting from "./usePrinting";
import OrderCardListing from "../../Global/OrderCardListing";
import Style from "./printing.module.scss";
import PrintingTable from "./PrintingTable";
import PrintType from "./PrintType";
import Back from "./CertificateTypes/VisitingCardOne/Back";
import PapperFront from "./CertificateTypes/PapperCard/PapperFront";
import PapperBack from "./CertificateTypes/PapperCard/PapperBack";
import BigFront from "./CertificateTypes/BigCard/BigFront";
import PapperDuplex from "./CertificateTypes/PapperCard/PapperDuplex";
import NewFrontCard from "./CertificateTypes/VisitingCardOne/NewFrontCard";
import NewVisitingDuplex from "./CertificateTypes/VisitingCardOne/NewVisitingDuplex";
import FoldableCard from "./CertificateTypes/FoldableCard";

const Printing = () => {
  const {
    lastCard,
    cardData,
    isLoading,
    apiStatus,
    activeCard,
    totalLength,
    sampleImgRef,
    printingState,
    papperDuplexRef,
    foldableCardRef,
    bigFrontPrintRef,
    vistingDuplexRef,
    papperBackPrintRef,
    papperFrontPrintRef,
    visitingOneBackPrintRef,
    visitingOneFrontPrintRef,
    setIsLoading,
    handleSearch,
    handleLoadMore,
    handleCardClick,
    handleModalClick,
  } = usePrinting();

  return (
    <>
      <div className={Style.varifaication_main_wrap}>
        <div className="row">
          <div className={`col-4 ${Style.card_main_wrapper}`}>
            <div className="row pro-mb-4 gx-2">
              <div className="col-auto">
                <SearchFilters
                  showDateRange={false}
                  onSearchInput={handleSearch}
                  showFilters={true}
                  showActions={false}
                  searchInputProps={{ value: printingState?.search }}
                  SearchIcon={
                    <span className="material-symbols-outlined"> search </span>
                  }
                />
              </div>
            </div>
            <div className={`pro-d-flex ${Style.card_flow_wrap}`}>
              <OrderCardListing
                isLoading={isLoading}
                data={cardData}
                lastCard={lastCard}
                handleChange={handleCardClick}
                handleLoadMore={handleLoadMore}
                setIsLoading={setIsLoading}
                activeCard={activeCard}
                totalLength={totalLength}
                extaClassName={Style.print_list}
                apiStatus={apiStatus}
                refBottom
              />
            </div>
          </div>
          {printingState?.showTable ? (
            <div className={`col-8 pro-pe-0 ${Style.table_main_wrapper}`}>
              <div className={`${Style.table_flow_wrapper}`}>
                <PrintingTable gliNumber={printingState?.gli_id} />
              </div>
            </div>
          ) : (
            <div className={`col-8 pro-pe-0 ${Style.table_main_wrapper}`}>
              <div className={`${Style.table_flow_wrapper}`}>
                {/* <h6 className="pro-ttl h6">Select one</h6> */}
              </div>
            </div>
          )}
          <ModalLayout
            show={printingState?.printModal}
            handleClose={handleModalClick}
            title={"Select Type"}
            closeIcon={<span className="material-symbols-outlined">close</span>}
          >
            <div className="pro-m-5">
              <PrintType
                visitingOneFrontPrintRef={visitingOneFrontPrintRef}
                visitingOneBackPrintRef={visitingOneBackPrintRef}
                papperFrontPrintRef={papperFrontPrintRef}
                papperBackPrintRef={papperBackPrintRef}
                bigFrontPrintRef={bigFrontPrintRef}
                vistingDuplexRef={vistingDuplexRef}
                papperDuplexRef={papperDuplexRef}
                foldableCardRef={foldableCardRef}
                sampleImgRef={sampleImgRef}
              />
            </div>
          </ModalLayout>

          <div className={Style.pdf_container}>
            <NewFrontCard visitingOneFrontPrintRef={visitingOneFrontPrintRef} />
            <Back visitingOneBackPrintRef={visitingOneBackPrintRef} />
            <PapperFront papperFrontPrintRef={papperFrontPrintRef} />
            <PapperBack papperBackPrintRef={papperBackPrintRef} />
            <BigFront bigFrontPrintRef={bigFrontPrintRef} />
            <NewVisitingDuplex vistingDuplexRef={vistingDuplexRef} />
            <PapperDuplex papperDuplexRef={papperDuplexRef} />
            <FoldableCard foldableCardRef={foldableCardRef} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Printing;
