import React from "react";
import { TabHeading } from "../../../Global/TabHeading";
import useVersionTab from "./useVersionTab";

const VersionTab = () => {
  const { recheckTab, handleTabClick } = useVersionTab();
  return (
    <div>
      <TabHeading
        data={recheckTab?.tabs}
        uniqueId={"id"}
        activeId={recheckTab?.activeTabId}
        handleClick={handleTabClick}
      />
     
    </div>
  );
};

export default VersionTab;
