import {
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import usePrintingTable from "./usePrintingTable";
import RemarksTableRecheck from "../../../Global/RemarksTableRecheck";

const PrintingTable = ({ gliNumber }) => {
  const {
    state,
    tableData,
    tableFields,
    paginationOptions,
    isFetching,
    currentPageSize,
    globalRemarkModal,
    handleModalClose,
    getRow,
    handlePagination,
    handlePageSize,
    handlePrintClick,
    handleSearch,
    isLoading
  } = usePrintingTable();

  return (
    <div>
      <h6 className="pro-ttl h6">{gliNumber}</h6>
      <SearchFilters
        onSearchInput={handleSearch}
        SearchIcon={<span className="material-symbols-outlined"> search </span>}
        showClearFilters={false}
        searchInputProps={{ value: state?.search }}
        showActions={false}
        loading={isLoading}
        
      />
      <Table
        multiSelect={true}
        data={tableData?.data?.data ?? []}
        uniqueID={"id"}
        fields={tableFields}
        SortIcon={<FaSort />}
        assignIcon={<span className="material-symbols-outlined">print</span>}
        getRow={getRow}
        loading={isFetching}
        perpage={currentPageSize}
        assignable={true}
        deletable={false}
        showCheckBox={true}
        editable={false}
        handleAssign={handlePrintClick}
        assignText={`Print`}
        clear={state.clear}
      />
      {tableData?.data?.data && (
        <Pagination
          currentPage={state?.currentRecheckPage}
          totalPageCount={Math.ceil(
            tableData?.data?.total_count / tableData?.data?.per_page
          )}
          onPageChange={handlePagination}
          options={paginationOptions}
          onActionChange={handlePageSize}
          center
        />
      )}
      <ModalLayout
        show={globalRemarkModal}
        handleClose={handleModalClose}
        title={"Remarks"}
        propStyle={{ root: "pro-modal-xxl" }}
        closeIcon={<span className="material-symbols-outlined">close</span>}
      >
        <div className="pro-m-5">
          <RemarksTableRecheck />
        </div>
      </ModalLayout>
    </div>
  );
};

export default PrintingTable;
