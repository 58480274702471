import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../store/slices/Billing/staffBillingSlice";
import { useEffect } from "react";
import {
  generateRecheckBulkEstimate,
  getEstimateList,
  getSubmitList,
} from "../api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { getSpecialList } from "../../api";

const useRecheckEstimate = () => {
  let paginationOptions = [
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
    { label: "100 Per page", value: 100 },
    { label: "140 Per page", value: 140 },
  ];
  const data = localStorage?.getItem("billing");
  const ParsedData = JSON?.parse(data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    // isPending,
    estimateResponse,
    estimateModal,
    selectedItems,
    selectedBilling,
    selectedStatus,
  } = useSelector((state) => state.staffBilling);
  const billingState = useSelector((state) => state.staffBilling);
  useEffect(() => {
    if (!estimateResponse) {
      let data = {
        ...ParsedData,
        per_page: 40,
      };
      navigate("/billing/billing-recheck");
      getSubmitList(data)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            dispatch(
              updateConfig((state) => {
                state.estimateResponse = response?.data?.data;
              })
            );
          }
        })
        .catch((error) => toast.error(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimateResponse]);

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleModal = (e) => {
    // dispatch(
    //   updateConfig((state) => {
    //     state.estimateModal = !state.estimateModal;
    //     state.selectedItems = e;
    //   })
    // );
    getMainSpecialList(e)
  };

  const getMainSpecialList = (e) => {
    getSpecialList({stock_receipt_id: selectedBilling?.[0]}).then((response) => {
      if(response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => {
            state.specialListData = response?.data?.data;
          })
        );
        if(e) {
          dispatch(
            updateConfig((state) => {
              state.estimateModal = !state.estimateModal;
              state.selectedItems = e;
            })
          );
        }
        else {
          dispatch(
            updateConfig((state) => {
              state.estimateModal = !state.estimateModal;
              state.generateBulk = true;
            })
          );
        }
        
      }
      
    })
  }

  const handlePageSize = ({ value: page_size }) => {
    let updatedData = {
      ...ParsedData,
      per_page: page_size,
    };
    getSubmitList(updatedData)
      .then((response) => {
        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig((state) => {
              state.estimateResponse = response?.data?.data;
            })
          );
        }
      })
      .catch((error) => toast.error(error));
  };

  const handlePagination = (page) => {
    let updatedData = {
      ...ParsedData,
      page: page,
    };
    getSubmitList(updatedData)
      .then((response) => {
        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig((state) => {
              state.estimateResponse = response?.data?.data;
            })
          );
        }
      })
      .catch((error) => toast.error(error));
  };
  const handleYes = () => {
    dispatch(
      updateConfig((state) => {
        state.estimateModal = !state.estimateModal;
        state.isPending = true;
      })
    );
    const params = {
      recheck_item_details_id: selectedItems ?? [],
    };
    getEstimateList(params).then((response) => {
      if (response?.data?.status_code === 200) {
        localStorage?.setItem("invoice_id", response?.data?.data?.estimate_id);
        dispatch(
          updateConfig((state) => {
            state.invoiceData = response?.data?.data;
            state.estimateModal = false;
            state.isPending = false;
          })
        );
        navigate("invoice");
      } else if (response?.status_code === 403) {
        toast.error(response?.message);
        dispatch(updateConfig((state) => (state.isPending = false)));
      } else if (response?.status_code === 422) {
        let errors = response?.errors;
        let errorFields = Object.keys(errors);
        errorFields.forEach((field) => {
          toast.error(errors[field]?.[0]);
        });
        dispatch(updateConfig((state) => (state.isPending = false)));
      } else {
        toast.error("Something went wrong");
        dispatch(updateConfig((state) => (state.isPending = false)));
      }
    });
  };
  const handleRecheckGenerateEstimate = async (value) => {
    if(value === null) {
      dispatch(updateConfig((state) => (state.generateBulk = false)));
    let data = {
      stock_receipt_id: selectedBilling?.[0],
      status: selectedStatus,
      is_bulk_estimate: true,
    };
    const response = await generateRecheckBulkEstimate(data);
    try {
      if (response?.data?.status_code === 200) {
        localStorage?.setItem("invoice_id", response?.data?.data?.estimate_id);
        dispatch(
          updateConfig((state) => {
            state.invoiceData = response?.data?.data;
            state.estimateModal = false;
          })
        );
        toast.success(response?.data?.message);
        navigate("invoice");
      } else if (response?.status_code === 403) {
        toast.error(response?.message);
        dispatch(updateConfig((state) => (state.isPending = false)));
      } else if (response?.status_code === 422) {
        let errors = response?.errors;
        let errorFields = Object.keys(errors);
        errorFields.forEach((field) => {
          toast.error(errors[field]?.[0]);
        });
        dispatch(updateConfig((state) => (state.isPending = false)));
      } else {
        toast.error("Something went wrong");
        dispatch(updateConfig((state) => (state.isPending = false)));
      }
    } catch (error) {
      toast.error("Something went wrong");
      dispatch(updateConfig((state) => (state.isPending = false)));
    }
  }
  else {
    getMainSpecialList();
  }
  };

  const formik = useFormik({
    initialValues: {
      status: selectedStatus && selectedStatus?.length > 0 ? 
          selectedStatus?.map((item) => item) : [],
    },
    onSubmit: (values) => {
      
      let status = values?.status?.map((value) => value);
      let data = {
        receipt_id: billingState?.selectedBilling?.[0],
        status,
        per_page: 40,
      };
      getSubmitList(data)
        .then((response) => {
          if (response?.data?.status_code === 200) {
            localStorage?.setItem("billing", JSON.stringify(data));
            dispatch(
              updateConfig((state) => {
                state.estimateResponse = response?.data?.data;
                state.showAddBillingModal = false;
              })
            );

          } else if (response?.status_code === 403) {
            toast.error(response?.message);
          }
        })
        .catch((error) => toast.error(error));
    },
  });

  const handleStatusChange = (value) => {
    // formik?.setFieldValue("status", value || null);
    formik.setFieldValue(
      "status",
      value.map((v) => v?.id)
    )
    dispatch(
      updateConfig((state) => {
        state.selectedStatus = value?.map((v) => v?.id);
      })
    );
    formik.handleSubmit();
  };
  return {
    estimateResponse,
    estimateModal,
    paginationOptions,
    handlePagination,
    handlePageSize,
    handleYes,
    getRow,
    handleModal,
    handleRecheckGenerateEstimate,
    handleStatusChange,
    formik
  };
};

export default useRecheckEstimate;
