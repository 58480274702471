import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getPackingsList,
  updateConfig,
} from "../../../../store/slices/Packings/packingsSlice";
import { PrintCertficate, getViewData, passAll } from "../api";
import { useGetPackingTableDataQuery } from "../../../../store/queries/Packing";
import { useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";
import { approvePacking } from "../../../Orders/Order/api";
import { toast } from "react-toastify";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const usePackingDetails = ({ id }) => {
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];

  const [packingTypeId, setPackingTypeId] = useState("");
  const [status, setSubmissionStatus] = useState();
  const [remarks, setRemarks] = useState("");
  const slug = localStorage?.getItem("slug");

  const packingState = useSelector((state) => state.packings);
  const { tableFields, globalRemarkModal } = useSelector(
    (state) => state.global
  );

  const {
    data: packingList = {},
    isFetching,
    refetch,
  } = useGetPackingTableDataQuery({
    receipt_id: id,
    process_slug: slug,
    page_size: packingState.table.currentPageSize,
    page: packingState.table.currentPage,
  });

  const dispatch = useDispatch();
  const disableMultiSelect = useMemo(
    () => packingList?.data?.data?.every((item) => item.disable === true),
    //eslint-disable-next-line
    [packingList?.data?.data]
  );

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = packingList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [packingList?.data]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.table.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.table.currentPageSize = page_size;
        state.table.currentPage = 1;
      })
    );
  };

  const handleEditClose = () => {
    dispatch(updateConfig((state) => (state.showCertificate = false)));
  };

  const handleView = () => {
    dispatch(updateConfig((state) => (state.viewModal = true)));
    getViewData(id).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => (state.viewData = response?.data?.data))
        );
      }
    });
  };

  const handleCloseViewModal = () => {
    dispatch(updateConfig((state) => (state.viewModal = false)));
  };

  const handleRemarksClick = (data) => {
    dispatch(
      globalUpdateConfig((state) => {
        state.globalRemarkId = data?.id;
        state.globalRemarkModal = true;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      status: (_, data) =>
        data?.status === "Rejected" ? (
          <span className={"pro-badge badge-danger-outline pro-d-inline-block"}>
            {"Rejected"}
          </span>
        ) : data?.status === "Marked" ? (
          <span className={"outline-primary pro-badge pro-d-inline-block"}>
            {"Marked"}
          </span>
        ) : data?.status === "Completed" ? (
          <span className={"outline-success pro-badge pro-d-inline-block"}>
            {"Completed"}
          </span>
        ) : data?.status === "Pending" ? (
          <span className={"outline-warning pro-badge pro-d-inline-block"}>
            {"Pending"}
          </span>
        ) : (
          <span className={"outline pro-badge pro-d-inline-block"}>
            {data?.status}
          </span>
        ),
      action: (_, data) => (
        <p className="pro-mb-0">
          {
            <Button
              className={"pro-btn-primary"}
              onClick={() => handleRemarksClick(data)}
            >
              Remarks
            </Button>
          }
        </p>
      ),
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleModalClose = () => {
    dispatch(globalUpdateConfig((state) => (state.globalRemarkModal = false)));
  };

  const handleConfirmModal = (e) => {
    let certificateCheck = [];
    dispatch(
      updateConfig((state) => {
        state.showAddPackingModal = !state.showAddPackingModal;
        certificateCheck.push(...[e]);
        state.selectedPacking = certificateCheck;
      })
    );
  };

  const handleConfirm = () => {
    let data = {
      item_details_id: packingState.selectedPacking?.[0],
      status: status,
      packing_type_id: packingTypeId,
      remarks: remarks,
    };
    let newObj = Object.entries(data);
    newObj = newObj
      .filter(
        (item) => item[1] !== undefined && item[1] !== "" && item[1] !== null
      )
      .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
    approvePacking(newObj).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Packing status changed");
        dispatch(getPackingsList({ id: id, slug: slug }));
        handleConfirmModal();
        dispatch(updateConfig((state) => (state.clear = true)));
        const timeOut = setTimeout(() => {
          dispatch(updateConfig((state) => (state.clear = false)));
          clearTimeout(timeOut);
        }, 1000);
      } else {
        toast.error("Failed changing packing status");
        handleConfirmModal();
      }
    });
  };
  const handleEditClick = (id) => {
    if (id?.length === 1) {
      if (!packingState?.showCertificate) {
        let data = {
          item_details_id: id,
          certificate_type: 3,
        };
        PrintCertficate(data).then((response) => {
          if (response?.data?.status_code === 200) {
            dispatch(
              updateConfig((state) => {
                state.cerificateData = response?.data?.data;
                state.showCertificate = true;
              })
            );
          }
        });
      } else {
        toast.error("Something went wrong");
      }
    } else {
      toast.warning("Select One");
    }
  };
  const handlePassAllModal = () => [
    dispatch(
      updateConfig((state) => {
        state.showPassAllModal = !state.showPassAllModal;
        state.packingTypeId = "";
      })
    ),
  ];
  const handlePassAll = () => {
    let data = {
      packing_type_id: packingState?.packingTypeId?._id,
      stock_receipt_id: packingState?.selectedId,
      process_slug: slug,
      remarks: packingState?.remark,
    };
    let newObj = Object.entries(data);
    newObj = newObj
      .filter(
        (item) => item[1] !== undefined && item[1] !== "" && item[1] !== null
      )
      .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
    passAll(newObj).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success(response?.data?.message);
        refetch();
        dispatch(
          updateConfig((state) => {
            state.clear = true;
            state.showPassAllModal = false;
            state.packingTypeId = "";
          })
        );
        const timeOut = setTimeout(() => {
          dispatch(updateConfig((state) => (state.clear = false)));
          clearTimeout(timeOut);
        }, 1000);
      } else if (response?.status_code === 403) {
        toast.error(response?.message ?? "Error ");
      } else {
        toast.error(response?.data?.message);
      }
    });
  };
  return {
    paginationOptions,
    disableMultiSelect,
    packingList,
    packingState,
    tableFields,
    itemId: packingState.selectedPacking?.[0]?.[0],
    isFetching,
    globalRemarkModal,
    handleEditClick,
    setSubmissionStatus,
    setPackingTypeId,
    handleConfirm,
    handleConfirmModal,
    getRow,
    handlePageSize,
    handlePagination,
    handleModalClose,
    handleCloseViewModal,
    handleView,
    handleEditClose,
    handlePassAllModal,
    handlePassAll,
    setRemarks,
  };
};

export default usePackingDetails;
