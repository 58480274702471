import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../api";

import useValidations from "../../../utils/hooks/useValidations";
import { toast } from "react-toastify";

const useResetPassword = (emailRef) => {
  const { onlyNumbers } = useValidations();
  const navigate = useNavigate();

  const [resetStatus, setResetStatus] = useState("idle");
  const [showPassword, setShowPassword] = useState(true);
  const [resetInfo, setResetInfo] = useState({});
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  useEffect(() => {
    if (resetStatus === "success") {
      navigate("/login");
    } else if (resetStatus === "invalid OTP") {
      formik?.setFieldError("otp", resetInfo?.otp?.[0]);
    } else if (resetStatus === "failed") {
      formik.setFieldError("confirm_password", "An error occurred");
    }
    // eslint-disable-next-line
  }, [resetStatus]);

  const formik = useFormik({
    initialValues: {
      otp: "",
      password: "",
      confirm_password: "",
    },
    validate: (values) => {
      let errors = {};
      if (!values?.otp) {
        errors.otp = "*Please enter OTP";
      }
      if (!onlyNumbers(values?.otp)) {
        errors.otp = "*Enter valid OTP";
      }
      if (values?.otp?.length > 4) {
        errors.otp = "*Enter valid OTP";
      }
      if (!values.password) {
        errors.password = "*Password required";
      }
      if (!values.confirm_password) {
        errors.confirm_password = "*Password confirmation required";
      }
      if (values.password !== values.confirm_password) {
        errors.confirm_password = "*Passwords does not match";
      }
      return errors;
    },
    onSubmit: (values) => {
      let data = {
        otp: parseInt(values?.otp),
        email: emailRef.current,
        new_password: values?.password,
      };
      setResetStatus("pending");
      resetPassword(data).then((response) => {
        if (response?.status === 200) {
          setResetStatus("success");
          toast.success("Reset success");
        } else if (response?.status_code === 422) {
          setResetStatus("invalid OTP");
          setResetInfo({ otp: response?.errors?.otp });
        } else {
          setResetStatus("failed");
          toast.error("Something went wrong");
        }
      });
    },
  });

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };
  const handleShowConfirmPassword = (e) => {
    e.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
    var x = document.getElementById("confirm_password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  return {
    formik,
    resetStatus,
    showPassword,
    showConfirmPassword,
    handleShowConfirmPassword,
    handleShowPassword,
  };
};

export default useResetPassword;
