import React from "react";

const Dashboard = () => {
  return (
    <div className="pro-d-flex pro-justify-center pro-items-center pro-text-center" style={{height: '80%'}}>
      <h1 className="pro-ttl h1 pro-mb-0">Master Dashboard <br/> Coming Soon</h1>
    </div>
  );
};

export default Dashboard;
