import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Billing/staffBillingSlice";
import { useSelector } from "react-redux";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { getEditedData, getEstimate, getInvoice, getReceipt } from "./api";
import { toast } from "react-toastify";

export const useRecheckInvoice = () => {
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];

  const invoiceRef = useRef();
  const estimateRef = useRef();
  const receiptRef = useRef();
  const dispatch = useDispatch();
  const invoiceState = useSelector((state) => state.staffBilling);
  const selectedId = localStorage?.getItem("invoice_id");
  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };
  useEffect(() => {
    if (!invoiceState?.invoiceData) {
      getEditedData(selectedId, 20).then((response) => {
        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig((state) => {
              state.invoiceData = response?.data?.data;
              state.estimateModal = false;
            })
          );
        } else {
          toast.error("Something went wrong");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceState?.invoiceData]);

  const handleSort = (label) => {
    if (invoiceState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = invoiceState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handlePageSize = ({ value: page_size }) => {
    getEditedData(selectedId, page_size)
      .then((response) => {
        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig((state) => {
              state.invoiceData = response?.data?.data;
            })
          );
        }
      })
      .catch((error) => toast.error(error));
  };

  const handlePagination = (page) => {
    getEditedData(selectedId, null, page)
      .then((response) => {
        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig((state) => {
              state.invoiceData = response?.data?.data;
            })
          );
        }
      })
      .catch((error) => toast.error(error));
  };

  const handleInvoicePrint = (invoiceId) => {
    getInvoice(invoiceId).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig(
            (state) => (state.invoicePrintData = response?.data?.data)
          )
        );
        setTimeout(() => {
          const element = invoiceRef?.current;
          html2pdf(element, {
            margin: [28, 0, 0, 0],
            filename: `${
              "Invoice" + moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
            }.pdf`,
            image: { type: "jpeg", quality: 1 },
            html2canvas: {
              dpi: 100,
              letterRendering: true,
              useCORS: true,
              logging: true,
              scale: 4,
              scrollY: 0,
            },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
            pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
            DisablePdfCompression: true,
          })
            .from("element-to-print")
            .outputPdf() // add this to replace implicite .save() method, which triggers file download
            .get("pdf")
            .then(function (pdfObj) {
              pdfObj.autoPrint();
              window.open(pdfObj.output("bloburl"), "F");
            });
        }, 500);
      } else {
        toast.error("Something went wrong to print");
      }
    });
  };

  const handleReceiptPrint = (invoiceId) => {
    getReceipt(invoiceId).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig(
            (state) => (state.receiptPrintData = response?.data?.data)
          )
        );
        setTimeout(() => {
          const element = receiptRef?.current;
          html2pdf(element, {
            margin: [28, 0, 0, 0],
            filename: `${
              "Receipt" + moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
            }.pdf`,
            image: { type: "jpeg", quality: 1 },
            html2canvas: {
              dpi: 100,
              letterRendering: true,
              useCORS: true,
              logging: true,
              scale: 4,
              scrollY: 0,
            },
            jsPDF: { unit: "mm", format: "a5", orientation: "portrait" },
            pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
            DisablePdfCompression: true,
          })
            .from("element-to-print")
            .outputPdf() // add this to replace implicite .save() method, which triggers file download
            .get("pdf")
            .then(function (pdfObj) {
              pdfObj.autoPrint();
              window.open(pdfObj.output("bloburl"), "F");
            });
        }, 500);
      } else {
        toast.error("Something went wrong to print");
      }
    });
  };

  const handleEstimatePrint = (invoiceId) => {
    getEstimate(invoiceId).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig(
            (state) => (state.estimatePrintData = response?.data?.data)
          )
        );
        setTimeout(() => {
          const element = estimateRef?.current;
          html2pdf(element, {
            margin: [28, 0, 0, 0],
            filename: `${
              "Estimate" + moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
            }.pdf`,
            image: { type: "jpeg", quality: 1 },
            html2canvas: {
              dpi: 100,
              letterRendering: true,
              useCORS: true,
              logging: true,
              scale: 4,
              scrollY: 0,
            },
            jsPDF: { unit: "mm", format: "a5", orientation: "portrait" },
            pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
            DisablePdfCompression: true,
          })
            .from("element-to-print")
            .outputPdf() // add this to replace implicite .save() method, which triggers file download
            .get("pdf")
            .then(function (pdfObj) {
              pdfObj.autoPrint();
              window.open(pdfObj.output("bloburl"), "F");
            });
        }, 500);
      } else {
        toast.error("Something went wrong to print");
      }
    });
  };

  return {
    invoiceRef,
    estimateRef,
    receiptRef,
    estimateList: invoiceState.invoiceData,
    paginationOptions,
    handlePageSize,
    handlePagination,
    getRow,
    handleSort,
    handleEstimatePrint,
    handleInvoicePrint,
    handleReceiptPrint,
  };
};

export default useRecheckInvoice;
