/* eslint-disable array-callback-return */
import React from "react";
import Style from "./permissions.module.scss";

export const Permissions = ({
  title,
  permissions,
  module,
  menu_id,
  uniqueID,
  singleID,
  defaultCheckedList,
  handleChange,
  handleSingleChange,
}) => {

  let count = 0;
  const newArray = [];
  permissions.map((item) => {
    item.permission_type === 1 && count++;
    newArray[count + 1] = [];
  });

  let count1 = 0;
  permissions.map((item) => {
    item.permission_type === 1 && count1++;
    newArray[count1 + 1].push(item);
  });

  return (
    <>
      {/* <div className="col-12 pro-pb-5 pro-pt-1">
        <div className="row">
          <div className="col-2">
            <h6 className="pro-ttl h6 pro-mb-0">{title}</h6>
          </div>
          <div className="col-10">
            {permissions?.length > 0 ? (
              <div className="row">
                {permissions?.map?.((permission, index) => (
                  <div className="col-12 pro-py-3" key={index}>
                    <div className="row pro-w-100 gx-6">
                      <div className={`col-auto ${Style.main_role}`}>
                        <div className="pro-check-box">
                          <input
                            type="checkbox"
                            id={permission[uniqueID]}
                            defaultChecked={defaultCheckedList?.some?.(
                              (nestedArray) =>
                                nestedArray.sub_menu_permissions?.some?.(
                                  (value) =>
                                    value?.sub_menu_id === permission[uniqueID]
                                )
                            )}
                            name="pro-check-box1"
                            className="pro-check"
                            onChange={(e) =>
                              handleChange?.({ e, module, ...permission })
                            }
                          />
                          <label
                            htmlFor={permission[uniqueID]}
                            className="pro-check-label"
                          >
                            {permission?.sub_menu_name || ""}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="pro-check-box">
                <>
                  <input
                    type="checkbox"
                    id={singleID}
                    defaultChecked={defaultCheckedList?.some?.(
                      (elm) => elm?.menu_id === singleID
                    )}
                    name="pro-check-box1"
                    className="pro-check"
                    onChange={(e) =>
                      handleSingleChange?.({ e, module, singleID })
                    }
                  />
                  <label htmlFor={singleID} className="pro-check-label">
                    {title ? `Access to ${title}` : ""}
                  </label>
                </>
              </div>
            )}
          </div>
        </div>
      </div> */}


      {/* new arrangement  */}
      <div className="col-12 pro-pb-5 pro-pt-1">
        <div className="row">
          <div className="col-2">
            <h6 className="pro-ttl h6 pro-mb-0">{title}</h6>
          </div>
          <div className="col-10">
            {newArray?.length > 0 ? (
              <div className="row">
              {newArray.map((item, index) => {
                return (
                  <div className="col-12 pro-py-4" key={index}>
                    <div className="row pro-w-100 gx-5 gy-4">
                      {item?.map((submenu, subIndex) => {
                        return (
                          <div className={`col-auto ${Style.main_role}`} key={subIndex}>
                            <div className="pro-check-box">
                              <input
                                type="checkbox"
                                id={submenu[uniqueID]}
                                defaultChecked={defaultCheckedList?.some?.(
                                  (nestedArray) =>
                                    nestedArray.sub_menu_permissions?.some?.(
                                      (value) =>
                                        value?.sub_menu_id === submenu[uniqueID]
                                    )
                                )}
                                name="pro-check-box1"
                                className="pro-check"
                                onChange={(e) =>
                                  handleChange?.({ e, module, ...submenu })
                                }
                              />
                              <label
                                htmlFor={submenu[uniqueID]}
                                className="pro-check-label"
                              >
                                {submenu?.sub_menu_name || ""}
                              </label>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
            ) : (
              <div className="pro-check-box">
                <>
                  <input
                    type="checkbox"
                    id={singleID}
                    defaultChecked={defaultCheckedList?.some?.(
                      (elm) => elm?.menu_id === singleID
                    )}
                    name="pro-check-box1"
                    className="pro-check"
                    onChange={(e) =>
                      handleSingleChange?.({ e, module, singleID })
                    }
                  />
                  <label htmlFor={singleID} className="pro-check-label">
                    {title ? `Access to ${title}` : ""}
                  </label>
                </>
              </div>
            )}
            
          </div>
        </div>
      </div>
    </>
  );
};
