import React from "react";
import Style from "./addReport.module.scss";
import { useAddReport } from "./useAddReport";
import Select from "react-select";
import ItemField from "./ItemField";
import ItemFieldCS from "./ItemFieldCS";
import ItemFieldSS from "./ItemFieldSS";

import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";

export const AddReport = (refetch, reportState) => {
  let tempQc = [];
  let tempQcP = [];
  let tempCS = [];
  let tempCSP = [];
  let tempSS = [];
  let tempSSP = [];
  const {
    isQc,
    formik,
    isEdit,
    shopLogo,
    basicData,
    backImage,
    frontImage,
    reportData,
    footerTypes,
    itemTypeData,
    supplierData,
    frontTopImage,
    frontFootImage,
    backgroundImage,
    certificateData,
    footerTypeValue,
    tempFilteredData,
    getFieldError,
    handleAddField,
    handleCloseModal,
    handleAddFieldSide,
    handleItemTypeChange,
    handleShopLogoChange,
    handleAddFieldCenter,
    handleBackImageChange,
    handleFrontImageChange,
    handleFrontTopImageChange,
    handleFrontFootImageChange,
    handleBackgroundImageChange,
    handleCertificateType,
  } = useAddReport(refetch, reportState);

  return (
    <div className="row">
      <div className="input-wrap pro-mb-4">
        <div className="pro-check-box pro-py-2">
          <input
            type="checkbox"
            className="pro-check"
            id="defult"
            name="pro-checkbox"
            checked={Boolean(formik.values?.format_type === 1) ?? false}
            onChange={(e) => {
              formik.setFieldValue(`format_type`, e?.target?.checked ? 1 : 2);
            }}
          />
          <label htmlFor="defult" className="pro-check-label">
            Default
          </label>
        </div>
      </div>
      
      <Input
        label={"Name"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${getFieldError("name") && " error"}`}
        {...formik.getFieldProps("name")}
        error={getFieldError("name")}
        errorMessage={getFieldError("name")}
      />
      {formik?.values?.format_type !== 1 && (
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Shop Name
          </label>
          <Select
            id="supplier_shop_previd"
            name="supplier_shop_id"
            placeholder={"Select"}
            className={`pro-input lg  ${
              getFieldError("supplier_shop_id") && " error"
            }`}
            classNamePrefix="pro-input"
            options={basicData?.data?.shop}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?._id}
            value={supplierData}
            onBlur={formik.handleBlur("supplier_shop_id")}
            onChange={(value) => {
              formik?.setFieldValue("supplier_shop_id", value?._id || null);
            }}
            menuPlacement="auto"
          />
          {getFieldError("supplier_shop_id") && (
            <span className="error-text">
              {getFieldError("supplier_shop_id")}
            </span>
          )}
        </div>
      )}

<div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Status
          </label>
          <Select
            id="status"
            name="status"
            placeholder={"Select"}
            className={`pro-input lg  ${getFieldError("status") && " error"}`}
            classNamePrefix="pro-input"
            options={basicData?.data?.status_list ?? []
            }
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
            value={formik?.values?.status}
            onBlur={formik.handleBlur("status")}
            onChange={(value) => formik?.setFieldValue("status", value || null)}
            menuPlacement="auto"
          />
          {getFieldError("status") && (
            <span className="error-text">{getFieldError("status")}</span>
          )}
        </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Certificate Type
        </label>
        <Select
          id="certificate_type"
          name="certificate_type"
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("certificate_type") && " error"
          }`}
          classNamePrefix="pro-input"
          options={basicData?.data?.certificate_type}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          value={certificateData}
          onBlur={formik.handleBlur("certificate_type")}
          onChange={handleCertificateType}
          menuPlacement="auto"
        />
        {getFieldError("certificate_type") && (
          <span className="error-text">
            {getFieldError("certificate_type")}
          </span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label
          htmlFor="qr_and_image_format"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Images Type
        </label>
        <Select
          id="qr_and_image_format"
          name="qr_and_image_format"
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("qr_and_image_format") && " error"
          }`}
          classNamePrefix="pro-input"
          options={basicData?.data?.qr_and_image_format}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          value={formik?.values?.qr_and_image_format}
          onBlur={formik.handleBlur("qr_and_image_format")}
          onChange={(value) => {
            formik?.setFieldValue("qr_and_image_format", value || "");
          }}
          menuPlacement="auto"
        />
        {getFieldError("qr_and_image_format") && (
          <span className="error-text">
            {getFieldError("qr_and_image_format")}
          </span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Item Type
        </label>
        <Select
          id="item_type_id"
          name="item_type_id"
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("item_type_id") && " error"
          }`}
          classNamePrefix="pro-input"
          options={basicData?.data?.item_type}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?._id}
          value={itemTypeData}
          onBlur={formik.handleBlur("item_type_id")}
          onChange={(value) => {
            formik?.setFieldValue("item_type_id", value?._id);
            handleItemTypeChange(value);
          }}
          menuPlacement="auto"
        />
        {getFieldError("item_type_id") && (
          <span className="error-text">{getFieldError("item_type_id")}</span>
        )}
      </div>

      {formik?.values?.certificate_type === 3 && (
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
            Shop Logo
          </label>
          <div className="input-drag">
            <input
              type="file"
              multiple
              accept="image/png, image/jpeg"
              placeholder="placeholder"
              className={`pro-input lg ${
                getFieldError("shop_logo") && " error"
              }`}
              id="shop_logo"
              onBlur={formik.handleBlur("shop_logo")}
              onChange={(e) => handleShopLogoChange(e)}
            />

            <span className="input-drag-box" style={{ padding: "50px" }}>
              {!isEdit && formik?.values?.shop_logo === "" ? (
                <IconText title={`Drop files to attach or browse`} />
              ) : (
                formik?.values?.shop_logo?.name
              )}
              {isEdit &&
                (reportData?.shop_logo?.original_name ?? (
                  <IconText title={`${"Drop files to attach or browses"}`} />
                ))}
            </span>

            {getFieldError("shop_logo") && (
              <span className="error-text">{getFieldError("shop_logo")}</span>
            )}
            <div className={`col-2 pro-my-2`}>
              <div className={`${""}`}>
                <Image
                  width={100}
                  height={100}
                  src={shopLogo}
                  alt={`image - 01`}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {formik?.values?.certificate_type === 4 && (
        <>
          <div className="input-wrap pro-mb-4">
            <label
              htmlFor="front_image"
              className={`pro-font-sm pro-mb-1 pro-fw-medium`}
            >
              Front Image
            </label>
            <div className="input-drag">
              <input
                type="file"
                accept="image/png, image/jpeg"
                placeholder="placeholder"
                className={`pro-input lg ${
                  getFieldError("front_image") && " error"
                }`}
                id="front_image"
                onBlur={formik.handleBlur("front_image")}
                onChange={(e) => handleFrontImageChange(e)}
              />
              <span className="input-drag-box" style={{ padding: "50px" }}>
                {formik?.values?.front_image?.name ? (
                  formik?.values?.front_image?.name
                ) : formik?.values?.front_image?.original_name ? (
                  formik?.values?.front_image?.original_name
                ) : (
                  <IconText title={`Drop files to attach or browse`} />
                )}
              </span>

              {getFieldError("front_image") && (
                <span className="error-text">
                  {getFieldError("front_image")}
                </span>
              )}
              <div className={`col-2 pro-my-2`}>
                <div className={`${""}`}>
                  <Image
                    width={100}
                    height={100}
                    src={frontImage}
                    alt={`image - 01`}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="input-wrap pro-mb-4">
        <label
          htmlFor="background_image"
          className={`pro-font-sm pro-mb-1 pro-fw-medium`}
        >
          Background Image
        </label>
        <div className="input-drag">
          <input
            type="file"
            accept="image/png, image/jpeg"
            placeholder="placeholder"
            className={`pro-input lg ${
              getFieldError("background_image") && " error"
            }`}
            id="background_image"
            onBlur={formik.handleBlur("background_image")}
            onChange={(e) => handleBackgroundImageChange(e)}
          />
          <span className="input-drag-box" style={{ padding: "50px" }}>
            {formik?.values?.background_image?.name ? (
              formik?.values?.background_image?.name
            ) : formik?.values?.background_image?.original_name ? (
              formik?.values?.background_image?.original_name
            ) : (
              <IconText title={`Drop files to attach or browse`} />
            )}
          </span>

          {getFieldError("background_image") && (
            <span className="error-text">
              {getFieldError("background_image")}
            </span>
          )}
          <div className={`col-2 pro-my-2`}>
            <div className={`${""}`}>
              <Image
                width={100}
                height={100}
                src={backgroundImage}
                alt={`image - 01`}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Front Top Image
        </label>
        <div className="input-drag">
          <input
            type="file"
            multiple
            accept="image/png, image/jpeg"
            placeholder="placeholder"
            className={`pro-input lg ${
              getFieldError("front_top_image") && " error"
            }`}
            id="front_top_image"
            onBlur={formik.handleBlur("front_top_image")}
            onChange={(e) => handleFrontTopImageChange(e)}
          />

          <span className="input-drag-box" style={{ padding: "50px" }}>
            {formik?.values?.front_top_image?.name ? (
              formik?.values?.front_top_image?.name
            ) : formik?.values?.front_top_image?.original_name ? (
              formik?.values?.front_top_image?.original_name
            ) : (
              <IconText title={`Drop files to attach or browse`} />
            )}
          </span>

          {getFieldError("front_top_image") && (
            <span className="error-text">
              {getFieldError("front_top_image")}
            </span>
          )}
          <div className={`col-2 pro-my-2`}>
            <div className={`${""}`}>
              <Image
                width={100}
                height={100}
                src={frontTopImage}
                alt={`image - 01`}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Select Footer
        </label>
        <Select
          id="footer_type"
          name="footer_type"
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("footer_type") && " error"
          }`}
          classNamePrefix="pro-input"
          options={footerTypes}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?._id}
          value={footerTypeValue}
          onBlur={formik.handleBlur("footer_type")}
          onChange={(value) => {
            formik?.setFieldValue("footer_type", value?._id);
          }}
          menuPlacement="auto"
        />
        {getFieldError("footer_type") && (
          <span className="error-text">{getFieldError("footer_type")}</span>
        )}
      </div>
      {formik?.values?.footer_type === "2" && (
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
            Front Foot Image
          </label>
          <div className="input-drag">
            <input
              type="file"
              multiple
              accept="image/png, image/jpeg"
              placeholder="placeholder"
              className={`pro-input lg ${
                getFieldError("front_foot_image") && " error"
              }`}
              id="front_foot_image"
              onBlur={formik.handleBlur("front_foot_image")}
              onChange={(e) => handleFrontFootImageChange(e)}
            />

            <span className="input-drag-box" style={{ padding: "50px" }}>
              {formik?.values?.front_foot_image?.name ? (
                formik?.values?.front_foot_image?.name
              ) : formik?.values?.front_foot_image?.original_name ? (
                formik?.values?.front_foot_image?.original_name
              ) : (
                <IconText title={`Drop files to attach or browse`} />
              )}
            </span>

            {getFieldError("front_foot_image") && (
              <span className="error-text">
                {getFieldError("front_foot_image")}
              </span>
            )}
            <div className={`col-2 pro-my-2`}>
              <div className={`${""}`}>
                <Image
                  width={100}
                  height={100}
                  src={frontFootImage}
                  alt={`image - 01`}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Back Image
        </label>
        <div className="input-drag">
          <input
            type="file"
            multiple
            accept="image/png, image/jpeg"
            placeholder="placeholder"
            // ref={documentRef}
            className={`pro-input lg ${
              getFieldError("back_image") && " error"
            }`}
            id="back_image"
            onBlur={formik.handleBlur("back_image")}
            onChange={(e) => handleBackImageChange(e)}
          />

          <span className="input-drag-box" style={{ padding: "50px" }}>
            {formik?.values?.back_image?.name ? (
              formik?.values?.back_image?.name
            ) : formik?.values?.back_image?.original_name ? (
              formik?.values?.back_image?.original_name
            ) : (
              <IconText title={`Drop files to attach or browse`} />
            )}
          </span>

          {getFieldError("back_image") && (
            <span className="error-text">{getFieldError("back_image")}</span>
          )}
          <div className={`col-2 pro-my-2`}>
            <div className={`${""}`}>
              <Image
                width={100}
                height={100}
                src={backImage}
                alt={`image - 01`}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={Style.address}>
        {isQc && (
          <>
            {formik?.values?.item_fields_CS?.map((fields, index) => {
              tempCS = [
                ...tempCS,
                formik?.values?.item_fields_CS[index]?.field_id,
              ];
              tempCSP = [
                ...tempCSP,
                formik?.values?.item_fields_CS[index]?.priority,
              ];
              return (
                <ItemFieldCS
                  key={index}
                  title={"Center Stone"}
                  fieldName={"Center Stone field"}
                  itmIndex={index}
                  fields={fields}
                  basicData={basicData}
                  formik={formik}
                  getFieldError={getFieldError}
                  tempIds={tempCS}
                  tempPIds={tempCSP}
                  temp
                  itemTypeData={itemTypeData}
                  isEdit={isEdit}
                />
              );
            })}
            {formik?.values?.item_fields_CS?.length !==
              tempFilteredData?.[0]?.center_stone_fields?.length && (
              <Button
                className={`${Style.add_btn} pro-btn-primary lg pro-w-100 pro-mt-4`}
                onClick={handleAddFieldCenter}
                disabled={!tempFilteredData?.[0]?.center_stone_fields?.length}
              >
                {` Add Another Center Stone`}
              </Button>
            )}
            {formik?.values?.item_fields_SS?.map((fields, index) => {
              tempSS = [
                ...tempSS,
                formik?.values?.item_fields_SS[index]?.field_id,
              ];
              tempSSP = [
                ...tempSSP,
                formik?.values?.item_fields_SS[index]?.priority,
              ];
              return (
                <ItemFieldSS
                  key={index}
                  title={"Side Stone"}
                  fieldName={"Side Stone field"}
                  itmIndex={index}
                  fields={fields}
                  basicData={basicData}
                  formik={formik}
                  getFieldError={getFieldError}
                  tempIds={tempSS}
                  tempPIds={tempSSP}
                  itemTypeData={itemTypeData}
                  isEdit={isEdit}
                />
              );
            })}
            {formik?.values?.item_fields_SS?.length !==
              tempFilteredData?.[0]?.side_stone_fields?.length && (
              <Button
                className={`${Style.add_btn} pro-btn-primary lg pro-w-100 pro-mt-4`}
                onClick={handleAddFieldSide}
                disabled={!tempFilteredData?.[0]?.side_stone_fields?.length}
              >
                {` Add Another Side Stone`}
              </Button>
            )}
          </>
        )}

        {formik?.values?.item_fields?.map((fields, index) => {
          tempQc = [...tempQc, formik?.values?.item_fields[index]?.field_id];
          tempQcP = [...tempQcP, formik?.values?.item_fields[index]?.priority];
          return (
            <ItemField
              key={index}
              title={"QC"}
              fieldName={"QC field"}
              itmIndex={index}
              fields={fields}
              basicData={basicData}
              formik={formik}
              getFieldError={getFieldError}
              tempIds={tempQc}
              tempPIds={tempQcP}
              itemTypeData={itemTypeData}
              isEdit={isEdit}
            />
          );
        })}
        {formik?.values?.item_fields?.length !==
          tempFilteredData?.[0]?.qc_fields?.length && (
          <Button
            className={`${Style.add_btn} pro-btn-primary lg pro-w-100 pro-mt-4`}
            onClick={handleAddField}
            disabled={!tempFilteredData?.[0]?.qc_fields?.length}
          >
            {` Add Another Qc`}
          </Button>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <div className="pro-check-box pro-py-2">
          <input
            type="checkbox"
            className="pro-check"
            id="bold"
            name="pro-checkbox"
            checked={Boolean(formik.values?.bold === 1) ?? false}
            onChange={(e) => {
              formik.setFieldValue(`bold`, e?.target?.checked ? 1 : 0);
            }}
          />
          <label htmlFor="bold" className="pro-check-label">
            Certificate Number Bold
          </label>
        </div>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn pro-mt-5`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {isEdit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default AddReport;
