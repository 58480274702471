import { getAxiosInstance } from "../../../../api";

export const getStatusData = async () => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(`/common-process-status`);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getPackingTypeData = async (id) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(`/packing/packing-list?item_details_id=${id}`);
    return res;
  } catch (error) {
    return error.response.data;
  }
};
