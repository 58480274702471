import useFilterForm from "./useFilter";

function Filter() {
  const { renderTabContent } = useFilterForm();

  return (
    <div>
      {/* eslint-disable-next-line no-script-url */}
      <form action="javascript:void(0)">{renderTabContent()}</form>
    </div>
  );
}

export default Filter;
