import React from "react";
import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../../components/Global/OrderColumn";
import useRole from "./useRole";

const Role = () => {
  const {
    roleState,
    showEditModal,
    tableFields,
    roleList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleCreateClick,
    handleEditAction,
    handleSearch,
    updateRoleFields,
    handleClearClick,
    handlePageSize,
    refetch,
  } = useRole();
  return (
    <div>
      <HeadingGroup
        title={"All Roles"}
        className={`pro-mb-4`}
        buttonTitle={"Add new"}
        handleClick={handleCreateClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: roleState?.search }}
          handleActionClick={handleEditColumnsClick}
        />
        <Table
          multiSelect={false}
          data={roleList?.data?.data || []}
          uniqueID={"_id"}
          fields={tableFields}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={roleState?.currentPageSize}
          assignable={false}
          handleEdit={handleEditAction}
          deletable={false}
          showCheckBox={true}
        />
        {roleList?.data?.data?.length > 0 && (
          <Pagination
            currentPage={roleState?.currentPage}
            totalPageCount={Math.ceil(
              roleList.data.total_count / roleState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={roleList?.data?.fields}
              moduleId={roleList?.data?.module_id}
              updateData={updateRoleFields}
            />
          </div>
        </ModalLayout>
      </div>
    </div>
  );
};

export default Role;
