import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Master/Department/departmentSlice";
import * as Yup from "yup";
import { addDepartment, editDepartment } from "../api";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useGetDepartmentSelectDataQuery } from "../../../../store/queries/Master";

const useAddDepartment = ({ refetch }) => {
  const dispatch = useDispatch();
  const departmentState = useSelector((state) => state.department);
  const {
    data: departmentSelect = {},
    refetch:departmentRefetch
  } = useGetDepartmentSelectDataQuery();

  useEffect(() => {
    if (departmentState?.isEdit && departmentState?.itemData) {
      let process = departmentSelect?.data?.all_process_list?.filter((obj) =>
        departmentState?.itemData?.process?.includes(obj._id)
      );
      formik?.setFieldValue("name", departmentState?.itemData?.name);
      formik.setFieldValue("process", process);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentState?.isEdit, departmentState?.itemData]);

  const validation = Yup.object({
    name: Yup.string().required("Enter Name").max(50, "Enter valid Name"),
    process: Yup.array()
        .required("Process is required")
        .min(1, "Process is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      process:""
    },
    validationSchema: validation,
    onSubmit: (values) => {
      const process = values?.process?.map((items) => {
        return items?._id;
      });
      let data = {
        name: values?.name,
        process_id:process
      };
      let editData = {
        name: values?.name,
        process_id:process,
        department_id: departmentState?.selectedDepartment,
      };
      if (departmentState?.isEdit) {
        editDepartment(editData).then((response) => {
          if (response?.data?.status_code === 200) {
            handleCloseModal();
            toast.success("Successfully Updated");
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
            refetch();
            departmentRefetch();
          } else if (response?.status_code === 422) {
            // displays backend errors
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        addDepartment(data).then((response) => {
          if (response?.data?.status_code === 200) {
            handleCloseModal();
            toast.success("Successfully Added");
            refetch();
            departmentRefetch();
          } else if (response?.status_code === 422) {
            // displays backend errors
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.showAddDepartmentModal = false)));
  };
  return {
    formik,
    departmentState,
    getFieldError,
    handleCloseModal,
    departmentSelect
  };
};

export default useAddDepartment;
