import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";
import { updateConfig } from "../../../../store/slices/PhotoSection/PhotoSectionSlice";
import { useGetPhotoSectionRecheckTableDataQuery } from "../../../../store/queries/PhotoSection";
import { Button, IconText } from "@wac-ui-dashboard/wac_component_library";
import { editedRecheckData, editedRecheckData2 } from "../api";
import { toast } from "react-toastify";

const usePhotoSectionTable = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "15 Per page", value: 15 },
    { label: "20 Per page", value: 20 },
  ];
  const dispatch = useDispatch();
  const [imageData, setImageData] = useState();
  const [showImageModal, setShowImageModal] = useState(false);
  const state = useSelector((state) => state.photoSection);
  const { tableFields, globalRemarkModal } = useSelector(
    (state) => state.global
  );
  const slug = localStorage?.getItem("slug");
  const {
    data: tableData,
    isFetching,
    isLoading,
    refetch,
  } = useGetPhotoSectionRecheckTableDataQuery({
    receipt_id: state?.selectedId,
    search: state.pendingOrderSearch,
    page_size: state.currentPageSize,
    page: state.currentPage,
    process_slug: slug,
  });

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = tableData?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [tableData]);

  const handleViewImage = (logoImage) => {
    setImageData(logoImage);
    setShowImageModal(true);
  };
  const closeModal = () => {
    setShowImageModal(false);
  };

  const handleRemarksClick = (data) => {
    dispatch(
      globalUpdateConfig((state) => {
        state.globalRemarkId = data?.id;
        state.globalRemarkModal = true;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(updateConfig((state) => (state.pendingOrderSearch = e?.target?.value)));
  };

  const handleModalClose = () => {
    dispatch(globalUpdateConfig((state) => (state.globalRemarkModal = false)));
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      status: (_, data) =>
        data?.status === "Rejected" ? (
          <span className={"pro-badge badge-danger-outline pro-d-inline-block"}>
            {"Rejected"}
          </span>
        ) : data?.status === "Marked" ? (
          <span className={"outline-primary pro-badge pro-d-inline-block"}>
            {"Marked"}
          </span>
        ) : data?.status === "Completed" ? (
          <span className={"outline-success pro-badge pro-d-inline-block"}>
            {"Completed"}
          </span>
        ) : data?.status === "Pending" ? (
          <span className={"outline-warning pro-badge pro-d-inline-block"}>
            {"Pending"}
          </span>
        ) : (
          <span className={"outline pro-badge pro-d-inline-block"}>
            {data?.status}
          </span>
        ),
      image: (feild, data) =>
        data?.[feild] ? (
          <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Photo"}`}
            onClick={() => handleViewImage(data?.[feild])}
          />
        ) : (
          ""
        ),
      action: (_, data) => (
        <p className="pro-mb-0">
          {
            <Button
              className={"pro-btn-primary"}
              onClick={() => handleRemarksClick(data)}
            >
              Remarks
            </Button>
          }
        </p>
      ),
      second_image: (feild, data) =>
        data?.[feild] ? (
          <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Photo"}`}
            onClick={() => handleViewImage(data?.[feild])}
          />
        ) : (
          ""
        ),
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const fillData = (id) => {
    if (tableData?.data?.table_type === 1) {
      editedRecheckData(id).then((response) => {
        if (response?.data?.status_code === 200) {
          let image1 = response?.data?.data?.image;
          let image2 = response?.data?.data?.second_image;
          dispatch(
            updateConfig((state) => {
              state.editData.photo1.id = image1?.id ?? "";
              state.editData.photo2.id = image2?.id ?? "";
              state.editData.photo1.file = image1?.url ?? "";
              state.editData.photo2.file = image2?.url ?? "";
              state.editData.remark = response?.data?.data?.remarks ?? "";
            })
          );
        } else {
          toast.error("Something went wrong");
        }
      });
    } else {
      editedRecheckData2(id).then((response) => {
        if (response?.data?.status_code === 200) {
          let image1 = response?.data?.data?.image;
          let image2 = response?.data?.data?.second_image;
          dispatch(
            updateConfig((state) => {
              state.editData.photo1.id = image1?.id ?? "";
              state.editData.photo2.id = image2?.id ?? "";
              state.editData.photo1.file = image1?.url ?? "";
              state.editData.photo2.file = image2?.url ?? "";
              state.editData.remark = response?.data?.data?.remarks ?? "";
            })
          );
        } else {
          toast.error("Something went wrong");
        }
      });
    }
  };

  const handleUploadModalClick = (e) => {
    let id = e?.[0];
    fillData(id);
    dispatch(
      updateConfig((state) => {
        state.uploadModal = true;
        state.certificateId = id;
      })
    );
  };

  const handleUploadModalClose = () => {
    dispatch(
      updateConfig((state) => {
        state.uploadModal = false;
        state.editData.photo1.id = "";
        state.editData.photo2.id = "";
        state.editData.photo1.file = "";
        state.editData.photo2.file = "";
        state.editData.remark = "";
      })
    );
  };

  const handleBulkImageUploadClick = (e) => {
    dispatch(
      updateConfig((state) => {
        state.bulkUploadModal = true;
      })
    );
  };

  const handleBulkUploadModalClose = () => {
    dispatch(
      updateConfig((state) => {
        state.bulkUploadModal = false;
        state.values.assetsImages = [];
        state.errors.assetsImages = "";
      })
    );
    refetch();
  };

  return {
    state,
    tableData,
    tableFields,
    paginationOptions,
    isFetching,
    imageData,
    globalRemarkModal,
    showImageModal,
    getRow,
    refetch,
    closeModal,
    handleModalClose,
    handlePagination,
    handlePageSize,
    handleUploadModalClick,
    handleUploadModalClose,
    handleBulkUploadModalClose,
    handleBulkImageUploadClick,
    handleSearch,
    isLoading
  };
};

export default usePhotoSectionTable;
