import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/QC/QCSlice";
import { useSelector } from "react-redux";
import { QC, useGetBasicDataQuery } from "../../../../store/queries/QC";
import { moreDetails } from "../api";
import { toast } from "react-toastify";

const useEnterMoreForm = () => {
  const dispatch = useDispatch();
  const slug = localStorage?.getItem("slug");
  const { certificateID, itemTypeCat } = useSelector((state) => state.qc);
  const { data: basicData } = useGetBasicDataQuery({
    process_slug: slug,
  });

  const formik = useFormik({
    initialValues: {
      color: "",
      clarity: "",
      seive_size: "",
      diamond_number: "",
      lot_number: "",
      weight: "",
    },
    validate: (values) => {
      const errors = {};
      Object.keys(values).forEach((key) => {
        if (!values[key]) {
          if (itemTypeCat === "diamond_loose" && key === "color") {
            return;
          } else if (
            itemTypeCat === "diamond_jewellery" &&
            key === "seive_size"
          ) {
            return;
          } else if (
            itemTypeCat === "diamond_jewellery" &&
            key === "lot_number"
          ) {
            return;
          } else {
            errors[key] = `${key} is required`;
          }
        }
      });
      if (values.diamond_number && isNaN(values.diamond_number)) {
        errors.diamond_number = "Diamond number must be a number";
      }
      // if (values.lot_number && isNaN(values.lot_number)) {
      //   errors.lot_number = "Lot number must be a number";
      // }
      if (values.weight && isNaN(values.weight)) {
        errors.weight = "Weight must be a number";
      }
      return errors;
    },
    onSubmit: (values) => {
      let data = {
        process_slug: slug,
        item_details_id: certificateID,
        lot_number: values?.lot_number,
        color: values?.color?._id,
        clarity: values?.clarity?._id,
        seive_size: values?.seive_size,
        number_of_diamonds: values?.diamond_number,
        weight: values?.weight,
      };
      moreDetails(data).then((response) => {
        if (response?.data?.status_code === 200) {
          formik?.resetForm();
          toast.success(response?.data?.message);
          dispatch(QC?.util?.invalidateTags(["formTable"]));
        } else if (response?.status_code === 422) {
          let errors = response?.errors;
          let errorFields = Object.keys(errors);
          errorFields.forEach((field) => {
            formik.setFieldError(field, errors[field]);
          });
        } else {
          toast.error("Something went wrong");
        }
      });
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.enterMoreFormModal = false;
        state.showMoreFormTable = true;
      })
    );
  };
  return {
    formik,
    basicData,
    getFieldError,
    handleCloseModal,
  };
};

export default useEnterMoreForm;
