import { useSelector } from "react-redux";
import { useGetGLIReportTableDataQuery, useGetOtherReportBasicDataQuery } from "../../../store/queries/Reports";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/Reports/reportsSlice";
import { useState } from "react";
import { subDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

const useGLIReport = () => {
  const navigate = useNavigate();
  const reportState = useSelector((state) => state.reports);

  // eslint-disable-next-line no-unused-vars
  const [date, setDate] = useState({
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
    key: "selection",
  });

  const formik = useFormik({
    initialValues: {
      branch: "",
      order_type: {
        "id": 1,
        "name": "Fresh"
    }
    },
  });

  const startDate = new Date(date.startDate).toISOString().split("T")[0];
  const endDate = new Date(date.endDate).toISOString().split("T")[0];
  const initialFormattedDate = [startDate, endDate];
  const [formattedDate, setFormattedDate] = useState(initialFormattedDate);
  const {
    data: GLIReportData = {},
    isFetching,
    isLoading,
  } = useGetGLIReportTableDataQuery({
    per_page: reportState?.GLIReport?.currentPageSize,
    page: reportState?.GLIReport?.currentPage,
    branch_ids: reportState?.GLIReport?.currentBranchFilter,
    sort_order: reportState?.GLIReport?.sortOrder,
    search: reportState?.GLIReport?.search,
    date: formattedDate,
    sort_by: formik?.values?.order_type?.id,
  });

  const dispatch = useDispatch();

  const { data: basicData = {} } = useGetOtherReportBasicDataQuery({});
  

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const handleDateChange = (date) => {
    const startDateUTC = new Date(date.startDate);
    const endDateUTC = new Date(date.endDate);
    const timeZoneOffset = 5.5 * 60 * 60 * 1000;
    const startDateIST = new Date(startDateUTC.getTime() + timeZoneOffset);
    const endDateIST = new Date(endDateUTC.getTime() + timeZoneOffset);

    const formatDate = (date) => {
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    const startDateFormatted = formatDate(startDateIST);
    const endDateFormatted = formatDate(endDateIST);
    const dateArray = [startDateFormatted, endDateFormatted];
    setFormattedDate(dateArray);
  }; 

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.GLIReport.currentPageSize = page_size;
        state.GLIReport.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.GLIReport.currentPage = page;
      })
    );
  };

  const handleBranchFilter = (ids) => {
    dispatch(
      updateConfig((state) => {
        state.GLIReport.currentPage = 1;
        state.GLIReport.currentBranchFilter = ids;
      })
    );
  };

  const handleSort = (label) => {
    if (reportState.GLIReport.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.GLIReport.currentPage = 1;
          state.GLIReport.sortOrder =
            reportState.GLIReport.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.GLIReport.currentPage = 1;
          state.GLIReport.sortBy = label;
          state.GLIReport.sortOrder = "asc";
        })
      );
    }
  };

  const handleGLIClick = (item) => {
    navigate({
      pathname: "/report/gli/detail",
      search: `?id=${item?._id}&activeTab=${"Passed"}`,
    });
    sessionStorage.setItem("active-gli", `${item?._id}`);
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      gli_number: (field, data) => (
        <p className="pro-mb-0 pro-pnt" onClick={() => handleGLIClick(data)}>
          {data[field]}
        </p>
      ),
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.GLIReport.search = e.target.value;
        state.GLIReport.currentPage = 1;
      })
    );
  };

  return {
    isLoading,
    isFetching,
    GLIReportData,
    paginationOptions,
    date,
    reportState,
    handleDateChange,
    getRow,
    handleSort,
    handlePagination,
    handlePageSize,
    handleSearch,
    basicData,
    formik,
    handleBranchFilter
  };
};

export default useGLIReport;
