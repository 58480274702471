import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";
import { updateConfig } from "../../../../store/slices/Printing/printingSlice";
import { useGetPrintingRecheckTableDataQuery } from "../../../../store/queries/Printing";
import { Button } from "@wac-ui-dashboard/wac_component_library";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Style from "../printing.module.scss";

const usePrintingTable = () => {
  let paginationOptions = [
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
    { label: "80 Per page", value: 80 },
    { label: "100 Per page", value: 100 },
  ];
  const dispatch = useDispatch();
  const state = useSelector((state) => state.printing);
  const { tableFields, globalRemarkModal } = useSelector(
    (state) => state.global
  );

  const slug = localStorage?.getItem("slug");
  const {
    data: tableData,
    isFetching,
    isLoading,
  } = useGetPrintingRecheckTableDataQuery({
    receipt_id: state?.selectedId,
    search: state.search,
    page_size: state.currentRecheckPageSize,
    page: state.currentRecheckPage,
    process_slug: slug,
  });

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = tableData?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [tableData]);

  const handleRemarksClick = (data) => {
    dispatch(
      globalUpdateConfig((state) => {
        state.globalRemarkId = data?.id;
        state.globalRemarkModal = true;
      })
    );
  };

  const handleModalClose = () => {
    dispatch(globalUpdateConfig((state) => (state.globalRemarkModal = false)));
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      certificate_number : (feild, data) =>
        <p className="pro-mb-0 pro-d-flex pro-justify-between pro-items-center pro-gap-4">
          <span>{data[feild]}</span>
          <OverlayTrigger
            placement="auto"
            onToggle={(toggle=>{
              // const scrollWidth = window.innerWidth - document.documentElement.clientWidth;
              const body = document.body;
              body.style.overflow = toggle ? "hidden" : null;
              // body.style.paddingRight = toggle ? `${scrollWidth}px` : null;
            })}
            overlay={
              <Tooltip id="button-tooltip" className={Style.tooltip}>
                <table className="">
                  <tbody>
                    <tr>
                      <td>Summary No.</td>
                      <td> : </td>
                      <td>{data[feild]}</td>
                    </tr>
                    {
                      data["graded_values"]?.qc_data?.map((item, index) => !!item.name && (
                        
                        <tr key={index}>
                          <td>{item?.name}</td>
                          <td> : </td>
                          <td>{item?.value || "-"}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </Tooltip>
            }
          >
            <span
                className={Style.info_btn}
              >
                <span className="material-symbols-outlined pro-fw-medium"> info </span>
              </span>
          </OverlayTrigger>
        </p>,
      status: (_, data) =>
        data?.status === "Rejected" ? (
          <span className={"pro-badge badge-danger-outline pro-d-inline-block"}>
            {"Rejected"}
          </span>
        ) : data?.status === "Marked" ? (
          <span className={"outline-primary pro-badge pro-d-inline-block"}>
            {"Marked"}
          </span>
        ) : data?.status === "Completed" ? (
          <span className={"outline-success pro-badge pro-d-inline-block"}>
            {"Completed"}
          </span>
        ) : data?.status === "Pending" ? (
          <span className={"outline-warning pro-badge pro-d-inline-block"}>
            {"Pending"}
          </span>
        ) : (
          <span className={"outline pro-badge pro-d-inline-block"}>
            {data?.status}
          </span>
        ),
        work_status: (_, data) =>
          data?.work_status === "Rejected" ? (
            <span className={"pro-badge badge-danger-outline pro-d-inline-block"}>
              {"Rejected"}
            </span>
          ) : data?.work_status === "Marked" ? (
            <span className={"outline-primary pro-badge pro-d-inline-block"}>
              {"Marked"}
            </span>
          ) : data?.work_status === "Completed" ? (
            <span className={"outline-success pro-badge pro-d-inline-block"}>
              {"Completed"}
            </span>
          ) : data?.work_status === "Pending" ? (
            <span className={"outline-warning pro-badge pro-d-inline-block"}>
              {"Pending"}
            </span>
          ) : (
            <span className={"outline pro-badge pro-d-inline-block"}>
              {data?.work_status}
            </span>
          ),
      action: (_, data) => (
        <p className="pro-mb-0">
          {
            <Button
              className={"pro-btn-primary"}
              onClick={() => handleRemarksClick(data)}
            >
              Remarks
            </Button>
          }
        </p>
      ),
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentRecheckPageSize = page_size;
        state.currentRecheckPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentRecheckPage = page;
      })
    );
  };

  const handlePrintClick = (items) => {
    dispatch(
      updateConfig((state) => {
        state.printModal = true;
        state.selectedItems = items;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(updateConfig((state) => (state.search = e?.target?.value)));
  };

  return {
    isFetching,
    isLoading,
    tableData,
    tableFields,
    paginationOptions,
    globalRemarkModal,
    state,
    getRow,
    handleModalClose,
    handlePageSize,
    handlePagination,
    handlePrintClick,
    handleSearch,
    
  };
};

export default usePrintingTable;
