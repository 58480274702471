import { useSelector } from "react-redux";
import {
  useGetPackingListDataQuery,
  useUpdatePackingTableHeadDataMutation,
} from "../../../store/queries/Master";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Master/Packing/packingSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getPackingData, deleteItem, approvePackingRequest } from "./api";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const usePacking = () => {
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];

  const dispatch = useDispatch();

  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const queryState = useSelector((state) => state.packing);
  const {
    data: queryList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetPackingListDataQuery({
    page_size: queryState.currentPageSize,
    page: queryState.currentPage,
    sort_by: queryState.sortBy,
    sort_order: queryState.sortOrder,
    start: queryState.startDate,
    end: queryState.endDate,
    search: queryState.search,
  });
  const [updatePackingFields] = useUpdatePackingTableHeadDataMutation();

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = queryList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [queryList]);

  useEffect(() => {
    if (queryState?.selectedItem && queryState?.isEdit) {
      fillPackingData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryState?.isEdit, queryState?.showAddPackingModal]);
  useEffect(() => {
    if (queryState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [queryState.clearSelection]);

  const handleModalClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddPackingModal = !state.showAddPackingModal;
        state.isEdit = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      status: (field, data) =>
        data?.status === "Pending" && queryList?.data?.approve_permission ? (
          <Button
            className={"pro-btn-primary sm"}
            type="button"
            onClick={() => handleApprovePermission(data?._id)}
          >
            {"Approve"}
          </Button>
        ) : data?.status === "Deleted" ? (
          <span className={"pro-badge badge-danger-outline"}>{"Deleted"}</span>
        ) : data?.status === "Active" ? (
          <span className={"pro-badge badge-success-outline"}>{"Active"}</span>
        ) : (
          data?.status
        ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleApprovePermission = (approveId) => {
    let params = {
      packing_type_id: approveId,
    };
    approvePackingRequest(params).then((response) => {
      if (response?.data?.status_code === 200) {
        refetch();
        toast.success("Approved successfully");
      } else {
        toast.error("Something went wrong");
      }
    });
  };
  const handleDelete = () => {
    let data = {
      packing_type_id: queryState?.selectedItem,
    };
    deleteItem(data).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Item deleted successfully");
        handleDeleteModal();
        refetch();
      } else {
        toast.error("Something went wrong");
        handleDeleteModal();
      }
    });
  };

  const fillPackingData = () => {
    getPackingData(queryState?.selectedItem).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => (state.itemData = response?.data?.data))
        );
      } else {
        toast.error("Something went wrong to fetch item data");
      }
    });
  };

  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedItem = e?.[0];
      })
    );
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showAddPackingModal = true;
        state.selectedItem = e?.[0];
        state.isEdit = true;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (queryState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = queryState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  return {
    queryState,
    showEditModal,
    tableFields,
    queryList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    handleDelete,
    handleDeleteModal,
    handleEditAction,
    handleSearch,
    updatePackingFields,
    handleClearClick,
    handlePageSize,
    refetch,
  };
};

export default usePacking;
