import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("receipt_id")}${getParams("end")}filter=${
            params?.filter || "all"
          }&${getParams("sort_by")}sort_order=${
            params?.sort_order || "asc"
          }&per_page=${params?.page_size || "10"}&${getParams("search")}page=${
            params?.page || 1
          }&${getParams("process_slug")}&${getParams(
            "item_details_id"
          )}&${getParams("recheck_item_details_id")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}${getParams("start")}${getParams("end")}${getParams(
            "sort_by"
          )}${getParams("search")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const QC = createApi({
  reducerPath: "qcApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["qc", "formTable", "recheckFormTable"],
  endpoints: (builder) => ({
    // form data
    getGliTableData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `employee-dashboard/order/list-assigned-certificate`,
      }),
      providesTags: ["qc"],
    }),
    getGliRecheckTableData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `employee-dashboard/recheck/list-assigned-certificate`,
      }),
      providesTags: ["qc"],
    }),
    getBasicData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/qc/basic-data`,
      }),
    }),
    getFormTableData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/qc/multi-color-clarity-list`,
      }),
      providesTags: ["formTable"],
    }),
    getFormTableDataRecheck: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/qc/recheck/multi-color-clarity-list`,
      }),
      providesTags: ["recheckFormTable"],
    }),
    getDescriptionData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/qc/description-suggetion`,
      }),
    }),
  }),
});

export const {
  useGetDescriptionDataQuery,
  useGetGliTableDataQuery,
  useGetFormTableDataRecheckQuery,
  useGetGliRecheckTableDataQuery,
  useGetBasicDataQuery,
  useGetFormTableDataQuery,
} = QC;
