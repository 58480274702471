import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useBasic from "./useBasic";

const Basic = ({ formik, getFieldError, basicData }) => {
  const { handleCloseModal, handleNext } = useBasic();
  
  return (
    <div className="row">
      <Input
        label={"Name"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${getFieldError("name") && " error"}`}
        {...formik.getFieldProps("name")}
        error={getFieldError("name")}
        errorMessage={getFieldError("name")}
      />
      <Input
        label={"Location"}
        type="text"
        id="location"
        name="location"
        className={`pro-input lg ${getFieldError("location") && " error"}`}
        {...formik.getFieldProps("location")}
        error={getFieldError("location")}
        errorMessage={getFieldError("location")}
      />
      <Input
        label={"LOT Prefix"}
        type="text"
        id="prefix"
        name="prefix"
        className={`pro-input lg ${getFieldError("prefix") && " error"}`}
        {...formik.getFieldProps("prefix")}
        error={getFieldError("prefix")}
        errorMessage={getFieldError("prefix")}
      />
      <Input
        label={"Certificate Prefix"}
        type="text"
        id="certification_prefix"
        name="certification_prefix"
        className={`pro-input lg ${
          getFieldError("certification_prefix") && " error"
        }`}
        {...formik.getFieldProps("certification_prefix")}
        error={getFieldError("certification_prefix")}
        errorMessage={getFieldError("certification_prefix")}
      />
      <Input
        label={"Short Name"}
        type="text"
        id="short_name"
        name="short_name"
        className={`pro-input lg ${getFieldError("short_name") && " error"}`}
        {...formik.getFieldProps("short_name")}
        error={getFieldError("short_name")}
        errorMessage={getFieldError("short_name")}
      />
      <div>
        <Input
          label={"Address Line 1"}
          type="text"
          id="address_1"
          name="address_1"
          className={`pro-input lg ${getFieldError("address_1") && " error"}`}
          {...formik.getFieldProps("address_1")}
          error={getFieldError("address_1")}
          errorMessage={getFieldError("address_1")}
        />
        <Input
          label={"Address Line 2"}
          type="text"
          id="address_2"
          name="address_2"
          className={`pro-input lg ${getFieldError("address_2") && " error"}`}
          {...formik.getFieldProps("address_2")}
          error={getFieldError("address_2")}
          errorMessage={getFieldError("address_2")}
        />

        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Country
          </label>
          <Select
            id="country"
            name="country"
            placeholder={"Select"}
            className={`pro-input lg  ${getFieldError("country") && " error"}`}
            classNamePrefix="pro-input"
            options={basicData?.country_list ?? []}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
            value={formik?.values?.country}
            onBlur={formik.handleBlur("country")}
            onChange={(value) =>
              formik?.setFieldValue("country", value || null)
            }
            menuPlacement="auto"
          />
          {getFieldError("country") && (
            <span className="error-text">{getFieldError("country")}</span>
          )}
        </div>
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            State
          </label>
          <Select
            id="state"
            name="state"
            placeholder={"Select"}
            className={`pro-input lg  ${getFieldError("state") && " error"}`}
            classNamePrefix="pro-input"
            options={basicData?.state_list?.filter(
              (item) => item?.country_id === formik?.values?.country?.id
            )}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
            value={formik?.values?.state}
            onBlur={formik.handleBlur("state")}
            onChange={(value) => formik?.setFieldValue("state", value || null)}
            menuPlacement="auto"
          />
          {getFieldError("state") && (
            <span className="error-text">{getFieldError("state")}</span>
          )}
        </div>
        <Input
          label={"City"}
          type="text"
          id="city"
          name="city"
          className={`pro-input lg ${getFieldError("city") && " error"}`}
          {...formik.getFieldProps("city")}
          error={getFieldError("city")}
          errorMessage={getFieldError("city")}
        />
        <Input
          label={"Postal Code"}
          type="text"
          id="postal_code"
          name="postal_code"
          className={`pro-input lg ${getFieldError("postal_code") && " error"}`}
          {...formik.getFieldProps("postal_code")}
          error={getFieldError("postal_code")}
          errorMessage={getFieldError("postal_code")}
        />
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Status
        </label>
        <Select
          id="status"
          name="status"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("status") && " error"}`}
          classNamePrefix="pro-input"
          options={basicData?.status_list ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          value={formik?.values?.status}
          onBlur={formik.handleBlur("status")}
          onChange={(value) => formik?.setFieldValue("status", value || null)}
          menuPlacement="auto"
        />
        {getFieldError("status") && (
          <span className="error-text">{getFieldError("status")}</span>
        )}
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={handleNext}
          type="button"
        >
          {"Next"}
        </Button>
      </div>
    </div>
  );
};

export default Basic;
