import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useAddStatus from "./useAddStatus";

export const AddStatus = ({
  handleConfirmModal,
  handleConfirm,
  error,
  itemId,
  setRemarks,
  setPackingTypeId,
  setSubmissionStatus,
}) => {
  const {
    formik,
    statusData,
    statusObj,
    packingTypeData,
    packingObj,
    getFieldError,
  } = useAddStatus({
    error,
    itemId,
    setPackingTypeId,
    setSubmissionStatus,
    setRemarks,
  });

  return (
    <>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Add Status
        </label>
        <Select
          id="status"
          name="status"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("status") && " error"}`}
          classNamePrefix="pro-input"
          options={statusData ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          value={statusObj}
          onBlur={formik?.handleBlur("status")}
          isMulti={false}
          onChange={(value) => {
            formik?.setFieldValue("status", value?.id || null);
          }}
          menuPlacement="auto"
        />
        {getFieldError("status") && (
          <span className="error-text">{getFieldError("status")}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Packing Type
        </label>
        <Select
          id="packing_type"
          name="packing_type"
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("packing_type") && " error"
          }`}
          classNamePrefix="pro-input"
          options={packingTypeData?.list ?? []}
          getOptionLabel={(option) => option?.packing_type}
          getOptionValue={(option) => option?._id}
          value={packingObj}
          onBlur={formik?.handleBlur("packing_type")}
          isMulti={false}
          onChange={(value) => {
            formik?.setFieldValue("packing_type", value?._id || null);
          }}
          menuPlacement="auto"
        />
        {getFieldError("packing_type") && (
          <span className="error-text">{getFieldError("packing_type")}</span>
        )}
      </div>
      {/* cr as per client */}
      {/* {formik?.values?.status !== 2 && ( */}
      <div className="input-wrap">
        <Input
          label={"Remarks"}
          type="text"
          id="remarks"
          name="remarks"
          className={`pro-input lg ${getFieldError("remarks") && " error"}`}
          {...formik.getFieldProps("remarks")}
          error={getFieldError("remarks")}
          errorMessage={getFieldError("remarks")}
        />
      </div>
      {/* )} */}
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleConfirmModal}
        >
          Cancel
        </Button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={handleConfirm}
        >
          {"Confirm"}
        </Button>
      </div>
    </>
  );
};

export default AddStatus;
