import { Pagination, SearchFilters, Table } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import usePrintingTable from "./usePrintingTable";
import ShowForm from "../ShowForm";

const PrintingTable = ({ gliNumber }) => {
  const {
    state,
    tableData,
    tableFields,
    paginationOptions,
    isFetching,
    currentPageSize,
    getRow,
    handlePagination,
    handlePageSize,
    handlePrintClick,
    handleEditClick,
    handleSearch,
    isLoading
  } = usePrintingTable();

  return (
    <div>
      <h6 className="pro-ttl h6">{gliNumber}7888</h6>
      <SearchFilters
        onSearchInput={handleSearch}
        SearchIcon={<span className="material-symbols-outlined"> search </span>}
        showClearFilters={false}
        searchInputProps={{ value: state?.search }}
        showActions={false}
        loading={isLoading}
        
      />
      {state?.showForm ? (
        <ShowForm />
      ) : (
        <Table
          multiSelect={true}
          data={tableData?.data?.data ?? []}
          uniqueID={"_id"}
          fields={tableFields}
          SortIcon={<FaSort />}
          assignIcon={<span className="material-symbols-outlined">print</span>}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          getRow={getRow}
          loading={isFetching}
          perpage={currentPageSize}
          assignable={true}
          deletable={false}
          showCheckBox={true}
          editable={true}
          handleEdit={handleEditClick}
          handleAssign={handlePrintClick}
          assignText={`Print`}
          clear={state.clear}
        />
      )}
      {tableData?.data?.data && !state?.showForm && (
        <Pagination
        currentPage={state?.currentReissuePage}
        totalPageCount={Math.ceil(
          tableData?.data?.total_count / tableData?.data?.per_page
        )}
        onPageChange={handlePagination}
        options={paginationOptions}
        onActionChange={handlePageSize}
        center
      />
      )}
    </div>
  );
};

export default PrintingTable;
