import { Table } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useRemaks from "./useRemaks";
import { FaSort } from "react-icons/fa";

const RemarksTableReissue = () => {
  const { globalRemarkTableData, getRow } = useRemaks();
  return (
    <div>
      <Table
        multiSelect={false}
        data={globalRemarkTableData?.data?.data || []}
        uniqueID={"_id"}
        fields={globalRemarkTableData?.data?.fields || []}
        showCheckBox={false}
        SortIcon={<FaSort />}
        getRow={getRow}
        perpage={10}
        assignable={false}
        deletable={false}
        editable={false}
      />
    </div>
  );
};

export default RemarksTableReissue;
