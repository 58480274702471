import { getAxiosInstance } from "../../../api";

export const assignWork = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      `/employee-dashboard/order/work-assign`,
      body
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const assignedCertificate = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `/employee-dashboard/order/list-assigned-certificate?receipt_id=${body}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};
export const assignAll = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`/employee-dashboard/order/bulk-assign`, params);
    return res;
  } catch (error) {
    return error.response.data;
  }
};
