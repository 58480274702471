import Style from "../../view.module.scss";
import { Table } from "@wac-ui-dashboard/wac_component_library";

const Items = ({ state }) => {
  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  return (
    <div className={`${Style.multicolumn_table} ${Style.pro_table_wrapper}`}>
      <h6 className="pro-ttl h6 pro-mb-2">{state?.item_type?.item_type}</h6>
      <Table
        multiSelect={false}
        data={state?.item?.data || []}
        uniqueID={"id"}
        fields={state?.item?.fields}
        showCheckBox={false}
        getRow={getRow}
        perpage={10}
        assignable={false}
      />
    </div>
  );
};

export default Items;
