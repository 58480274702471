import { useSelector } from "react-redux";
import { useGetAvailableReportsDataQuery } from "../../../store/queries/MainReport";
import { getPrintData } from "../api";
import moment from "moment";
import { toast } from "react-toastify";

const useReportPrint = () => {
  const { selectedItem } = useSelector((state) => state.mainReport);
  const { data: availableReports, isFetching } =
    useGetAvailableReportsDataQuery({
      receipt_id: selectedItem,
    });

  const handlePrintClick = (index) => {
    let count = index + 1;
    getPrintData(selectedItem, count).then((response) => {
      if (response?.status === 200) {
        let dataType = response?.data?.type;
        let binaryData = [];
        binaryData.push(response?.data);
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute(
          "download",
          `${moment(Date.now()).format("MM_DD_YYYY_")}${`excel`}.xlsx`
        );
        document.body.appendChild(downloadLink);
        downloadLink.click();
      } else {
        toast.error("Something went wrong");
      }
    });
  };
  return {
    availableReports,
    handlePrintClick,
    isFetching,
  };
};

export default useReportPrint;
