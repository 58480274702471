import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 20,
  sortBy: "",
  sortOrder: "",
  search: "",
  clearSelection: false,
  showAddItemGradingModal: false,
  showDeleteModal: false,
  selectedItem: "",
  isEdit: false,
  itemData: [],
};

const itemGradingSlice = createSlice({
  name: "itemGrading",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = itemGradingSlice.actions;

export default itemGradingSlice.reducer;
