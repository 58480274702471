import useFontCases from "../../../../../utils/hooks/useFontCases";

export const useCardPrint = () => {
  
  const { getFontCase } = useFontCases();

  const renderText = (text, type) => {
      const fontCase = getFontCase(parseInt(type));
      switch (fontCase) {
          case "Uppercase": return <span style={{ textTransform: "uppercase" }}>{text}</span>;
          case "Lowercase": return <span style={{ textTransform: "lowercase" }}>{text}</span>;
          case "Title_Case": return <span style={{ textTransform: "capitalize" }}>{text}</span>;
          case "Sentence_Case":
              const sentenceCase = text.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
              return <span>{sentenceCase}</span>;
          case "Camel_Case":
              const camelCase = text.toLowerCase().replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) => index === 0 ? letter.toLowerCase() : letter.toUpperCase());
              return <span>{camelCase}</span>;
          default: return <span>{text}</span>;
      }
  };

  return{
    renderText,
  }
}
