import { getAxiosInstance } from "../../../api";

export const getReportData = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/report/edit?report_id=${id}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
export const getBasicData = async () => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`report/basic-data`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
export const getPostReportData = async (url, formValues) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(url, formValues);
    return res;
  } catch (error) {
    return error?.response?.data;
  }
};
export const updateReportData = async (url, formValues) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(url, formValues);
    return res;
  } catch (error) {
    return error?.response?.data;
  }
};
export const approveReportRequest = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/report/approve`, params);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
