import {
  HeadingGroup,
  ModalLayout,
  NavGroup,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useRecheck from "./useRecheck";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import Filter from "./Filter";
import Style from "./recheck.module.scss";
import RecheckDetails from "./Details/Recheck";
import { Link } from "react-router-dom";
import StockReceipt from "./StockReceipt";
import RecheckOfficeSlip from "./RecheckOfficeSlip/RecheckOfficeSlip";
// import ReissueDetails from "./Details/Reissue";

const Recheck = () => {
  const {
    tableFields,
    recheckList,
    isFetching,
    recheckState,
    filterShow,
    showEditModal,
    tabNavs,
    paginationOptions,
    StockReceiptRef,
    recheckOfficeSlipRef,
    getRow,
    refetch,
    handleSort,
    handleClose,
    handleSearch,
    setFilterShow,
    handlePageSize,
    handlePagination,
    handleClearClick,
    handleReissueModal,
    handleRecheckModal,
    handleEditColumnsClick,
    updateRecheckTableData,
    handleOfficeSlipModal,
  } = useRecheck();

  return (
    <>
      <HeadingGroup title={"Recheck"} className={`pro-mb-4`} />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <div className={`${Style.main_container}`}>
          <NavGroup navigations={tabNavs} type={"type-2"} Link={Link} />
        </div>
        <div className="row gx-2">
          <div className="col-auto">
            <SearchFilters
              onSearchInput={handleSearch}
              showActions={true}
              showFilters={true}
              handleActionClick={handleEditColumnsClick}
              // loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              handleClear={handleClearClick}
              searchInputProps={{ value: recheckState?.search }}
              showDateRange={false}
            />
          </div>
          <div className="col-auto">
            <button
              className={`pro-btn pro-btn-outline pro-h-100  pro-items-center pro-px-5 ${Style.filter_btn}`}
              onClick={() => setFilterShow(true)}
            >
              <span className="material-symbols-outlined">tune</span>
              <span>Filter</span>
            </button>
          </div>
        </div>
        <Table
          multiSelect={recheckState?.activeTab === "certificate"}
          data={recheckList?.data?.data || []}
          uniqueID={"_id"}
          fields={tableFields}
          SortIcon={<FaSort />}
          clear={recheckState.clearSelection}
          editIcon={
            recheckState?.activeTab === "certificate" ? (
              <span className="material-symbols-outlined">change_circle</span>
            ) : (
              <span className="material-symbols-outlined">print</span>
            )
          }
          assignIcon={
            recheckState?.activeTab === "certificate" ? (
              <span className="material-symbols-outlined">arrow_split</span>
            ) : (
              <span className="material-symbols-outlined">print</span>
            )
          }
          deleteIcon={<span className="material-symbols-outlined">print</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={recheckList?.currentPageSize}
          assignable={true}
          editable={true}
          editText={
            recheckState?.activeTab === "certificate" ? "Reissue" : "Report"
          }
          assignText={
            recheckState?.activeTab === "certificate"
              ? "Recheck"
              : "Stock Receipt Slip"
          }
          deleteText={`Office Slip`}
          handleEdit={handleReissueModal}
          handleAssign={handleRecheckModal}
          handleDelete={handleOfficeSlipModal}
          deletable={recheckState?.activeTab === "certificate" ? false : true}
          showCheckBox={true}
        />
        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={recheckList?.data?.fields}
              moduleId={recheckList?.data?.module_id}
              updateData={updateRecheckTableData}
            />
          </div>
        </ModalLayout>
        {recheckList?.data?.data?.length > 0 && (
          <Pagination
            currentPage={recheckState?.currentPage}
            totalPageCount={Math.ceil(
              recheckList?.data?.total_count / recheckState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <OffCanvasLayout
          show={recheckState?.showAddRecheckModal}
          handleClose={handleClose}
          title="Recheck"
        >
          <RecheckDetails refetch={refetch} />
        </OffCanvasLayout>
        <OffCanvasLayout
          show={recheckState?.showAddReissueModal}
          handleClose={handleClose}
          title="Reissue"
        >
          <RecheckDetails refetch={refetch} />
        </OffCanvasLayout>
        <OffCanvasLayout
          show={filterShow}
          title={"Filter"}
          handleClose={() => {
            setFilterShow(false);
          }}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <Filter setShowform={setFilterShow} />
        </OffCanvasLayout>
      </div>
      <div className={Style.print}>
        <StockReceipt stockPrintRef={StockReceiptRef} />
        <RecheckOfficeSlip recheckOfficeSlipRef={recheckOfficeSlipRef} />
      </div>
    </>
  );
};

export default Recheck;
