import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/Reports/reportsSlice";
import { useSelector } from "react-redux";
import { useGetOtherReportBasicDataQuery } from "../../../store/queries/Reports";
import { useLocation } from "react-router-dom";

const useOtherReport = () => {
  const reportState = useSelector((state) => state.reports);
  const dispatch = useDispatch();
  const location = useLocation();
  const activeTab = location?.search?.slice(11);

  useEffect(() => {
    dispatch(
      updateConfig((state) => (state.otherReport.activeTab = activeTab))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const { data: basicData = {} } = useGetOtherReportBasicDataQuery({});

  const navigation = [
    {
      label: "Branch",
      title: "Branch",
      link: `/report/other?activeTab=Branch`,
      active: activeTab.includes("Branch"),
    },
    {
      label: "Shop/Supplier",
      title: "Shop/Supplier",
      link: `/report/other?activeTab=Shop/Supplier`,
      active: activeTab.includes("Shop/Supplier"),
    },
    {
      label: "Employee",
      title: "Employee",
      link: `/report/other?activeTab=Employee`,
      active: activeTab.includes("Employee"),
    },
    {
      label: "Department",
      title: "Department",
      link: `/report/other?activeTab=Department`,
      active: activeTab.includes("Department"),
    },
  ];

  return {
    activeTab,
    reportState,
    tabNavs: navigation,
    basicData: basicData?.data,
  };
};

export default useOtherReport;
