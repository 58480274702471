import { getAxiosInstance } from "../../../api";

export const PrintCertficate = async (body) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`/printing-report/create`, body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const getViewData = async (id) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.get(`/verification/stock-recept-details?id=${id}`);
    return res;
  } catch (error) {
    return error.response.data;
  }
};
export const passAll = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(
      `/employee-dashboard/order/bulk-status-complete`,
      params
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};
