import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  tableFields: {},
  dashboard_permissions: {},
  dashboard_permissions_status: "idle",
  branch_list_status: "idle",
  branchList: "",
  showEditModal: false,
  showLoginModal: false,
  formData: [],
  currentBranch: [],
  showSettingsModal: false,
  logoutModal: false,
  userDetails: "",
  passAllModal: false,
  globalRemarkId: "",
  globalRemarkModal: false,
  globalRemarkTableData: "",
};

export const getMenuPermissions = createAsyncThunk(
  "global/getMenuPermissions",
  async (body, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/user/menu-list`, body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBranchList = createAsyncThunk(
  "global/getBranchList",
  async (body, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/user/branch-list`, body);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMenuPermissions.pending, (state) => {
      state.dashboard_permissions_status = "pending";
    });
    builder.addCase(getMenuPermissions.fulfilled, (state, action) => {
      state.dashboard_permissions = action.payload.data?.data;
      state.dashboard_permissions_status = "fulfilled";
      localStorage?.setItem(
        "dashboard_permissions",
        JSON.stringify(action.payload.data?.data)
      );
    });
    builder.addCase(getMenuPermissions.rejected, (state) => {
      state.dashboard_permissions_status = "rejected";
    });
    builder.addCase(getBranchList.pending, (state) => {
      state.branch_list_status = "pending";
    });
    builder.addCase(getBranchList.fulfilled, (state, action) => {
      state.branchList = action.payload.data?.data;
      state.branch_list_status = "fulfilled";
      localStorage?.setItem(
        "branch_list",
        JSON.stringify(action.payload.data?.data)
      );
      state.currentBranch = action.payload.data?.data.branch_list.filter(
        (value) => value?._id === action.payload.data?.data.branch_id
      );
    });
    builder.addCase(getBranchList.rejected, (state) => {
      state.branch_list_status = "rejected";
    });
  },
});

export const { updateConfig } = globalSlice.actions;

export default globalSlice.reducer;
