import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../store/slices/Master/Branches/branchesSlice";
import { useSelector } from "react-redux";

const useBasic = (formik) => {
  // const [lastUploadedLogo, setLastUploadedLogo] = useState();
  const dispatch = useDispatch();
  const { isEdit, logoPreview } = useSelector((state) => state.branches);

  const handleLogoChange = (event) => {
    const selectedLogo = event?.target?.files[0];
    if (selectedLogo) {
      // setLastUploadedLogo(selectedLogo);
      formik.setFieldValue("logo", selectedLogo);
      const file = selectedLogo;
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state.logoPreview = reader.result;
          })
        );
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };
  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddBranchModal = false;
      })
    );
  };

  const handleNext = () => {
    dispatch(
      updateConfig((state) => {
        state.activeTab = "Address";
      })
    );
  };

  return {
    isEdit,
    logoPreview,
    handleLogoChange,
    handleCloseModal,
    handleNext,
  };
};

export default useBasic;
