import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { login } from "../api";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {
  updateConfig as globalUpdateConfig,
  updateConfig,
} from "../../../store/slices/Global";
import { useDispatch } from "react-redux";

const useLogin = () => {
  const [showPassword, setShowPassword] = useState(true);
  const [loginStatus, setLoginStatus] = useState("idle");
  const [loginInfo, setLoginInfo] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const regexp = /^\S*$/;
  useEffect(() => {
    if (loginStatus === "success") {
      if (localStorage?.getItem("user_type") === "Super_Admin") {
        navigate("/overview");
      } else {
        navigate("/home");
      }
    } else if (loginStatus === "failed") {
      let errorFields = Object.keys(loginInfo);
      errorFields.forEach((field) => {
        formik.setFieldError(field, loginInfo[field]);
      });
    }
    // eslint-disable-next-line
  }, [loginStatus]);

  const formik = useFormik({
    initialValues: {
      user_name: "",
      password: "",
    },
    validate: (values) => {
      let errors = {};
      if (!values.user_name || !regexp.test(values?.user_name)) {
        errors.user_name = "*Username Required";
      }
      if (!values.password || !regexp.test(values?.password)) {
        errors.password = "*Password Required";
      }
      return errors;
    },
    onSubmit: (values) => {
      setLoginStatus("pending");
      login(values).then((response) => {
        if (response?.data?.status_code === 200) {
          // toast.success("Authentication Success");
          setLoginInfo(response?.data?.data);
          localStorage.setItem(
            "USER_ACCESS_TOKEN",
            response?.data?.data?.token
          );
          localStorage.setItem(
            "user_type",
            response?.data?.data?.user?.user_type
          );
          localStorage?.setItem(
            "user_details",
            JSON?.stringify(response?.data?.data?.user)
          );
          dispatch(
            updateConfig(
              (state) => (state.userDetails = response?.data?.data?.user)
            )
          );
          if (
            response?.data?.data?.user_departments?.select_department === true
          ) {
            dispatch(
              globalUpdateConfig((state) => {
                state.showLoginModal = true;
                state.formData = response?.data?.data?.user_departments;
              })
            );
          } else {
            dispatch(
              globalUpdateConfig((state) => (state.showLoginModal = false))
            );
          }
          setLoginStatus("success");
        } else if (response?.status_code === 403) {
          setLoginStatus("failed");
          setLoginInfo({ password: response?.message });
        } else if (response?.status_code === 422) {
          setLoginStatus("failed");
          setLoginInfo({ user_name: response?.message });
        } else {
          setLoginStatus("An error occurred");
          // toast.error("Something went wrong");
        }
      });
    },
  });

  const handleForgotPassword = () => {
    navigate("/login/forgot-password");
  };

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  return {
    formik,
    showPassword,
    loginStatus,
    handleForgotPassword,
    handleShowPassword,
  };
};

export default useLogin;
