import { getAxiosInstance } from "../../../api";

export const getEmployeeData = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/employee/edit?employee_id=${id}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getBasicData = async () => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/employee/basic-data`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getPostEmployeeData = async (formValues) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/employee/create`, formValues);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

// export const getPostEmployeeData = async (url, formValues) => {
//   try {
//     const api = await getAxiosInstance();
//     const res = await api.post(url, formValues);
//     return res;
//   } catch (error) {
//     return {
//       data: {
//         status: res,
//         err: JSON.stringify(error),
//       },
//     };
//   }
// };

export const updateEmployeeData = async (formValues) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.put(`employee/update`, formValues);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
// export const updateEmployeeData = async (url, formValues) => {
//   try {
//     const api = await getAxiosInstance();
//     const res = await api.put(url, formValues);
//     return res;
//   } catch (error) {
//     return {
//       data: {
//         status: "Not Found",
//         err: JSON.stringify(error),
//       },
//     };
//   }
// };
export const deleteEmployee = async (url, employeeId) => {
  const params = {
    employee_id: employeeId,
  };
  try {
    const api = await getAxiosInstance();
    const res = await api.delete(url, { data: params });
    return res;
  } catch (error) {
    return {
      data: {
        status: "Not Found",
        err: JSON.stringify(error),
      },
    };
  }
};
export const approveEmployeeRequest = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/employee/approve`, params);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
