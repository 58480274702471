import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import OrderColumn from "../../Global/OrderColumn";
import useReports from "./useReports";
import { FaSort } from "react-icons/fa";
import ReportPrint from "../ReportPrint";
import ExcelUpload from "./ExcelUpload";

const Reports = () => {
  const {
    isFetching,
    isLoading,
    paginationOptions,
    reportList,
    reportState,
    showEditModal,
    tableFields,
    handleSearch,
    handleEditColumnsClick,
    getRow,
    handleClearClick,
    handleViewReport,
    handlePageSize,
    handlePagination,
    handleSort,
    refetch,
    handlePrintClick,
    updateReportFields,
    handleClosePrintModal,
    handleExcelUpload,
  } = useReports();
  return (
    <div>
      <HeadingGroup title={"All Report"} className={`pro-mb-4`} />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          showActions={true}
          handleActionClick={handleEditColumnsClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: reportState?.search }}
        />
        <Table
          multiSelect={false}
          data={reportList?.data?.data || []}
          uniqueID={"_id"}
          fields={tableFields}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">print</span>}
          deleteIcon={<span className="material-symbols-outlined">upload</span>}
          assignIcon={
            <span className="material-symbols-outlined">summarize</span>
          }
          handleSort={handleSort}
          clear={reportState?.clearSelection}
          getRow={getRow}
          loading={isFetching}
          perpage={reportState?.currentPageSize}
          assignable={true}
          handleDelete={handleExcelUpload}
          deletable={true}
          editable={reportList?.data?.create_permission}
          editText={"Print"}
          showCheckBox={true}
          deleteText={"Excel Upload"}
          handleEdit={handlePrintClick}
          assignText={"View Report"}
          handleAssign={handleViewReport}
        />
        {reportList?.data?.data?.length > 0 && (
          <Pagination
            currentPage={reportState?.currentPage}
            totalPageCount={Math.ceil(
              reportList?.data?.total_count / reportState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={reportList?.data?.fields}
              moduleId={reportList?.data?.module_id}
              updateData={updateReportFields}
            />
          </div>
        </ModalLayout>
        <ModalLayout
          show={reportState?.printModal}
          handleClose={handleClosePrintModal}
          title={"Reports"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <ReportPrint />
        </ModalLayout>
        <ModalLayout
          show={reportState.showExcelUploadModal}
          handleClose={handleExcelUpload}
          title={`Excel Upload`}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
        >
          <div className="pro-m-5">
            <ExcelUpload />
          </div>
        </ModalLayout>
      </div>
    </div>
  );
};

export default Reports;
