import { createSlice } from "@reduxjs/toolkit";
import { subDays } from "date-fns";
const startDate = new Date(subDays(new Date(), 30)).toISOString().split("T")[0];
const endDate = new Date(new Date()).toISOString().split("T")[0];

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 20,
  sortBy: "",
  sortOrder: "",
  search: "",
  showAddOrderModal: false,
  clearSelection: false,
  isEdit: false,
  selectedOrder: "",
  orderData: [],
  showDeleteModal: false,
  engravingImagePreview: "",
  certificateImagePreview: "",
  printData: "",
  addShopModal: false,
  showFilter: false,
  filter: {
    shop: "",
    status: "",
    date: [startDate, endDate],
    item_type: "",
  },
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = orderSlice.actions;

export default orderSlice.reducer;
