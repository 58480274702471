import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 20,
  startDate: null,
  endDate: null,
  sortBy: "",
  sortOrder: "",
  receipt_id: "",
  search: "",
  recheckSearch: "",
  overViewData: [],
  overViewRecheckData: [],
  overViewStatus: "idle",
  overViewRecheckStatus: "idle",
  showAddOrderModal: false,
  filterModal: false,
  selectedOrder: "",
  orderData: [],
  showDeleteModal: false,
  engravingImagePreview: "",
  certificateImagePreview: "",
  date: [],
  recheckDate: [],
  filter: {
    itemType: [],
    shop: [],
    supplier: [],
    priority: [],
  },
  total: {
    overView: "",
    overViewRecheck: "",
  },
  viewModal: false,
  viewSubModal: false,
  empDetails: [],
  subCardsDetails: "",
};
export const getOverView = createAsyncThunk(
  "work/new-orders-overview",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/work/new-orders-overview`, params);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getOverViewRecheck = createAsyncThunk(
  "work/recheck",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/work/recheck-overview`, params);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
const overviewSlice = createSlice({
  name: "overview",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    [getOverView.pending]: (state) => {
      state.overViewStatus = "pending";
    },
    [getOverView.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedData = [];
      // Add existing items with unique _id values to uniqueItems
      state.overViewData.forEach((item) => {
        uniqueItems[item.id] = true;
        combinedData.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedData
      newData.forEach((item) => {
        if (!uniqueItems[item.id]) {
          uniqueItems[item.id] = true;
          combinedData.push(item);
        }
      });
      state.overViewData = combinedData;
      state.total.overView = action?.payload?.data?.data?.total_count;
      state.overViewStatus = "fulfilled";
    },
    [getOverView.rejected]: (state) => {
      state.overViewStatus = "failed";
    },
    [getOverViewRecheck.pending]: (state) => {
      state.overViewRecheckStatus = "pending";
    },
    [getOverViewRecheck.fulfilled]: (state, action) => {
      state.overViewRecheckData = action.payload.data.data?.data;
      state.total.overViewRecheck = action?.payload?.data?.data?.total_count;
      state.overViewRecheckStatus = "fulfilled";
    },
    [getOverViewRecheck.rejected]: (state) => {
      state.overViewRecheckStatus = "failed";
    },
  },
});

export const { updateConfig } = overviewSlice.actions;

export default overviewSlice.reducer;
