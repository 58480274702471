import { Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Style from "../../../staffBilling.module.scss";

const MasterData = ({ values, formik, itemIndex }) => {
  return (
    <div className={Style.container}>
      <Input
        label={"Item Type"}
        type="text"
        id="item_type"
        name="item_type"
        className={`pro-input lg`}
        value={values?.item_type}
        disabled={true}
      />
      <Input
        label={"Service Type"}
        type="text"
        id="service_type"
        name="service_type"
        className={`pro-input lg`}
        value={values?.service_type}
        disabled={true}
      />
      {formik?.values?.updated_service_data?.[itemIndex]?.rate_per_piece !==
        undefined && (
        <Input
          label={"Rate Per Piece"}
          type="text"
          id="rate_per_piece"
          name="rate_per_piece"
          className={`pro-input lg ${
            formik.touched?.updated_service_data?.[itemIndex]?.rate_per_piece &&
            formik.errors?.updated_service_data?.[itemIndex]?.rate_per_piece &&
            "error"
          }`}
          onChange={(e) =>
            formik?.setFieldValue(
              `updated_service_data.[${itemIndex}].rate_per_piece`,
              e?.target?.value
            )
          }
          onBlur={formik?.handleBlur(
            `updated_service_data.[${itemIndex}].rate_per_piece`
          )}
          error={
            formik.touched?.updated_service_data?.[itemIndex]?.rate_per_piece &&
            formik.errors?.updated_service_data?.[itemIndex]
              ?.rate_per_piece && (
              <span className="error-text">
                {
                  formik.errors?.updated_service_data?.[itemIndex]
                    ?.rate_per_piece
                }
              </span>
            )
          }
          errorMessage={
            formik.touched?.updated_service_data?.[itemIndex]?.rate_per_piece &&
            formik.errors?.updated_service_data?.[itemIndex]
              ?.rate_per_piece && (
              <span className="error-text">
                {
                  formik.errors?.updated_service_data?.[itemIndex]
                    ?.rate_per_piece
                }
              </span>
            )
          }
          value={values?.rate_per_piece}
        />
      )}
      {formik?.values?.updated_service_data?.[itemIndex]?.rate_per_character !==
        undefined && (
        <Input
          label={"Rate Per Character"}
          type="text"
          id="rate_per_character"
          name="rate_per_character"
          className={`pro-input lg ${
            formik.touched?.updated_service_data?.[itemIndex]?.rate_per_character &&
            formik.errors?.updated_service_data?.[itemIndex]?.rate_per_character &&
            "error"
          }`}
          onChange={(e) =>
            formik?.setFieldValue(
              `updated_service_data.[${itemIndex}].rate_per_character`,
              e?.target?.value
            )
          }
          onBlur={formik?.handleBlur(
            `updated_service_data.[${itemIndex}].rate_per_character`
          )}
          error={
            formik.touched?.updated_service_data?.[itemIndex]?.rate_per_character &&
            formik.errors?.updated_service_data?.[itemIndex]
              ?.rate_per_character && (
              <span className="error-text">
                {
                  formik.errors?.updated_service_data?.[itemIndex]
                    ?.rate_per_character
                }
              </span>
            )
          }
          errorMessage={
            formik.touched?.updated_service_data?.[itemIndex]?.rate_per_character &&
            formik.errors?.updated_service_data?.[itemIndex]
              ?.rate_per_character && (
              <span className="error-text">
                {
                  formik.errors?.updated_service_data?.[itemIndex]
                    ?.rate_per_character
                }
              </span>
            )
          }
          value={values?.rate_per_character}
        />
      )}
      {formik?.values?.updated_service_data?.[itemIndex]?.invoice_description !==
        undefined && (
        <Input
          label={"Description"}
          type="text"
          id="invoice_description"
          name="invoice_description"
          className={`pro-input lg ${
            formik.touched?.updated_service_data?.[itemIndex]?.invoice_description &&
            formik.errors?.updated_service_data?.[itemIndex]?.invoice_description &&
            "error"
          }`}
          onChange={(e) =>
            formik?.setFieldValue(
              `updated_service_data.[${itemIndex}].invoice_description`,
              e?.target?.value
            )
          }
          onBlur={formik?.handleBlur(
            `updated_service_data.[${itemIndex}].invoice_description`
          )}
          error={
            formik.touched?.updated_service_data?.[itemIndex]?.invoice_description &&
            formik.errors?.updated_service_data?.[itemIndex]
              ?.invoice_description && (
              <span className="error-text">
                {
                  formik.errors?.updated_service_data?.[itemIndex]
                    ?.invoice_description
                }
              </span>
            )
          }
          errorMessage={
            formik.touched?.updated_service_data?.[itemIndex]?.invoice_description &&
            formik.errors?.updated_service_data?.[itemIndex]
              ?.invoice_description && (
              <span className="error-text">
                {
                  formik.errors?.updated_service_data?.[itemIndex]
                    ?.invoice_description
                }
              </span>
            )
          }
          value={values?.invoice_description}
        />
      )}
      <>
        {/* new case  */}
        {formik.values?.updated_service_data?.[itemIndex]
          ?.rate_per_piece_single_grade !== undefined && (
          <Input
            label={"Rate Per Piece Single Grade"}
            type="text"
            id="rate_per_piece_single_grade"
            name="rate_per_piece_single_grade"
            className={`pro-input lg ${
              formik.touched?.updated_service_data?.[itemIndex]
                ?.rate_per_piece_single_grade &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.rate_per_piece_single_grade &&
              "error"
            }`}
            onChange={(e) =>
              formik?.setFieldValue(
                `updated_service_data.[${itemIndex}].rate_per_piece_single_grade`,
                e?.target?.value
              )
            }
            onBlur={formik?.handleBlur(
              `updated_service_data.[${itemIndex}].rate_per_piece_single_grade`
            )}
            error={
              formik.touched?.updated_service_data?.[itemIndex]
                ?.rate_per_piece_single_grade &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.rate_per_piece_single_grade && (
                <span className="error-text">
                  {
                    formik.errors?.updated_service_data?.[itemIndex]
                      ?.rate_per_piece_single_grade
                  }
                </span>
              )
            }
            errorMessage={
              formik.touched?.updated_service_data?.[itemIndex]
                ?.rate_per_piece_single_grade &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.rate_per_piece_single_grade && (
                <span className="error-text">
                  {
                    formik.errors?.updated_service_data?.[itemIndex]
                      ?.rate_per_piece_single_grade
                  }
                </span>
              )
            }
            value={values?.rate_per_piece_single_grade}
          />
        )}
        {formik.values?.updated_service_data?.[itemIndex]
          ?.rate_per_piece_double_grade !== undefined && (
          <Input
            label={"Rate Per Piece Double Grade"}
            type="text"
            id="rate_per_piece_double_grade"
            name="rate_per_piece_double_grade"
            className={`pro-input lg ${
              formik.touched?.updated_service_data?.[itemIndex]
                ?.rate_per_piece_double_grade &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.rate_per_piece_double_grade &&
              "error"
            }`}
            onChange={(e) =>
              formik?.setFieldValue(
                `updated_service_data.[${itemIndex}].rate_per_piece_double_grade`,
                e?.target?.value
              )
            }
            onBlur={formik?.handleBlur(
              `updated_service_data.[${itemIndex}].rate_per_piece_double_grade`
            )}
            error={
              formik.touched?.updated_service_data?.[itemIndex]
                ?.rate_per_piece_double_grade &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.rate_per_piece_double_grade && (
                <span className="error-text">
                  {
                    formik.errors?.updated_service_data?.[itemIndex]
                      ?.rate_per_piece_double_grade
                  }
                </span>
              )
            }
            errorMessage={
              formik.touched?.updated_service_data?.[itemIndex]
                ?.rate_per_piece_double_grade &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.rate_per_piece_double_grade && (
                <span className="error-text">
                  {
                    formik.errors?.updated_service_data?.[itemIndex]
                      ?.rate_per_piece_double_grade
                  }
                </span>
              )
            }
            value={values?.rate_per_piece_double_grade}
          />
        )}
        {formik.values?.updated_service_data?.[itemIndex]
          ?.rate_per_piece_natural_gold !== undefined && (
          <Input
            label={"Rate Per Piece Natural Gold"}
            type="text"
            id="rate_per_piece_natural_gold"
            name="rate_per_piece_natural_gold"
            className={`pro-input lg ${
              formik.touched?.updated_service_data?.[itemIndex]
                ?.rate_per_piece_natural_gold &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.rate_per_piece_natural_gold &&
              "error"
            }`}
            onChange={(e) =>
              formik?.setFieldValue(
                `updated_service_data.[${itemIndex}].rate_per_piece_natural_gold`,
                e?.target?.value
              )
            }
            onBlur={formik?.handleBlur(
              `updated_service_data.[${itemIndex}].rate_per_piece_natural_gold`
            )}
            error={
              formik.touched?.updated_service_data?.[itemIndex]
                ?.rate_per_piece_natural_gold &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.rate_per_piece_natural_gold && (
                <span className="error-text">
                  {
                    formik.errors?.updated_service_data?.[itemIndex]
                      ?.rate_per_piece_natural_gold
                  }
                </span>
              )
            }
            errorMessage={
              formik.touched?.updated_service_data?.[itemIndex]
                ?.rate_per_piece_natural_gold &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.rate_per_piece_natural_gold && (
                <span className="error-text">
                  {
                    formik.errors?.updated_service_data?.[itemIndex]
                      ?.rate_per_piece_natural_gold
                  }
                </span>
              )
            }
            value={values?.rate_per_piece_natural_gold}
          />
        )}
        {/* caret starts  */}
        {formik.values?.updated_service_data?.[itemIndex]
          ?.rate_per_carat_single_grade !== undefined && (
          <Input
            label={"Rate Per Piece Single Grade"}
            type="text"
            id="rate_per_carat_single_grade"
            name="rate_per_carat_single_grade"
            className={`pro-input lg ${
              formik.touched?.updated_service_data?.[itemIndex]
                ?.rate_per_carat_single_grade &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.rate_per_carat_single_grade &&
              "error"
            }`}
            onChange={(e) =>
              formik?.setFieldValue(
                `updated_service_data.[${itemIndex}].rate_per_carat_single_grade`,
                e?.target?.value
              )
            }
            onBlur={formik?.handleBlur(
              `updated_service_data.[${itemIndex}].rate_per_carat_single_grade`
            )}
            error={
              formik.touched?.updated_service_data?.[itemIndex]
                ?.rate_per_carat_single_grade &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.rate_per_carat_single_grade && (
                <span className="error-text">
                  {
                    formik.errors?.updated_service_data?.[itemIndex]
                      ?.rate_per_carat_single_grade
                  }
                </span>
              )
            }
            errorMessage={
              formik.touched?.updated_service_data?.[itemIndex]
                ?.rate_per_carat_single_grade &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.rate_per_carat_single_grade && (
                <span className="error-text">
                  {
                    formik.errors?.updated_service_data?.[itemIndex]
                      ?.rate_per_carat_single_grade
                  }
                </span>
              )
            }
            value={values?.rate_per_carat_single_grade}
          />
        )}
        {formik.values?.updated_service_data?.[itemIndex]
          ?.rate_per_carat_double_grade !== undefined && (
          <Input
            label={"Rate Per Piece Double Grade"}
            type="text"
            id="rate_per_carat_double_grade"
            name="rate_per_carat_double_grade"
            className={`pro-input lg ${
              formik.touched?.updated_service_data?.[itemIndex]
                ?.rate_per_carat_double_grade &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.rate_per_carat_double_grade &&
              "error"
            }`}
            onChange={(e) =>
              formik?.setFieldValue(
                `updated_service_data.[${itemIndex}].rate_per_carat_double_grade`,
                e?.target?.value
              )
            }
            onBlur={formik?.handleBlur(
              `updated_service_data.[${itemIndex}].rate_per_carat_double_grade`
            )}
            error={
              formik.touched?.updated_service_data?.[itemIndex]
                ?.rate_per_carat_double_grade &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.rate_per_carat_double_grade && (
                <span className="error-text">
                  {
                    formik.errors?.updated_service_data?.[itemIndex]
                      ?.rate_per_carat_double_grade
                  }
                </span>
              )
            }
            errorMessage={
              formik.touched?.updated_service_data?.[itemIndex]
                ?.rate_per_carat_double_grade &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.rate_per_carat_double_grade && (
                <span className="error-text">
                  {
                    formik.errors?.updated_service_data?.[itemIndex]
                      ?.rate_per_carat_double_grade
                  }
                </span>
              )
            }
            value={values?.rate_per_carat_double_grade}
          />
        )}
        {formik.values?.updated_service_data?.[itemIndex]
          ?.rate_per_carat_natural_gold !== undefined && (
          <Input
            label={"Rate Per Piece Natural Gold"}
            type="text"
            id="rate_per_carat_natural_gold"
            name="rate_per_carat_natural_gold"
            className={`pro-input lg ${
              formik.touched?.updated_service_data?.[itemIndex]
                ?.rate_per_carat_natural_gold &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.rate_per_carat_natural_gold &&
              "error"
            }`}
            onChange={(e) =>
              formik?.setFieldValue(
                `updated_service_data.[${itemIndex}].rate_per_carat_natural_gold`,
                e?.target?.value
              )
            }
            onBlur={formik?.handleBlur(
              `updated_service_data.[${itemIndex}].rate_per_carat_natural_gold`
            )}
            error={
              formik.touched?.updated_service_data?.[itemIndex]
                ?.rate_per_carat_natural_gold &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.rate_per_carat_natural_gold && (
                <span className="error-text">
                  {
                    formik.errors?.updated_service_data?.[itemIndex]
                      ?.rate_per_carat_natural_gold
                  }
                </span>
              )
            }
            errorMessage={
              formik.touched?.updated_service_data?.[itemIndex]
                ?.rate_per_carat_natural_gold &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.rate_per_carat_natural_gold && (
                <span className="error-text">
                  {
                    formik.errors?.updated_service_data?.[itemIndex]
                      ?.rate_per_carat_natural_gold
                  }
                </span>
              )
            }
            value={values?.rate_per_carat_natural_gold}
          />
        )}
      </>

      <>
        {/* normal case  */}
        {formik.values?.updated_service_data?.[itemIndex]?.rate_per_carat !==
          undefined && (
          <Input
            label={"Rate Per Carat"}
            type="text"
            id="rate_per_carat"
            name="rate_per_carat"
            className={`pro-input lg ${
              formik.touched?.updated_service_data?.[itemIndex]
                ?.rate_per_carat &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.rate_per_carat &&
              "error"
            }`}
            onChange={(e) =>
              formik?.setFieldValue(
                `updated_service_data.[${itemIndex}].rate_per_carat`,
                e?.target?.value
              )
            }
            onBlur={formik?.handleBlur(
              `updated_service_data.[${itemIndex}].rate_per_carat`
            )}
            error={
              formik.touched?.updated_service_data?.[itemIndex]
                ?.rate_per_carat &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.rate_per_carat && (
                <span className="error-text">
                  {
                    formik.errors?.updated_service_data?.[itemIndex]
                      ?.rate_per_carat
                  }
                </span>
              )
            }
            errorMessage={
              formik.touched?.updated_service_data?.[itemIndex]
                ?.rate_per_carat &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.rate_per_carat && (
                <span className="error-text">
                  {
                    formik.errors?.updated_service_data?.[itemIndex]
                      ?.rate_per_carat
                  }
                </span>
              )
            }
            value={values?.rate_per_carat}
          />
        )}

        {formik.values?.updated_service_data?.[itemIndex]
          ?.single_stone_charge !== undefined && (
          <Input
            label={"Single Stone Charge"}
            type="text"
            id="single_stone_charge"
            name="single_stone_charge"
            className={`pro-input lg ${
              formik.touched?.updated_service_data?.[itemIndex]
                ?.single_stone_charge &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.single_stone_charge &&
              "error"
            }`}
            onChange={(e) =>
              formik?.setFieldValue(
                `updated_service_data.[${itemIndex}].single_stone_charge`,
                e?.target?.value
              )
            }
            onBlur={formik?.handleBlur(
              `updated_service_data.[${itemIndex}].single_stone_charge`
            )}
            error={
              formik.touched?.updated_service_data?.[itemIndex]
                ?.single_stone_charge &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.single_stone_charge && (
                <span className="error-text">
                  {
                    formik.errors?.updated_service_data?.[itemIndex]
                      ?.single_stone_charge
                  }
                </span>
              )
            }
            errorMessage={
              formik.touched?.updated_service_data?.[itemIndex]
                ?.single_stone_charge &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.single_stone_charge && (
                <span className="error-text">
                  {
                    formik.errors?.updated_service_data?.[itemIndex]
                      ?.single_stone_charge
                  }
                </span>
              )
            }
            value={values?.single_stone_charge}
          />
        )}

        {formik.values?.updated_service_data?.[itemIndex]
          ?.other_stone_charge !== undefined && (
          <Input
            label={"Other Stone Charge"}
            type="text"
            id="other_stone_charge"
            name="other_stone_charge"
            className={`pro-input lg ${
              formik.touched?.updated_service_data?.[itemIndex]
                ?.other_stone_charge &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.other_stone_charge &&
              "error"
            }`}
            onChange={(e) =>
              formik?.setFieldValue(
                `updated_service_data.[${itemIndex}].other_stone_charge`,
                e?.target?.value
              )
            }
            onBlur={formik?.handleBlur(
              `updated_service_data.[${itemIndex}].other_stone_charge`
            )}
            error={
              formik.touched?.updated_service_data?.[itemIndex]
                ?.other_stone_charge &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.other_stone_charge && (
                <span className="error-text">
                  {
                    formik.errors?.updated_service_data?.[itemIndex]
                      ?.other_stone_charge
                  }
                </span>
              )
            }
            errorMessage={
              formik.touched?.updated_service_data?.[itemIndex]
                ?.other_stone_charge &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.other_stone_charge && (
                <span className="error-text">
                  {
                    formik.errors?.updated_service_data?.[itemIndex]
                      ?.other_stone_charge
                  }
                </span>
              )
            }
            value={values?.other_stone_charge}
          />
        )}

        {/* new  */}
        {formik.values?.updated_service_data?.[itemIndex]?.packing_charge !==
          undefined && (
          <Input
            label={"Packing Charge"}
            type="text"
            id="packing_charge"
            name="packing_charge"
            className={`pro-input lg ${
              formik.touched?.updated_service_data?.[itemIndex]
                ?.packing_charge &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.packing_charge &&
              "error"
            }`}
            onChange={(e) =>
              formik?.setFieldValue(
                `updated_service_data.[${itemIndex}].packing_charge`,
                e?.target?.value
              )
            }
            onBlur={formik?.handleBlur(
              `updated_service_data.[${itemIndex}].packing_charge`
            )}
            error={
              formik.touched?.updated_service_data?.[itemIndex]
                ?.packing_charge &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.packing_charge && (
                <span className="error-text">
                  {
                    formik.errors?.updated_service_data?.[itemIndex]
                      ?.packing_charge
                  }
                </span>
              )
            }
            errorMessage={
              formik.touched?.updated_service_data?.[itemIndex]
                ?.packing_charge &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.packing_charge && (
                <span className="error-text">
                  {
                    formik.errors?.updated_service_data?.[itemIndex]
                      ?.packing_charge
                  }
                </span>
              )
            }
            value={values?.packing_charge}
          />
        )}

        {formik.values?.updated_service_data?.[itemIndex]?.printing_charge !==
          undefined && (
          <Input
            label={"Printing Charge"}
            type="text"
            id="printing_charge"
            name="printing_charge"
            className={`pro-input lg ${
              formik.touched?.updated_service_data?.[itemIndex]
                ?.printing_charge &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.printing_charge &&
              "error"
            }`}
            onChange={(e) =>
              formik?.setFieldValue(
                `updated_service_data.[${itemIndex}].printing_charge`,
                e?.target?.value
              )
            }
            onBlur={formik?.handleBlur(
              `updated_service_data.[${itemIndex}].printing_charge`
            )}
            error={
              formik.touched?.updated_service_data?.[itemIndex]
                ?.printing_charge &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.printing_charge && (
                <span className="error-text">
                  {
                    formik.errors?.updated_service_data?.[itemIndex]
                      ?.printing_charge
                  }
                </span>
              )
            }
            errorMessage={
              formik.touched?.updated_service_data?.[itemIndex]
                ?.printing_charge &&
              formik.errors?.updated_service_data?.[itemIndex]
                ?.printing_charge && (
                <span className="error-text">
                  {
                    formik.errors?.updated_service_data?.[itemIndex]
                      ?.printing_charge
                  }
                </span>
              )
            }
            value={values?.printing_charge}
          />
        )}
        {/* <Input
          label={"Rate Per Piece"}
          type="text"
          id="rate_per_piece"
          name="rate_per_piece"
          className={`pro-input lg ${
            formik.touched?.updated_service_data?.[itemIndex]?.rate_per_piece &&
            formik.errors?.updated_service_data?.[itemIndex]?.rate_per_piece &&
            "error"
          }`}
          onChange={(e) =>
            formik?.setFieldValue(
              `updated_service_data.[${itemIndex}].rate_per_piece`,
              e?.target?.value
            )
          }
          onBlur={formik?.handleBlur(
            `updated_service_data.[${itemIndex}].rate_per_piece`
          )}
          error={
            formik.touched?.updated_service_data?.[itemIndex]?.rate_per_piece &&
            formik.errors?.updated_service_data?.[itemIndex]
              ?.rate_per_piece && (
              <span className="error-text">
                {
                  formik.errors?.updated_service_data?.[itemIndex]
                    ?.rate_per_piece
                }
              </span>
            )
          }
          errorMessage={
            formik.touched?.updated_service_data?.[itemIndex]?.rate_per_piece &&
            formik.errors?.updated_service_data?.[itemIndex]
              ?.rate_per_piece && (
              <span className="error-text">
                {
                  formik.errors?.updated_service_data?.[itemIndex]
                    ?.rate_per_piece
                }
              </span>
            )
          }
          value={values?.rate_per_piece}
        /> */}
      </>
    </div>
  );
};

export default MasterData;
