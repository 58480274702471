import React from "react";
import useEditPrint from "./useEditPrint";
import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import ItemFields from "./ItemFields";
import Style from "./editPrint.module.scss";

const EditPrint = () => {
  const { formik, getFieldError, handleAddField, handleCloseModal } =
    useEditPrint();
  return (
    <>
      <div className={Style.address}>
        <small>Vendor Details</small>
        <div className="input-wrap">
          <Input
            label={"Person"}
            type="text"
            id="vendor_details_person"
            name="vendor_details_person"
            className={`pro-input lg ${
              formik?.errors?.vendor_details?.person &&
              formik?.touched?.vendor_details?.person &&
              "error"
            }`}
            error={
              formik?.errors?.vendor_details?.person &&
              formik?.touched?.vendor_details?.person
            }
            errorMessage={
              formik?.errors?.vendor_details?.person &&
              formik?.touched?.vendor_details?.person &&
              formik?.errors?.vendor_details?.person
            }
            onChange={(e) =>
              formik?.setFieldValue("vendor_details.person", e?.target?.value)
            }
            onBlur={formik?.handleBlur("vendor_details.person")}
            value={formik?.values?.vendor_details?.person}
          />
        </div>
        <div className="input-wrap">
          <Input
            label={"Contact"}
            type="text"
            id="vendor_details_contact"
            name="vendor_details_contact"
            className={`pro-input lg ${
              formik?.errors?.vendor_details?.contact_number &&
              formik?.touched?.vendor_details?.contact_number &&
              "error"
            }`}
            error={
              formik?.errors?.vendor_details?.contact_number &&
              formik?.touched?.vendor_details?.contact_number
            }
            errorMessage={
              formik?.errors?.vendor_details?.contact_number &&
              formik?.touched?.vendor_details?.contact_number &&
              formik?.errors?.vendor_details?.contact_number
            }
            onChange={(e) =>
              formik?.setFieldValue(
                "vendor_details.contact_number",
                e?.target?.value
              )
            }
            onBlur={formik?.handleBlur("vendor_details.contact_number")}
            value={formik?.values?.vendor_details?.contact_number}
          />
        </div>
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Address
          </label>
          <textarea
            id="address"
            rows={4}
            name="address"
            className={`pro-input lg ${
              formik?.errors?.vendor_details?.address &&
              formik?.touched?.vendor_details?.address &&
              " error"
            }`}
            onChange={(e) =>
              formik?.setFieldValue("vendor_details.address", e.target.value)
            }
            onBlur={formik?.handleBlur("vendor_details.address")}
            value={formik?.values?.vendor_details.address}
          ></textarea>
          {formik?.errors?.vendor_details?.address &&
            formik?.touched?.vendor_details?.address && (
              <span className="error-text">
                {formik?.errors?.vendor_details?.address}
              </span>
            )}
        </div>
      </div>
      <div className="input-wrap">
        <Input
          label={"Customer Name"}
          type="text"
          id="customer_name"
          name="customer_name"
          className={`pro-input lg ${
            getFieldError("customer_name") && " error"
          }`}
          {...formik.getFieldProps("customer_name")}
          error={getFieldError("customer_name")}
          errorMessage={getFieldError("customer_name")}
        />
      </div>
      <div className="input-wrap">
        <Input
          label={"Address Line 1"}
          type="text"
          id="address_line1"
          name="address_line1"
          className={`pro-input lg ${
            getFieldError("address_line1") && " error"
          }`}
          {...formik.getFieldProps("address_line1")}
          error={getFieldError("address_line1")}
          errorMessage={getFieldError("address_line1")}
        />
      </div>
      <div className="input-wrap">
        <Input
          label={"Address Line 2"}
          type="text"
          id="address_line2"
          name="address_line2"
          className={`pro-input lg ${
            getFieldError("address_line2") && " error"
          }`}
          {...formik.getFieldProps("address_line2")}
          error={getFieldError("address_line2")}
          errorMessage={getFieldError("address_line2")}
        />
      </div>
      <div className="input-wrap">
        <Input
          label={"City"}
          type="text"
          id="city"
          name="city"
          className={`pro-input lg ${getFieldError("city") && " error"}`}
          {...formik.getFieldProps("city")}
          error={getFieldError("city")}
          errorMessage={getFieldError("city")}
        />
      </div>
      <div className="input-wrap">
        <Input
          label={"Contact Number"}
          type="text"
          id="contact_number"
          name="contact_number"
          className={`pro-input lg ${
            getFieldError("contact_number") && " error"
          }`}
          {...formik.getFieldProps("contact_number")}
          error={getFieldError("contact_number")}
          errorMessage={getFieldError("contact_number")}
        />
      </div>
      <div className="input-wrap">
        <Input
          label={"GST Number"}
          type="text"
          id="gst_number"
          name="gst_number"
          className={`pro-input lg ${getFieldError("gst_number") && " error"}`}
          {...formik.getFieldProps("gst_number")}
          error={getFieldError("gst_number")}
          errorMessage={getFieldError("gst_number")}
        />
      </div>
      <div className={Style.address}>
        {formik?.values?.item_fields?.map((item, index) => {
          return (
            <ItemFields
              key={index}
              fieldIndex={index}
              field={item}
              getFieldError={getFieldError}
              formik={formik}
            />
          );
        })}
        {true && (
          <Button
            className={`${Style.add_btn} pro-btn-primary lg pro-w-100 pro-mt-4`}
            onClick={handleAddField}
            disabled={false}
          >
            {` Add Another`}
          </Button>
        )}
      </div>
      <div className="input-wrap">
        <Input
          label={"Total Amount"}
          type="text"
          id="total_amount"
          name="total_amount"
          className={`pro-input lg ${
            getFieldError("total_amount") && " error"
          }`}
          {...formik.getFieldProps("total_amount")}
          error={getFieldError("total_amount")}
          errorMessage={getFieldError("total_amount")}
          disabled={true}
        />
      </div>
      <div className="input-wrap">
        <Input
          label={"Remarks"}
          type="text"
          id="remarks"
          name="remarks"
          className={`pro-input lg ${getFieldError("remarks") && " error"}`}
          {...formik.getFieldProps("remarks")}
          error={getFieldError("remarks")}
          errorMessage={getFieldError("remarks")}
        />
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn pro-mt-5`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {"Update"}
        </Button>
      </div>
    </>
  );
};

export default EditPrint;
