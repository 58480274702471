import Barcode from "react-barcode";
import { useCardPrint } from "../VisitingCardOne/useCardPrint";
import QRCode from "react-qr-code";

export const BigTemplateA = ({ value }) => {
  const { renderText } = useCardPrint();

  const arrayItems = value?.data?.graded_values;
  const isLargeArray = arrayItems?.length > 8;

  const topArray = arrayItems?.filter(
    (item) =>
      item?.slug === "description" ||
      item?.slug === "description_of_jewellery" ||
      item?.slug === "supplier"
  );
  const middleArray = arrayItems?.filter(
    (item) =>
      item?.slug !== "comments" &&
      item?.slug !== "conclusion" &&
      item?.slug !== "description" &&
      item?.slug !== "description_of_jewellery" &&
      item?.slug !== "supplier"
  );
  const bottomArray = arrayItems?.filter(
    (item) => item?.slug === "comments" || item?.slug === "conclusion"
  );

  //to change the length of key
  const maxKeyLength = arrayItems?.reduce(
    (max, str) => Math.max(max, str?.text?.length),
    0
  );
  const newKeyValue = {
    ...styles.key,
    width: maxKeyLength > 14 ? 100 : 90,
  };
  //end to change the length of key

  return (
    <>
      <div style={styles.outer_wrapper} className="item-class-big-card">
        <div style={styles.header_wrap}>
          <img
            style={styles.header_img}
            src={value?.front_top_image || ""}
            alt="Header"
          />
          {parseInt(value?.qr_and_image_format) === 2 && value?.qr && (
            <span style={styles.qr_wrap}>
              <QRCode
                value={value?.qr}
                width={60}
                height={60}
                style={styles.qr_code}
              />
            </span>
          )}
        </div>
        <div style={styles.body}>
          <div style={styles.top_wrap}>
            <div style={styles.row}>
              <p
                style={{
                  ...newKeyValue,
                  lineHeight: arrayItems?.length <= 6 ? "1.3" : "1.1",
                }}
              >
                {value?.bold === 1 ? (
                  <b style={{ fontSize: 9.5, fontWeight: "600" }}>SUMMARY NO</b>
                ) : (
                  "SUMMARY NO"
                )}
              </p>
              <p
                style={{
                  ...styles.separate,
                  lineHeight: arrayItems?.length <= 6 ? "1.3" : "1.1",
                }}
              >
                :
              </p>
              <p
                style={{
                  ...styles.value,
                  lineHeight: arrayItems?.length <= 6 ? "1.3" : "1.1",
                }}
              >
                {value?.bold === 1 ? (
                  <b style={{ fontSize: 9.5, fontWeight: "600" }}>
                    {value?.certificate_number}
                  </b>
                ) : (
                  value?.certificate_number
                )}
              </p>
            </div>
            {topArray?.map((item, index) => (
              <div style={styles.row} key={index}>
                <p
                  style={{
                    ...newKeyValue,
                    lineHeight: arrayItems?.length <= 6 ? "1.3" : "1.1",
                  }}
                >
                  {item?.bold === 1 ? (
                    <b style={{ fontSize: 9.5, fontWeight: "600" }}>
                      {renderText(item?.text, item?.key_font_case)}
                    </b>
                  ) : (
                    renderText(item?.text, item?.key_font_case)
                  )}
                </p>
                <p
                  style={{
                    ...styles.separate,
                    lineHeight: arrayItems?.length <= 6 ? "1.3" : "1.1",
                  }}
                >
                  :
                </p>
                <p
                  style={{
                    ...styles.value,
                    lineHeight: arrayItems?.length <= 6 ? "1.3" : "1.1",
                  }}
                >
                  {item?.bold === 1 ? (
                    <b style={{ fontSize: 9.5, fontWeight: "600" }}>
                      {renderText(item?.value, item?.value_font_case)}
                    </b>
                  ) : (
                    renderText(item?.value, item?.value_font_case)
                  )}
                </p>
              </div>
            ))}
          </div>
          <div style={styles.middle_wrap}>
            <div style={styles.content_wrap}>
              {middleArray?.map((item, index) => (
                <div style={styles.row} key={index}>
                  <p
                    style={{
                      ...newKeyValue,
                      lineHeight:
                        item?.bold === 1
                          ? "1.06"
                          : isLargeArray
                          ? "1.06"
                          : "1.5",
                    }}
                  >
                    {item?.bold === 1 ? (
                      <b style={{ fontSize: 9.5, fontWeight: "600" }}>
                        {renderText(item?.text, item?.key_font_case)}
                      </b>
                    ) : (
                      renderText(item?.text, item?.key_font_case)
                    )}
                  </p>
                  <p
                    style={{
                      ...styles.separate,
                      lineHeight:
                        item?.bold === 1
                          ? "1.06"
                          : isLargeArray
                          ? "1.06"
                          : "1.5",
                    }}
                  >
                    :
                  </p>
                  <p
                    style={{
                      ...styles.value,
                      lineHeight:
                        item?.bold === 1
                          ? "1.06"
                          : isLargeArray
                          ? "1.06"
                          : "1.5",
                    }}
                  >
                    {item?.bold === 1 ? (
                      <b style={{ fontSize: 9.5, fontWeight: "600" }}>
                        {renderText(item?.value, item?.value_font_case)}
                      </b>
                    ) : (
                      renderText(item?.value, item?.value_font_case)
                    )}
                  </p>
                </div>
              ))}
            </div>
            <div style={styles.img_wrap}>
              <img
                style={styles.main_img}
                src={value?.image || ""}
                alt="Card images"
              />
            </div>
          </div>
          <div style={styles.bottom_wrap}>
            {bottomArray?.map((item, index) => (
              <div style={styles.row} key={index}>
                <p
                  style={{
                    ...newKeyValue,
                    lineHeight:
                      item?.bold !== 1 && isLargeArray ? "1.06" : "1.2",
                  }}
                >
                  {item?.bold === 1 ? (
                    <b style={{ fontSize: 9.5, fontWeight: "600" }}>
                      {renderText(item?.text, item?.key_font_case)}
                    </b>
                  ) : (
                    renderText(item?.text, item?.key_font_case)
                  )}
                </p>
                <p
                  style={{
                    ...styles.separate,
                    lineHeight:
                      item?.bold !== 1 && isLargeArray ? "1.06" : "1.2",
                  }}
                >
                  :
                </p>
                <p
                  style={{
                    ...styles.value,
                    lineHeight:
                      item?.bold !== 1 && isLargeArray ? "1.06" : "1.2",
                  }}
                >
                  {item?.bold === 1 ? (
                    <b style={{ fontSize: 9.5, fontWeight: "600" }}>
                      {renderText(item?.value, item?.value_font_case)}
                    </b>
                  ) : (
                    renderText(item?.value, item?.value_font_case)
                  )}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div style={styles.footer_wrap}>
          {parseInt(value?.qr_and_image_format) === 2 ? (
            <div style={styles.barcode_wrap}>
              {value?.certificate_number && (
                <Barcode
                  value={value?.certificate_number}
                  height={15}
                  width={0.5}
                  displayValue={false}
                  margin={0}
                />
              )}
            </div>
          ) : (
            <img
              style={styles.footer_img}
              src={value?.front_foot_image || ""}
              alt="Card footer"
            />
          )}
        </div>
      </div>
    </>
  );
};

const styles = {
  outer_wrapper: {
    width: 472.44,
    minWidth: 472.44,
    maxWidth: 472.44,
    height: 354.33,
    maxHeight: 354.33,
    minHeight: 354.33,
    border: "1px solid #f3f3f3",
    color: "#000000",
    overflow: "hidden",
    fontFamily: "'Graphik',san-serif",
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "column",
  },
  header_wrap: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
    height: 79,
    // height : '1.2cm'
    width: "100%",
    position: "relative",
  },
  header_img: {
    maxWidth: 472.44,
    width: "100%",
    maxHeight: 79,
    height: 79,
  },
  qr_wrap: {
    width: 60,
    height: 60,
    display: "inline-block",
    position: "absolute",
    top: 10,
    right: 28,
  },
  qr_code: {
    width: 60,
    height: 60,
  },
  footer_wrap: {
    height: 41,
    // height : '0.8cm'
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  footer_img: {
    width: "100%",
    maxHeight: 41,
    objectFit: "contain",
  },
  body: {
    // height : 141.76,
    maxHeight: 232.33,
    padding: "1px 28px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  middle_wrap: {
    display: "flex",
    padding: "1px 0",
    alignItems: "center",
    flex: "1",
  },
  row: {
    display: "flex",
    padding: "0.8px 0px",
  },
  key: {
    width: 100,
    fontSize: 10,
    fontWeight: 400,
    lineHeight: "1.1",
    marginBottom: 0,
  },
  value: {
    flex: "1",
    verticalAlign: "top",
    fontWeight: 400,
    lineHeight: "1.1",
    marginBottom: 0,
    fontSize: 10,
  },
  separate: {
    width: 10,
    paddingRight: 4,
    paddingLeft: 4,
    fontWeight: 400,
    fontSize: 10,
    lineHeight: "1.1",
    marginBottom: 0,
    position: "relative",
    top: -1,
  },
  content_wrap: {
    flex: "1",
  },
  img_wrap: {
    width: 160,
  },
  main_img: {
    width: "100%",
    maxWidth: 160,
    height: 100,
    maxHeight: 100,
  },
  barcode_wrap: {
    textAlign: "center",
    backgroundColor: "#ffffff",
    lineHeight: 0,
    width: "100%",
  },
  footer_text: {
    textAlign: "center",
    width: "100%",
    position: "absolute",
    left: 0,
    bottom: 15,
  },
};
