import { useSelector } from "react-redux";
import {
  useGetServiceListDataQuery,
  useUpdateServiceTableHeadDataMutation,
} from "../../../store/queries/Master";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Master/Service/serviceSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { approveServiceRequest, deleteService, getServiceData } from "./api";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const useService = () => {
  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];

  const dispatch = useDispatch();

  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const serviceState = useSelector((state) => state.service);
  const {
    data: serviceList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetServiceListDataQuery({
    page_size: serviceState.currentPageSize,
    page: serviceState.currentPage,
    sort_by: serviceState.sortBy,
    sort_order: serviceState.sortOrder,
    start: serviceState.startDate,
    end: serviceState.endDate,
    search: serviceState.search,
  });
  const [updateServiceFields] = useUpdateServiceTableHeadDataMutation();

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = serviceList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [serviceList]);

  useEffect(() => {
    if (serviceState?.selectedItem && serviceState?.isEdit) {
      fillServiceData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceState?.isEdit, serviceState?.showAddServiceModal]);
  useEffect(() => {
    if (serviceState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [serviceState.clearSelection]);

  const handleModalClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddServiceModal = !state.showAddServiceModal;
        state.isEdit = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      sub_process: (feild, data) => (
        <ul>
          {data[feild]?.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      ),
      status: (field, data) =>
        data?.status === "Pending" && serviceList?.data?.approve_permission ? (
          <Button
            className={"pro-btn-primary sm"}
            type="button"
            onClick={() => handleApprovePermission(data?._id)}
          >
            {"Approve"}
          </Button>
        ) : data?.status === "Deleted" ? (
          <span className={"pro-badge badge-danger-outline"}>{"Deleted"}</span>
        ) : data?.status === "Active" ? (
          <span className={"pro-badge badge-success-outline"}>{"Active"}</span>
        ) : (
          data?.status
        ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };
  const handleApprovePermission = (approveId) => {
    let params = {
      service_id: approveId,
    };
    approveServiceRequest(params).then((response) => {
      if (response?.data?.status_code === 200) {
        refetch();
        toast.success("Approved successfully");
      } else {
        toast.error("Something went wrong");
      }
    });
  };
  const handleDelete = () => {
    let data = {
      service_id: serviceState?.selectedItem,
    };
    deleteService(data).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Service deleted successfully");
        handleDeleteModal();
        refetch();
      } else {
        toast.error("Something went wrong");
        handleDeleteModal();
      }
    });
  };

  const fillServiceData = () => {
    getServiceData(serviceState?.selectedItem).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => (state.itemData = response?.data?.data))
        );
      } else {
        toast.error("Something went wrong to fetch data");
      }
    });
  };

  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedItem = e?.[0];
      })
    );
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showAddServiceModal = true;
        state.selectedItem = e?.[0];
        state.isEdit = true;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (serviceState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = serviceState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  return {
    serviceState,
    showEditModal,
    tableFields,
    serviceList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    handleDelete,
    handleDeleteModal,
    handleEditAction,
    handleSearch,
    updateServiceFields,
    handleClearClick,
    handlePageSize,
    refetch,
  };
};

export default useService;
