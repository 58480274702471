import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../store/slices/Master/Billing/billingSlice";
import { addBillingData, editBillingData } from "../api";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useGetBillingBasicDataQuery } from "../../../../store/queries/Master";

const useAddBilling = ({ refetch }) => {
  let numberOnly = /^[0-9]+(\.\d+)?$/;
  const dispatch = useDispatch();
  const queryState = useSelector((state) => state.billing);

  const { data: basicData = {}, isLoading } = useGetBillingBasicDataQuery({
    skip: true,
  });

  const invoiceTypes = [{
    name: "Daily" ,
    id: 1
  },
{
  name: "Monthly",
  id: 2
}]

  useEffect(() => {
    if (queryState?.itemData && queryState?.isEdit && !isLoading) {
      formik?.setFieldValue("supplier", "");
      formik?.setFieldValue("shop", "");
      Object.keys(queryState?.itemData || {}).forEach((key) => {
        
        if (key === "item_type") {
          formik.setFieldValue(key, queryState?.itemData?.item_type);
        } else if (key === "calculation_type") {
          const calculationType = basicData?.data?.calculation_types?.filter(
            (val) => val?.id === queryState?.itemData?.calculation_type
          )?.[0];

          formik?.setFieldValue("calculation_type", calculationType);
        } else if (key === "invoice_type") {
          const invoiceType = invoiceTypes?.filter(
            (val) => val?.id === queryState?.itemData?.invoice_type
          )?.[0];

          formik?.setFieldValue("invoice_type", invoiceType);
        } else if (key === "type") {
          
          const type = basicData?.data?.type?.filter(
            // eslint-disable-next-line eqeqeq
            (value) => value?.id == queryState?.itemData?.[key]
          );
          formik?.setFieldValue("type", type?.[0]);
          if (type[0]?.id === 1) {
            // const services = basicData?.data?.item_type?.filter(
            //   (val) => val?._id === formik?.values?.item_type?._id
            // );
            // const services = basicData?.data?.service_type?.filter(
            //   (val) => val?._id === queryState?.itemData?.service_type
            // );
            dispatch(
              updateConfig(
                // (state) => (state.serviceItems = services?.[0]?.services)
                (state) => (state.serviceItems = basicData?.data?.service_type)
              )
            );
          } else if (type[0]?.id === 2) {
            dispatch(
              updateConfig(
                (state) =>
                  (state.serviceItems = basicData?.data?.certificate_type)
              )
            );
          } else if (type[0]?.id === 3) {
            dispatch(
              updateConfig(
                (state) => (state.serviceItems = basicData?.data?.packing_type)
              )
            );
          } 
          else if (type[0]?.id === 4) {
            const certificateType = basicData?.data?.certificate_type?.filter(
              // eslint-disable-next-line eqeqeq
              (value) => value?._id == queryState?.itemData?.type_id
            );
            formik?.setFieldValue("service_type", certificateType?.[0]);
          } else {
            dispatch(updateConfig((state) => (state.serviceItems = [])));
          }
        } else if (key === "service_type") {
          
          const serviceType = basicData?.data?.service_type?.filter(
            // eslint-disable-next-line eqeqeq
            (value) => value?._id == queryState?.itemData?.[key]
          );
          
          formik?.setFieldValue("service_type", serviceType?.[0]);

        } else if (key === "certificate_type") {
          
          const certificateType = basicData?.data?.certificate_type?.filter(
            // eslint-disable-next-line eqeqeq
            (value) => value?._id == queryState?.itemData?.[key]
          );
          formik?.setFieldValue("service_type", certificateType?.[0]);
        } else if (key === "packing_type") {
          
          const packingType = basicData?.data?.packing_type?.filter(
            // eslint-disable-next-line eqeqeq
            (value) => value?._id == queryState?.itemData?.[key]
          );
          formik?.setFieldValue("service_type", packingType?.[0]);
        } else {
      
          formik.setFieldValue(key, queryState?.itemData?.[key]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryState?.itemData, isLoading]);

  const validation = Yup.object({
    item_type: Yup.object().required("Select one"),
    invoice_type: Yup.object().required("Select one"),
    type: Yup.object().required("Select one"),
    service_type: Yup.object().required("Select one"),
    carat_slab: Yup.string().matches(numberOnly, "Invalid number"),
  });

  const formik = useFormik({
    initialValues: {
      supplier: "",
      shop: "",
      item_type: "",
      invoice_type: "",
      type: "",
      service_type: "",
      rate_per_piece: "",
      carat_slab: "",
      rate_per_carat: "",
      number_of_stones_slab: "",
      rate_per_stones: "",
      rate_per_card: "",
      format_type: 2,
      calculation_type: "",
      rate_per_piece_single_grade: "",
      rate_per_piece_double_grade: "",
      rate_per_piece_natural_gold: "",
      rate_per_carat_single_grade: "",
      rate_per_carat_double_grade: "",
      rate_per_carat_natural_gold: "",
      rate_per_character: "",
      invoice_description: "",
      single_stone_charge: "",
      other_stone_charge: "",
    },
    validationSchema: validation,
    validate: (values) => {
      let errors = {};
      // Regex pattern for numbers only
      if (values?.format_type === 2) {
        if (!values?.shop?._id) {
          errors.shop = "Select Shop";
        }
      }

      if (values?.type?.id !== 3) {
        if (
          !values?.invoice_description ||
          values?.invoice_description?.trim() === ""
        ) {
          errors.invoice_description = "*Required";
        }
        if (values?.rate_per_piece) {
          if (!numberOnly.test(values.rate_per_piece.toString())) {
            errors.rate_per_piece = "Invalid Number";
          }
        }
      }

      if (values?.type?.id === 1) {
        if (!values?.calculation_type?.id) {
          errors.calculation_type = "Select Calculation Type";
        }
      }
      if (
        values?.calculation_type &&
        ((values?.calculation_type?.id === 1 &&
          values?.item_type?.slug !== "gemstone_jewellery" &&
          values?.item_type?.slug !== "gemstone_loose") ||
          values?.calculation_type?.id === 4 ||
          values?.calculation_type?.id === 3)
      ) {
        if (!values?.carat_slab) {
          errors.carat_slab = "*Required";
        } else if (values?.carat_slab) {
          if (!numberOnly.test(values.carat_slab.toString())) {
            errors.carat_slab = "Invalid Number";
          }
        }
      }
      if (
        values?.calculation_type &&
        ((values?.calculation_type?.id === 1 &&
          (values?.item_type?.slug !== "gemstone_jewellery" &&
            values?.item_type?.slug !== "gemstone_loose")) ||
          values?.calculation_type?.id === 2 ||
          values?.calculation_type?.id === 4 ||
          values?.calculation_type?.id === 6)
      ) {
        if (!values?.rate_per_piece) {
          errors.rate_per_piece = "*Required";
        } else if (values?.rate_per_piece) {
          if (!numberOnly.test(values.rate_per_piece.toString())) {
            errors.rate_per_piece = "Invalid Number";
          }
        }
      }

      if (values?.type?.slug !== "service_type") {
        if (!values?.rate_per_piece) {
          errors.rate_per_piece = "*Required";
        } else if (values?.rate_per_piece) {
          if (!numberOnly.test(values?.rate_per_piece.toString())) {
            errors.rate_per_piece = "Invalid Numbers";
          }
        }
      }

      if (
        values?.calculation_type &&
        ((values?.calculation_type?.id === 1 &&
          (values?.item_type?.slug === "gemstone_jewellery" ||
            values?.item_type?.slug === "gemstone_loose")) ||
          values?.calculation_type?.id === 4)
      ) {
        if (!values?.single_stone_charge) {
          errors.single_stone_charge = "*Required";
        } else if (values?.single_stone_charge) {
          if (!numberOnly.test(values.single_stone_charge.toString())) {
            errors.single_stone_charge = "Invalid Number";
          }
        }

        if (!values?.other_stone_charge) {
          errors.other_stone_charge = "*Required";
        } else if (values?.other_stone_charge) {
          if (!numberOnly.test(values.other_stone_charge.toString())) {
            errors.other_stone_charge = "Invalid Number";
          }
        }
      }

      if (
        values?.calculation_type &&
        ((values?.calculation_type?.id === 1 &&
          (values?.item_type?.slug !== "gemstone_jewellery" &&
            values?.item_type?.slug !== "gemstone_loose")) ||
          values?.calculation_type?.id === 4)
      ) {
        if (values?.rate_per_carat) {
          if (values?.rate_per_carat) {
            if (!numberOnly.test(values.rate_per_carat.toString())) {
              errors.rate_per_carat = "Invalid Number";
            }
          }
        }
      }
      if(values?.calculation_type?.id && (values?.calculation_type?.id === 5 || values?.calculation_type?.id === 6)) {
        if (!values?.rate_per_character) {
          errors.rate_per_character = "*Required";
        } else if (values?.rate_per_character) {
          if (
            !numberOnly.test(values?.rate_per_character.toString())
          ) {
            errors.rate_per_character = "Invalid Number";
          }
        }
      }
      if (values?.calculation_type?.id && values?.calculation_type?.id === 3) {
        if (!values?.rate_per_piece_single_grade) {
          errors.rate_per_piece_single_grade = "*Required";
        } else if (values?.rate_per_piece_single_grade) {
          if (!numberOnly.test(values.rate_per_piece_single_grade.toString())) {
            errors.rate_per_piece_single_grade = "Invalid Number";
          }
        }
        if (!values?.rate_per_piece_double_grade) {
          errors.rate_per_piece_double_grade = "*Required";
        } else if (values?.rate_per_piece_double_grade) {
          if (!numberOnly.test(values.rate_per_piece_double_grade.toString())) {
            errors.rate_per_piece_double_grade = "Invalid Number";
          }
        }
        if (!values?.rate_per_piece_natural_gold) {
          errors.rate_per_piece_natural_gold = "*Required";
        } else if (values?.rate_per_piece_natural_gold) {
          if (!numberOnly.test(values.rate_per_piece_natural_gold.toString())) {
            errors.rate_per_piece_natural_gold = "Invalid Number";
          }
        }
        if (!values?.rate_per_carat_single_grade) {
          errors.rate_per_carat_single_grade = "*Required";
        } else if (values?.rate_per_carat_single_grade) {
          if (
            !numberOnly.test(values?.rate_per_carat_single_grade.toString())
          ) {
            errors.rate_per_carat_single_grade = "Invalid Number";
          }
        }
        if (!values?.rate_per_carat_double_grade) {
          errors.rate_per_carat_double_grade = "*Required";
        } else if (values?.rate_per_carat_double_grade) {
          if (
            !numberOnly.test(values?.rate_per_carat_double_grade.toString())
          ) {
            errors.rate_per_carat_double_grade = "Invalid Number";
          }
        }
        if (!values?.rate_per_carat_natural_gold) {
          errors.rate_per_carat_natural_gold = "*Required";
        } else if (values?.rate_per_carat_natural_gold) {
          if (
            !numberOnly.test(values?.rate_per_carat_natural_gold.toString())
          ) {
            errors.rate_per_carat_natural_gold = "Invalid Number";
          }
        }
        
      }
      return errors;
    },

    onSubmit: (values) => {
      let formatType = values?.format_type;
      let data = {
        supplier_id: formatType === 2 ? values?.supplier?._id : "",
        shop_id: formatType === 2 ? values?.shop?._id : "",
        item_type_id: values?.item_type?._id,
        invoice_type: values?.invoice_type?.id,
        type: values?.type?.id,
        type_id: values?.service_type?._id,
        carat_slab:
          (values?.calculation_type?.id === 1 &&
            (values?.item_type?.slug !== "gemstone_jewellery" &&
              values?.item_type?.slug !== "gemstone_loose")) ||
          values?.calculation_type?.id === 3 ||
          values?.calculation_type?.id === 4
            ? values?.carat_slab
            : "",
        rate_per_piece:
          (values?.calculation_type?.id === 1 &&
            (values?.item_type?.slug !== "gemstone_jewellery" &&
              values?.item_type?.slug !== "gemstone_loose")) ||
          values?.calculation_type?.id === 2 ||
          values?.calculation_type?.id === 4 ||
          values?.calculation_type?.id === 6 ||
          values?.type?.slug !== "service_type"
            ? values?.rate_per_piece
            : "",
        rate_per_carat:
          (values?.calculation_type?.id === 1 &&
            (values?.item_type?.slug !== "gemstone_jewellery" &&
              values?.item_type?.slug !== "gemstone_loose")) ||
          values?.calculation_type?.id === 4
            ? values?.rate_per_carat
            : "",

        single_stone_charge:
          (values?.calculation_type?.id === 1 &&
            (values?.item_type?.slug === "gemstone_jewellery" ||
              values?.item_type?.slug === "gemstone_loose")) ||
          values?.calculation_type?.id === 4
            ? values?.single_stone_charge
            : "",

        other_stone_charge:
          (values?.calculation_type?.id === 1 &&
            (values?.item_type?.slug === "gemstone_jewellery" ||
              values?.item_type?.slug === "gemstone_loose")) ||
          values?.calculation_type?.id === 4
            ? values?.other_stone_charge
            : "",
        // number_of_stones_slab:
        //   values?.type?.id === 1 ? values?.number_of_stones_slab : "",
        // rate_per_stones: values?.type?.id === 1 ? values?.rate_per_stones : "",
        // rate_per_card: values?.type?.id === 1 ? values?.rate_per_card : "",
        format_type: formatType,
        calculation_type:
          values?.type?.id === 1 ? values?.calculation_type?.id : "",
        //new fields
        rate_per_piece_single_grade:
          values?.calculation_type?.id === 3
            ? values?.rate_per_piece_single_grade
            : "",
        rate_per_piece_double_grade:
          values?.calculation_type?.id === 3
            ? values?.rate_per_piece_double_grade
            : "",
        rate_per_piece_natural_gold:
          values?.calculation_type?.id === 3
            ? values?.rate_per_piece_natural_gold
            : "",
        rate_per_carat_single_grade:
          values?.calculation_type?.id === 3
            ? values?.rate_per_carat_single_grade
            : "",
        rate_per_carat_double_grade:
          values?.calculation_type?.id === 3
            ? values?.rate_per_carat_double_grade
            : "",
        rate_per_carat_natural_gold:
          values?.calculation_type?.id === 3
            ? values?.rate_per_carat_natural_gold
            : "",
        rate_per_character:
          values?.calculation_type?.id === 5 || 
          values?.calculation_type?.id === 6
            ? values?.rate_per_character
            : "",
        invoice_description:
          values?.type?.id !== 3 ? values?.invoice_description : "",
      };
      if (queryState?.isEdit) {
        let editData = {
          billing_id: queryState?.selectedItem,
          ...data,
        };
        let newObj = Object.entries(editData);
        newObj = newObj
          .filter(
            (item) =>
              item[1] !== undefined && item[1] !== "" && item[1] !== null
          )
          .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
        editBillingData(newObj).then((response) => {
          if (response?.data?.status_code === 200) {
            handleCloseModal();
            toast.success("Added successfully");
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
                state.selectedItem = "";
              })
            );
            formik?.setFieldValue("supplier", "");
            formik?.setFieldValue("shop", "");
            formik?.resetForm();
            refetch();
          } else if (response?.status_code === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
            if (response?.errors?.shop_id) {
              formik?.setFieldError("shop", "Bill already exist for this shop");
            }
            if (response?.errors?.item_type_id) {
              formik?.setFieldError("item_type", "Already taken");
            }
          } else {
            toast.error("Something went wrong");
            handleBackendError(response);
          }
        });
      } else {
        let newObj = Object.entries(data);
        newObj = newObj
          .filter(
            (item) =>
              item[1] !== undefined && item[1] !== "" && item[1] !== null
          )
          .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
        addBillingData(newObj).then((response) => {
          if (response?.data?.status_code === 200) {
            handleCloseModal();
            toast.success("Added successfully");
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
                state.selectedItem = "";
              })
            );
            refetch();
            formik?.resetForm();
            formik?.setFieldValue("supplier", "");
            formik?.setFieldValue("shop", "");
          } else if (response?.status_code === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
            if (response?.errors?.shop_id) {
              formik?.setFieldError("shop", "Bill already exist for this shop");
            }
            if (response?.errors?.item_type_id) {
              formik?.setFieldError("item_type", "Already taken");
            }
          } else {
            toast.error("Something went wrong");
            handleBackendError(response);
          }
        });
      }
    },
  });
  const handleCalculationTypeChange = (value) => {
    formik?.setFieldValue("calculation_type", value);
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddModal = false;
        state.itemData = [];
      })
    );
  };

  const handleTypeChange = (value) => {
    
        formik?.setFieldValue("type", value || "");
    if (value?.id === 1) {
      dispatch(
        updateConfig(
          (state) =>
            // (state.serviceItems = formik?.values?.item_type?.services ?? [])
          (state.serviceItems = basicData?.data?.service_type ?? [])
        )
      );
    } else if (value.id === 2) {
      dispatch(
        updateConfig(
          (state) => (state.serviceItems = basicData?.data?.certificate_type)
        )
      );
    } else if (value.id === 3) {
      dispatch(
        updateConfig(
          (state) => (state.serviceItems = basicData?.data?.packing_type)
        )
      );
    } else if (value.id === 4) {
      dispatch(
        updateConfig(
          (state) => (state.serviceItems = basicData?.data?.certificate_type)
        )
      );
    } else {
      dispatch(updateConfig((state) => (state.serviceItems = [])));
    }
    resetFormFields();
    formik.setFieldValue("calculation_type", "");
  };

  const handleBackendError = (response) => {
    if (response?.status_code === 422) {
      let errors = response?.errors;
      let errorField = Object.keys(errors);
      errorField.forEach((field) => {
        formik?.setFieldError(field, errors[field]);
      });
    }
  };
  const handleItemTypeChange = (value) => {
    formik?.setFieldValue("item_type", value || null);
    resetFormFields();
  };
  const resetFormFields = () => {
    formik.setFieldValue("carat_slab", "");
    formik.setFieldValue("service_type", "");
    formik.setFieldValue("rate_per_card", "");
    formik.setFieldValue("rate_per_piece", "");
    formik.setFieldValue("rate_per_carat", "");
    formik.setFieldValue("rate_per_stones", "");
    formik.setFieldValue("number_of_stones_slab", "");
    formik.setFieldValue("rate_per_carat_double_grade", "");
    formik.setFieldValue("rate_per_carat_natural_gold", "");
    formik.setFieldValue("rate_per_character", "");
    formik.setFieldValue("rate_per_carat_single_grade", "");
    formik.setFieldValue("rate_per_piece_double_grade", "");
    formik.setFieldValue("rate_per_piece_natural_gold", "");
    formik.setFieldValue("rate_per_piece_single_grade", "");
    formik.setFieldValue("single_stone_charge", "");
    formik.setFieldValue("other_stone_charge", "");
  };

  return {
    formik,
    queryState,
    basicData: basicData?.data,
    serviceItems: queryState?.serviceItems,
    getFieldError,
    handleCloseModal,
    handleTypeChange,
    handleItemTypeChange,
    handleCalculationTypeChange,
    invoiceTypes
  };
};

export default useAddBilling;
