import { useFormik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";
import { updateCertificateData } from "../api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../store/slices/Verification/approvedSlice";

export const useEditApprovedCertificate = ({
  refetch,
  handleApprovedData,
  basicData,
}) => {
  const dispatch = useDispatch();
  const approvedState = useSelector((state) => state.approved);
  const initialValues = {
    certificate_type: "",
    packing_type: "",
    // tag_number: "",
  };
  const validationSchema = Yup.object({
    certificate_type: Yup.string().nullable(),
    // .required("Select Certificate Type"),
  });
  useEffect(() => {
    formik?.setFieldValue("certificate_type", basicData?.certificate_type_id);
    formik?.setFieldValue("packing_type", basicData?.packing_type_id);
    // formik?.setFieldValue("tag_number", basicData?.tag_number_id);
    //eslint-disable-next-line
  }, [approvedState?.isEdit, basicData]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      //tag number removed as per client request
      // let withTagNumber = {
      //   item_details_id: approvedState.selectedOrder,
      //   certificate_type: values?.certificate_type,
      //   packing_type_id: values?.packing_type,
      //   // tag_number_id: values.tag_number,
      // };
      let withoutTagNumber = {
        item_details_id: approvedState.selectedOrder,
        certificate_type: values?.certificate_type,
        packing_type_id: values?.packing_type,
      };
      // const body = basicData?.tag_number_id ? withoutTagNumber : withTagNumber;
      updateCertificateData(withoutTagNumber).then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Updated successfully");
          refetch();
          handleApprovedData();
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
        } else {
          toast.error("Something went wrong");
          handleApprovedData();
        }
      });
    },
  });
  let certificateObj = basicData?.certificate_type?.filter(
    (item) => item?.id === formik?.values?.certificate_type
  );
  let packingObj = basicData?.packing_type?.filter(
    (item) => item?._id === formik?.values?.packing_type
  );
  // let tagObj = basicData?.tag_number?.filter(
  //   (item) => item?._id === formik?.values?.tag_number
  // );

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  return {
    formik,
    certificateObj,
    packingObj,
    // tagObj,
    getFieldError,
  };
};
export default useEditApprovedCertificate;
