import { HeadingGroup, Input } from "@wac-ui-dashboard/wac_component_library";
import useAddRole from "./useAddRole";
import Style from "./addRole.module.scss";
import Select from "react-select";
import { Permissions } from "../../../Global/Permissions";
const AddRole = () => {
  const {
    state,
    role,
    formik,
    getFieldError,
    handlePermissionChange,
    handleSingleMenuChange,
  } = useAddRole();
  return (
    <>
      <HeadingGroup
        title={"Role Management"}
        buttonTitle={"Save"}
        handleClick={formik?.handleSubmit}
        extraClassName={`pro-justify-between pro-pb-4`}
        buttonProps={{ type: "submit" }}
      />
      <div className={`row`}>
        <div className="col">
          <Input
            label={"Name"}
            type="text"
            id="name"
            name="name"
            className={`pro-input lg ${getFieldError("name") && " error"}`}
            {...formik.getFieldProps("name")}
            error={getFieldError("name")}
            errorMessage={getFieldError("name")}
          />
        </div>
        <div className="input-wrap pro-mb-4 col">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Departments
          </label>
          <Select
            id="departments"
            name="departments"
            placeholder={"Select"}
            className={`pro-input lg  ${
              getFieldError("departments") && " error"
            }`}
            classNamePrefix="pro-input"
            options={state?.allPermissions?.departments}
            value={formik?.values?.departments}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?._id}
            onBlur={formik.handleBlur("departments")}
            onChange={(value) =>
              formik?.setFieldValue("departments", value || null)
            }
            isMulti={true}
            menuPlacement="auto"
          />
          {getFieldError("departments") && (
            <span className="error-text">{getFieldError("departments")}</span>
          )}
        </div>
      </div>
      <div className="pro-pb-6">
        {(role ? state.rolePermission?.[role] : true) &&
          state?.allPermissions?.menu_permissions?.map?.(
            (permission, index) => (
              <div className={Style.root} key={index}>
                <Permissions
                  title={permission?.menu_name}
                  permissions={permission?.sub_menu_permissions}
                  defaultCheckedList={
                    state.rolePermission?.[role]?.menu_permissions
                  }
                  menu_id={permission.menu_id}
                  key={index}
                  uniqueID={"_id"}
                  singleID={permission?._id}
                  handleChange={(item) =>
                    handlePermissionChange(item, permission._id)
                  }
                  handleSingleChange={(item) =>
                    handleSingleMenuChange(item, permission?._id)
                  }
                />
              </div>
            )
          )}
      </div>
    </>
  );
};

export default AddRole;
