import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  cvdPendingStatus: "idle",
  cvdAssignedStatus: "idle",
  cvdCompletedStatus: "idle",
  cvdRejectedStatus: "idle",
  cvdMarkedStatus: "idle",
  cvdRecheckPendingStatus: "idle",
  cvdRecheckAssignedStatus: "idle",
  cvdRecheckCompletedStatus: "idle",
  cvdRecheckMarkedStatus: "idle",
  cvdRecheckRejectedStatus: "idle",
  cvdData: {
    Assigned: [],
    Pending: [],
    Passed: [],
    Marked: [],
    Rejected: [],
  },
  cvdRecheckData: {
    Assigned: [],
    Pending: [],
    Passed: [],
    Marked: [],
    Rejected: [],
  },
  total: {
    Assigned: "",
    Pending: "",
    Passed: "",
    Marked: "",
    Rejected: "",
  },
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 20,
  sortBy: "",
  sortOrder: "",
  search: "",
  showForm: false,
  showCvdConfirmModal: false,
  formType: "",
  certificateID: "",
  assignedID: "",
  showValues: false,
  allValues: [],
  valueType: "",
  itemID: "",
  printData: [],
  showGli: false,
  gliNumber: "",
  itemType: "",
};

export const getCVDAssignedList = createAsyncThunk(
  "cvd/assigned-list",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/order/assigned-to-me`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCVDCompletedList = createAsyncThunk(
  "cvd/completed-list",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/order/work-completed`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCVDPendingList = createAsyncThunk(
  "cvd/pending-list",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/order/work-pending`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCVDMarkedList = createAsyncThunk(
  "cvd/marked-list",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/order/rejected_or_marked`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCVDRejectedList = createAsyncThunk(
  "cvd/rejected-list",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/order/rejected_or_marked`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//recheck section
export const getCVDAssignedRecheckList = createAsyncThunk(
  "cvd/recheck/assigned-list",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/recheck/assigned-to-me`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCVDCompletedRecheckList = createAsyncThunk(
  "cvd/recheck/completed-list",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/recheck/work-completed`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCVDPendingRecheckList = createAsyncThunk(
  "cvd/recheck/pending-list",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/recheck/work-pending`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCVDMarkedRecheckList = createAsyncThunk(
  "cvd/recheck/marked-list",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/recheck/rejected_or_marked`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCVDRejectedRecheckList = createAsyncThunk(
  "cvd/recheck/rejected-list",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/employee-dashboard/recheck/rejected_or_marked`,
        params
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const CVDSlice = createSlice({
  name: "cvd",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    // completed list
    [getCVDCompletedList.pending]: (state) => {
      state.cvdCompletedStatus = "pending";
    },
    [getCVDCompletedList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedCompleted = [];
      // Add existing items with unique _id values to uniqueItems
      state?.cvdData?.Passed?.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedCompleted.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData?.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedCompleted.push(item);
        }
      });
      state.cvdData.Passed = combinedCompleted;
      state.total.Passed = action.payload.data.data.total_count;
      state.cvdCompletedStatus = "fulfilled";
      // state.cvdData.Completed = [
      //   ...state.cvdData.Completed,
      //   ...action.payload.data.data.data,
      // ];
    },
    [getCVDCompletedList.rejected]: (state) => {
      state.cvdCompletedStatus = "failed";
    },
    // assigned list
    [getCVDAssignedList.pending]: (state) => {
      state.cvdAssignedStatus = "pending";
    },
    [getCVDAssignedList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedAssigned = [];
      // Add existing items with unique _id values to uniqueItems
      state?.cvdData?.Assigned?.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedAssigned.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData?.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedAssigned.push(item);
        }
      });
      state.cvdData.Assigned = combinedAssigned;
      state.total.Assigned = action.payload.data.data.total_count;
      state.cvdAssignedStatus = "fulfilled";
      // state.cvdData.Assigned = [
      //   ...state.cvdData.Assigned,
      //   ...action.payload.data.data.data,
      // ];
    },
    [getCVDAssignedList.rejected]: (state) => {
      state.cvdAssignedStatus = "failed";
    },
    // pending list
    [getCVDPendingList.pending]: (state) => {
      state.cvdPendingStatus = "pending";
    },
    [getCVDPendingList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedPending = [];
      // Add existing items with unique _id values to uniqueItems
      state.cvdData.Pending.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedPending.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedPending.push(item);
        }
      });
      state.cvdData.Pending = combinedPending;
      state.total.Pending = action.payload.data.data.total_count;
      state.cvdPendingStatus = "fulfilled";
      // state.cvdData.Pending = [
      //   ...state.cvdData.Pending,
      //   ...action.payload.data.data.data,
      // ];
    },
    [getCVDPendingList.rejected]: (state) => {
      state.cvdPendingStatus = "failed";
    },

    //getCVD Marked List
    [getCVDMarkedList.pending]: (state) => {
      state.cvdMarkedStatus = "pending";
    },
    [getCVDMarkedList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedMarked = [];
      // Add existing items with unique _id values to uniqueItems
      state?.cvdData?.Marked?.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedMarked.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData?.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedMarked.push(item);
        }
      });
      state.cvdData.Marked = combinedMarked;
      state.total.Marked = action.payload.data.data.total_count;
      state.cvdMarkedStatus = "fulfilled";
    },
    [getCVDMarkedList.rejected]: (state) => {
      state.cvdMarkedStatus = "failed";
    },

    //getCVD Rejected List
    [getCVDRejectedList.pending]: (state) => {
      state.cvdRejectedStatus = "pending";
    },
    [getCVDRejectedList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedRejected = [];

      // Add existing items with unique _id values to uniqueItems
      state.cvdData.Rejected.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedRejected.push(item);
      });

      // Add new items with unique _id values to uniqueItems and combinedRejected
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedRejected.push(item);
        }
      });
      state.cvdData.Rejected = combinedRejected;
      state.total.Rejected = action.payload.data.data.total_count;
      state.cvdRejectedStatus = "fulfilled";
    },
    [getCVDRejectedList.rejected]: (state) => {
      state.cvdRejectedStatus = "failed";
    },

    //recheck section
    //completed list
    [getCVDCompletedRecheckList.pending]: (state) => {
      state.cvdRecheckCompletedStatus = "pending";
    },
    [getCVDCompletedRecheckList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedCompleted = [];
      // Add existing items with unique _id values to uniqueItems
      state.cvdRecheckData.Passed.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedCompleted.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedCompleted.push(item);
        }
      });
      state.cvdRecheckData.Passed = combinedCompleted;
      state.total.Passed = action.payload.data.data.total_count;
      state.cvdRecheckCompletedStatus = "fulfilled";
    },
    [getCVDCompletedRecheckList.rejected]: (state) => {
      state.cvdRecheckCompletedStatus = "failed";
    },
    // assigned list
    [getCVDAssignedRecheckList.pending]: (state) => {
      state.cvdRecheckAssignedStatus = "pending";
    },
    [getCVDAssignedRecheckList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedAssigned = [];
      // Add existing items with unique _id values to uniqueItems
      state.cvdRecheckData.Assigned.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedAssigned.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedAssigned.push(item);
        }
      });
      state.cvdRecheckData.Assigned = combinedAssigned;
      state.total.Assigned = action.payload.data.data.total_count;
      state.cvdRecheckAssignedStatus = "fulfilled";
    },
    [getCVDAssignedRecheckList.rejected]: (state) => {
      state.cvdRecheckAssignedStatus = "failed";
    },
    // pending list
    [getCVDPendingRecheckList.pending]: (state) => {
      state.cvdRecheckPendingStatus = "pending";
    },
    [getCVDPendingRecheckList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedPending = [];
      // Add existing items with unique _id values to uniqueItems
      state.cvdRecheckData.Pending.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedPending.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedPending
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedPending.push(item);
        }
      });
      state.cvdRecheckData.Pending = combinedPending;
      state.total.Pending = action.payload.data.data.total_count;
      state.cvdRecheckPendingStatus = "fulfilled";
    },
    [getCVDPendingRecheckList.rejected]: (state) => {
      state.cvdRecheckPendingStatus = "failed";
    },

    //Marked List
    [getCVDMarkedRecheckList.pending]: (state) => {
      state.cvdRecheckMarkedStatus = "pending";
    },
    [getCVDMarkedRecheckList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedMarkedRecheck = [];
      // Add existing items with unique _id values to uniqueItems
      state.cvdRecheckData.Marked.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedMarkedRecheck.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedMarkedRecheck
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedMarkedRecheck.push(item);
        }
      });
      state.cvdRecheckData.Marked = combinedMarkedRecheck;
      state.total.Marked = action.payload.data.data.total_count;
      state.cvdRecheckMarkedStatus = "fulfilled";
    },
    [getCVDMarkedRecheckList.rejected]: (state) => {
      state.cvdRecheckMarkedStatus = "failed";
    },

    //Rejected List
    [getCVDRejectedRecheckList.pending]: (state) => {
      state.cvdRecheckRejectedStatus = "pending";
    },
    [getCVDRejectedRecheckList.fulfilled]: (state, action) => {
      const newData = action.payload.data.data.data;
      const uniqueItems = {};
      const combinedRejectedRecheck = [];
      // Add existing items with unique _id values to uniqueItems
      state.cvdRecheckData.Rejected.forEach((item) => {
        uniqueItems[item._id] = true;
        combinedRejectedRecheck.push(item);
      });
      // Add new items with unique _id values to uniqueItems and combinedRejectedRecheck
      newData.forEach((item) => {
        if (!uniqueItems[item._id]) {
          uniqueItems[item._id] = true;
          combinedRejectedRecheck.push(item);
        }
      });
      state.cvdRecheckData.Rejected = combinedRejectedRecheck;
      state.total.Rejected = action.payload.data.data.total_count;
      state.cvdRecheckRejectedStatus = "fulfilled";
    },
    [getCVDRejectedRecheckList.rejected]: (state) => {
      state.cvdRecheckRejectedStatus = "failed";
    },
  },
});

export const { updateConfig } = CVDSlice.actions;

export default CVDSlice.reducer;
