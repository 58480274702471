import React from "react";
import Select from "react-select";
import useSubmitForm from "./useSubmitForm";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const SubmitForm = ({ handleClose }) => {
  const { formik, getFieldError, handleStatusChange, statusData } =
    useSubmitForm();
  return (
    <div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="status" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Status
        </label>
        <Select
          id="status"
          name="status"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("status") && " error"}`}
          classNamePrefix="pro-input"
          options={
            statusData ?? [
              {
                name: "Completed",
                id: 2,
              },
              {
                name: "Rejected",
                id: 4,
              },
              {
                name: "Marked",
                id: 5,
              },
            ]
          }
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          onBlur={formik.handleBlur("status")}
          isMulti={true}
          onChange={(value) => handleStatusChange(value)}
          menuPlacement="auto"
          value={formik?.values?.status}
        />
        {getFieldError("status") && (
          <span className="error-text">{getFieldError("status")}</span>
        )}
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleClose}
          type="button"
        >
          Cancel
        </Button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default SubmitForm;
