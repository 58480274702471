import {
  DatePickerWithInput,
  HeadingGroup,
  Pagination,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../Global/EmptyData";
import useGLIReport from "./useGLIReport";
import Select from "react-select";
import Style from "../report.module.scss";

const GLIReport = () => {
  const {
    date,
    isFetching,
    reportState,
    GLIReportData,
    paginationOptions,
    getRow,
    handleSort,
    handleSearch,
    handlePageSize,
    handlePagination,
    handleDateChange,
    basicData,
    formik,
    handleBranchFilter
  } = useGLIReport();

  return (
    <div>
      <HeadingGroup title={"GLI Report"} className={`pro-mb-4`} />
      <div className="col-auto pro-pt-3 pro-pb-6">
        {/* <div className="row gx-2 gy-2 flex-fill">
          <div className={`col-auto`}>
            <div className="input-wrap">
              <input
                type="text"
                onChange={(e) => {
                  handleSearch(e);
                }}
                placeholder="Search this table"
                className={`pro-input icon-r lg`}
                value={reportState?.GLIReport?.search}
              />
              <span className="pro-icon">
                {<span className="material-symbols-outlined">search</span>}
              </span>
            </div>
          </div>
          <div className={`col-auto`}>
            <DatePickerWithInput
              CalendarOutlineIcon={
                <span className="material-symbols-outlined">
                  calendar_month
                </span>
              }
              range={true}
              initialValue={date}
              onDateChange={handleDateChange}
            ></DatePickerWithInput>
          </div>
        </div> */}
        <div className="col-12">
          <div className="row gx-2 gy-2">
            <div className={`col-3 ${Style.pt_24}`}>
            <div className="input-wrap">
              <input
                type="text"
                onChange={(e) => {
                  handleSearch(e);
                }}
                placeholder="Search this table"
                className={`pro-input icon-r lg`}
                value={reportState?.GLIReport?.search}
              />
              <span className="pro-icon">
                {<span className="material-symbols-outlined">search</span>}
              </span>
            </div>
            </div>
            <div className={`col-auto ${Style.pt_24}`}>
            <DatePickerWithInput
              CalendarOutlineIcon={
                <span className="material-symbols-outlined">
                  calendar_month
                </span>
              }
              range={true}
              initialValue={date}
              onDateChange={handleDateChange}
            ></DatePickerWithInput>
          </div>
            <div className="col-3">
              <div className="input-wrap ">
                <label
                  htmlFor="branch"
                  className="pro-font-sm pro-mb-1 pro-fw-medium"
                >
                  Branch
                </label>
                <Select
                  id="branch"
                  placeholder={"Select"}
                  className={"pro-input lg"}
                  classNamePrefix="pro-input"
                  isMulti
                  name="branch"
                  options={basicData?.data?.branch_list}
                  getOptionValue={(option) => option?._id}
                  getOptionLabel={(option) => option?.branch_name}
                  value={basicData?.data?.branch_list?.filter((p) =>
                    reportState?.GLIReport?.currentBranchFilter?.includes(p._id)
                  )}
                  onChange={(value) => 
                    handleBranchFilter(value?.map((v) => v._id))
                    
                  }
                  menuPlacement="auto"
                />
              </div>
            </div>
            
            <div className="col-3">
              <div className="input-wrap">
                <label
                  htmlFor="order_type"
                  className="pro-font-sm pro-mb-1 pro-fw-medium"
                >
                  Order Type
                </label>
                <Select
                  id="order_type"
                  placeholder={"Select"}
                  className={"pro-input lg"}
                  classNamePrefix="pro-input"
                  name="order_type"
                  options={basicData?.data?.order_types}
                  getOptionValue={(option) => option?.id}
                  getOptionLabel={(option) => option?.name}
                  value={formik?.values?.order_type}
                  onChange={(value) =>
                    formik.setFieldValue("order_type", value)
                  }
                  menuPlacement="auto"
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          {GLIReportData?.data?.data?.length > 0 ? (
            <>
              <Table
                multiSelect={false}
                data={GLIReportData?.data?.data || []}
                uniqueID={"id"}
                fields={GLIReportData?.data?.fields}
                SortIcon={<FaSort />}
                handleSort={handleSort}
                getRow={getRow}
                loading={isFetching}
                perpage={reportState?.GLIReport?.currentPageSize}
                assignable={false}
                editable={false}
                deletable={false}
                showCheckBox={false}
              />
              {GLIReportData?.data?.data?.length > 0 && (
                <Pagination
                  currentPage={reportState?.GLIReport?.currentPage}
                  totalPageCount={Math.ceil(
                    GLIReportData?.data?.total_count /
                      reportState?.GLIReport?.currentPageSize
                  )}
                  onPageChange={handlePagination}
                  options={paginationOptions}
                  onActionChange={handlePageSize}
                  center
                />
              )}
            </>
          ) : (
            <EmptyData />
          )}
        </div>
      </div>
    </div>
  );
};

export default GLIReport;
