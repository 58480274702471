import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../store/slices/PhotoSection/PhotoSectionSlice";
import { useSelector } from "react-redux";
import {
  UploadPhoto,
  deletePhoto,
  deletePhoto2,
  edittedData,
  edittedData2,
} from "../../api";
import { toast } from "react-toastify";
import { useEffect } from "react";

const useUpload = ({ refetch, tableType }) => {
  const dispatch = useDispatch();
  const { selectedId, certificateId, editData } = useSelector(
    (state) => state?.photoSection
  );

  const handleClose = () => {
    dispatch(
      updateConfig((state) => {
        state.uploadModal = false;
        state.editData.photo1.id = "";
        state.editData.photo2.id = "";
        state.editData.photo1.file = "";
        state.editData.photo2.file = "";
        state.editData.remark = "";
      })
    );
    refetch();
  };

  useEffect(() => {
    formik?.setFieldValue("remark", editData?.remark);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);
  const formik = useFormik({
    initialValues: {
      photo1: "",
      photo2: "",
      remark: "",
    },
    validate: (values) => {
      let errors = {};
      if (typeof values?.image1 === "object" && !values?.photo1) {
        errors.photo1 = "First image is required";
      } else {
        if (typeof values?.photo1 === "object") {
          const allowedExtensions = ["jpg", "jpeg", "png", "gif"]; // Add more if needed
          const fileExtension = values?.photo1?.name
            .split(".")
            .pop()
            .toLowerCase();
          // const fileSizeMB = values.photo.size / (1024 * 1024); // Convert bytes to megabytes

          if (!allowedExtensions.includes(fileExtension)) {
            errors.photo1 =
              "Only image files (jpg, jpeg, png, gif) are allowed";
          }
        }
      }

      if (typeof values?.photo2 === "object") {
        const allowedExtensions = ["jpg", "jpeg", "png", "gif"]; // Add more if needed
        const fileExtension = values?.photo2?.name
          ?.split(".")
          .pop()
          .toLowerCase();
        // const fileSizeMB = values.photo.size / (1024 * 1024); // Convert bytes to megabytes

        if (!allowedExtensions.includes(fileExtension)) {
          errors.photo2 = "Only image files (jpg, jpeg, png, gif) are allowed";
        }
      }
      return errors;
    },
    onSubmit: (values) => {
      let formData = new FormData();
      formData?.append("id", certificateId);
      formData?.append("image", values?.photo1 ?? "");
      formData?.append("remarks", values?.remark);
      formData?.append("second_image", values?.photo2 ?? "");
      formData?.append("receipt_id", selectedId);
      UploadPhoto(formData).then((response) => {
        if (response?.data?.status_code === 200) {
          toast?.success("Success");
          handleClose();
          refetch();
          dispatch(updateConfig((state) => (state.clear = true)));
          const timeOut = setTimeout(() => {
            dispatch(updateConfig((state) => (state.clear = false)));
            clearTimeout(timeOut);
          }, 1000);
        } else {
          toast?.error("Something went wrong");
        }
      });
    },
  });
  useEffect(() => {
    if (editData && editData?.remark) {
      formik.setFieldValue("remark", editData?.remark);
    }
    //eslint-disable-next-line
  }, [editData?.remark]);

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleFirstImageChange = (e) => {
    const image = e?.target?.files[0];
    formik.setFieldValue("photo1", image);
    const file = image;
    const reader = new FileReader();
    reader.onloadend = () => {
      dispatch(
        updateConfig((state) => (state.editData.photo1.file = reader.result))
      );
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSecondImageChange = (e) => {
    const image = e?.target?.files[0];
    formik.setFieldValue("photo2", image);
    const file = image;
    const reader = new FileReader();
    reader.onloadend = () => {
      dispatch(
        updateConfig((state) => (state.editData.photo2.file = reader.result))
      );
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const fillData = (id) => {
    edittedData(id).then((response) => {
      if (response?.data?.status_code === 200) {
        let image1 = response?.data?.data?.image;
        let image2 = response?.data?.data?.second_image;
        dispatch(
          updateConfig((state) => {
            state.editData.photo1.id = image1?.id ?? "";
            state.editData.photo2.id = image2?.id ?? "";
            state.editData.photo1.file = image1?.url ?? "";
            state.editData.photo2.file = image2?.url ?? "";
            state.editData.remark = response?.data?.data?.remarks ?? "";
          })
        );
      } else {
        toast.error("Something went wrong");
      }
    });
  };
  const fillData2 = (id) => {
    edittedData2(id).then((response) => {
      if (response?.data?.status_code === 200) {
        let image1 = response?.data?.data?.image;
        let image2 = response?.data?.data?.second_image;
        dispatch(
          updateConfig((state) => {
            state.editData.photo1.id = image1?.id ?? "";
            state.editData.photo2.id = image2?.id ?? "";
            state.editData.photo1.file = image1?.url ?? "";
            state.editData.photo2.file = image2?.url ?? "";
            state.editData.remark = response?.data?.data?.remarks ?? "";
          })
        );
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const handleFirstImageDelete = () => {
    const data = {
      id: certificateId,
      image_type: 1,
    };
    if (formik?.values?.photo1) {
      formik?.setFieldValue("photo1", "");
      dispatch(updateConfig((state) => (state.editData.photo1.file = "")));
    } else {
      if (tableType === 1) {
        deletePhoto(data).then((response) => {
          if (response?.data?.status_code === 200) {
            fillData(certificateId);
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        data.receipt_id = selectedId;
        deletePhoto2(data).then((response) => {
          if (response?.data?.status_code === 200) {
            fillData2(certificateId);
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    }
  };

  const handleSecondImageDelete = () => {
    const data = {
      id: certificateId,
      image_type: 2,
    };
    if (formik?.values?.photo2) {
      formik?.setFieldValue("photo2", "");
      dispatch(updateConfig((state) => (state.editData.photo2.file = "")));
    } else {
      if (tableType === 1) {
        deletePhoto(data).then((response) => {
          if (response?.data?.status_code === 200) {
            fillData(certificateId);
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        data.receipt_id = selectedId;

        deletePhoto2(data).then((response) => {
          if (response?.data?.status_code === 200) {
            fillData2(certificateId);
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    }
  };

  return {
    formik,
    editData,
    getFieldError,
    handleClose,
    handleFirstImageChange,
    handleFirstImageDelete,
    handleSecondImageChange,
    handleSecondImageDelete,
  };
};

export default useUpload;
