import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  useGetViewReportsDataQuery,
  useUpdateViewReportTableHeadDataMutation,
} from "../../../../store/queries/MainReport";
import { updateConfig } from "../../../../store/slices/MainReport/mainReportSlice";
import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";
import { deleteReport } from "../api";
import Style from "../../report.module.scss";
// import moment from "moment";
const useShowReport = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  let reportId = localStorage.getItem("selectedReport");
  const dispatch = useDispatch();
  const selectedReportId = localStorage.getItem("selectedReport");
  const reportState = useSelector((state) => state.mainReport);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const {
    data: reportViewList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetViewReportsDataQuery({
    page_size: reportState?.currentPageSize,
    page: reportState?.currentPage,
    sort: reportState?.sortBy,
    sort_order: reportState?.sortOrder,
    search: reportState?.search,
    receipt_id: reportId,
  });
  const [updateViewReportFields] = useUpdateViewReportTableHeadDataMutation();

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (reportState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = reportState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      download: (field, data) => {
        return (
          <>
            <a href={data?.path} className={`pro-pnt`}>
              <span>
                <span className={`material-symbols-outlined x4 ${Style.icon}`}>
                  download
                </span>
                {"Download"}
              </span>
            </a>
          </>
        );
      },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedId = e?.[0];
      })
    );
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.selectedId = e?.[0];
        state.isEdit = true;
      })
    );
  };
  const handleDelete = async () => {
    let data = {
      id: reportState?.selectedId,
      receipt_id: selectedReportId,
    };
    const response = await deleteReport(data);
    if (response && response.status === 200) {
      toast.success(response?.data?.message);
      refetch();
    } else {
      toast.error(`Something went wrong !`);
    }
    handleDeleteModal();
    dispatch(
      updateConfig((state) => {
        state.clearSelection = true;
      })
    );
  };
  useEffect(() => {
    if (reportState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [reportState.clearSelection]);
  // const handleDownload = async (e) => {
  //   let data = reportViewList?.data?.filter((item) => item?.id === e?.[0]);
  //   downloadExcelFile(data?.[0]?.path).then((response) => {
  //     let dataType = response?.data?.type;
  //     let binaryData = [];
  //     binaryData.push(response?.data);
  //     let downloadLink = document.createElement("a");
  //     downloadLink.href = window.URL.createObjectURL(
  //       new Blob(binaryData, { type: dataType })
  //     );
  //     downloadLink.setAttribute(
  //       "download",
  //       `${moment(Date.now()).format("MM_DD_YYYY_")}${`excel`}.xlsx`
  //     );
  //     document.body.appendChild(downloadLink);
  //     downloadLink.click();
  //   });
  // };

  return {
    isLoading,
    isFetching,
    tableFields,
    reportState,
    showEditModal,
    reportViewList,
    paginationOptions,
    handleEditColumnsClick,
    updateViewReportFields,
    handleDeleteModal,
    handleClearClick,
    handlePagination,
    handleEditAction,
    // handleDownload,
    handlePageSize,
    handleDelete,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  };
};

export default useShowReport;
