import React from "react";
import Select from "react-select";
import useSubmitForm from "./useSubmitForm";
import { Button, Input } from "@wac-ui-dashboard/wac_component_library";

const SubmitForm = ({ handleClose }) => {
  const {
    formik,
    getFieldError,
    handleStatusChange,
    handleOrderTypeChange,
    specialData,
    isSpecialCase,
    setIsSpecialCase,
    addExtraService,
    setAddExtraService,
    handleServiceChange,
  } = useSubmitForm();

  const statusList = [
    { name: "Completed", id: 2 },
    { name: "Rejected", id: 4 },
    { name: "Marked", id: 5 },
  ];

  const orderTypeList = [
    { name: "Daily", id: 1 },
    { name: "Monthly", id: 2 },
  ];

  return (
    <div>
      {/* <div className="pro-d-flex">
        <div className="pro-check-box pro-py-2 pro-mb-2 pro-w-auto">
          <input
            type="checkbox"
            className="pro-check"
            id="specialCase"
            name="specialCase"
            checked={isSpecialCase}
            onChange={(e) => {
              setIsSpecialCase(!isSpecialCase);
              formik.setFieldValue("special_case", !isSpecialCase);
            }}
          />
          <label htmlFor="specialCase" className="pro-check-label">
            Special Case
          </label>
        </div>
      </div> */}
      <div className={`col-md-8`}>
                <div className="input-wrap pro-mb-4">
                  <label
                    htmlFor={`estimate_type`}
                    className="pro-font-sm pro-mb-1 pro-fw-medium"
                  >
                    Estimate Type
                  </label>
                  <Select
                    id={`estimate_type`}
                    name={`estimate_type`}
                    placeholder="Select"
                    className="pro-input lg"
                    classNamePrefix="pro-input"
                    options={specialData?.estimate_types}
                    getOptionLabel={(option) => option?.name}
                    getOptionValue={(option) => option?._id}
                    onBlur={formik?.handleBlur}
                    onChange={(value) => formik.setFieldValue("estimate_type", value?._id)}
                    value={specialData?.estimate_types?.find(
                      (opt) => opt._id === formik?.values?.estimate_type
                    )}
                  />
                </div>
              </div>
      {(formik?.values?.estimate_type === 3 || formik?.values?.estimate_type === 4) && (
        <>
          {formik?.values?.service?.map((service, index) => (
            <div key={index} className="row gx-2">
              <div className="col-md-4">
                <Input
                  label={"Service"}
                  type="text"
                  name={`service[${index}].service_id`}
                  value={specialData?.stock_services[index]?.name}
                  readOnly
                  className="pro-input lg"
                />
              </div>
              <div className="col-md-4">
                <div className="input-wrap pro-mb-4">
                  <label
                    htmlFor={`service[${index}].order_type`}
                    className="pro-font-sm pro-mb-1 pro-fw-medium"
                  >
                    Order Type
                  </label>
                  <Select
                    id={`service[${index}].order_type`}
                    name={`service[${index}].order_type`}
                    placeholder="Select"
                    className={`pro-input lg ${formik?.errors?.service?.[index]?.order_type && formik?.touched?.service?.[index]?.order_type && "error"}`}
                    classNamePrefix="pro-input"
                    options={orderTypeList}
                    getOptionLabel={(option) => option?.name}
                    getOptionValue={(option) => option?.id}
                    onBlur={formik?.handleBlur}
                    onChange={(value) => handleOrderTypeChange(value, index)}
                    value={orderTypeList.find(
                      (opt) =>
                        opt.id === formik?.values?.service[index]?.order_type
                    )}
                  />
                  {formik?.errors?.service?.[index]?.order_type && formik?.touched?.service?.[index]?.order_type && (
                    <span className="error-text">
                      {formik.errors.service[index].order_type}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="input-wrap pro-mb-4">
                  <label
                    htmlFor={`service[${index}].status`}
                    className="pro-font-sm pro-mb-1 pro-fw-medium"
                  >
                    Status
                  </label>
                  <Select
                    id={`service[${index}].status`}
                    name={`service[${index}].status`}
                    placeholder="Select"
                    className={`pro-input lg ${formik?.errors?.service?.[index]?.status && formik?.touched?.service?.[index]?.status && "error"}`}
                    classNamePrefix="pro-input"
                    options={statusList}
                    getOptionLabel={(option) => option?.name}
                    getOptionValue={(option) => option?.id}
                    isMulti={true}
                    onBlur={formik.handleBlur}
                    onChange={(value) =>
                      handleStatusChange(value, index, formik.setFieldValue)
                    }
                    value={formik?.values?.service[index]?.status.map(
                      (statusId) =>
                        statusList.find((opt) => opt.id === statusId)
                    )}
                  />
                  {formik?.errors?.service?.[index]?.status && formik?.touched?.service?.[index]?.status && (
                    <span className="error-text">
                      {formik.errors.service[index].status}
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))}
          {specialData?.item_services.length !== 0 && (
            <div className="pro-d-flex">
              <div className="pro-check-box pro-py-2 pro-w-auto pro-mb-2">
                <input
                  type="checkbox"
                  name="addExtraService"
                  id="addExtraService"
                  className="pro-check"
                  checked={addExtraService}
                  onChange={(e) => {
                    setAddExtraService(!addExtraService);
                    formik.setFieldValue(
                      "add_extra_service",
                      !addExtraService
                    );
                  }}
                />
                <label htmlFor="addExtraService" className="pro-check-label">
                  Add Extra Service
                </label>
              </div>
            </div>
          )}
          {addExtraService && (
            <div className="row gx-2">
              <div className={`col-md-4`}>
                <div className="input-wrap pro-mb-4">
                  <label
                    htmlFor={`item_services`}
                    className="pro-font-sm pro-mb-1 pro-fw-medium"
                  >
                    Services
                  </label>
                  <Select
                    id={`item_services`}
                    name={`item_services`}
                    placeholder="Select"
                    className="pro-input lg"
                    classNamePrefix="pro-input"
                    options={specialData?.item_services}
                    getOptionLabel={(option) => option?.name}
                    getOptionValue={(option) => option?._id}
                    onBlur={formik?.handleBlur}
                    onChange={(value) => handleServiceChange(value)}
                    value={specialData?.item_services.find(
                      (opt) => opt._id === formik?.values?.item_services
                    )}
                  />
                </div>
              </div>
              <div className={`col-md-4`}>
                <div className="input-wrap pro-mb-4">
                  <label
                    htmlFor={`service_order_type`}
                    className="pro-font-sm pro-mb-1 pro-fw-medium"
                  >
                    Order Type
                  </label>
                  <Select
                    id={`service_order_type`}
                    name={`service_order_type`}
                    placeholder="Select"
                    className={`pro-input lg ${formik?.errors?.service_order_type && formik?.touched?.service_order_type && "error"}`}
                    classNamePrefix="pro-input"
                    options={orderTypeList}
                    getOptionLabel={(option) => option?.name}
                    getOptionValue={(option) => option?.id}
                    onBlur={formik?.handleBlur}
                    onChange={(value) => handleOrderTypeChange(value, null)}
                    value={orderTypeList.find(
                      (opt) =>
                        opt.id === formik?.values?.service_order_type
                    )}
                  />
                  {formik?.errors?.service_order_type && formik?.touched?.service_order_type && (
                    <span className="error-text">
                      {formik.errors.service_order_type}
                    </span>
                  )}
                </div>
              </div>
              <div className={`col-md-4`}>
                <div className="input-wrap pro-mb-4">
                  <label
                    htmlFor={`service_status`}
                    className="pro-font-sm pro-mb-1 pro-fw-medium"
                  >
                    Status
                  </label>
                  <Select
                    id={`service_status`}
                    name={`service_status`}
                    placeholder="Select"
                    className={`pro-input lg ${formik?.errors?.service_status && formik?.touched?.service_status && "error"}`}
                    classNamePrefix="pro-input"
                    options={statusList}
                    getOptionLabel={(option) => option?.name}
                    getOptionValue={(option) => option?.id}
                    isMulti={true}
                    onBlur={formik.handleBlur}
                    onChange={(value) =>
                      handleStatusChange(value, null, formik.setFieldValue)
                    }
                    value={formik?.values?.service_status.map(
                      (statusId) =>
                        statusList.find((opt) => opt.id === statusId)
                    )}
                  />
                  {formik?.errors?.service_status && formik?.touched?.service_status && (
                    <span className="error-text">
                      {formik.errors.service_status}
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleClose}
          type="button"
        >
          Cancel
        </Button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default SubmitForm;
