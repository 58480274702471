import Style from "./orderCardListing.module.scss";
import OrderCard from "../../../Global/OrderCard";
import React from "react";

const QCCardListing = ({
  data,
  handleChange,
  lastElement,
  title,
  dataKey,
  isOverView = false,
}) => {
  const activeCard = { id: "", key: "" };


  return (
    <>
      <div className={`${Style.root_list}`}>
        <h6 className="pro-ttl pro-pb-3 pro-pt-4 pro-font-regular">{title}</h6>
        {/* <div className={`${Style.root_list_item}`}></div> */}
        <div className={`${Style.item_wrap}`}>
          {data?.map((item, index) => (
            <>
              {item?.[dataKey] === false ? (
                <div className={`${Style.root_list_item}`} key={index}></div>
              ) : item !== null && item[dataKey] === true ? (
                <React.Fragment key={index}>
                  <OrderCard
                    lastElement={
                      index === data?.data?.length - 10 ? lastElement : false
                    }
                    onClick={() => handleChange(item, title)}
                    title={item.gli_number}
                    subTitle={`${item.shop}`}
                    completed={item[dataKey]}
                    badgeText={item.priority}
                    activeCard={activeCard}
                    extraClassName={Style.board}
                    key={index}
                    isOverView={isOverView}
                    item={item}
                    supplier={item?.supplier}
                  />
                </React.Fragment>
              ) : (
                item !== null && (
                  <React.Fragment key={index}>
                    <OrderCard
                      lastElement={
                        index === data?.data?.length - 10 ? lastElement : false
                      }
                      onClick={() => handleChange(item, title)}
                      title={item.gli_number}
                      subTitle={`${item.shop}`}
                      curretNumber={item[dataKey]}
                      tottalNumber={item.total}
                      badgeText={item.priority}
                      activeCard={activeCard}
                      extraClassName={Style.board}
                      key={index}
                      isOverView={isOverView}
                      item={item}
                      supplier={item?.supplier}
                    />
                  </React.Fragment>
                )
              )}
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default QCCardListing;
