import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useForm2 from "./useForm2";
import Select from "react-select";

const Form2 = ({ setFormValues, handleConfirm, statusData }) => {
  const {
    formik,
    getFieldError,
    handleCVDPassDNOChange,
    handleRFTDNOChange,
    handleCVDDNOChange,
    handleHTPTDNOChange,
  } = useForm2({
    setFormValues,
    handleConfirm,
  });
  return (
    <div className="row">
      <div
        className={`col-12 pro-d-flex pro-justify-start pro-gap-5 ${"offcanvas-footer-sticky-btn"}`}
      >
        <div className="input-wrap pro-mb-4">
          <label
            htmlFor="status2"
            className="pro-font-sm pro-mb-1 pro-fw-medium"
          >
            Status
          </label>
          <Select
            id="status2"
            name="status2"
            placeholder={"Select"}
            className={`pro-input lg  ${getFieldError("status2") && " error"}`}
            classNamePrefix="pro-input"
            options={statusData ?? []}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
            onBlur={formik.handleBlur("status2")}
            isMulti={false}
            onChange={(value) => {
              formik?.setFieldValue("status2", value?.id || null);
            }}
            menuPlacement="auto"
          />
          {getFieldError("status2") && (
            <span className="error-text">{getFieldError("status2")}</span>
          )}
        </div>
        <Input
          label={"Remark"}
          type="text"
          id="remark"
          name="remark"
          className={`pro-input lg ${getFieldError("remark") && " error"}`}
          {...formik.getFieldProps("remark")}
          error={getFieldError("remark")}
          errorMessage={getFieldError("remark")}
        />
      </div>
      {/* eslint-disable-next-line eqeqeq */}
      <>
        <div className="col-6">
          <div>
            <h6>RFT</h6>
            <Input
              label={"Weight"}
              type="text"
              id="rft_weight"
              name="rft_weight"
              className={`pro-input lg ${
                getFieldError("rft_weight") && " error"
              }`}
              {...formik.getFieldProps("rft_weight")}
              error={getFieldError("rft_weight")}
              errorMessage={getFieldError("rft_weight")}
            />
            <Input
              label={"Diamond/No"}
              type="text"
              id="rft_d_no"
              name="rft_d_no"
              className={`pro-input lg ${
                getFieldError("rft_d_no") && " error"
              }`}
              {...formik.getFieldProps("rft_d_no")}
              error={getFieldError("rft_d_no")}
              errorMessage={getFieldError("rft_d_no")}
              onChange={handleRFTDNOChange}
            />
            <Input
              label={"Percentage"}
              type="text"
              id="rft_percentage"
              name="rft_percentage"
              className={`pro-input lg ${
                getFieldError("rft_percentage") && " error"
              }`}
              {...formik.getFieldProps("rft_percentage")}
              error={getFieldError("rft_percentage")}
              errorMessage={getFieldError("rft_percentage")}
            />
          </div>
          <div>
            <h6>CVD</h6>
            <Input
              label={"Weight"}
              type="text"
              id="cvd_weight"
              name="cvd_weight"
              className={`pro-input lg ${
                getFieldError("cvd_weight") && " error"
              }`}
              {...formik.getFieldProps("cvd_weight")}
              error={getFieldError("cvd_weight")}
              errorMessage={getFieldError("cvd_weight")}
            />
            <Input
              label={"Diamond/No"}
              type="text"
              id="cvd_d_no"
              name="cvd_d_no"
              className={`pro-input lg ${
                getFieldError("cvd_d_no") && " error"
              }`}
              {...formik.getFieldProps("cvd_d_no")}
              error={getFieldError("cvd_d_no")}
              errorMessage={getFieldError("cvd_d_no")}
              onChange={handleCVDDNOChange}
            />
            <Input
              label={"Percentage"}
              type="text"
              id="cvd_percentage"
              name="cvd_percentage"
              className={`pro-input lg ${
                getFieldError("cvd_percentage") && " error"
              }`}
              {...formik.getFieldProps("cvd_percentage")}
              error={getFieldError("cvd_percentage")}
              errorMessage={getFieldError("cvd_percentage")}
            />
          </div>
        </div>
        <div className="col-6">
          <div>
            <h6>HPHT</h6>
            <Input
              label={"Weight"}
              type="text"
              id="htpt_weight"
              name="htpt_weight"
              className={`pro-input lg ${
                getFieldError("htpt_weight") && " error"
              }`}
              {...formik.getFieldProps("htpt_weight")}
              error={getFieldError("htpt_weight")}
              errorMessage={getFieldError("htpt_weight")}
            />
            <Input
              label={"Diamond/No"}
              type="text"
              id="htpt_d_no"
              name="htpt_d_no"
              className={`pro-input lg ${
                getFieldError("htpt_d_no") && " error"
              }`}
              {...formik.getFieldProps("htpt_d_no")}
              error={getFieldError("htpt_d_no")}
              errorMessage={getFieldError("htpt_d_no")}
              onChange={handleHTPTDNOChange}
            />
            <Input
              label={"Percentage"}
              type="text"
              id="htpt_percentage"
              name="htpt_percentage"
              className={`pro-input lg ${
                getFieldError("htpt_percentage") && " error"
              }`}
              {...formik.getFieldProps("htpt_percentage")}
              error={getFieldError("htpt_percentage")}
              errorMessage={getFieldError("htpt_percentage")}
            />
          </div>
          <div>
            <h6>CVD Pass</h6>
            <Input
              label={"Weight"}
              type="text"
              id="cvd_pass_weight"
              name="cvd_pass_weight"
              className={`pro-input lg ${
                getFieldError("cvd_pass_weight") && " error"
              }`}
              {...formik.getFieldProps("cvd_pass_weight")}
              error={getFieldError("cvd_pass_weight")}
              errorMessage={getFieldError("cvd_pass_weight")}
            />
            <Input
              label={"Diamond/No"}
              type="text"
              id="cvd_pass_d_no"
              name="cvd_pass_d_no"
              className={`pro-input lg ${
                getFieldError("cvd_pass_d_no") && " error"
              }`}
              {...formik.getFieldProps("cvd_pass_d_no")}
              error={getFieldError("cvd_pass_d_no")}
              errorMessage={getFieldError("cvd_pass_d_no")}
              onChange={handleCVDPassDNOChange}
            />
            <Input
              label={"Percentage"}
              type="text"
              id="cvd_pass_percentage"
              name="cvd_pass_percentage"
              className={`pro-input lg ${
                getFieldError("cvd_pass_percentage") && " error"
              }`}
              {...formik.getFieldProps("cvd_pass_percentage")}
              error={getFieldError("cvd_pass_percentage")}
              errorMessage={getFieldError("cvd_pass_percentage")}
            />
          </div>
        </div>
      </>

      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => formik?.handleReset()}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={formik?.handleSubmit}
          type="submit"
        >
          {"Submit"}
        </Button>
      </div>
    </div>
  );
};

export default Form2;
