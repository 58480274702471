import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useContact from "./useContact";
// import Style from "../addShop.module.scss";
// import Item from "../Item";

const Contact = ({ formik, getFieldError, checkError, basicData }) => {
  const {
    countryCodeOptions,
    isEdit,
    showPassword,
    handleCloseModal,
    setSubmit,
    // handleImageChange,
    // handleAddItemClick,
    handleShowPassword,
  } = useContact(formik);
  // let ids = [];
  return (
    <div className="row">
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Parent Shop
        </label>
        <Select
          id="parent_shop"
          name="parent_shop"
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("parent_shop") && " error"
          }`}
          classNamePrefix="pro-input"
          options={basicData?.parent_shop ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?._id}
          value={formik?.values?.parent_shop}
          onBlur={formik.handleBlur("parent_shop")}
          onChange={(value) =>
            formik?.setFieldValue("parent_shop", value || null)
          }
          menuPlacement="auto"
        />
        {getFieldError("parent_shop") && (
          <span className="error-text">{getFieldError("parent_shop")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Add As
        </label>
        <Select
          id="category"
          name="category"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("category") && " error"}`}
          classNamePrefix="pro-input"
          options={basicData?.add_as ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          value={formik?.values?.category}
          onBlur={formik.handleBlur("category")}
          onChange={(value) => formik?.setFieldValue("category", value || null)}
          menuPlacement="auto"
        />
        {getFieldError("category") && (
          <span className="error-text">{getFieldError("category")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Reset Prefix
        </label>
        <Select
          id="reset_prefix"
          name="reset_prefix"
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("reset_prefix") && " error"
          }`}
          classNamePrefix="pro-input"
          options={basicData?.reset_prefix ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.id}
          value={formik?.values?.reset_prefix}
          onBlur={formik.handleBlur("reset_prefix")}
          onChange={(value) =>
            formik?.setFieldValue("reset_prefix", value || null)
          }
          menuPlacement="auto"
        />
        {getFieldError("reset_prefix") && (
          <span className="error-text">{getFieldError("reset_prefix")}</span>
        )}
      </div>
      {/* <div className={Style.address}>
        {formik?.values?.items?.map((value, index) => {
          ids = [...ids, value?.item_type?._id];
          return (
            <Item
              key={index}
              itmIndex={index}
              formik={formik}
              getFieldError={getFieldError}
              basicData={basicData}
              handleImageChange={handleImageChange}
              values={value}
              filterIds={ids}
              newValues={formik?.values?.items}
            />
          );
        })}
        {formik?.values?.items.length !== basicData?.item_type.length && (
          <Button
            className={`${Style.add_btn} pro-btn-primary lg pro`}
            onClick={handleAddItemClick}
            type="Button"
          >
            {` Add Another`}
          </Button>
        )}
      </div> */}
      <Input
        label={"LOT Number"}
        type="text"
        id="serial_number"
        name="serial_number"
        className={`pro-input lg ${getFieldError("serial_number") && " error"}`}
        {...formik.getFieldProps("serial_number")}
        error={getFieldError("serial_number")}
        errorMessage={getFieldError("serial_number")}
        disabled={isEdit}
        placeholder={isEdit ? "Serial number can't be changed" : ""}
      />
      <Input
        label={"Secondary Name"}
        type="text"
        id="sec_name"
        name="sec_name"
        className={`pro-input lg ${getFieldError("sec_name") && " error"}`}
        {...formik.getFieldProps("sec_name")}
        error={getFieldError("sec_name")}
        errorMessage={getFieldError("sec_name")}
      />
      <div className="input-wrap pro-mb-4 ">
        <div className="input-country-wrap ">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Secondary Contact Number
          </label>
          <div className="input-main-wrap verify-btn-wrap">
            <div className="code-wrap pro-mb-4 ">
              <Select
                id="sec_country_code"
                options={countryCodeOptions}
                getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => `${option.value}`}
                value={formik?.values?.sec_country_code}
                classNamePrefix="pro-input"
                onBlur={formik.handleBlur("sec_country_code")}
                onChange={(value) =>
                  formik.setFieldValue("sec_country_code", value)
                }
                menuPlacement="auto"
              ></Select>
            </div>
            <input
              type="text"
              id="sec_number"
              name="sec_number"
              className={`pro-input lg ${
                formik.errors.sec_number &&
                formik.touched.sec_number &&
                " error"
              }`}
              {...formik.getFieldProps("sec_number")}
            />
          </div>
          {formik.errors.sec_number && formik.touched.sec_number && (
            <>
              <span className="error-text">{formik.errors.sec_number}</span>
            </>
          )}
        </div>
      </div>
      <Input
        label={"Secondary Email"}
        type="text"
        id="sec_email"
        name="sec_email"
        className={`pro-input lg ${getFieldError("sec_email") && " error"}`}
        {...formik.getFieldProps("sec_email")}
        error={getFieldError("sec_email")}
        errorMessage={getFieldError("sec_email")}
      />
      <div className="col-12 pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Secondary Address
          </label>
          <textarea
            id="sec_address"
            rows={3}
            name="sec_address"
            className={`pro-input lg ${
              formik.errors.sec_address &&
              formik.touched.sec_address &&
              " error"
            }`}
            value={formik?.values?.sec_address}
            onBlur={formik?.handleBlur("sec_address")}
            onChange={(e) =>
              formik.setFieldValue("sec_address", e.target.value)
            }
          ></textarea>
          {formik.touched.sec_address && formik.errors.sec_address && (
            <span className="error-text">{formik.errors.sec_address}</span>
          )}
        </div>
      </div>
      <Input
        label={"Username"}
        type="text"
        id="user_name"
        name="user_name"
        className={`pro-input lg ${getFieldError("user_name") && " error"}`}
        {...formik.getFieldProps("user_name")}
        error={getFieldError("user_name")}
        errorMessage={getFieldError("user_name")}
        disabled={isEdit}
        placeholder={isEdit ? "User name can't be changed" : ""}
      />
      {!isEdit && (
        <div className="col-12 p-re">
          <Input
            label={"Password"}
            type="text"
            id="password"
            name="password"
            className={`pro-input lg ${getFieldError("password") && " error"}`}
            {...formik.getFieldProps("password")}
            error={getFieldError("password")}
            errorMessage={getFieldError("password")}
            disabled={isEdit}
            placeholder={isEdit ? "Password can't be changed" : ""}
          />
          {!isEdit && (
            <>
              {showPassword ? (
                <button
                  className={`hide-icon`}
                  onClick={(e) => handleShowPassword(e)}
                >
                  <span className="material-symbols-outlined">
                    {" "}
                    visibility_off{" "}
                  </span>
                </button>
              ) : (
                <button
                  className={`hide-icon`}
                  onClick={(e) => handleShowPassword(e)}
                >
                  <span className="material-symbols-outlined">
                    {" "}
                    visibility{" "}
                  </span>
                </button>
              )}
            </>
          )}
        </div>
      )}
      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={() => {
            setSubmit();
            checkError();
          }}
        >
          {isEdit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default Contact;
