import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddForm from "./useAddForm";
import Select from "react-select";

const AddForm = (refetch) => {
  const { formik, basicData, getFieldError, handleCloseModal, queryState } =
    useAddForm(refetch);

  return (
    <div>
      <div className={`pro-p-4`}>
        <Input
          label={"Name"}
          type="text"
          id="name"
          name="name"
          className={`pro-input lg ${getFieldError("name") && " error"}`}
          {...formik.getFieldProps("name")}
          error={getFieldError("name")}
          errorMessage={getFieldError("name")}
        />
        {
          queryState?.itemData?.format_type === 1 && queryState?.isEdit ?
            <>
            </>
            :
            <div className="input-wrap pro-mb-4">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Shop
              </label>
              <Select
                id="shop"
                name="shop"
                placeholder={"Select"}
                className={`pro-input lg  ${getFieldError("shop") && " error"}`}
                classNamePrefix="pro-input"
                options={basicData?.shop_list ?? []}
                getOptionLabel={(option) => option?.name}
                getOptionValue={(option) => option?._id}
                value={formik?.values?.shop}
                onBlur={formik.handleBlur("shop")}
                onChange={(value) => formik?.setFieldValue("shop", value || null)}
                menuPlacement="auto"
              />
              {getFieldError("shop") && (
                <span className="error-text">{getFieldError("shop")}</span>
              )}
            </div>
        }

        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Engraving Fields
          </label>
          <Select
            id="engraving"
            name="engraving"
            placeholder={"Select"}
            className={`pro-input lg  ${getFieldError("engraving") && " error"
              }`}
            classNamePrefix="pro-input"
            options={basicData?.field_list ?? []}
            getOptionLabel={(option) => option?.field_name}
            getOptionValue={(option) => option?._id}
            value={formik?.values?.engraving}
            onBlur={formik.handleBlur("engraving")}
            onChange={(value) =>
              formik?.setFieldValue("engraving", value || null)
            }
            isMulti={true}
            menuPlacement="auto"
          />
          {getFieldError("engraving") && (
            <span className="error-text">{getFieldError("engraving")}</span>
          )}
        </div>

        <div
          className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
        >
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>

          <Button
            className={"pro-btn-primary lg pro-ms-3"}
            type="submit"
            onClick={formik.handleSubmit}
          >
            {queryState?.isEdit ? "Update" : "Create"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddForm;
