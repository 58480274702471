import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useBulkUpload from "./useBulkUpload";
import FileUpload from "../../../../Global/FileUpload";
import UploadPhoto from "../../../../Global/UploadPhoto";
import Select from "react-select";


const BulkUpload = ({ refetch, tableType, values, errors }) => {
  const {
    formik,
    editData,
    handleClose,
    getFieldError,
    handleAssetsImagesChange,
    handleAssetsImagesDelete,
    typeList
  } = useBulkUpload({
    refetch,
    tableType,
  });

  return (
    <div className="input-wrap pro-mb-4">
      <div className="pro-d-flex pro-gap-3 pro-w-100 pro-justify-center">
        
        <div className="pro-mb-4 pro-w-100">
        <UploadPhoto
          previews={values?.assetsImages}
          handleChange={handleAssetsImagesChange}
          title={""}
          handleDeleteClick={handleAssetsImagesDelete}
          mutiple
          accept={".png,.jpeg,.jpg"}
          addText={"Add Images"}
          isError={errors?.assetsImages ? true : false}
          errorMsg={errors?.assetsImages}
        />
      </div>
      </div>
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Select Type *
          </label>

          <Select
            id="image_type"
            isClearable={false}
            placeholder={"Select"}
            className={`pro-input lg  ${
              formik.touched.image_type && formik.errors.image_type && " error"
            }`}
            classNamePrefix="pro-input"
            options={typeList}
            getOptionValue={(option) => option?.value}
            getOptionLabel={(option) => option?.label}
            value={typeList?.filter((m) => formik.values.image_type === m?.value)}
            onBlur={formik.handleBlur("image_type")}
            onChange={(selectedOption) =>
              {
                formik.setFieldValue("image_type", selectedOption?.value)
              }
            }
          />
          {formik.touched?.image_type && formik.errors?.image_type && (
            <div className="error-text">{formik.errors?.image_type}</div>
          )}
        </div>

      <div className="input-wrap">
        <Input
          label={"Remark"}
          type="text"
          id="remark"
          name="remark"
          className={`pro-input lg ${getFieldError("remark") && " error"}`}
          {...formik.getFieldProps("remark")}
          error={getFieldError("remark")}
          errorMessage={getFieldError("remark")}
        />
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end pro-mt-5  offcanvas-footer-sticky-btn `}
      >
        <Button className={"pro-btn-link lg pro-px-5"} onClick={handleClose}>
          Cancel
        </Button>
        <Button
					className={`pro-btn-primary lg pro-ms-3 ${formik?.isSubmitting ? "loading" : ""}`}
					type="submit"
          disabled={formik?.isSubmitting ? true : false}
					onClick={formik.handleSubmit}
				>
          {"Submit"}
        </Button>
      </div>
    </div>
  );
};

export default BulkUpload;
