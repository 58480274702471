import axios from "axios";
const apiUrls = {
  WEBC: process.env.REACT_APP_APIURL,
  UAT: process.env.REACT_APP_UAT_APIURL,
  LIVE: process.env.REACT_APP_LIVE_APIURL,
};

const BASE_URL = apiUrls[process.env.REACT_APP_TYPE] || "invalid base uri";

let token = "";

export const getAxiosInstance = async () => {
  try {
    token = localStorage.getItem("USER_ACCESS_TOKEN");
  } catch (e) {
  } finally {
    const instance = axios.create({
      baseURL: BASE_URL,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    instance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response) {
          if (error?.response?.data?.status_code === 401) {
            localStorage.clear("USER_ACCESS_TOKEN");
            localStorage.clear("dashboard_permissions");
            localStorage.clear("user_type");
            localStorage.clear("slug");
            localStorage.clear("invoice_id");
            localStorage.clear("invoice");
            localStorage.clear("billing");
            window.location.href = "/login";
          }
        }
        return Promise.reject(error);
      }
    );

    return instance;
  }
};
