import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useAddress from "./useAddress";

const Address = ({ formik, getFieldError, formData }) => {
  const { handleCloseModal, handleNext } = useAddress();
  return (
    <div className="row">
      <div>
        <Input
          label={"Address line 1"}
          type="text"
          id="address1"
          name="address1"
          className={`pro-input lg ${getFieldError("address1") && " error"}`}
          {...formik.getFieldProps("address1")}
          error={getFieldError("address1")}
          errorMessage={getFieldError("address1")}
        />
        <Input
          label={"Address line 2"}
          type="text"
          id="address2"
          name="address2"
          className={`pro-input lg ${getFieldError("address2") && " error"}`}
          {...formik.getFieldProps("address2")}
          error={getFieldError("address2")}
          errorMessage={getFieldError("address2")}
        />
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Country
          </label>
          <Select
            id="country"
            name="country"
            placeholder={"Select"}
            className={`pro-input lg  ${getFieldError("country") && " error"}`}
            classNamePrefix="pro-input"
            options={formData?.country_list}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
            value={formik?.values?.country}
            onBlur={formik.handleBlur("country")}
            onChange={(value) =>
              formik?.setFieldValue("country", value || null)
            }
            menuPlacement="auto"
          />
          {getFieldError("country") && (
            <span className="error-text">{getFieldError("country")}</span>
          )}
        </div>
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            State
          </label>
          <Select
            id="state"
            name="state"
            placeholder={"Select"}
            className={`pro-input lg  ${getFieldError("state") && " error"}`}
            classNamePrefix="pro-input"
            options={formData?.state_list.filter(item =>(item.country_id === formik?.values?.country?.id))}
            value={formik?.values?.state}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
            onBlur={formik.handleBlur("state")}
            onChange={(value) => formik?.setFieldValue("state", value || null)}
            menuPlacement="auto"
          />
          {getFieldError("state") && (
            <span className="error-text">{getFieldError("state")}</span>
          )}
        </div>
        <Input
          label={"City"}
          type="text"
          id="city"
          name="city"
          className={`pro-input lg ${getFieldError("city") && " error"}`}
          {...formik.getFieldProps("city")}
          error={getFieldError("city")}
          errorMessage={getFieldError("city")}
        />
        <Input
          label={"Pin code"}
          type="text"
          id="pin_code"
          name="pin_code"
          className={`pro-input lg ${getFieldError("pin_code") && " error"}`}
          {...formik.getFieldProps("pin_code")}
          error={getFieldError("pin_code")}
          errorMessage={getFieldError("pin_code")}
        />
      </div>
      <div>
        <Input
          label={"Bank Name"}
          type="text"
          id="bank_name"
          name="bank_name"
          className={`pro-input lg ${getFieldError("bank_name") && " error"}`}
          {...formik.getFieldProps("bank_name")}
          error={getFieldError("bank_name")}
          errorMessage={getFieldError("bank_name")}
        />
        <Input
          label={"Account Number"}
          type="text"
          id="ac_no"
          name="ac_no"
          className={`pro-input lg ${getFieldError("ac_no") && " error"}`}
          {...formik.getFieldProps("ac_no")}
          error={getFieldError("ac_no")}
          errorMessage={getFieldError("ac_no")}
        />
        <Input
          label={"IFSC Code"}
          type="text"
          id="ifsc"
          name="ifsc"
          className={`pro-input lg ${getFieldError("ifsc") && " error"}`}
          {...formik.getFieldProps("ifsc")}
          error={getFieldError("ifsc")}
          errorMessage={getFieldError("ifsc")}
        />
        <Input
          label={"Bank Address"}
          type="text"
          id="bank_address"
          name="bank_address"
          className={`pro-input lg ${
            getFieldError("bank_address") && " error"
          }`}
          {...formik.getFieldProps("bank_address")}
          error={getFieldError("bank_address")}
          errorMessage={getFieldError("bank_address")}
        />
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button className={"pro-btn-primary lg pro-ms-3"} onClick={handleNext}>
          {"Next"}
        </Button>
      </div>
    </div>
  );
};

export default Address;
