import { getAxiosInstance } from "../../../api";

export const getFormData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `/qc/recheck/fields?recheck_item_details_id=${body?.item_details_id}&item_type_id=${body?.item_type_id}&process_slug=${body?.process_slug}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getExcelFormData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `/qc/recheck/excel-qc?recheck_item_details_id=${body?.item_details_id}&item_type_id=${body?.item_type_id}&process_slug=${body?.process_slug}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getExcelData = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/qc/excel-data?item_details_id=${body}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const submitForm = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/qc/recheck/save`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const updateForm = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/qc/recheck/save-updated`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getReport = async (id, slug) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `/qc/recheck/edit?recheck_item_details_id=${id}&process_slug=${slug}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};
export const passRecheckAll = async (params) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(
      `/employee-dashboard/recheck/bulk-status-complete`,
      params
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const moreDetailsRecheck = async (body) => {
  try {
    const api = await getAxiosInstance();
    const res = await api.post(`/qc/recheck/multi-color-clarity`, body);
    return res;
  } catch (error) {
    return error.response.data;
  }
};
