import { useFormik } from "formik";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getExcelFormData, updateForm } from "../../api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../store/slices/QC/QCSlice";

const useVersionTwo = () => {
  const {
    item,
    excelEditedFormData,
    dropdownValues,
    itemID,
    data,
    activeId,
    cnfModal,
    certificateID,
    excelFormData,
    certificateNumber,
  } = useSelector((state) => state.qc);
  const slug = localStorage?.getItem("slug");
  const dispatch = useDispatch();
  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeId]);

  const currentTab = data?.filter(
    (value) => value?.name === `Version ${activeId}`
  );

  const handleReset = () => {
    const data =
      excelEditedFormData?.length > 0
        ? excelEditedFormData?.[
            !currentTab?.[0]?.disabled
              ? excelEditedFormData?.length - 1
              : activeId - 2
          ]?.data
        : excelFormData?.data;
    data?.map((value) => formik?.setFieldValue(`${value?.slug}`, value?.value));

    const sideData =
      excelEditedFormData?.length > 0
        ? excelEditedFormData?.[
            !currentTab?.[0]?.disabled
              ? excelEditedFormData?.length - 1
              : activeId - 2
          ]?.side_stone
        : excelFormData?.side_stone;

    const centerData =
      excelEditedFormData?.length > 0
        ? excelEditedFormData?.[
            !currentTab?.[0]?.disabled
              ? excelEditedFormData?.length - 1
              : activeId - 2
          ]?.center_stone
        : excelFormData?.center_stone;

    sideData?.map((value) =>
      formik?.setFieldValue(`side_stone_${value?.slug}`, value?.value)
    );
    centerData?.map((value) =>
      formik?.setFieldValue(`center_stone_${value?.slug}`, value?.value)
    );
  };

  const formik = useFormik({
    initialValues: {},
    // validate: (values) => {
    //   let errors = {};
    //   Object.keys(values || {}).forEach((key) => {
    //     if (!values[key]) {
    //       errors[`${key}`] = `This field is required`;
    //     }
    //   });
    //   return errors;
    // },
    onSubmit: (values) => {
      let data = {
        process_slug: slug,
        item_details_id: certificateID,
        ...values,
      };
      let formData = new FormData();
      for (let key in data) {
        if (key.startsWith("center_stone")) {
          const formattedKey = key.replace("center_stone_", ""); // Remove the prefix
          formData.append(`center_stone[${formattedKey}]`, data[key]);
        } else if (key.startsWith("side_stone")) {
          const formattedKey = key.replace("side_stone_", ""); // Remove the prefix
          formData.append(`side_stone[${formattedKey}]`, data[key]);
        } else {
          // For other keys, append them as they are
          formData.append(key, data[key]);
        }
      }
      updateForm(formData).then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Data updated ");
          dispatch(
            updateConfig((state) => {
              state.certificateID = item?._id;
              state.itemID = item?.gli_number;
              state.showCompare = false;
            })
          );
          let data = {
            item_details_id: item?._id,
            item_type_id: item?.item_type_id,
            process_slug: slug,
          };
          getExcelFormData(data)
            .then((response) => {
              if (response?.data?.status_code === 200) {
                // handleReset();
                handleModal();
                if (response?.data?.data?.updated_values.length !== 0) {
                  const newData = response.data.data.updated_values.map(
                    (_, index) => ({
                      name: `Version ${index + 2}`,
                    })
                  );
                  dispatch(
                    updateConfig((state) => {
                      state.data = [{ name: "Version 1" }, ...newData].map(
                        (version) => ({
                          ...version,
                          disabled: true, // Set the 'disabled' property to true for all versions
                        })
                      );
                    })
                  );
                }
                dispatch(
                  updateConfig((state) => {
                    state.excelFormData = response?.data?.data?.graded_values;
                    state.excelEditedFormData =
                      response?.data?.data?.updated_values.length === 0
                        ? (state.excelFormData =
                            response?.data?.data?.graded_values)
                        : response?.data?.data?.updated_values;
                    state.showValues = true;
                    state.showForm = false;
                  })
                );
              }
            })
            .catch((error) => toast.error(error));
        } else {
          toast.error("Something went wrong");
        }
      });
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleModal = () => {
    dispatch(updateConfig((state) => (state.cnfModal = !state.cnfModal)));
  };

  const handleYes = () => {
    dispatch(
      updateConfig((state) => {
        state.showVersionTwoTable = true;
        state.cnfModal = false;
      })
    );
  };

  return {
    formik,
    dropdownValues,
    excelEditedFormData,
    excelFormData,
    itemID,
    data,
    activeId,
    certificateNumber,
    currentTab,
    cnfModal,
    handleYes,
    handleModal,
    getFieldError,
    handleReset,
  };
};

export default useVersionTwo;
