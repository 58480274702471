import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 20,
  sortBy: "",
  sortOrder: "",
  search: "",
  showDeleteModal: false,
  clearSelection: false,
  showAddSourceModal: false,
  selectedSource: "",
  isEdit: false,
  sourceData: "",
};

const sourceSlice = createSlice({
  name: "source",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = sourceSlice.actions;

export default sourceSlice.reducer;
