import QRCode from "react-qr-code";
import Barcode from "react-barcode";
import { useCardPrint } from "./useCardPrint";
export const StaticHtmlTemplate = ({ value }) => {
  const { renderText, checkSyleCode } = useCardPrint();

  const arrayItems = value?.data?.graded_values;
  const isLargeArray = arrayItems?.length > 8;

  const topArray = arrayItems?.filter((item) => parseInt(item?.section) === 1);
  const middleArray = arrayItems?.filter(
    (item) => parseInt(item?.section) === 2
  );
  const bottomArray = arrayItems?.filter(
    (item) => parseInt(item?.section) === 3
  );

  //to change the length of key
  const maxKeyLength = arrayItems?.reduce(
    (max, str) => Math.max(max, str?.text?.length),
    0
  );
  const newKeyValue = {
    ...styles.key,
    width: maxKeyLength > 14 ? 78 : 73,
  };
  //end to change the length of key

  return (
    <>
      <div
        style={{
          ...styles.outer_wrapper,
        }}
        className="printable-item"
      >
        <img
          style={styles.bg_image}
          src={value?.background_image}
          alt="background images"
        />
        <div style={styles.header_wrap}>
          <img
            style={styles.header_img}
            src={value?.front_top_image || ""}
            alt="Header"
          />
          {parseInt(value?.qr_and_image_format) === 2 && value?.qr && (
            <span style={styles.qr_wrap}>
              <QRCode
                value={value?.qr}
                width={39.37}
                height={39.37}
                style={styles.qr_code}
              />
            </span>
          )}
        </div>
        <div style={styles.body}>
          <div
            style={{
              minHeight: middleArray?.length <= 6 ? 45 : "auto",
            }}
          >
            <div style={styles.row}>
              <p
                style={{
                  ...newKeyValue,
                  lineHeight: arrayItems?.length <= 6 ? "1.3" : "1.1",
                }}
              >
                {value?.bold === 1 ? (
                  <b style={{ fontWeight: "600" }}>SUMMARY NO</b>
                ) : (
                  "SUMMARY NO"
                )}
              </p>
              <p
                style={{
                  ...styles.separate,
                  lineHeight: arrayItems?.length <= 6 ? "1.3" : "1.1",
                }}
              >
                :
              </p>
              <p
                style={{
                  ...styles.value,
                  lineHeight: arrayItems?.length <= 6 ? "1.3" : "1.1",
                }}
              >
                {value?.bold === 1 ? (
                  <b style={{ fontWeight: "600" }}>
                    {value?.certificate_number}
                  </b>
                ) : (
                  value?.certificate_number
                )}
              </p>
            </div>
            {topArray?.map((item, index) => (
              <div style={styles.row} key={index}>
                <p
                  style={{
                    ...newKeyValue,
                    lineHeight: arrayItems?.length <= 6 ? "1.3" : "1.1",
                  }}
                >
                  {item?.bold === 1 ? (
                    <b
                      style={{
                        fontSize: item?.text?.length >= 12 ? 6.8 : "inherit",
                        fontWeight: "600",
                      }}
                    >
                      {renderText(item?.text, item?.key_font_case)}
                    </b>
                  ) : (
                    renderText(item?.text, item?.key_font_case)
                  )}
                </p>
                <p
                  style={{
                    ...styles.separate,
                    lineHeight: arrayItems?.length <= 6 ? "1.3" : "1.1",
                  }}
                >
                  :
                </p>
                <p
                  style={{
                    ...styles.value,
                    lineHeight: arrayItems?.length <= 6 ? "1.3" : "1.1",
                  }}
                >
                  {item?.bold === 1 ? (
                    <b
                      style={{
                        fontSize: item?.text?.length >= 12 ? 6.8 : "inherit",
                        fontWeight: "600",
                      }}
                    >
                      {renderText(item?.value, item?.value_font_case)}
                    </b>
                  ) : (
                    renderText(item?.value, item?.value_font_case)
                  )}
                </p>
              </div>
            ))}
          </div>
          <div style={styles.middle_wrap}>
            <div style={styles.content_wrap}>
              {middleArray?.map((item, index) => (
                <div style={styles.row} key={index}>
                  <p
                    style={{
                      ...newKeyValue,
                      lineHeight:
                        item?.bold === 1
                          ? "1.06"
                          : isLargeArray
                          ? "1.06"
                          : "1.6",
                    }}
                  >
                    {item?.bold === 1 ? (
                      <b
                        style={{
                          fontSize: item?.text?.length >= 12 ? 6.8 : "inherit",
                          fontWeight: "600",
                        }}
                      >
                        {renderText(item?.text, item?.key_font_case)}
                      </b>
                    ) : (
                      renderText(item?.text, item?.key_font_case)
                    )}
                  </p>
                  <p
                    style={{
                      ...styles.separate,
                      lineHeight:
                        item?.bold === 1
                          ? "1.06"
                          : isLargeArray
                          ? "1.06"
                          : "1.6",
                    }}
                  >
                    :
                  </p>
                  <p
                    style={{
                      ...styles.value,
                      lineHeight:
                        item?.bold === 1
                          ? "1.06"
                          : isLargeArray
                          ? "1.06"
                          : "1.6",
                    }}
                  >
                    {item?.bold === 1 ? (
                      <b
                        style={{
                          fontSize: item?.text?.length >= 12 ? 6.8 : "inherit",
                          fontWeight: "600",
                        }}
                      >
                        {renderText(item?.value, item?.value_font_case)}
                      </b>
                    ) : (
                      renderText(item?.value, item?.value_font_case)
                    )}
                  </p>
                </div>
              ))}
            </div>
            <div style={styles.img_wrap}>
              <img
                style={styles.main_img}
                src={value?.image || ""}
                alt="Card images"
              />
            </div>
          </div>
          <div style={styles.bottom_wrap}>
            {bottomArray?.map((item, index) => (
              <div style={styles.row} key={index}>
                <p
                  style={{
                    ...newKeyValue,
                    lineHeight:
                      item?.bold !== 1 && isLargeArray ? "1.06" : "1.2",
                  }}
                >
                  {item?.bold === 1 ? (
                    <b
                      style={{
                        fontSize: item?.text?.length >= 12 ? 6.8 : "inherit",
                        fontWeight: "600",
                      }}
                    >
                      {renderText(item?.text, item?.key_font_case)}
                    </b>
                  ) : (
                    renderText(item?.text, item?.key_font_case)
                  )}
                </p>
                <p
                  style={{
                    ...styles.separate,
                    lineHeight:
                      item?.bold !== 1 && isLargeArray ? "1.06" : "1.2",
                  }}
                >
                  :
                </p>
                <p
                  style={{
                    ...styles.value,
                    lineHeight:
                      item?.bold !== 1 && isLargeArray ? "1.06" : "1.2",
                  }}
                >
                  {item?.bold === 1 ? (
                    <b
                      style={{
                        fontSize: item?.text?.length >= 12 ? 6.8 : "inherit",
                        fontWeight: "600",
                      }}
                    >
                      {renderText(
                        checkSyleCode(item?.value),
                        item?.value_font_case
                      )}
                    </b>
                  ) : (
                    renderText(
                      checkSyleCode(item?.value),
                      item?.value_font_case
                    )
                  )}
                </p>
              </div>
            ))}
          </div>
        </div>
        {!!value?.kalyan_footer_text && (
          <div style={styles.footer_text}>
            https://certificates.kalyanjewellers.net/
          </div>
        )}
        <div style={styles.footer_wrap}>
          {parseInt(value?.footer_type) !== 2 ? (
            <div style={styles.barcode_wrap}>
              {value?.certificate_number && (
                <Barcode
                  value={value?.certificate_number}
                  height={15}
                  width={0.5}
                  displayValue={false}
                  margin={0}
                />
              )}
            </div>
          ) : (
            <img
              style={styles.footer_img}
              src={value?.front_foot_image || ""}
              alt="Card footer"
            />
          )}
        </div>
      </div>
    </>
  );
};

const styles = {
  outer_wrapper: {
    width: 321,
    minWidth: 321,
    maxWidth: 321,
    height: 207,
    maxHeight: 207,
    minHeight: 207,
    border: "1px solid #f3f3f3",
    color: "#000000",
    overflow: "hidden",
    fontFamily: "'Graphik',san-serif",
    backgroundColor: "#ffffff",
    position: "relative",
  },
  header_wrap: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
    height: 47.24,
    // height : '1.2cm'
    width: "100%",
    position: "relative",
  },
  header_img: {
    maxWidth: 321,
    width: "100%",
    maxHeight: 47.24,
    height: 47.24,
  },
  qr_wrap: {
    width: 39.37,
    height: 39.37,
    display: "inline-block",
    position: "absolute",
    top: 2,
    right: 7,
  },
  qr_code: {
    width: 39.37,
    height: 39.37,
  },
  footer_wrap: {
    height: 15,
    // height : '0.8cm'
    width: "100%",
    display: "flex",
    position: "relative",
  },
  footer_img: {
    width: "100%",
    maxHeight: 15,
    height: 15,
  },
  body: {
    height: 141.76,
    maxHeight: 141.76,
    padding: "1px 7px",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  middle_wrap: {
    display: "flex",
    padding: "1px 0",
    // alignItems : "center",
    // flex :"1",
  },
  row: {
    display: "flex",
    padding: "0.8px 0px",
  },
  key: {
    width: 73,
    fontSize: 7.8,
    fontWeight: 400,
    lineHeight: "1.1",
    marginBottom: 0,
  },
  value: {
    flex: "1",
    verticalAlign: "top",
    fontWeight: 400,
    lineHeight: "1.1",
    marginBottom: 0,
    fontSize: 7.8,
  },
  separate: {
    width: 10,
    paddingRight: 4,
    paddingLeft: 4,
    fontWeight: 400,
    fontSize: 7.8,
    lineHeight: "1.1",
    marginBottom: 0,
    position: "relative",
    top: -1,
  },
  content_wrap: {
    flex: "1",
  },
  img_wrap: {
    width: 90,
    marginRight: 4,
  },
  main_img: {
    width: "100%",
    maxWidth: 90,
    height: 60,
    maxHeight: 60,
  },
  barcode_wrap: {
    textAlign: "center",
    backgroundColor: "#ffffff",
    lineHeight: 0,
    width: "100%",
  },
  footer_text: {
    textAlign: "center",
    width: "100%",
    position: "absolute",
    left: 0,
    bottom: 15,
  },
  bg_image: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
};
