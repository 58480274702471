import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Master/ItemGrading/itemGradingSlice";
import { useGetItemGradingBasicDataQuery } from "../../../../store/queries/Master";
import * as Yup from "yup";
import { updateItemGradingData } from "../api";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const useAddItemGrading = ({ refetch }) => {
  const dispatch = useDispatch();
  const { data: basicData = {} } = useGetItemGradingBasicDataQuery();
  const queryState = useSelector((state) => state.itemGrading);

  useEffect(() => {
    const result = basicData?.data?.filter(
      (item) => queryState?.itemData?.type === item?.id
    );
    if (queryState?.isEdit && queryState?.itemData) {
      formik?.setFieldValue("name", queryState?.itemData?.name);
      formik?.setFieldValue("type", result?.[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryState?.isEdit, queryState?.itemData]);

  const validation = Yup.object({
    name: Yup.string()
      .trim()
      .required("Enter Name")
      .max(50, "Enter Valid Name"),
    type: Yup.object().required("Please select at least one value"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      type: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      let data = {
        name: values?.name,
        type: values?.type?.id,
        item_grading_id: "",
      };
      if (queryState?.isEdit) {
        data = {
          name: values?.name,
          type: values?.type?.id,
          item_grading_id: queryState?.selectedItem,
        };
      }
      updateItemGradingData(data).then((response) => {
        if (response?.data?.status_code === 200) {
          handleCloseModal();
          toast.success(
            queryState?.isEdit ? "Successfully Edited " : "Successfully Added"
          );
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
          refetch();
        } else {
          toast.error("Something went wrong");
        }
      });
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(updateConfig((state) => (state.showAddItemGradingModal = false)));
  };
  return {
    formik,
    basicData: basicData?.data,
    queryState,
    getFieldError,
    handleCloseModal,
  };
};

export default useAddItemGrading;
