import {
  Button,
  ConfirmationBox,
  DatePickerWithInput,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useOrder from "./useOrder";
import { FaSort } from "react-icons/fa";
import AddOrder from "./AddOrder";
import OrderColumn from "../../Global/OrderColumn";
import StockReceipt from "./StockInvoice/StockReceipt";
import AddShop from "./AddShop";
import Filter from "./Filter";

const Order = () => {
  const {
    date,
    handleDateChange,
    // isLoading,
    ordersList,
    tableFields,
    isFetching,
    orderState,
    paginationOptions,
    showEditModal,
    stockPrintRef,
    handleFilterClose,
    handleFilter,
    handleSearch,
    handleClearClick,
    handleEditClick,
    handlePagination,
    handlePageSize,
    refetch,
    handleAddShop,
    handleSort,
    handleCloseAddOrderModal,
    handleEditColumnsClick,
    handleAddOrderClick,
    handleDelete,
    handleDeleteModal,
    handleCloseAddShopModal,
    updateOrderFields,
    getRow,
    handlePrintClick,
  } = useOrder();

  return (
    <>
      <div className="pro-d-flex pro-justify-betwen pro-items-center">
        <HeadingGroup
          title={"Orders"}
          className={`pro-mb-4`}
          buttonTitle={ordersList?.create_permission ? "Add new" : ""}
          handleClick={handleAddOrderClick}
        />
{ordersList?.private_party_permission && (
  <button
          className="pro-btn pro-btn-outline-primary no-wrap"
          onClick={handleAddShop}
        >
          Add Private Party
        </button>
)}
        
      </div>

      <div className="col-auto pro-pt-3 pro-pb-6">
        <div className="row gx-2 gy-2 flex-fill">
          <div className={`col-auto`}>
            <div className="input-wrap">
              <input
                type="text"
                onChange={handleSearch}
                placeholder="Search this table"
                className={`pro-input icon-r lg`}
              />
              <span className="pro-icon">
                {<span className="material-symbols-outlined">search</span>}
              </span>
            </div>
          </div>
          <div className={`col-auto`}>
            <DatePickerWithInput
              CalendarOutlineIcon={
                <span className="material-symbols-outlined">
                  calendar_month
                </span>
              }
              range={true}
              initialValue={date}
              onDateChange={handleDateChange}
              datePickerProps={"this_year"}
            ></DatePickerWithInput>
          </div>
          <div className={`col-auto`}>
            <button
              className="pro-btn pro-btn-outline lg"
              onClick={handleEditColumnsClick}
            >
              Edit Columns
            </button>
          </div>
          <div className={`col-auto`}>
            <button
              className="pro-btn pro-btn-outline lg"
              onClick={handleFilter}
            >
              <span className="material-symbols-outlined">tune</span>
              Filters
            </button>
          </div>
          <div className={`col-auto`}>
            <Button
              className={"pro-btn-link lg pro-px-5"}
              onClick={handleClearClick}
            >
              Clear
            </Button>
          </div>
        </div>
        <Table
          multiSelect={false}
          data={ordersList?.data ?? []}
          uniqueID={"_id"}
          fields={tableFields}
          SortIcon={<FaSort />}
          clear={orderState.clearSelection}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={orderState?.currentPageSize}
          assignable={true}
          handleEdit={handleEditClick}
          handleDelete={handleDeleteModal}
          deletable={ordersList?.delete_permission}
          showCheckBox={true}
          editable={ordersList?.edit_permission}
          assignText={"Print"}
          assignIcon={<span className="material-symbols-outlined">print</span>}
          handleAssign={handlePrintClick}
        />

        {ordersList?.data?.length > 0 && (
          <Pagination
            currentPage={orderState?.currentPage}
            totalPageCount={Math.ceil(
              ordersList?.total_count / orderState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout
          show={orderState?.showDeleteModal}
          handleClose={handleDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure delete the selected item ?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteModal}
              submitText={"Yes"}
              submitAction={handleDelete}
              isRight={true}
            />
          </div>
        </ModalLayout>
        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={ordersList?.fields}
              moduleId={ordersList?.module_id}
              updateData={updateOrderFields}
            />
          </div>
        </ModalLayout>
        <div
          style={{
            position: "fixed",
            opacity: "0",
            visibility: "hidden",
            height: "0",
            width: "0",
          }}
        >
          <StockReceipt stockPrintRef={stockPrintRef} />
        </div>

        <OffCanvasLayout
          show={orderState?.addShopModal}
          handleClose={handleCloseAddShopModal}
          title={"Add Private Party"}
          closeicon={<span className="material-symbols-outlined"> close </span>}
        >
          <AddShop />
        </OffCanvasLayout>
        <OffCanvasLayout
          show={orderState?.showFilter}
          handleClose={handleFilterClose}
          title={"Filter"}
          closeicon={<span className="material-symbols-outlined"> close </span>}
        >
          <Filter />
        </OffCanvasLayout>

        <OffCanvasLayout
          show={orderState?.showAddOrderModal}
          handleClose={handleCloseAddOrderModal}
          title={orderState?.isEdit ? "Edit Order" : "Add Order"}
          closeicon={<span className="material-symbols-outlined"> close </span>}
        >
          <AddOrder selectedUser={""} refetch={refetch} />
        </OffCanvasLayout>
      </div>
    </>
  );
};
export default Order;
