import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useRecheckInvoices from "./useInvoices";
import { FaSort } from "react-icons/fa";
import EditForm from "./EditForm";
import InvoicePdf from "../RecheckInvoice/Print/InvoicePdf";
import ReceiptPdf from "../RecheckInvoice/Print/ReceiptPdf";
import Style from "../staffBilling.module.scss";

const RecheckInvoices = () => {
  const {
    paginationOptions,
    queryState,
    isFetching,
    queryList,
    tableFields,
    isLoading,
    invoiceRef2,
    receiptRef2,
    refetch,
    handleInvoiceClick,
    handleSlipClick,
    handleSearch,
    handleEditColumnsClick,
    handleSort,
    getRow,
    handleEditAction,
    handlePagination,
    handlePageSize,
  } = useRecheckInvoices();
  return (
    <div>
      <div>
        <HeadingGroup title={"Invoice History"} className={`pro-mb-4`} />
        <div className="col-auto pro-pt-3 pro-pb-6">
          <SearchFilters
            onSearchInput={handleSearch}
            showActions={true}
            handleActionClick={handleEditColumnsClick}
            loading={isLoading}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
            searchInputProps={{ value: queryState?.search }}
          />
          <Table
            multiSelect={false}
            data={queryList?.data?.data || []}
            uniqueID={"_id"}
            fields={tableFields}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">print</span>
            }
            assignIcon={
              <span className="material-symbols-outlined">print</span>
            }
            deleteText={"Slip"}
            assignText={"Invoice"}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={queryState?.currentPageSize}
            assignable={true}
            handleEdit={handleEditAction}
            handleAssign={handleInvoiceClick}
            handleDelete={handleSlipClick}
            deletable={true}
            showCheckBox={true}
          />
          {queryList?.data?.data?.length > 0 && (
            <Pagination
              currentPage={queryState?.currentPage}
              totalPageCount={Math.ceil(
                queryList.data.total_count / queryState.currentPageSize
              )}
              onPageChange={handlePagination}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          )}
          <ModalLayout
            show={queryState?.invoiceModal}
            handleClose={handleEditAction}
            title={"Edit"}
            closeIcon={<span className="material-symbols-outlined">close</span>}
          >
            <div className="pro-m-5">
              <EditForm refetch={refetch} />
            </div>
          </ModalLayout>

          <div className={Style.pdf_container}>
            <InvoicePdf invoiceRef={invoiceRef2} />
            <ReceiptPdf receiptRef={receiptRef2} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecheckInvoices;
