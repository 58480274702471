import { useEffect, useRef, useState } from "react";

const UseOrderCardListing = ({
  data,
  handleLoadMore,
  setIsLoading,
  totalLength,
}) => {
  let debounceTimeout;
  const [mouseHover, setMouseHover] = useState("");
  const scrollRefs = useRef([]);

  useEffect(() => {
    function isScrollAtEnd(element) {
      return (
        element?.scrollTop + element?.clientHeight + 1 >= element?.scrollHeight
      );
    }
    function handleScroll() {
      const element = scrollRefs.current[mouseHover];
      if (
        isScrollAtEnd(element) &&
        data?.[mouseHover]?.length !== totalLength?.[`${mouseHover}`]
      ) {
        setIsLoading(true);
        clearTimeout(debounceTimeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        debounceTimeout = setTimeout(() => {
          handleLoadMore(mouseHover);
        }, 1000);
      }
    }
    const element = scrollRefs.current[mouseHover];

    element?.addEventListener("scroll", handleScroll);

    return () => {
      element?.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollRefs, mouseHover, handleLoadMore]);

  const handleMouseOver = (e) => {
    setMouseHover(e);
  };
 
  return { scrollRefs, mouseHover, handleMouseOver };
};

export default UseOrderCardListing;
