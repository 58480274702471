import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentFormData: [],
  backendErrors: "",
  activeTab: "Collection",
  completedTabs: {
    Collection: false,
    Item: false,
    Service: false,
    engraving: false,
  },
  CompletedPercentage: {
    Collection: 0,
    Item: 0,
    Service: 0,
    engraving: 0,
  },
  isEditOrder: true,
  selectedOrder: "",
  orderStatus: "idle",
  itemTypeCategory: "",
};

const addOrderSlice = createSlice({
  name: "addOrder",
  initialState,
  reducers: {
    updateOrderData: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateOrderData } = addOrderSlice.actions;

export default addOrderSlice.reducer;
