import React from "react";
import Style from "./view.module.scss";
import Address from "./Components/Address";
import Services from "./Components/Services";
import Items from "./Components/Items";
import Engraving from "./Components/Engraving";
import useView from "./useView";

const View = () => {
  const { state } = useView();
  return (
    <div className={`col pro-pe-0 ${Style.table_main_wrapper}`}>
      <div className={`${Style.table_flow_wrapper} pro-pe-3`}>
        <div className={`row gx-3 ${Style.multicolumn_wrapper}`}>
          <div className="col-xxl-6">
            <Address state={state} />
          </div>
          <div className="col-xxl-6">
            <Services state={state} />
          </div>
        </div>
        <div className={`row gx-3 ${Style.multicolumn_wrapper} pro-mb-4`}>
          <div className="col-xxl-6">
            <Items state={state} />
          </div>
          <div className="col-xxl-6">
            {state?.pendingData?.is_engraving !== false ? (
              <Engraving state={state} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
