import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/EngravingSection/engravingSectionSlice";
import { approveCertificate, passAll } from "../api";
import { Button, IconText } from "@wac-ui-dashboard/wac_component_library";
import { saveAs } from "file-saver";
import Style from "../engravingSection.module.scss";
import moment from "moment";
import { toast } from "react-toastify";
import { useGetEngravingCertificateListDataQuery } from "../../../../store/queries/Engraving";
import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";
import { useFormik } from "formik";

const useEngravingCertificate = () => {
  const engravingSectionState = useSelector((state) => state.engravingSection);
  const [imageData, setImageData] = useState();
  const [showImageModal, setShowImageModal] = useState(false);
  const slug = localStorage?.getItem("slug");

  const { passAllModal, globalRemarkModal } = useSelector(
    (state) => state.global
  );

  let paginationOptions = [
    { label: "20 Per page", value: 20 },
    { label: "40 Per page", value: 40 },
    { label: "60 Per page", value: 60 },
  ];
  const dispatch = useDispatch();

  const {
    data: certificateList,
    refetch,
    isFetching,
  } = useGetEngravingCertificateListDataQuery({
    page_size: engravingSectionState.certificate?.currentPageSize,
    page: engravingSectionState.certificate?.currentPage,
    sort_by: engravingSectionState.sortBy,
    sort_order: engravingSectionState.sortOrder,
    process_slug: slug,
    receipt_id: engravingSectionState.selectedCardId,
  });

  const disableMultiSelect = useMemo(
    () => certificateList?.data?.data.every((item) => item.disable === true),
    //eslint-disable-next-line
    [certificateList]
  );
  // useEffect(() => {
  //   if (engravingSectionState?.clear) {
  //     dispatch(updateConfig((state) => (state.clear = false)));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [engravingSectionState?.clear]);

  const formik = useFormik({
    initialValues: {
      remark: "",
    },
  });

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.certificate.currentPageSize = page_size;
        state.certificate.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.certificate.currentPage = page;
      })
    );
  };

  const handleRemarksClick = (data) => {
    dispatch(
      globalUpdateConfig((state) => {
        state.globalRemarkId = data?.id;
        state.globalRemarkModal = true;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      status: (_, data) =>
        data?.status === "Rejected" ? (
          <span className={"pro-badge badge-danger-outline pro-d-inline-block"}>
            {"Rejected"}
          </span>
        ) : data?.status === "Marked" ? (
          <span className={"outline-primary pro-badge pro-d-inline-block"}>
            {"Marked"}
          </span>
        ) : data?.status === "Completed" ? (
          <span className={"outline-success pro-badge pro-d-inline-block"}>
            {"Completed"}
          </span>
        ) : data?.status === "Pending" ? (
          <span className={"outline-warning pro-badge pro-d-inline-block"}>
            {"Pending"}
          </span>
        ) : (
          <span className={"outline pro-badge pro-d-inline-block"}>
            {data?.status}
          </span>
        ),
      engraving_logo: (feild, data) =>
        data?.[feild] ? (
          <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Photo"}`}
            onClick={() => handleViewImage(data?.[feild])}
          />
        ) : (
          ""
        ),
      engraving_text: (field, data) =>
        data?.[field] ? (
          <div className="pro-d-flex pro-items-center pro-gap-2 no-wrap">
            <p className="pro-mb-0">{data[field]}</p>
            <span
              className={Style.copy_text}
              onClick={() => handleCopyText(data[field])}
            >
              <span className="material-symbols-outlined">content_copy</span>
            </span>
          </div>
        ) : (
          ""
        ),
      action: (_, data) => (
        <p className="pro-mb-0">
          {
            <Button
              className={"pro-btn-primary"}
              onClick={() => handleRemarksClick(data)}
            >
              Remarks
            </Button>
          }
        </p>
      ),
      engraving_format: (field, data) =>
        data?.[field] ? (
          <div className="pro-d-flex pro-items-center pro-gap-2 no-wrap">
            <p className="pro-mb-0">{data[field]}</p>
            <span
              className={Style.copy_text}
              onClick={() => handleCopyFormat(data[field])}
            >
              <span className="material-symbols-outlined">content_copy</span>
            </span>
          </div>
        ) : (
          ""
        ),
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleViewImage = (logoImage) => {
    setImageData(logoImage);
    setShowImageModal(true);
  };
  const closeModal = () => {
    setShowImageModal(false);
  };

  const handleCopyText = async (data) => {
    try {
      await navigator.clipboard.writeText(data);
      toast.success("Copied");
    } catch (error) {
      toast.error("Failed to copy");
    }
  };

  const handleCopyFormat = async (data) => {
    try {
      await navigator.clipboard.writeText(data);
      toast.success("Copied");
    } catch (error) {
      toast.error("Failed to copy");
    }
  };

  const handleDownload = (e) => {
    saveAs(
      e,
      `${
        "Engraving-image" +
        "-" +
        moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
      }`
    );
  };

  const handleConfirmModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showAddEngravingSecModal = !state.showAddEngravingSecModal;
        state.selectedEngravingSection = e;
      })
    );
  };
  const handleConfirm = (values) => {
    let data = {
      item_details_id: engravingSectionState.selectedEngravingSection,
      status: values?.status ?? "",
      remarks: values?.remarks ?? "",
    };
    let newObj = Object.entries(data);
    newObj = newObj
      .filter(
        (item) => item[1] !== undefined && item[1] !== "" && item[1] !== null
      )
      .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
    approveCertificate(newObj).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Certificate approved successfully");
        handleConfirmModal();
        refetch();
        dispatch(updateConfig((state) => (state.clear = true)));
        const timeOut = setTimeout(() => {
          dispatch(updateConfig((state) => (state.clear = false)));
          clearTimeout(timeOut);
        }, 1000);

        // getEngravingCertificate({
        //   receipt_id: engravingSectionState.selectedCardId,
        // }).then((response) => {
        //   // const certificateList = response?.data;
        //   // setCertificateList(certificateList);
        // });
      } else {
        toast.error("Something went wrong");
        handleConfirmModal();
      }
    });
  };

  const handleModalClose = () => {
    dispatch(globalUpdateConfig((state) => (state.globalRemarkModal = false)));
  };

  const handlePassAllClick = () => {
    dispatch(
      globalUpdateConfig((state) => (state.passAllModal = !state.passAllModal))
    );
  };

  const handlePassAll = () => {
    let data = {
      stock_receipt_id: engravingSectionState?.selectedCardId,
      process_slug: slug,
      remarks: formik?.values?.remark,
    };
    passAll(data).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success(response?.data?.message);
        refetch();
        handlePassAllClick();
        dispatch(updateConfig((state) => (state.clear = true)));
        const timeOut = setTimeout(() => {
          dispatch(updateConfig((state) => (state.clear = false)));
          clearTimeout(timeOut);
        }, 1000);
      } else if (response?.status_code === 403) {
        toast.error(response?.message ?? "Error ");
      } else {
        toast.error(response?.data?.message);
      }
    });
  };

  return {
    paginationOptions,
    disableMultiSelect,
    engravingSectionState,
    imageData,
    showImageModal,
    isFetching,
    passAllModal,
    formik,
    certificateList,
    globalRemarkModal,
    handleModalClose,
    getRow,
    closeModal,
    handlePassAll,
    handleConfirm,
    handleDownload,
    handlePageSize,
    handlePagination,
    handleConfirmModal,
    handlePassAllClick,
  };
};

export default useEngravingCertificate;
