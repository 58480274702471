import React from "react";
import { useSelector } from "react-redux";

const Back = React.forwardRef(({ visitingOneBackPrintRef }, ref) => {
  const { printingData } = useSelector((state) => state.printing);
  return (
    <div
      ref={visitingOneBackPrintRef}
      style={{
        display: "flex",
        flexWrap: "wrap",
        columnGap: 70,
        rowGap: 16,
        paddingTop: 6,
        flexDirection: "row-reverse",
        // paddingRight: 40,
      }}
    >
      <title>DHC Card Back</title>
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <style
        dangerouslySetInnerHTML={{
          __html:
            "@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');\n",
        }}
      />

      {printingData?.map((value, index) => {
        return (
          <div
            key={index}
            style={{
              backgroundColor: "#ffffff",
              maxWidth: 321,
              minWidth: 321,
              width: 321,
              maxHeight: 207,
              minHeight: 207,
              height: 207,
              lineHeight: 0,
              border: "1px solid #f3f3f3",
              padding: 0,
              margin: 0,
              overflow : "hidden",
            }}
          >
            <img
              src={value?.back_image || ""}
              width={321}
              height={207}
              alt="back_image"
              style={{
                width : "100%",
                height : "100%",
                lineHeight: 0,
                padding : 0,
                margin : 0,
              }}
            />
          </div>
        );
      })}
    </div>
  );
});

export default Back;
