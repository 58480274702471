import React from "react";
import { useSelector } from "react-redux";

const Form1Print = React.forwardRef(({ form1PrintRef }, ref) => {
  const { printData, itemID } = useSelector((state) => state.cvd);
  // const currentDate = new Date();
  // const options = {
  //   day: "2-digit",
  //   month: "2-digit",
  //   year: "numeric",
  //   timeZone: "Asia/Kolkata",
  // };
  // const formattedDate = currentDate.toLocaleDateString("en-IN", options);
  return (
    <div ref={form1PrintRef}>
      <table
        border={0}
        cellSpacing={0}
        role="presentation"
        cellPadding={0}
        style={{
          backgroundColor: "#ffffff",
          maxWidth: "364px",
          width: "100%",
          borderCollapse: "collapse",
          margin: "0 auto",
          // border: "1px solid #000000",
          fontSize: 8,
        }}
      >
        <tbody>
          <tr>
            <td>
              <table>
                <tbody>
                  <tr>
                    <td style={{ width: 48, paddingRight: 34 }}>
                      <img
                        src={printData?.branch?.logo}
                        alt="logo"
                        width={48}
                        height={48}
                        style={{
                          width: 48,
                          height: 48,
                          minWidth: 48,
                          maxWidth: 48,
                        }}
                      />
                    </td>
                    <td>
                      <table
                        align="center"
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        width="100%"
                        style={{
                          borderCollapse: "collapse",
                          backgroundColor: "#ffffff",
                          boxSizing: "border-box",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                fontSize: 15,
                                fontWeight: 800,
                                textAlign: "center",
                                lineHeight: "28px",
                              }}
                            >
                              {printData?.branch?.name}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontSize: 8,
                                fontWeight: 600,
                                textAlign: "center",
                              }}
                            >
                              {printData?.branch?.address?.address_line1}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontSize: 8,
                                fontWeight: 600,
                                textAlign: "center",
                                paddingBottom: 10,
                              }}
                            >
                              {printData?.branch?.address?.address_line2}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontSize: 8,
                                fontWeight: 600,
                                textAlign: "center",
                              }}
                            >
                              {printData?.branch?.country_code}-
                              {printData?.branch?.contact_number_1},
                              {printData?.branch?.contact_number_2 && (
                                <>
                                  {printData?.branch?.country_code}-
                                  {printData?.branch?.contact_number_2}
                                </>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td style={{ padding: "0px 105px 8px" }}>
              <table
                align="center"
                border={0}
                cellSpacing={0}
                role="presentation"
                cellPadding={0}
                width="100%"
                style={{
                  borderCollapse: "collapse",
                  backgroundColor: "#000000",
                  boxSizing: "border-box",
                  color: "#ffffff",
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                        fontSize: 8,
                        textTransform: "uppercase",
                        fontWeight: 600,
                        padding: "2px 0",
                      }}
                    >
                      CVD REPORT - {printData?.item_type?.toUpperCase()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td>
              <table
                border={0}
                cellSpacing={0}
                role="presentation"
                cellPadding={0}
                style={{
                  width: "100%",
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        paddingBottom: 4,
                      }}
                    >
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                      >
                        <tbody>
                          <tr>
                            <td>Date</td>
                            <td
                              style={{
                                width: 6,
                                padding: "2px 2px",
                                fontWeight: 500,
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                              }}
                            >
                              {printData?.date}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td
                      style={{
                        paddingBottom: 4,
                      }}
                    >
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                        style={{
                          marginLeft: "auto",
                          marginRight: 0,
                        }}
                      >
                        <tbody>
                          <tr>
                            <td>GLI</td>
                            <td
                              style={{
                                width: 6,
                                padding: "2px 2px",
                                fontWeight: 500,
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                              }}
                            >
                              {itemID}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td
                      colSpan={2}
                      style={{
                        verticalAlign: "top",
                        padding: "6px 0px",
                      }}
                    >
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                width: 72,
                                verticalAlign: "top",
                              }}
                            >
                              Supplier
                            </td>
                            <td
                              style={{
                                width: 10,
                                padding: "0px 4px",
                                fontWeight: 500,
                                verticalAlign: "top",
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                              }}
                            >
                              {printData?.supplier?.name} <br />
                              {printData?.supplier?.address?.address_line1},
                              {printData?.supplier?.address?.address_line2}
                              <br />
                              {printData?.supplier?.address?.city},
                              {printData?.supplier?.address?.state}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td
                      colSpan={2}
                      style={{
                        verticalAlign: "top",
                        padding: "6px 0px",
                      }}
                    >
                      <table
                        border={0}
                        cellSpacing={0}
                        role="presentation"
                        cellPadding={0}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                width: 72,
                                verticalAlign: "top",
                              }}
                            >
                              For the Shop
                            </td>
                            <td
                              style={{
                                width: 10,
                                padding: "0px 4px",
                                fontWeight: 500,
                                verticalAlign: "top",
                              }}
                            >
                              :
                            </td>
                            <td
                              style={{
                                fontWeight: 600,
                              }}
                            >
                              {printData?.shop?.name}
                              <br />
                              {printData?.shop?.address?.address_line1},
                              {printData?.shop?.address?.address_line2}
                              <br />
                              {printData?.shop?.address?.city},
                              {printData?.shop?.address?.state}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>

          <tr>
            <td
              style={{
                paddingTop: 10,
              }}
            >
              <table
                border={0}
                cellSpacing={0}
                role="presentation"
                cellPadding={0}
                style={{
                  width: "100%",
                }}
              >
                <tbody>
                  {printData?.data?.map((value, i) => (
                    <tr key={i}>
                      <td>
                        <table
                          border={0}
                          cellSpacing={0}
                          role="presentation"
                          cellPadding={0}
                          style={{
                            width: "100%",
                            border: "0.5px solid #C8C8C8",
                            borderTop: i === 0 ? "0.5px solid #C8C8C8" : 0,
                          }}
                        >
                          {i === 0 && (
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    padding: "3.5px 5px",
                                    fontSize: 7,
                                    borderRight: "0.5px solid #C8C8C8",
                                    borderBottom: "0.5px solid #C8C8C8",
                                    fontWeight: 500,
                                    backgroundColor: "#FAFAFA",
                                    textAlign: "left",
                                    width: 65,
                                  }}
                                >
                                  Item
                                </th>
                                <th
                                  style={{
                                    padding: "3.5px 5px",
                                    fontSize: 7,
                                    borderRight: "0.5px solid #C8C8C8",
                                    borderBottom: "0.5px solid #C8C8C8",
                                    fontWeight: 500,
                                    backgroundColor: "#FAFAFA",
                                    textAlign: "center",
                                    width: 64,
                                  }}
                                >
                                  Certification No.
                                </th>
                                <th
                                  style={{
                                    padding: "3.5px 5px",
                                    fontSize: 7,
                                    borderRight: "0.5px solid #C8C8C8",
                                    borderBottom: "0.5px solid #C8C8C8",
                                    fontWeight: 500,
                                    backgroundColor: "#FAFAFA",
                                    textAlign: "center",
                                    width: 45,
                                  }}
                                >
                                  Weight
                                </th>
                                <th
                                  style={{
                                    padding: "3.5px 5px",
                                    fontSize: 7,
                                    borderRight: "0.5px solid #C8C8C8",
                                    borderBottom: "0.5px solid #C8C8C8",
                                    fontWeight: 500,
                                    backgroundColor: "#FAFAFA",
                                    textAlign: "center",
                                    width: 31,
                                  }}
                                >
                                  D/No
                                </th>
                                <th
                                  style={{
                                    padding: "3.5px 5px",
                                    fontSize: 7,
                                    borderRight: "0.5px solid #C8C8C8",
                                    borderBottom: "0.5px solid #C8C8C8",
                                    fontWeight: 500,
                                    backgroundColor: "#FAFAFA",
                                    textAlign: "center",
                                    width: 46,
                                  }}
                                >
                                  GWT
                                </th>
                                <th
                                  style={{
                                    padding: "3.5px 5px",
                                    fontSize: 7,
                                    borderRight: "0.5px solid #C8C8C8",
                                    borderBottom: "0.5px solid #C8C8C8",
                                    fontWeight: 500,
                                    backgroundColor: "#FAFAFA",
                                    textAlign: "center",
                                    width: 40,
                                  }}
                                >
                                  D/Shape
                                </th>
                                <th
                                  style={{
                                    padding: "3.5px 5px",
                                    fontSize: 7,
                                    borderRight: "0.5px solid #C8C8C8",
                                    borderBottom: "0.5px solid #C8C8C8",
                                    fontWeight: 500,
                                    backgroundColor: "#FAFAFA",
                                    textAlign: "center",
                                    width: 43,
                                  }}
                                >
                                  RFT-D/No
                                </th>
                                <th
                                  style={{
                                    padding: "3.5px 5px",
                                    fontSize: 7,
                                    borderBottom: "0.5px solid #C8C8C8",
                                    fontWeight: 500,
                                    backgroundColor: "#FAFAFA",
                                    textAlign: "center",
                                    width: 32,
                                  }}
                                >
                                  %
                                </th>
                              </tr>
                            </thead>
                          )}
                          <tbody>
                            {value?.values?.map((val, i) => (
                              <tr key={i}>
                                <td
                                  style={{
                                    borderRight: "0.5px solid #C8C8C8",
                                    fontSize: 7,
                                    padding: "3.5px 5px",
                                    width: 65,
                                  }}
                                >
                                  {val?.item}
                                </td>
                                <td
                                  style={{
                                    borderRight: "0.5px solid #C8C8C8",
                                    fontSize: 7,
                                    padding: "3.5px 5px",
                                    width: 64,
                                  }}
                                >
                                  {val?.certificationNo}
                                </td>
                                <td
                                  style={{
                                    borderRight: "0.5px solid #C8C8C8",
                                    fontSize: 7,
                                    padding: "3.5px 5px",
                                    textAlign: "center",
                                    width: 45,
                                  }}
                                >
                                  {val?.weight}
                                </td>
                                <td
                                  style={{
                                    borderRight: "0.5px solid #C8C8C8",
                                    fontSize: 7,
                                    padding: "3.5px 5px",
                                    textAlign: "center",
                                    width: 31,
                                  }}
                                >
                                  {val?.diamond_number}
                                </td>
                                <td
                                  style={{
                                    borderRight: "0.5px solid #C8C8C8",
                                    fontSize: 7,
                                    padding: "3.5px 5px",
                                    textAlign: "center",
                                    width: 46,
                                  }}
                                >
                                  {val?.grossweight}
                                </td>
                                <td
                                  style={{
                                    borderRight: "0.5px solid #C8C8C8",
                                    fontSize: 7,
                                    padding: "3.5px 5px",
                                    textAlign: "center",
                                    width: 40,
                                  }}
                                >
                                  {val?.shape}
                                </td>
                                <td
                                  style={{
                                    borderRight: "0.5px solid #C8C8C8",
                                    fontSize: 7,
                                    padding: "3.5px 5px",
                                    textAlign: "center",
                                    width: 43,
                                  }}
                                >
                                  {val?.rft_diamond_number}
                                </td>
                                <td
                                  style={{
                                    borderRight: "0.5px solid #C8C8C8",
                                    fontSize: 7,
                                    padding: "3.5px 5px",
                                    textAlign: "center",
                                    width: 32,
                                  }}
                                >
                                  {val?.percentage}
                                </td>
                              </tr>
                            ))}

                            <tr>
                              <td
                                colSpan={2}
                                style={{
                                  padding: "3.5px 5px",
                                  fontSize: 7,
                                  borderTop: "0.5px solid #C8C8C8",
                                  borderRight: "0.5px solid #C8C8C8",
                                  fontWeight: 700,
                                  textAlign: "center",
                                }}
                              >
                                Total
                              </td>
                              <td
                                style={{
                                  padding: "3.5px 5px",
                                  fontSize: 7,
                                  borderTop: "0.5px solid #C8C8C8",
                                  borderRight: "0.5px solid #C8C8C8",
                                  fontWeight: 700,
                                  textAlign: "center",
                                }}
                              ></td>
                              <td
                                style={{
                                  padding: "3.5px 5px",
                                  fontSize: 7,
                                  borderTop: "0.5px solid #C8C8C8",
                                  borderRight: "0.5px solid #C8C8C8",
                                  fontWeight: 700,
                                  textAlign: "center",
                                }}
                              >
                                {value?.total?.diamond_number}
                              </td>
                              <td
                                style={{
                                  padding: "3.5px 5px",
                                  fontSize: 7,
                                  borderTop: "0.5px solid #C8C8C8",
                                  borderRight: "0.5px solid #C8C8C8",
                                  fontWeight: 700,
                                  textAlign: "center",
                                }}
                              >
                                {value?.total?.grossweight}
                              </td>
                              <td
                                style={{
                                  padding: "3.5px 5px",
                                  fontSize: 7,
                                  borderTop: "0.5px solid #C8C8C8",
                                  borderRight: "0.5px solid #C8C8C8",
                                  fontWeight: 700,
                                  textAlign: "center",
                                }}
                              >
                                {value?.total?.shape}
                              </td>
                              <td
                                style={{
                                  padding: "3.5px 5px",
                                  fontSize: 7,
                                  borderTop: "0.5px solid #C8C8C8",
                                  borderRight: "0.5px solid #C8C8C8",
                                  fontWeight: 700,
                                  textAlign: "center",
                                }}
                              >
                                {value?.total?.rft_diamond_number}
                              </td>
                              <td
                                style={{
                                  padding: "3.5px 5px",
                                  fontSize: 7,
                                  borderTop: "0.5px solid #C8C8C8",
                                  fontWeight: 700,
                                  textAlign: "center",
                                }}
                              >
                                {value?.total?.percentage}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});

export default Form1Print;
