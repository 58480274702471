import React from "react";
import useSubCards from "./useSubCards";
import Style from "./subCards.module.scss";
const SubCards = () => {
  const { subCardsDetails } = useSubCards();
  return (
    <div className="pro-p-5">
      <div className={`pro-d-flex ${Style.wrapper}`}>
        <div className={`${Style.wrapper_child}`}>
          <p className="pro-mb-0">GLI No.</p>
        </div>
        <span className="pro-d-iniline-block pro-px-2 pro-pe-5 pro-lh-1"> : </span>
        <div className={`${Style.wrapper_child}`}>
          <p className="pro-ttl pro-mb-0">{subCardsDetails?.gli_number}</p>
        </div>
      </div>

      <div className={`pro-d-flex ${Style.wrapper}`}>
        <div className={`${Style.wrapper_child}`}>
          <p className=" pro-mb-0">Shop</p>
        </div>
        <span className="pro-d-iniline-block pro-px-2 pro-pe-5 pro-lh-1"> : </span>
        <div className={`${Style.wrapper_child}`}>
          <p className="pro-ttl pro-mb-0">{subCardsDetails?.shop}</p>
          <p className="pro-mb-0">{subCardsDetails?.shop_location}</p>
        </div>
      </div>

      <div className={`pro-d-flex ${Style.wrapper}`}>
        <div className={`${Style.wrapper_child}`}>
          <p className="pro-mb-0">Supplier</p>
        </div>
        <span className="pro-d-iniline-block pro-px-2 pro-pe-5 pro-lh-1"> : </span>
        <div className={`${Style.wrapper_child}`}>
          <p className="pro-mb-0 pro-ttl">{subCardsDetails?.supplier}</p>
          <p className="pro-mb-0">{subCardsDetails?.supplier_location}</p>
        </div>
      </div>

      <div className={`pro-d-flex ${Style.wrapper}`}>
        <div className={`${Style.wrapper_child}`}>
          <p className=" pro-mb-0">Item Type</p>
        </div>
        <span className="pro-d-iniline-block pro-px-2 pro-pe-5 pro-lh-1"> : </span>
        <div className={`${Style.wrapper_child}`}>
          <p className="pro-mb-0 pro-ttl">{subCardsDetails?.item_type}</p>
        </div>
      </div>
      <div className={`pro-d-flex ${Style.wrapper}`}>
        <div className={`${Style.wrapper_child}`}>
          <p className=" pro-mb-0">Remark</p>
        </div>
        <span className="pro-d-iniline-block pro-px-2 pro-pe-5 pro-lh-1"> : </span>
        <div className={`${Style.wrapper_child}`}>
          <p className="pro-mb-0 pro-ttl">{subCardsDetails?.remark}</p>
        </div>
      </div>
      {subCardsDetails?.service && (
        <div className={`${Style.wrapper} pro-pt-4`}>
          <h6 className="pro-ttl h6 pro-mb-0">Services</h6>
          <ul className="pro-mt-2">
            {subCardsDetails?.service?.map((value, index) => (
              <li key={index} className="pro-badge badge-grey">{value}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SubCards;
